/* CDN */
$cdn_base: "https://cdn.calltheone.com/public";

/* Colors */

$color-blue: #0CB5CF;
$color-light-blue: #41CDE2;
$color-lighter-blue: #C9F6FF;
$color-soft-blue: #A7B7D3;
$color-red: #CA4C84;
$color-really-red: #CF0C23;
$color-soft-red: #FFCCCC;
$color-violet: #FF4884;
$color-black: #333333;
$color-off-black: #1D1D1B;
$color-nero: #333333;
$color-darkest-grey: #4D4D4D;
$color-darker-grey: #706F6F;
$color-dark-grey: #878787;
$color-grey: #9D9D9C;
$color-light-grey: #888D91;
$color-lighter-grey: #818181;
$color-lightest-grey: #B1B1B1;
$color-close-white: #DADADA;
$color-closer-white: #EDEDED;
$color-off-white: #F9F9F8;
$color-white: #FFFFFF;
$color-xmas: #D5808C;
$color-online: #B7D45A;
$color-offline: #B3B3B3;
$color-yellow: #F6FD4D;

$color-green: #ACD137;
$color-yellow-green: #D4E652;
$color-darker-green: #697A21;
$color-pink: #E94B87;
$color-light-pink: #DBC0C3;
$color-bright-pink: #F8E1E3;
$color-bright-light-pink: #FFEBEC;
$color-bright-blue: #C9F6FF;
$color-primary: #C99FA1;
$color-teal: #9ADEDB;
$color-light-lavender: #E7E6ED;

$color-panel-bg: rgba(0, 0, 0, 0.88);
$color-panel-size-lg: 400px;
$color-panel-size-md: 350px;

$call-ui-labeled-height: 60px;

/**
the google / search dropdown colors are all different shades of grey
**/

/* famous colors */

$color-facebook: #39579A;
$color-twitter: #1DCAFF;
$color-linkedin: #0077B5;
$color-google: #ECEFF1;

//the light grey

/* Font Weights */

$font-weight-xthin: 100;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 800;
$font-weight-xblack: 900;
$loader-color: $color-nero;
$primary-text-color: $color-nero;
$primary-color: $color-light-pink;
$primary-active-color: #333;
$primary-highlight-color: $color-blue;
$secondary-color: $color-red;
$primary-bg-color: $color-white;
$secondary-bg-color: $color-off-white;
$border-color: #E5E5E5;
$dark-alpha: 0.85;
$alpha: 0.66666;
$light-alpha: 0.25;
$verylight-alpha: 0.1;
$color-warning: $color-really-red;

$header-height: 80px;
$navbar-height: 98px;
$navbar-xs-height: 54px;

//640x480
//800x600
//100x75
$video-width-4x3: 800px;
$video-height-4x3: 600px;
$video-width-4x3-small: 100px;
$video-height-4x3-small: 75px;

//1600x900
//800x450
//100x56
$video-width-16x9: 800px;
$video-height-16x9: 450px;
$video-width-16x9-small: 100px;
$video-height-16x9-small: 56px;
$video-width: $video-width-16x9;
$video-height: $video-height-16x9;
$video-width-small: $video-width-4x3-small;
$video-height-small: $video-height-4x3-small;
$capability-size: 71px;
$capability-size-mini: 20px;
$capability-size-midi: 32px;
$modal-footer-height: 65px;
$lookalong-modal-dialog-width: 340px;
$lookalong-modal-dialog-width-xs: 90%;
$lookalong-modal-image-width: 115px;
