/* breakpoints */

// Defining values
// beide kanten 20 pixels d'raf
// text inspring 10 pixels era
// 315 (kleinste versie)
// 375
// 730
// 1085
// 1576
$container-xs: 375px;
$container-sm: 720px;
$container-md: 1085px;
$container-lg: 1160px;
$screen-sm-min: 768px;
$screen-xs-max: 767px;
$screen-md-min: $container-md + 40px;
$screen-sm-max: $screen-md-min - 1;
$screen-lg-min: $container-lg + 40px;
$screen-md-max: $screen-lg-min - 1;

/* basic font styles */

$font-size-base: 14px;
$text-color: $primary-text-color;
$font-family-sans-serif: proxima;

/* basic coloring */

$brand-primary: $primary-color;
$input-group-addon-bg: $color-white;

//$input-border: transparent;
$legend-border-color: transparent;
$input-border-focus: $primary-color;
$btn-default-bg: transparent;

/* remove border radius */

$border-radius-large: 0px;
$border-radius-base: 0px;
$border-radius-small: 0px;
$modal-backdrop-bg: rgba($color-black, $dark-alpha);

$grid-gutter-width: 20px;
