/* 5th options */
%col-xs-5ths, .col-xs-5ths {
  @include make-xs-column(2.4);
}

%col-xs-offset-5ths, .col-xs-offset-5ths {
  @include make-xs-column-offset(2.4);
}

@media (min-width: $screen-sm-min) {
  %col-sm-5ths, .col-sm-5ths {
    @include make-sm-column(2.4);
  }

  %col-sm-offset-5ths, .col-sm-offset-5ths {
    @include make-sm-column-offset(2.4);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-5ths, .col-md-5ths {
    @include make-md-column(2.4);
  }

  %col-md-offset-5ths, .col-md-offset-5ths {
    @include make-md-column-offset(2.4);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-5ths, .col-lg-5ths {
    @include make-lg-column(2.4);
  }

  %col-lg-offset-5ths, .col-lg-offset-5ths {
    @include make-lg-column-offset(2.4);
  }
}

%col-xs-2-5ths, .col-xs-2-5ths {
  @include make-xs-column(4.8);
}

@media (min-width: $screen-sm-min) {
  %col-sm-2-5ths, .col-sm-2-5ths {
    @include make-sm-column(4.8);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-2-5ths, .col-md-2-5ths {
    @include make-md-column(4.8);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-2-5ths, .col-lg-2-5ths {
    @include make-lg-column(4.8);
  }
}

%col-xs-3-5ths, .col-xs-3-5ths {
  @include make-xs-column(7.2);
}

@media (min-width: $screen-sm-min) {
  %col-sm-3-5ths, .col-sm-3-5ths {
    @include make-sm-column(7.2);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-3-5ths, .col-md-3-5ths {
    @include make-md-column(7.2);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-3-5ths, .col-lg-3-5ths {
    @include make-lg-column(7.15);
  }
}

%col-xs-4-5ths, .col-xs-4-5ths {
  @include make-xs-column(9.6);
}

@media (min-width: $screen-sm-min) {
  %col-sm-4-5ths, .col-sm-4-5ths {
    @include make-sm-column(9.6);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-4-5ths, .col-md-4-5ths {
    @include make-md-column(9.6);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-4-5ths, .col-lg-4-5ths {
    @include make-lg-column(9.6);
  }
}

/* 10th options */
%col-xs-10ths, .col-xs-10ths {
  @include make-xs-column(1.2);
}

@media (min-width: $screen-sm-min) {
  %col-sm-10ths, .col-sm-10ths {
    @include make-sm-column(1.2);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-10ths, .col-md-10ths {
    @include make-md-column(1.2);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-10ths, .col-lg-10ths {
    @include make-lg-column(1.2);
  }
}

%col-xs-2-10ths, .col-xs-2-10ths {
  @include make-xs-column(2.4);
}

@media (min-width: $screen-sm-min) {
  %col-sm-2-10ths, .col-sm-2-10ths {
    @include make-sm-column(2.4);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-2-10ths, .col-md-2-10ths {
    @include make-md-column(2.4);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-2-10ths, .col-lg-2-10ths {
    @include make-lg-column(2.4);
  }
}

%col-xs-3-10ths, .col-xs-3-10ths {
  @include make-xs-column(3.6);
}

@media (min-width: $screen-sm-min) {
  %col-sm-3-10ths, .col-sm-3-10ths {
    @include make-sm-column(3.6);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-3-10ths, .col-md-3-10ths {
    @include make-md-column(3.6);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-3-10ths, .col-lg-3-10ths {
    @include make-lg-column(3.6);
  }
}

%col-xs-4-10ths, .col-xs-4-10ths {
  @include make-xs-column(4.8);
}

@media (min-width: $screen-sm-min) {
  %col-sm-4-10ths, .col-sm-4-10ths {
    @include make-sm-column(4.8);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-4-10ths, .col-md-4-10ths {
    @include make-md-column(4.8);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-4-10ths, .col-lg-4-10ths {
    @include make-lg-column(4.8);
  }
}

%col-xs-5-10ths, .col-xs-5-10ths {
  @include make-xs-column(6);
}

@media (min-width: $screen-sm-min) {
  %col-sm-5-10ths, .col-sm-5-10ths {
    @include make-sm-column(6);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-5-10ths, .col-md-5-10ths {
    @include make-md-column(6);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-5-10ths, .col-lg-5-10ths {
    @include make-lg-column(6);
  }
}

%col-xs-6-10ths, .col-xs-6-10ths {
  @include make-xs-column(7.2);
}

@media (min-width: $screen-sm-min) {
  %col-sm-6-10ths, .col-sm-6-10ths {
    @include make-sm-column(7.2);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-6-10ths, .col-md-6-10ths {
    @include make-md-column(7.2);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-6-10ths, .col-lg-6-10ths {
    @include make-lg-column(7.2);
  }
}

%col-xs-7-10ths, .col-xs-7-10ths {
  @include make-xs-column(8.4);
}

@media (min-width: $screen-sm-min) {
  %col-sm-7-10ths, .col-sm-7-10ths {
    @include make-sm-column(8.4);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-7-10ths, .col-md-7-10ths {
    @include make-md-column(8.4);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-7-10ths, .col-lg-7-10ths {
    @include make-lg-column(8.4);
  }
}

%col-xs-8-10ths, .col-xs-8-10ths {
  @include make-xs-column(9.6);
}

@media (min-width: $screen-sm-min) {
  %col-sm-8-10ths, .col-sm-8-10ths {
    @include make-sm-column(9.6);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-8-10ths, .col-md-8-10ths {
    @include make-md-column(9.6);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-8-10ths, .col-lg-8-10ths {
    @include make-lg-column(9.6);
  }
}

%col-xs-9-10ths, .col-xs-9-10ths {
  @include make-xs-column(10.8);
}

@media (min-width: $screen-sm-min) {
  %col-sm-9-10ths, .col-sm-9-10ths {
    @include make-sm-column(10.8);
  }
}

@media (min-width: $screen-md-min) {
  %col-md-9-10ths, .col-md-9-10ths {
    @include make-md-column(10.8);
  }
}

@media (min-width: $screen-lg-min) {
  %col-lg-9-10ths, .col-lg-9-10ths {
    @include make-lg-column(10.8);
  }
}
