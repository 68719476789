@mixin make-container {
  @include container-fixed;

  @media (max-width: $screen-xs-max) {
    max-width: 375px;
    width: 100%;
  }

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }

  @media (min-width: $screen-md-min) {
    width: $container-md;
  }

  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.container {
  @include when-xs {
    width: 100%;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
}

ol,
ul,
li, p {
  line-height: 1.5;
  font-size: 14px;

  @include when-md {
    font-size: 20px;
  }
}

.modal {
  z-index: 999999 !important;

  // position: absolute;
}

// p {
//   font-size: 20px;
//   line-height: 26px;
// }

.pink {
  color: #DBC0C3;
}

.bg_pink {
  background: #DBC0C3;
}

.bg_black {
  background: $color-nero;
}

.p-100 {
  padding: 100px 0px;
}

.p-t-100 {
  padding-top: 100px;
}

.p-125 {
  padding: 125px 0px;
}

.p-t-125 {
  padding-top: 125px;
}

.lg-text {
  h2 {
    font-size: 172px !important;
    text-transform: uppercase;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
    line-height: 150px;
    justify-content: center;

    @extend .flex-class;
  }
}

.home {
  .lg-text {
    h2 {
      font-size: 222px !important;
      text-transform: uppercase;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      line-height: 193px;
      justify-content: center;

      @extend .flex-class;
    }
  }

  &.size-md {
    h2 {
      font-size: 172px;
      line-height: 150px;
    }
  }
}

.wide {
  line-height: 1.6em !important;
}

.txt-white {
  color: $color-white;
}

.bg-grey {
  background: #E7E6EE;
}

.uppercase {
  text-transform: uppercase;
}

.btn-cust {
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
  letter-spacing: 1px;
  line-height: 20px;
  font-size: 20px;

  @extend .uppercase;

  background: $color-nero;

  .fa {
    text-align: right;
    float: right;
    right: 10px;
    position: relative;
    -webkit-text-stroke: 2px $color-nero;
    -moz-text-stroke: 2px $color-nero;
    -ms-text-stroke: 2px $color-nero;
    top: -1px;
  }
}

.fooled-text {
  display: none;
}

.flex-area {
  display: flex;
  display: -webkit-box;

  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;

  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;

  /* TWEENER - IE 10 */
  display: -webkit-flex;
  align-items: center;
}

.sm-text {
  color: $color-light-grey;
  font-size: 22px;
  text-transform: uppercase;
  font-family: "proxima", "Helvetica", "Arial", sans-serif;
  font-weight: $font-weight-black !important;
  letter-spacing: 1px;

  &.size-md {
    font-size: 34px !important;
    line-height: 32px;
  }
}

.black-stroke {
  -webkit-text-stroke: 3px $color-nero;
  -moz-text-stroke: 3px $color-nero;
  -ms-text-stroke: 3px $color-nero;
  text-stroke: 3px $color-nero;
}

.grey-straok {
  -webkit-text-stroke: 3px #E7E6EE;
  -moz-text-stroke: 3px #E7E6EE;
  -ms-text-stroke: 3px #E7E6EE;
  text-stroke: 3px #E7E6EE;
}

.white-stroke {
  -webkit-text-stroke: 3px $color-white;
  -moz-text-stroke: 3px $color-white;
  -ms-text-stroke: 3px $color-white;
  text-stroke: 3px $color-white;
}

.flex-class {
  display: flex;
  display: -webkit-box;

  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;

  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;

  /* TWEENER - IE 10 */
  display: -webkit-flex;
}

.bold-txt {
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
  letter-spacing: 1px;
  line-height: 20px;
  font-size: 20px;
}

.blog-center {
  margin: 0 auto;
  float: none;
}

.text-btn {
  @extend .bold-txt;

  background: $color-nero;
  color: $color-white;
  padding-left: 40px;

  @extend .flex-class;

  position: relative;
  align-items: center;

  .fa {
    text-align: right;
    float: right;
    right: 10px;
    position: relative;

    @extend .black-stroke;

    flex: 1;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background: $color-white;
    left: 148px;
    top: 7px;
    right: 30px;
    opacity: 0.8;
    right: 30px;
    top: 50%;
    transform: translate(0%, -50%);
  }
}

.border {
  position: relative;
  width: 100%;
  height: 10px;

  &:before {
    content: "";
    color: #E6E6E6;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 2px;
    background-image: cdn("/images/border.png");
    background-position: top;
    background-repeat: repeat-x;
    top: 7px;
  }
}

.border-after {
  position: relative;
  width: 100%;
  height: 10px;

  &:after {
    content: "";
    color: #E6E6E6;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 2px;
    background-image: cdn("/images/border.png");
    background-position: top;
    background-repeat: repeat-x;
    top: 7px;
  }
}

.bootstrap-select {
  .dropdown-toggle {
    border: none;

    .bs-caret {
      display: none;
    }
  }
}

[data-to-root] .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  outline: 0px auto -webkit-focus-ring-color !important;
  outline-offset: 0px;
}

/*=====  End of masonry Page  ======*/

a {
  text-decoration: none !important;
}

.p-b-30 {
  padding-bottom: 30px;
}

.show-responsive {
  display: none !important;
}

@media only screen and (max-width: 1100px) {
  #intro-background #intro-background-items .item:nth-child(1) img {
    width: 100% !important;
  }

  .container.max-width-mds {
    div {}
  }

  .about-container {
    .befor-earn-section {
      .flex-area {
        flex-direction: column;

        .col-md-4 {
          order: 2;
          text-align: left;

          /* margin: 0 auto; */
          float: left;
          width: 66.6666666667%;

          p {
            text-align: left;

            /* margin: 0 auto; */
            float: left !important;
            width: 100% !important;
          }
        }

        .col-md-8 {
          text-align: left;

          /* margin: 0 auto; */
          float: left;
        }
      }
    }
  }
}

@media only screen and (max-width: 1410px) {
  .navbar-header .nav-bar-search-wrapper .form-control {}

  .show-responsive {
    display: block !important;
  }

  .show-desktop {
    display: none;
  }

  #app-layout.home .inner.fixed {
    .container {}
  }
}

.inner {
  .navbar-fixed-side-right {}
}

@media only screen and (max-width: 1085px) {
  #sidebar {
    top: auto;
    position: relative;
    margin-bottom: 30px;
  }

  #nav-holder {
    .navbar-right li {
      a {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .menu-sidebar {
    top: 56px;
  }

  body {
    .settings-menu .logout a p {
      display: block;
      top: 60px !important;
      left: 0 !important;
    }

    &#app-layout {
      #mySidenav {
        .settings-menu .log-out-btn {
          margin-top: 50px !important;

          .profile-save-btn {
            font-size: 14px !important;
            min-width: 100px !important;

            a {
              font-size: 14px !important;
            }
          }
        }
      }
    }

    .input-group-addon {
      .search-icon {}
    }

    .search-page .map-search.map li {
      font-size: 14px !important;
    }

    .get-fooled-section .col-md-7 {
      margin-top: -62px;
    }

    .use-us-section .content p {
      width: 100%;
      margin: 0 auto;
    }

    .hamburger-btn {
      display: block;
    }

    .navbar li.profile .avatar {}

    .smartsearch {}

    .flex-container {
      .smartsearch {
        top: 55px !important;
      }
    }

    #nav-holder .navbar-right .dropdown-menu {
      a {}
    }

    .container-intro {
      .container > .row {
        padding: 0 25px !important;
        bottom: 25px !important;

        .col-lg-12 {
          height: calc(100vw - 111px) !important;
        }
      }

      .navbar-left.input-location {
        margin-right: 0;
      }

      .flex-right small {
        right: 27px;
      }

      .flex-container {
        margin-left: 28px;
      }
    }

    &#app-layout {
      #nav-holder {
        .navbar-right {
          .dropdown {
            &.show-responsive {
              .dropdown-menu {
                margin-top: 1px !important;
                background: #ffff;

                li {
                  margin: 0;
                  text-align: left;
                  padding-bottom: 0 !important;
                  margin-left: 0;

                  img {
                    width: 80px;
                    height: auto;
                  }

                  &:last-child {
                    a {
                      border: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .use-us-section .lg-text h2 .inform {
        top: 32px !important;
      }

      &.home {
        .lg-text {
          h2 {
            font-size: 116px !important;
            line-height: 100px;
          }
        }

        .banner {
          position: relative;
          z-index: 9999;
          width: 100%;
          min-height: 100vh;
          height: 100vh;
          max-height: 100vh;
        }

        .container-intro {
          h1 {
            font-size: 76px !important;
            line-height: 65px;
            top: 15px;

            .indo {
              font-size: 68px !important;
            }
          }

          small {
            font-size: 13px;
            line-height: 12px;
          }

          h2 {
            font-size: 23px;
            line-height: 22px;
          }

          .content {
            top: 60px;
          }

          .flex-right small {
            top: -12px;
            right: 25px;
          }
        }

        .we-are-the-one {
          margin-top: 70px !important;

          p {
            font-size: 14px;
            line-height: 18px;
            width: 680px;
          }

          .video-container {
            .right-text {
              right: -34px;
              width: 36px;
              font-size: 12px;
            }
          }

          .title {
            h2 {
              font-size: 23px;
              line-height: 21px;
            }
          }

          .video-container {
            .video-box {
              max-width: 448px;
              height: 292px;

              &:after {
                position: absolute;
                content: '';
                width: calc(100% + 34px);
                margin-left: 174px;
              }
            }

            .left-text {
              font-size: 116px;
              line-height: 112px;
            }

            .video-box-videos {
              margin-left: 174px;
            }
          }

          .action-btns {
            max-width: 448px;
            margin-left: 174px;
            left: 0px;

            .action-btn {
              font-size: 13px;
              line-height: 11px;
            }

            img {
              width: auto !important;
              height: 43px !important;
            }

            .action-btn.join {
              img {
                height: 70px !important;
              }
            }
          }
        }

        .everyone-section {
          .slider {
            margin-top: 50px;
            position: relative;

            .money-container {
              left: -94px;
              bottom: 56px;

              .btn-cust {
                padding: 10px;
                font-size: 13px;
                line-height: 11px;

                .center {
                  font-size: 81px;
                  line-height: 71px;
                }

                .sm-text {
                  font-size: 13px;
                  line-height: 11px;

                  .line {
                    top: 13px;
                  }
                }
              }
            }

            .easy-to-earn {
              width: 28px;

              p {
                font-size: 13px;
                line-height: 11px;
                right: -7px;
              }
            }
          }
        }

        .one-platform-container,
        .community {
          padding-right: 0px !important;
          position: relative;

          .img-container {
            background-size: auto !important;
            height: 657px !important;
            width: 450px !important;
            background-image: cdn("/images/home/asset1.png");

            .text-container {
              position: absolute;
              right: 0;
              left: 360px;
              top: 122px;

              .line-title {
                font-size: 13px;
                line-height: 11px;

                &:after {
                  bottom: 2px;
                  left: 176px;
                }
              }
            }

            .content {
              width: 180px;
              margin-left: 146px;
              padding-top: 20px;
            }
          }

          .lg-text h2 {
            justify-content: left;
          }

          .try-btn {
            width: 175px;
            height: 60px;
            text-align: left;
            margin-top: 30px;

            div {
              line-height: 11px;
              font-size: 13px;
              margin-left: 10px;

              .fa {
                -webkit-text-stroke: 1px $color-nero;
                -moz-text-stroke: 1px $color-nero;
                -ms-text-stroke: 1px $color-nero;
                opacity: 0.8;
              }

              &:after {
                background: #FFFFFF;
                width: 47px;
                top: 4px;
                right: 20px !important;
                opacity: 0.5;
                right: 30px;
                opacity: 0.5;
                left: auto;
              }
            }
          }
        }

        .community {
          .community-wrapper {
            width: 450px !important;

            .text-container {
              margin-right: -216px;
            }

            .content {
              width: 180px;
              margin-left: 146px;
            }
          }

          .title-wrapper {
            h3 {
              font-size: 46px;
            }
          }
        }

        .get-fooled-section {
          .bg-grey {
            padding: 0 25px;
          }

          .lg-text {
            top: -53px;
            position: relative;
          }

          .col-md-5 {
            width: 235px;

            img {
              height: auto;
              width: 232px;
              top: 5px;
              position: relative;
            }
          }

          .col-md-7 {
            width: calc(100% - 235px);

            .get-fooled-section-inner-text {
              font-size: 16px;
              line-height: 18px;
              margin-top: 10px;
              margin-bottom: 0px;
            }

            .input-location {
              margin-top: 16px;
            }
          }
        }

        .live-section,
        .everyone-section,
        .get-fooled-section,
        .embed-code-section {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }

      .live-section {
        .img-container {
          width: auto;
          height: 370px;

          .how-work-container {
            height: 49px;

            .btn {
              line-height: 11px;
              font-size: 13px;
              width: 130px;
              height: 50px;
              padding-top: 4px;
              margin-left: 47px;

              .fa {
                -webkit-text-stroke: 1px $color-nero;
                -moz-text-stroke: 1px $color-nero;
                -ms-text-stroke: 1px $color-nero;
                right: 5px;
              }
            }
          }

          .right-text {
            margin-top: 58px;

            .img-text {
              margin-right: 30px;
            }

            .sm-text {
              margin-right: 25px;
            }
          }
        }

        p {
          margin-top: 0px;
        }
      }

      .search-page .map-img .lg-text h2 {
        font-size: 100px !important;
        line-height: 100px;
        margin: 0;
      }

      .search-page .map-img .btn {
        width: calc(100% - 10px);
      }

      .navbar.inner,
      .banner-header {
        .navbar-brand img {
          height: 18px;
          width: auto;
        }

        .navbar-header {
          .navbar-left {
            .nav-bar-search-wrapper {}

            &.app-link {
              width: 200px !important;
            }
          }

          .nav-bar-search-wrapper {
            .form-control {}

            .fa-search {
              font-size: 22px;
              line-height: 22px;
              -webkit-text-stroke: 0px #FFFFFF;
              -moz-text-stroke: 0px #FFFFFF;
              -ms-text-stroke: 0px #FFFFFF;
            }
          }
        }

        #nav-holder {
          .navbar-right {
            li {
              .user-balance {
                .main-currency {
                  font-size: 10px;
                }
              }
            }
          }
        }

        .navbar-nav.navbar-left {
          li {
            a {
              padding-bottom: 12px !important;
            }
          }
        }
      }

      .navbar-left.input-location {}

      .navbar-left img {
        height: 18px !important;
        width: auto !important;
      }

      .container-intro,
      .get-fooled-section {
        .input-location .form-holder .nav-bar-search-wrapper {}

        .form-text {
          background: $color-nero;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          color: #FFFFFF;
          font-size: 13px;
          letter-spacing: 1px;
          padding-top: 4px;
          height: 24px;
        }
      }

      .title h2 {
        font-size: 23px;
        line-height: 28px;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
      }
    }

    ul,
    ol,
    li,
    p {
      font-size: 14px;
      line-height: 18px;
    }

    .everyone-section {
      .sign-up-btn {
        width: 127px;
        height: 24px;
        margin: 20px auto auto auto;

        a {
          font-size: 13px;
          line-height: 11px;
          padding-top: 2px;
        }
      }
    }

    .p-t-125 {
      padding-top: 75px;
    }

    .p-b-125 {
      padding-bottom: 75px;
    }

    .p-125 {
      padding: 125px 0px;
    }

    .p-100 {
      padding: 50px 0px;
    }

    .p-b-100 {
      padding-bottom: 75px;
    }

    .use-us-section {
      .lg-text {
        h2 {
          .btn-cust {
            top: 18px;
            left: -34px;
            min-width: 65px;
            height: 21px;
            padding-top: 6px;
            color: #FFFFFF;
            font-size: 13px;
            line-height: 11px;
          }

          .right-txt {
            .req {
              div {
                line-height: 11px;
                font-size: 13px;
                color: $color-nero;
                padding: 0;
                margin: 0;
                display: block;
                padding: 9px 10px 0;
                background: #E7E6EE;
                min-width: 129px;
                width: fit-content;
                height: 26px;
                cursor: pointer;

                .fa {
                  // right: 5px;
                  -webkit-text-stroke: 1px #E7E6EE;
                  -moz-text-stroke: 1px #E7E6EE;
                  -ms-text-stroke: 1px #E7E6EE;
                  text-stroke: 1px #E7E6EE;
                }
              }
            }
          }
        }
      }
    }

    .container-footer ul li a {
      font-size: 14px;
    }

    .how-its-work-section {
      .lg-text h2 {
        font-size: 58px !important;
        letter-spacing: 1px;
        line-height: 50px;
      }
    }
  }

  #app-layout {
    &.settings,
    &.profiles,
    &.requests {
      .settings-menu {
        font-size: 16px !important;
      }
    }

    &.profiles {
      #avatar-sidebar-holder,
      .content-wrap,
      .bottom-line {
        max-width: 320px;

        .launguage-details {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  #app-layout.profiles.guide #section-gallery {
    padding: 0 15px !important;
  }
}

@media (min-width: 1085px) and (max-width: 1399px) {
  [data-to-root] {
    .location-search-map {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 14px !important;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 14px !important;
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 14px !important;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        font-size: 14px !important;
      }
    }

    .input-group-addon {
      .search-icon {}
    }

    .search-page .map-search.map li {
      font-size: 16px !important;
    }

    .navbar li.profile .avatar {}

    .smartsearch {}

    .flex-container {
      .smartsearch {}
    }

    .use-us-section .content p {
      width: 100%;
      margin: 0 auto;
    }

    #nav-holder {
      .navbar-right {
        .dropdown {
          &.show-responsive {
            .dropdown-menu {
              margin-top: 1px !important;
              background: #ffff;

              li {
                margin: 0;
                text-align: left;
                padding-bottom: 0 !important;
                margin-left: 0;

                img {
                  width: 100px;
                  height: auto;
                }

                &:last-child {
                  a {
                    border: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    #sidebar,
    .menu-sidebar {
      top: 77px;
    }

    .how-its-work-section {
      .lg-text h2 {
        font-size: 116px !important;
        letter-spacing: 1px;
        line-height: 110px;
      }
    }

    &.home {
      .lg-text {
        h2 {
          font-size: 172px !important;
          line-height: 150px;
        }
      }

      .banner {
        position: relative;
        z-index: 9999;
        width: 100%;
        min-height: 100vh;
        height: 100vh;
        max-height: 100vh;
      }

      .container-intro {
        .flex-container {
          // margin-left: 40px;
      
        }

        h1 {
          font-size: 114px !important;
          line-height: 98px;

          .indo {
            font-size: 100px !important;
          }
        }

        .content {
          top: 80px;
        }

        .flex-right small {
          top: 31px;
          right: 25px;
        }

        .container > .row {
          padding: 0 30px;
        }

        .navbar-left.input-location {
          margin-right: 0;
        }
      }

      .we-are-the-one {
        .video-container {
          .video-box {
            max-width: 666px;
            height: 434px;

            &:after {
              position: absolute;
              content: '';
              width: calc(100% + 45px);
              margin-left: 238px;
            }
          }

          .left-text {
            font-size: 172px;
            line-height: 150px;
          }

          .right-text {
            font-size: 18px;
          }

          .video-box-videos {
            margin-left: 238px;
          }
        }

        .action-btns {
          max-width: 666px;
          margin-left: 238px;
          left: 0px;

          .action-btn img {
            width: auto !important;
            height: 60px !important;
          }

          .action-btn.join {
            img {
              height: 110px !important;
            }
          }
        }
      }

      .everyone-section {
        .slider {
          margin-top: 50px;
          position: relative;

          .money-container {
            left: -136px;

            .btn-cust {
              padding: 15px;
              font-size: 18px;
              line-height: 16px;

              .center {
                font-size: 120px;
                line-height: 105px;
              }

              .sm-text {
                font-size: 18px;
                line-height: 16px;
              }
            }
          }

          .easy-to-earn {
            width: 35px;

            p {
              font-size: 18px;
              line-height: 16px;
              right: -7px;
            }
          }
        }
      }

      .one-platform-container,
      .community {
        .img-container {
          height: 976px !important;
          background-size: 682px !important;

          .text-container {
            .line-title {
              font-size: 18px;
              line-height: 16px;

              &:after {
                bottom: 3px;
                left: 232px;
              }
            }
          }

          .content {
            padding-top: 50px;
          }
        }

        .try-btn {
          width: 260px;
          height: 77px;
          margin-top: 55px;

          div {
            line-height: 16px;
            font-size: 18px;
          }
        }
      }

      .community {
        .try-btn {}

        .title-wrapper {
          h3 {
            font-size: 70px;
          }
        }

        .text-container {
          margin-right: -343px;
        }

        .community-wrapper {
          width: 682px !important;
        }
      }

      .live-section,
      .everyone-section,
      .get-fooled-section {
        padding-left: 0px !important;
        padding-right: 0px !important;

        .get-fooled-section-inner-text {
          font-size: 20px;
          line-height: 26px;
        }

        .img-container {
          .right-text {
            .sm-text {
              p {
                line-height: 16px;
                font-size: 18px;
              }
            }
          }

          .how-work-container {
            height: 76px;

            .btn {
              line-height: 16px;
              font-size: 18px;
              width: 181px;
            }
          }
        }
      }

      .get-fooled-section {
        .bg-grey {
          padding-right: 0px;
        }

        .col-md-5 {
          width: 340px;

          img {
            height: 210px;
            width: 345px;
          }
        }

        .col-md-7 {
          width: calc(100% - 340px);

          .input-location {
            margin-top: 25px;
          }
        }
      }

      .use-us-section {
        .lg-text {
          h2 {
            .inform {
              top: 26px;
            }

            .btn-cust {
              top: 32px;
              left: -52px;
              min-width: 92px;
              height: 40px;
              padding-top: 16px;
              color: #FFFFFF;
              font-size: 17px;
              line-height: 12px;
            }

            .right-txt {
              top: -10px;

              .req {
                div {
                  line-height: 15px;
                  font-size: 17px;
                  color: $color-nero;
                  padding: 0;
                  margin: 0;
                  display: block;
                  padding: 12px 10px 0;
                  background: #E7E6EE;
                  min-width: 179px;
                  width: fit-content;
                  height: 37px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .navbar.inner,
    .banner-header {
      .navbar-header {
        .navbar-left {
          width: 370px;

          .nav-bar-search-wrapper {}
        }

        .nav-bar-search-wrapper {
          .form-control {}
        }
      }

      .navbar-brand {}

      .navbar-brand {}

      .navbar-nav.navbar-left {
        li {
          a {
            padding-bottom: 15px !important;
          }
        }
      }
    }

    .nav-bar-search-wrapper {
      .form-control {}
    }
  }

  #app-layout {
    &.settings,
    &.profiles,
    &.requests {
      .settings-menu {
        font-size: 18px !important;
      }
    }
  }
}

.show-mobile-view-app {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  margin: 0 auto;
  max-width: 375px;
  position: relative;

  .xmas-mobile-wrapper {
    background: cdn("/images/home/xmas/bck_mob.jpg") no-repeat center;
    background-size: cover;
    padding: 20px;

    .img-wrapper {
      height: 200px;

      @keyframes randimgs {
        0% {
          opacity: 1;
        }

        45% {
          opacity: 1;
        }

        55% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
      }

      img {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &:nth-child(2) {
          animation-name: randimgs;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-duration: 7s;
          animation-direction: alternate;
        }
      }
    }

    .random-call-btn {
      font-size: 20px;
      padding: 5px 10px;
      max-width: 335px;
      width: 100%;
      margin-bottom: -25px;
      white-space: normal;

      &:hover {
        color: $color-xmas !important;
      }

      .svg {
        margin-left: 10px;
      }
    }
  }

  h2 {
    letter-spacing: 0 !important;
    margin-bottom: 0 !important;
  }

  h5 {
    padding-bottom: 20px;
  }

  h6 {
    font-size: 18px;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
  }

  .bg-alt1, .bg-alt2 {
    background-color: #6DF9BC;
  }

  .bg-alt3, .bg-alt4 {
    background-color: #6DEBFF;
  }

  .bg-alt5, .bg-alt6 {
    background-color: #ABFFB7;
  }

  .bg-alt7, .bg-alt8 {
    background-color: #AAC5F5;
  }

  .bg-alt9, .bg-alt10 {
    background-color: #EBC7B9;
  }

  .bg-alt11, .bg-alt12 {
    background-color: #FF6F83;
  }

  .bg-alt13, .bg-alt14 {
    background-color: #EBFF61;
  }

  .bg-alt15, .bg-alt16 {
    background-color: #A6EA9D;
  }

  .bg-alt17, .bg-alt18 {
    background-color: #67DBFC;
  }

  .bg-alt19, .bg-alt20 {
    background-color: #73EBFC;
  }

  .bg-alt21, .bg-alt22 {
    background-color: #AEFEB9;
  }

  .bg-alt23, .bg-alt24 {
    background-color: #72F7BD;
  }

  .bg-alt25, .bg-alt26 {
    background-color: #ABC6F3;
  }

  .bg-alt27, .bg-alt28 {
    background-color: #EBFD6D;
  }

  .banner-header-mobile {
    &.alt2,
    &.alt4,
    &.alt6,
    &.alt10,
    &.alt12,
    &.alt14,
    &.alt16,
    &.alt18,
    &.alt20,
    &.alt22,
    &.alt24,
    &.alt26,
    &.alt28 {
      .app-links-text {
        background-color: #F9B233;

        span {
          color: $color-nero;
        }
      }
    }
  }

  .app-links-text {
    &.alt2,
    &.alt4,
    &.alt10,
    &.alt14,
    &.alt20,
    &.alt22,
    &.alt24,
    &.alt26,
    &.alt28 {
      background-color: #F9B233;

      span {
        color: $color-nero;
      }
    }

    &.alt6,
    &.alt12,
    &.alt16,
    &.alt18 {
      background-color: #ABFFB7;

      span {
        color: $color-nero;
      }
    }
  }

  .balloon {
    z-index: 2;
    width: 160px;
    height: 160px;

    &[class*="alt"] {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 161.075px;
        z-index: -1;
      }
    }

    &.alt1,
    &.alt2 {
      &:before {
        @include bg-balloon-text(#6DF9BC);
      }
    }

    &.alt3,
    &.alt4 {
      &:before {
        @include bg-balloon-text(#6DEBFF);
      }
    }

    &.alt5,
    &.alt6 {
      &:before {
        @include bg-balloon-text(#ABFFB7);
      }
    }

    &.alt7,
    &.alt8 {
      &:before {
        @include bg-balloon-text(#AAC5F5);
      }
    }

    &.alt9,
    &.alt10 {
      &:before {
        @include bg-balloon-text(#EBC7B9);
      }
    }

    &.alt11, &.alt12 {
      &:before {
        @include balloon-text(#FF6F83);
      }
    }

    &.alt13,
    &.alt14 {
      &:before {
        @include balloon-text(#EBFF61);
      }
    }

    &.alt15,
    &.alt16 {
      &:before {
        @include balloon-text(#A6EA9D);
      }
    }

    &.alt17,
    &.alt18 {
      &:before {
        @include balloon-text(#67DBFC);
      }
    }

    &.alt19,
    &.alt20 {
      &:before {
        @include balloon-text(#73EBFC);
      }
    }

    &.alt21,
    &.alt22 {
      &:before {
        @include balloon-text(#AEFEB9);
      }
    }

    &.alt23,
    &.alt24 {
      &:before {
        @include balloon-text(#72F7BD);
      }
    }

    &.alt25,
    &.alt26 {
      &:before {
        @include bg-balloon-text(#ABC6F3);
      }
    }

    &.alt27,
    &.alt28 {
      &:before {
        @include bg-balloon-text(#EBFD6D);
      }
    }

    .balloon-content {
      padding: 10px;
    }

    span {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-size: 32px;
      font-weight: $font-weight-black;
    }
  }

  .banner-content {
    height: 100%;

    .banner-text {
      z-index: 0;
      padding-bottom: 20px;

      &:before {
        content: ' ';
        background-color: $color-nero;
        opacity: .33;
        width: calc(90% + 20px);
        top: 0;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: -20px;
      }

      .sticky {
        margin-top: 0;
        position: block;
      }

      h2 {
        padding: 20px 0;
      }

      p {
        padding: 20px 0;
        margin-bottom: 0;
        border-bottom: 1px solid $color-white;
      }
    }
  }

  ol {
    padding-inline-start: 15px;
  }

  .to-player {
    position: relative;
    padding-bottom: 56.23%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: $color-teal;

    .play {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      cursor: pointer;
    }

    img {
      bottom: 0;
      display: block;
      left: 0;
      margin: auto;
      max-width: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      border: none;
      height: auto;
      cursor: pointer;
      transition: .4s all;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: transparent;
    }
  }

  .sticky {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 1px;
      left: 0;
      z-index: -1;
      transform: scale(0.97);
      box-shadow: 0px 0px 8px 2px #000000;
    }
  }

  .img-balloon-container {
    &.alt1,
    &.alt3,
    &.alt5,
    &.alt9,
    &.alt11,
    &.alt13,
    &.alt15,
    &.alt17,
    &.alt19,
    &.alt21,
    &.alt23,
    &.alt25,
    &.alt27 {
      .img-wrapper {
        padding-right: 0 !important;
      }
    }

    &.alt2,
    &.alt4,
    &.alt6,
    &.alt7,
    &.alt10,
    &.alt12,
    &.alt14,
    &.alt16,
    &.alt18,
    &.alt20,
    &.alt22,
    &.alt24,
    &.alt26,
    &.alt28 {
      display: flex;

      .img-wrapper {
        margin-left: auto;
        margin-right: auto;
      }

      .balloon-wrapper {
        display: none;
      }
    }
  }

  .app-link-wrapper {
    .app-links {
      .app-links-text {
        .icon-right-arrow {
          &.alt2,
          &.alt4,
          &.alt6,
          &.alt10,
          &.alt12,
          &.alt14,
          &.alt16,
          &.alt18,
          &.alt20,
          &.alt22,
          &.alt24,
          &.alt26,
          &.alt28 {
            @include icon-right-arrow($color-nero);
          }
        }
      }
    }

    .header-subtitle {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      background-color: #a7b7d3;
      padding: 8px 0 3px;
      line-height: 100%;
    }
  }

  // height: 300px;
  ul {
    li {
      list-style: none;
      display: inline;

      &.app-link {
        max-width: 127.546px;
        width: 95%;
        margin: auto;
      }
    }
  }

  .fit {
    max-width: 100%;
  }

  .star-rating {
    margin: -8px auto 0;
  }

  .container.mobile .content {
    padding: 0  !important;

    h3 {
      font-size: 45px !important;
      white-space: normal !important;

      .icon-live-video {
        height: 0.6em;
      }
    }

    h4 {
      font-weight: $font-weight-bold !important;
      color: $color-nero;
      text-transform: none;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;

      .normal {
        font-weight: normal !important;
      }
    }

    h5 {
      font-weight: $font-weight-black !important;
      color: #7190ba;
      text-transform: uppercase;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-size: 16px;
    }

    .subtitle {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      padding: 8px 7px 3px;
      line-height: 100%;
      position: relative;
      white-space: normal;
      position: relative;

      .bg-left {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }
    }

    .bg-teal {
      background-color: $color-teal;
    }

    .bg-blue {
      background-color: #a7b7d3;
    }

    .bg-gray {
      background-color: #f2f2f2;
    }

    .bg-blue-gray {
      background-color: #e7e6ed;
    }

    .bg-light-blue {
      background-color: #c9f6ff;
    }

    .img-wrapper {
      img {
        max-height: 320px;
        max-width: 100%;
      }
    }

    .mobile-footer {
      position: absolute;
      color: #999999;
      font-size: 10px;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      right: 1%;
      bottom: 0%;
      margin: 0;
    }
  }
}

.comma {
  line-height: 1px;
  position: relative;
  top: -36px;

  &.sm {
    top: -16px;
  }

  &.upsm {
    right: -13px;
    top: 0;
  }

  &.sm-money {
    top: -13px;
    padding-right: 0 !important;
    margin: 0;
  }
}

.navbar-left {
  .smartsearch {
    li {
      width: auto !important;
      height: auto !important;
    }
  }
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: -260px;
  background-color: #F9F9F8;
  overflow-x: hidden;
  transition: 0.25s;
  padding: 15px;
  padding-top: 60px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.sidenav a {
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.max-width-mds {
  position: relative;

  .hamburger-btn {
    font-size: 21px;
    cursor: pointer;
    position: absolute;
    top: 23px;
    right: 30px;
    z-index: 999;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
}

.hit-me {
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

  /* font-weight: 600 !important; */
  color: $color-nero !important;
  font-size: 22px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @extend .flex-class;

  .web-cam {
    background: cdn("/images/web-cam.svg");
    background-size: 100%;
    width: 50px;
    height: 52px;
    background-repeat: no-repeat;
    margin-left: 10px;
  }

  &.disabled, &.disabled:hover {
    color: $color-lighter-grey !important;
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

.smartsearch > ul > li p.group-title.places::after {
  -webkit-filter: grayscale(100%);

  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@media only screen and (max-width: 729px) {
  [data-to-root]#app-layout {
    .modal {
      &:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
      }

      .modal-dialog {
        transform: translate(0, -50%);
        top: 50%;
      }
    }

    #main-container {
      .desktop-container {
        display: none;
      }

      .search-page {
        @include make-container;
      }
    }

    #nav-holder {}

    .navbar {
      display: block;

      .container {
        .navbar-header {
          max-width: 375px;
          width: 100%;
          margin-right: auto;
          margin-left: auto;

          .search-icon.mobile {
            display: block !important;
            float: left;
            position: relative;
            background-color: $color-white;
            z-index: 2;

            &:not(.icon-close) {
              border: 1px solid rgba(0, 0, 0, 0.18);
              width: 52px;
              height: 55px;
              background-image: cdn("/images/home/search.png");
              background-repeat: no-repeat;
              background-size: 29px;
              background-position: left 10px bottom 9px;
              top: 10px;
              position: absolute;
              left: 90px;
            }

            &.icon-close {
              height: 54px;
              width: 35px;
              padding: 29px 10px 10px;
              position: absolute;
              right: -10px;
              bottom: 0;
            }
          }

          .menu.mobile {
            height: 54px;
            width: auto;
            padding: 29px 10px 10px;
            position: absolute;
            right: -10px;
            bottom: 0;
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            font-size: 13px;
            cursor: pointer;

            &.collapse {
              &:after {
                @include icon-close($color-nero);

                height: 10px;
                width: 10px;
              }
            }

            &:after {
              @include icon-menu($color-nero);

              height: 13px;
              width: 10px;
              display: inline-block;
              margin-left: 4px;
            }
          }
        }
      }

      &.inner {
        opacity: 1;

        .navbar-header .navbar-left {
          z-index: 1;
          position: absolute;
          display: block !important;
          width: 52px !important;
          height: 52px;
          left: 90px;
          overflow: hidden;
          margin-right: 0 !important;

          // .smartsearch {
          //   height: 0;
          //   overflow: hidden;
          //   min-width: 0;
          // }

          &.show {
            width: calc(100% - 115px) !important;
            overflow: visible;
            right: 25px;
            transition: width .2s;
          }

          .nav-bar-search-wrapper {
            width: 100%;

            input {
              display: block !important;
            }
          }
        }
      }
    }

    &.blog.page,
    &.info.help,
    &.info.press,
    &.info.careers,
    &.info.about,
    &.info.how-it-works,
    &.info.toc,
    &.info.inside,
    &.profiles.guide,
    &.map,
    &.home {
      .modal-backdrop {
        display: block !important;
      }

      .mobile-home-container {
        display: none !important;
      }

      #main-container {
        display: block;

        .search-page {
          margin: auto;
        }
      }
    }
  }

  [data-to-root]#app-layout.home {
    .mobile-home-container {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 729px) {
  [data-to-root] {
    padding-top: 0 !important;
    height: auto !important;

    .p-t-15 {
      padding-top: 15px;
    }

    // .navbar {
    //   display: none;
    // }

    .banner-header-mobile {
      background-image: cdn("/images/home/new-home-mobile/top3_mob.jpg");
      background-size: cover;
      height: 530px;
      position: relative;
      background-position: center;
      z-index: 2;

      &.alt1, &.alt2 {
        background-image: cdn("/images/home/topmob_language.jpg");
      }

      &.alt3, &.alt4 {
        background-image: cdn("/images/home/topmob_homeschooling.jpg");
      }

      &.alt5, &.alt6 {
        background-image: cdn("/images/home/topmob_diet.jpg");
      }

      &.alt7, &.alt8 {
        background-image: cdn("/images/home/topmob_coaching.jpg");
      }

      &.alt9, &.alt10 {
        background-image: cdn("/images/home/topmob_consultants.jpg");
      }

      &.alt11, &.alt12 {
        background-image: cdn("/images/home/topmob_realestate.jpg");
      }

      &.alt13, &.alt14 {
        background-image: cdn("/images/home/topmob_energy.jpg");
      }

      &.alt15, &.alt16 {
        background-image: cdn("/images/home/topmob_local.jpg");
      }

      &.alt17, &.alt18 {
        background-image: cdn("/images/home/topmob_interiordesign.jpg");
      }

      &.alt19, &.alt20 {
        background-image: cdn("/images/home/topmob_crafts.jpg");
      }

      &.alt21, &.alt22 {
        background-image: cdn("/images/home/topmob_gardening.jpg");
      }

      &.alt23, &.alt24 {
        background-image: cdn("/images/home/topmob_pets.jpg");
      }

      &.alt25, &.alt26 {
        background-image: cdn("/images/home/topmob_photography.jpg");
      }

      &.alt27, &.alt28 {
        background-image: cdn("/images/home/topmob_web.jpg");
      }
    }

    #intro-bg {
      position: absolute;
      height: 530px;
      width: 100%;
      z-index: -1;

      #intro-bg-items {
        position: relative;
        height: 100%;

        .item {
          height: 100%;
          background-size: auto 100% !important;
          background-position: right -87px center !important;
          background-repeat: no-repeat !important;
          position: absolute;
          width: 100%;
        }
      }
    }

    &:not(.experts) .tag {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      color: $color-white;
      font-size: 13px;
      margin-bottom: 0;
      line-height: 1;
      letter-spacing: 1px;
    }

    .tag-wrapper {
      background: $color-black;
    }

    .ml-auto {
      margin-left: auto;
    }

    .container {
      .content {
        .fit {
          width: max-content;
          padding: 10px;

          &.subtitle {
            line-height: 70%;
            padding: 8px 7px;
          }
        }

        p.strong strong {
          font-size: 16px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-black !important;
          color: $color-light-grey;
          text-transform: uppercase;
        }

        p {
          font-size: 14px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
        }

        h3 {
          font-size: 58px !important;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
          color: #9adedb;
          line-height: 0.8 !important;
          letter-spacing: normal !important;
          margin: 0;
        }

        .icon-live-video {
          @include icon-live-video($color-light-grey);

          height: 37.175px;
          width: auto;
        }

        #mobile-video {
          width: 100%;

          .video-container {
            position: relative;

            &:hover {
              img {
                opacity: 0.9;
              }
            }

            .youtube-player {
              position: relative;
              padding-bottom: 56.23%;

              /* Use 75% for 4:3 videos */
              height: 0;
              overflow: hidden;
              max-width: 100%;
              background: #000;
            }

            .youtube-player iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 100;
              background: transparent;
            }

            .youtube-player img {
              bottom: 0;
              display: block;
              left: 0;
              margin: auto;
              max-width: 100%;
              width: 100%;
              position: absolute;
              right: 0;
              top: 0;
              border: none;
              height: auto;
              cursor: pointer;
              -webkit-transition: .4s all;
              -moz-transition: .4s all;
              transition: .4s all;
            }

            .youtube-player .play {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

body#app-layout {
  &.settings {
    .expertise {
      h2 {
        .th {
          font-size: 49px;
        }
      }
    }
  }
}

@media (min-width: 1085px) and (max-width: 1399px) {
  [data-to-root]#app-layout {
    &.settings {
      .expertise {
        h2 {
          .th {
            font-size: 46px;
          }
        }
      }
    }
  }
}

.help-block {
  position: relative;
}

@keyframes loading-animation {
  0% {
    opacity: $dark-alpha;
  }

  50% {
    opacity: $light-alpha;
  }

  100% {
    opacity: $dark-alpha;
  }
}

input.pending + span::after {
  opacity: 1;
  animation: loading-animation 0.3s infinite;
}

.input-location {
  top: 10px;

  @include when-sm {
    margin-right: 10px;
  }


  @include when-md {
    margin-right: 20px;
  }


  @include when-md {}


  .nav-bar-search-wrapper {
    align-items: flex-end;

    @include when-sm-down {
      width: 250px;
      height: 55px;
      padding-bottom: 16px;
    }


    @include when-md {
      width: 370px;
      height: 76px;
      padding-bottom: 22px;
    }


    @include when-lg {
      width: 500px;
    }
  }

  .form-control {
    padding: 0px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: normal;
    color: $color-nero;
    line-height: 1em;

    @include when-sm-down {
      font-size: 18px;
    }


    @include when-md {
      font-size: 28px;
    }
  }

  .smartsearch {
    @include when-sm-down {
      top: 39px;
    }


    @include when-md {
      top: 60px;
    }
  }

  .search-icon {
    background: cdn("/images/home/search.png") no-repeat;
    float: right;
    position: relative;
    background-size: 100%;
    margin: auto;

    @include when-sm-down {
      margin-bottom: -7px;
      width: 29px;
      height: 29px;
    }


    @include when-md {
      margin-bottom: -11px;
      width: 48px;
      height: 48px;
    }
  }
}

[data-to-root]#app-layout.settings.notifications,
[data-to-root]#app-layout.settings.requests {
  @include when-xs {
    .menu-sidebar li.messages > a {
      color: $color-black;
    }
  }
}

@include when-xs {
  .settings-menu {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    letter-spacing: 1px;
    color: $color-dark-grey;
    padding-bottom: 30px;

    a {
      color: $color-dark-grey;
      display: block;
      padding: 10px 20px;

      &:hover {
        text-decoration: none;
      }
    }

    > li > a:hover {
      color: $color-nero;
    }

    > li.active {
      > a {
        width: auto;
        display: table;
        color: $primary-active-color;
      }

      &:not(.active-sub) {
        > a {
          background: $color-bright-pink;
        }
      }
    }

    ul {
      padding-left: 0;
    }

    .submenu {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      font-size: 16px;
      padding-top: 0px;

      a {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        padding: 10px 10px 10px 30px;
        background-size: 95px 100%;
        background-repeat: no-repeat;
        color: $color-nero;
        letter-spacing: 0px;
        position: relative;
        z-index: 2;

        &:hover {
          color: $color-primary;
        }
      }

      .active {
        > a {
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-bold !important;
        }

        &::before {
          content: " ";
          display: inline-block;
          position: absolute;
          width: 90px;
          height: 42px;
          background: $color-bright-pink;
        }
      }
    }

    .unimportant {
      > a {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 16px;
        color: $color-grey;
        letter-spacing: 0px;
      }
    }

    .badge {
      background-color: $color-red;

      // display: inline;
      margin-left: 5px;
      font-size: 11px;
    }

    .logout {
      a {
        position: relative;
        display: table;

        p {
          font-size: 14px;
          margin-left: 10px;
          background: transparent;
          position: ABSOLUTE;
          left: 100%;
          width: 241px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .log-out-warning {
      display: none;
    }

    .log-out-btn {
      @include when-xs {
        margin: 45px 0px;
      }


      .btn {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
        font-size: 22px !important;
        width: auto !important;
        display: inline-block;
        float: right;
        margin: 0px 10px 7.5px 0px;
        top: 0 !important;
        padding-top: 12px !important;

        &.custom {
          background: #fff;
          color: #333;
          margin-top: 0px;
        }

        a {
          padding: 0 !important;
        }
      }
    }
  }

  .backdrop {
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
}
