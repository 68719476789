
.barrow {
	position:relative;

	&:after {
	    content:"";
		border-style: solid;
	    position: absolute;
		left: 50%;
		bottom: -10px;
	    margin-left:-10px;
		border-width: 10px 12.5px 0 12.5px;
		border-color: $primary-color transparent transparent transparent;
		z-index:1;
	}
	&:hover:after {
		border-color: $primary-active-color transparent transparent transparent;
	}
}


.barrow-dark {
	position:relative;

	&:after {
	    content:"";
		border-style: solid;
	    position: absolute;
		left: 50%;
		bottom: -10px;
	    margin-left:-10px;
		border-width: 10px 12.5px 0 12.5px;
		border-color: $primary-active-color transparent transparent transparent;
		z-index:1;
	}
}
