/*
 * put generic modal styles here
 */

.modal.modal-message {
  &:before {
    height: auto;
  }

  .modal-dialog {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .modal-content {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.7);
    border: 0 none;

    .modal-body {
      width: 330px;
      margin: auto;
      color: white;
      text-align: center;
      user-select: none;
      padding: 0;

      .title {
        position: relative;
        height: 150px;
        overflow: hidden;

        h1 {
          color: #4D4D4D;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          font-size: 140px;
        }

        .dashline {
          position: absolute;
          display: block;
          top: 118px;
          width: 100%;
          height: 1px;
          overflow: hidden;
          opacity: 0.7;
          background-image: cdn('/images/border.png');
          background-repeat: repeat-x;
          background-position: center center;
        }
      }

      .text {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        font-size: 16px;
        width: 240px;
        margin: auto;
      }

      .buttons {
        padding-top: 15px;
        padding-bottom: 30px;

        button {
          display: inline-block;
          border: 0 none;
          color: $color-black;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          font-size: 20px;
          letter-spacing: 1px;
          padding: 7px 2px 2px 2px;
          width: 158px;

          &.button-primary {
            background-color: $color-light-pink;
          }

          &.button-green {
            background-color: $color-green;
          }
        }
      }
    }
  }
}

/* ----------------------- TO BE REVIEWED styles below - are they used? ----------------------- */

/* code to center modals */
.modal {
  text-align: center;
}

@media screen and (min-width: 730px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

@include when-md {
  .modal-dialog {
    width: 710px;
  }

  #request-guide .modal-dialog,
  #request-nearby .modal-dialog {
    width: 460px;
    max-width:95vw;
  }

  // #request-guide #modal-request-details { width:710px; }
}


.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

/* end code to center modals */
.modal-backdrop {
  opacity: $dark-alpha !important;
}
