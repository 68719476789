html[lang=ru] { /*this cannot be embedded TODO: copy the lang to <body data-to-root */
	h5 {
		font-size: 20px;
	}

	#request-block .content .btn:nth-child(4) {
		font-size: 10px;
	}

	#app-layout.info.how-it-works #agreements-payments {
		line-height: 28px;
	}

	#app-layout.settings.guide .profile-media li .button-holder .edit-button {
		font-size: 12px;
	}
}
