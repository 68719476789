body[data-to-root].my-webinars {
	&.has-popover {
		overflow: hidden;
	}

	.webinar-menu-container .mobile-menu {
		@include when-xs {
			display: none;
		}
	}

	textarea::placeholder, input::placeholder {
		color: inherit !important;
	}

	textarea {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		width: 100%;
		border: none !important;
		position: relative;
		background-color: transparent;

		&:focus {
			border: dashed 2px $color-closer-white !important;
			top: -2px;
			left: -2px;
		}

		&.wrong-start, &.wrong-start:focus {
			border: dashed 2px $color-red !important;
			top: -2px;
			left: -2px;
		}
	}

	[data-max-length] {
		position: relative;

		&::after {
			position: absolute;
			bottom: -15px;
			right: 0px;
			font-weight: $font-weight-normal;
			color: $color-grey;
			font-size: 15px;
			content: attr(data-current-length) "/" attr(data-max-length);
		}
	}

	input[type=file] {
		display: none;
	}

	[data-popup-entry] {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	a.user {
		@include when-xs {
			.avatar {
				height: 36px;
				width: 36px;
			}
		}
	}

	.popup-elements {
		input, .input-group-addon {
			border-color: $color-lightest-grey !important;
		}

		.title {
			font-family: proxima;
			font-weight: $font-weight-black;
			color: $color-grey;
			font-size: 23px;
			text-transform: uppercase;
			padding: 10px 0px 10px;

			.beta {
				display: inline;
				color: $color-primary;
				font-weight: $font-weight-normal;
				text-transform: normal;
				font-size: 18px;
			}
		}

		.subtitle.beta {
			color: $color-blue;
			position: relative;
			top: -10px;
		}

		.subtitle.warning {
			color: $color-red;
			position: relative;
			top: -10px;
		}

		.input-group {
			padding: 5px 0px 10px;
		}

		.radio-select input[type=checkbox] {
			display: none !important;
		}

		.input-group.inline {
			display: flex;
		}

		label, .label {
			color: $color-nero;
			font-family: proxima;
			font-size: 14px;
			font-weight: $font-weight-normal;
			padding: 5px 0px;
			position: relative;
		}

		.text {
			color: $color-grey;
			font-family: proxima;
			font-size: 14px;
		}

		[name=start_time] + .combodate .bootstrap-select, [name=end_time] + .combodate .bootstrap-select {
			width: 60px !important;
		}

		[name=start_date] + .combodate .bootstrap-select {
			width: 80px !important;
		}

		[name=start_date] + .combodate, [name=start_time] + .combodate, [name=end_time] + .combodate {
			display: block;
			padding-top: 10px;
		}

		.bootstrap-select.beta .disabled {
			color: $color-off-white;
		}

		#time .input-group .label {
			width: 50px;
			text-align: left;
		}

		#video .input-group, [name=video_url] {
			width: 100%;
		}

		#price > .input-group {
			width: 100%;
			align-items: center;

			.label {
				width: 100%;
				text-align: left;
			}

			// [type=checkbox] {
			// 	position: relative;
			// 	left: -20px;
			// }

			[type=text], [type=number] {
				width: 80px;
			}

			.input-group {
				justify-self: flex-end;
				width: 160px;
			}
		}

		.warning {
			position: relative;
			background-color: $color-bright-light-pink;
			left: -20px;
			width: calc(100% + 40px);
			bottom: -20px;
			padding: 10px 20px;

			a {
				color: $color-nero;

				&::after {
					display: inline;
					content: ' >';
				}

				&:hover::after {
					content: ' >>';
				}
			}
		}
	}

	.webinar-title-start {
		p {
			margin-left: 42px;

			textarea {
				resize: none;
				overflow: hidden;
			}
		}

		h3 {
			display: flex !important;
			align-items: flex-start;
			justify-content: flex-start;

			> :last-child {
				flex-grow: 1;
			}

			.remove, .add {
				border: solid 1px $color-lightest-grey;
				flex-basis: 38px;
				flex-grow: 0;
				flex-shrink: 0;
				height: 38px;
				margin-top: 2px;
				margin-right: 4px;
				border-radius: 44px;
				text-align: center;
				line-height: 34px;
				cursor: pointer;
				padding-top: 2px;
				color: $color-lightest-grey;
				font-weight: $font-weight-thin;
				font-size: 30px;
				position: relative;
				left: -4px;

				&:hover {
					background-color: $color-nero;
					color: $color-white;
					border-color: $color-nero;
				}
			}

			.remove {
				width: 39px;
				padding-top: 0px;
				border: solid 1px transparent;
			}

			.btn-group {
				width: auto !important;
			}

			.btn-group .bs-caret {
				display: block !important;
			}

			.webinar-title-select button {
				padding-left: 2px;
			}

			textarea {
				position: relative;
				top: 7px;
				height: calc(2em + 10px);

				&:focus {
					top: 5px;
					margin-left: 4px;
				}
			}

			font-family: proxima !important;
			color: $color-primary;
			font-size: 23px !important;
			text-transform: none !important;
			font-weight: $font-weight-bold !important;
			letter-spacing: 0px !important;

			.dropdown-toggle, dropdown-toggle span, .dropdown-menu a, .dropdown-menu, .dropdown-menu a span {
				color: inherit;
			}

			.dropdown-menu a, .dropdown-toggle span {
				font-family: proxima !important;
				font-size: 23px !important;
				text-transform: none !important;
				font-weight: $font-weight-bold !important;
				letter-spacing: 0px !important;
			}
		}
	}

	.image-holder {
		position: relative;

		.button-holder {
			position: absolute;
			top: calc(50% - 50px);
			width: 200px;

			.btn {
				text-align: left;
			}

			.btn:hover {
				text-decoration: none !important;
			}
		}

		.webinar-img-container {
			overflow: hidden;
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			&[data-video] {
				background-color: $color-nero;
			}

			@include when-xs {
				width: 100%;
				height: 0;
				padding-top: calc(254 / 450 * 100%);
			}


			@include when-sm {
				width: 260px;
				height: 150px;
			}


			@include when-md {
				width: 450px;
				height: 254px;
			}


			// @include when-lg {
			// 	width: 620px;
			// 	height: 350px;
			// }

			img {
				visibility: hidden;
			}

			&[style="background-image:url()"] {
				background-color: $color-white !important;
				border: dashed 6px $color-closer-white;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
			}
		}

		.webinar-img-container[data-video] {
			cursor: pointer;

			&::after {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				@include icon-play($color-nero);
			}

			&:hover::after {
				@include icon-play($color-primary);
			}
		}

		.webinar-img {
			// object-fit:cover;
	
		}

		.tip {
			position: absolute;
			left: -20px;
			top: 10px;

			@include when-xs {
				right: 10px;
				left: auto;
				top: 10px;
				z-index: 1;
			}
		}
	}

	.webinar-listing-type {
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: var(--font-size-webinar-md);
	}

	.webinar-desc {
		white-space: nowrap;

		@include when-xs {
			width: 100% !important;
		}


		div {
			width: auto !important;
			margin-right: 0px !important;
		}

		.btn-more {
			width: calc(100% - 20px) !important;

			@include when-md {
				width: calc(100% - 30px) !important;
			}
		}
	}

	.webinar-details {
		@include when-sm-down {
			padding-top: 50px !important;
		}


		.button-holder {
			display: flex;
			justify-content: flex-end;

			a {
				&:not(:hover) {
					color: $color-lightest-grey;
				}

				font-size: 13px;
			}
		}
	}

	.tip {
		position: absolute;
		left: -17.5px;
		top: calc(50% - 7.5px);

		@include when-xs {
			right: 3px;
			left: auto;
			top: 0;
		}
	}

	.webinar-title {
		@include when-xs {
			min-height: 1.2em;

			.tip {
				top: 0;
			}
		}
	}

	textarea[name=title] {
		min-height: 2em;
		padding-top: 0;
		padding-bottom: 0;
		display: block;

		@include when-md {
			min-height: 3em;
		}
	}

	textarea[name^=short_description] {
		height: calc(4em + 20px);
	}

	textarea[name=long_description] {
		height: calc(10em + 20px);
	}

	.color-picker-holder {
		position: absolute;
		right: 20px;
		top: -25px;
		width: 190px;

		.color-picker-title {
			line-height: 25px;
		}

		.color-picker > * {
			border: none !important;
		}

		.color-picker-preview {
			width: 32px;
		}

		.color-picker-input {
			background-color: $color-close-white !important;
		}
	}

	.theme-picker-holder {
		position: absolute;
		right: 130px;
		top: -25px;
		width: 190px;

		.color-picker-title {
			line-height: 25px;
		}

		.btn {
			height: 35px;
		}

		.btn.theme-dark {
			background-color: $color-white;
			color: $color-nero;
		}

		.btn.theme-light {
			background-color: $color-nero;
			color: $color-white;
			position: relative;
			left: -4px;
		}

		input {
			display: none;
		}

		.btn input + span {
			color: $color-grey;
		}

		.btn.theme-dark input:checked + span {
			color: $color-nero;
		}

		.btn.theme-light input:checked + span {
			color: $color-white;
		}
	}

	.bottom-section.dark {
		.color-picker-title {
			color: rgba($color-black, $dark-alpha) !important;
		}
	}

	.bottom-section.light {
		.color-picker-title {
			color: rgba($color-white, $dark-alpha) !important;
		}
	}

	/* ----------------- rehearsal items ----------------- */

	.rehearsal-settings {
		margin-bottom: 20px;
	}

	.rehearsal-settings-left {
		background-color: $color-bright-light-pink;
		padding: 20px 40px;
		font-weight: bold;
	}

	.rehearsal-settings-step {
		font-size: 20px;
		margin-bottom: 30px;
	}

	.rehearsal-settings-main {
		flex: 1;
		padding: 20px 0 20px 20px;
	}

	.rehearsal-settings-data {
		display: flex;
		font-size: 24px;
	}

	.rehearsal-settings-data-label {
		padding-right: 5px;
	}

	.rehearsal-settings-data-separator {
		padding: 0 10px;
	}

	.rehearsal-settings-invite {
		margin: 20px 0 0 0;

		.search {
			max-width: 300px;
			position: relative;

			input {
				border: 1px solid $color-light-grey !important;
				width: 100%;
				padding: 6px 35px 8px 10px;
				box-shadow: 0px 1px 3px #bbb;
				font-size: 16px;
			}

			.search-icon {
				position: absolute;
				right: 10px;
				top: 8px;
				width: 20px;
				height: 20px;
			}

			.smartsearch {
				ul.results {
					li {
						font-size: 14px;
					}
				}
			}
		}
	}

	.rehearsal-settings-invite-form {
		padding-top: 20px;
		display: flex;

		ul {
			flex: 1;

			li {
				list-style-type: none;
				margin-bottom: 5px;

				.remove-invite {
					display: inline-block;
					width: 10px;
					height: 10px;
					background-image: url("/images/icons/icon-cross-red.svg");
					background-size: contain;
					margin-right: 10px;
				}
			}
		}

		.buttons {
			flex: 1;
			text-align: right;
		}
	}
}

body[data-to-root].my-webinars.topics {
	ul.webinar-topics {
		padding: 0px;
		margin: 20px 0px;
		display: flex;
		flex-wrap: wrap;

		li {
			list-style-type: none;
			width: calc(33% - 6px);

			@include when-xs {
				width: 100%;
			}


			&.checked label {
				background-color: $color-off-white;
			}

			label {
				margin: 3px;
				padding: 4px;
				cursor: pointer;

				&:hover {
					background-color: $color-bright-light-pink;
				}

				font-size: 16px;
				font-weight: $font-weight-bold;
				color: $color-grey;
				font-family: proxima;
				display: flex;
				align-items: center;
				height: 50px;

				input {}

				span {
					padding: 0px 4px;
					line-height: 18px;

					&::before {
						left: 8px;
						top: 12px;
					}

					&::after {
						left: 14px;
						top: 18px;
					}
				}
			}
		}
	}
}

[data-to-root].settings.settings-webinars-settings {
	#main-container {
		background-color: #F5F5F5;
	}

	.warning {
		position: relative;
		background-color: $color-bright-light-pink;
		left: -30px;
		width: 100%;
		bottom: -10px;
		padding: 10px 30px;

		a {
			color: $color-nero;

			&::after {
				display: inline;
				content: ' >';
			}

			&:hover::after {
				content: ' >>';
			}
		}
	}

	.top-section {
		@include make-container;
	}

	.webinar-settings {
		@include make-row;

		.left {
			@include make-md-column(6);
		}

		.right {
			@include make-md-column(6);
		}

		.left,
		.right {
			margin-bottom: 70px;
		}

		.title {
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-bold !important;
			color: #444443;
			text-transform: none;
			margin: 0 0 30px;
			line-height: 1;

			@include when-xs {
				font-size: 26px;
			}


			@include when-sm {
				font-size: 28px;
			}


			@include when-md {
				font-size: 36px;
			}


			@include when-lg {
				font-size: 44px;
			}
		}

		#invite-theone,
		#email-invitation,
		#invite-group,
		.settings-group {
			background-color: $color-white;
		}

		.settings-group {
			.extra-options {
				padding-top: 30px;

				&.off {
					opacity: 0.3;

					.call-ui-btn--panelbutton {
						cursor: default;
					}
				}
			}
		}

		textarea[name=email_description] {
			border: dashed 2px $color-closer-white !important;
			top: -2px;
			left: -2px;
			resize: none;
			overflow: hidden;
		}

		#interest-range {
			background-color: #FFF2F3;
			font-size: 20px;
			min-height: 280px;
			position: relative;
			z-index: 1;

			&:before {
				content: '';
				background-image: url("/images/webinar/map-pink.png");
				background-size: auto 213px;
				background-repeat: no-repeat;
				background-position: right 10px bottom 20px;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				bottom: 0;
				z-index: -1;
				opacity: .5;
			}
		}

		#invite-theone,
		#email-invitation,
		#invite-group,
		#interest-range,
		.settings-group {
			padding: 30px;
			width: 100%;
		}

		.interest-title, .sub-title {
			margin-top: 40px;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			font-size: 22px;
			color: $color-dark-grey;
			margin-bottom: 20px;
			line-height: .8;
		}

		#invite-theone {
			.input-group {
				display: block;

				label {
					position: relative;

					input {
						opacity: 0;
						width: 0;
						height: 0;
					}

					.slider {
						position: relative;
						cursor: pointer;
						background-color: #ccc;
						-webkit-transition: .4s;
						transition: .4s;
						border-radius: 34px;
						display: inline-block;
						width: 44px;
						height: 24px;
					}

					.slider:before {
						position: absolute;
						content: "";
						height: 18px;
						width: 18px;
						left: 3px;
						bottom: 3px;
						background-color: white;
						-webkit-transition: .4s;
						transition: .4s;
						border-radius: 50%;
					}

					input:checked + .slider {
						background-color: $color-online;
					}

					input:focus + .slider {
						box-shadow: 0 0 1px $color-online;
					}

					input:checked + .slider:before {
						-webkit-transform: translateX(20px);
						-ms-transform: translateX(20px);
						transform: translateX(20px);
					}
				}
			}

			select {
				width: 100%;
				max-width: 280px;
				height: 36px;
				-webkit-appearance: none;
				line-height: 36px;
				padding: 0 10px;
				border: 1px solid $color-close-white !important;
				background: $color-white;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background-image: url(svg-encode(icon-arrow-down($color-close-white)));
				background-repeat: no-repeat;
				margin-bottom: 0;
				background-size: 16.463px 8.232px;
				background-position: center right 12px;

				@include when-xs {
					max-width: none;
				}


				&:-ms-expand {
					display: none;
				}
			}
		}

		#email-invitation {
			#preview-email, #send-email {
				margin-top: 20px;
				font-size: 16px;

				&:not(:hover) {
					background-color: $color-lightest-grey;
					color: $color-white;
					border-color: $color-lightest-grey;
				}
			}
		}

		#invite-group,
		.settings-group {
			label {
				display: block;
				color: $color-nero;
			}
		}
	}

	.group-btn {
		.preview-webinar-btn,
		.publish-webinar-btn {
			margin: 10px auto;
			width: auto;
			background-color: #444443;
			padding-top: 12px;
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-bold;

			@include when-xs {
				min-width: 250px;
				font-size: 20px;
			}


			@include when-sm {
				font-size: 26px;
				min-width: 350px;
			}


			@include when-md {
				font-size: 28px;
				min-width: 500px;
			}


			@include when-lg {
				font-size: 34px;
				min-width: 524px;
			}
		}

		.preview-webinar-btn {
			background-color: $color-white;
			color: #444443;
		}
	}
}

[data-to-root].settings.settings-webinars-publish {
	#main-container {
		background-color: #F5F5F5;
	}

	.top-section {
		@include make-container;
	}

	.webinar-settings {
		.title {
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-bold !important;
			color: #444443;
			text-transform: none;
			margin: 0 0 15px;
			line-height: 1;

			@include when-xs {
				font-size: 26px;
			}


			@include when-sm {
				font-size: 28px;
			}


			@include when-md {
				font-size: 36px;
			}


			@include when-lg {
				font-size: 44px;
			}
		}

		.title + ul {
			margin-top: 30px;
		}

		p {
			margin-bottom: 30px;
			font-size: 20px;
		}

		ul {
			padding-left: 20px;
			list-style: none;

			li {
				margin-bottom: 10px;
				position: relative;

				&:before {
					content: '>';
					position: absolute;
					left: -20px;
				}
			}
		}
	}

	#mandatory-info,
	#optional-info,
	#invite-info,
	#publish-info {
		font-size: 20px;
		background-color: $color-white;
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 40px;
		padding-right: 40px;
		margin-bottom: 20px;

		.errors {
			color: #ED1C24;
			font-weight: $font-weight-bold;
		}

		.suggestions {
			color: #F7931E;
			font-weight: $font-weight-bold;
		}
	}

	.group-btn {
		.preview-webinar-btn,
		.publish-webinar-btn {
			margin: 10px auto;
			width: auto;
			background-color: #444443;
			padding-top: 12px;
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-bold;

			@include when-xs {
				min-width: 250px;
				font-size: 20px;
			}


			@include when-sm {
				font-size: 26px;
				min-width: 350px;
			}


			@include when-md {
				font-size: 28px;
				min-width: 500px;
			}


			@include when-lg {
				font-size: 34px;
				min-width: 524px;
			}
		}

		.preview-webinar-btn {
			background-color: $color-white;
			color: #444443;
		}

		.btn[disabled] {
			background-color: #E6E6E6;
			border-color: #E6E6E6;
		}
	}

	.small-preview {
		@include make-row;

		.webinar-content {
			display: block;
			margin: auto;
			float: none;
			width: 100%;
			max-width: 340px;

			.webinar-img {
				height: auto;
			}

			.webinar-desc {
				white-space: normal;
			}

			&:hover {
				pointer-events: none;
				cursor: default;

				.webinar-desc {
					background-color: unset;
				}
			}
		}
	}
}

[data-to-root].settings.webinars {
	p {
		@include when-lg {
			font-size: 16px;
		}
	}

	&.settings-webinars-index {
		.img-container {
			position: relative;
			margin-top: 35px;
			margin-bottom: 30px;

			.visible-xs {
				max-width: 100%;
				height: auto;
			}

			.hidden-xs {
				margin-left: -20px;
				max-width: calc(100% + 20px);
				height: auto;
			}

			.logo {
				width: 100px;
				height: auto;
				position: absolute;
				top: 15px;
				left: 0;

				@include when-xs {
					padding: 0 10px;
				}


				@include when-lg {
					width: 143px;
				}
			}

			h2 {
				color: $color-light-grey;
				font-size: 18px;
				font-weight: $font-weight-bold !important;
				position: absolute;
				top: 80px;
				padding-top: 0;
				margin-top: 0;

				@include when-xs {
					padding: 0 10px;
				}


				@include when-lg {
					font-size: 22px;
					top: 110px;
				}


				span {
					color: #64686B;
					display: block;
				}
			}
		}

		.webinar-actions {
			list-style: none;
			padding-left: 0;

			li {
				color: #64686B;
				font-size: 18px;
				font-weight: $font-weight-bold !important;
				line-height: 2.5;

				&:not(:last-child) {
					border-bottom: 1px solid $color-close-white;
				}

				@include when-xs {
					font-size: 15px;
				}


				@include when-lg {
					font-size: 22px;
				}


				a {
					display: block;

					&:not(:hover) {
						color: inherit;
					}
				}
			}
		}
	}

	&.settings-webinars-drafts,
	&.settings-webinars-upcoming,
	&.settings-webinars-recorded {
		h1 {
			@include when-sm {
				margin-bottom: 40px;
			}


			@include when-md {
				margin-bottom: 50px;
			}
		}

		.webinar-list {
			.webinar-item {
				&[data-id] {
					cursor: pointer;

					&:hover {
						.btn.edit {
							background-color: $color-nero;
						}

						.title {
							color: $color-primary;
						}
					}
				}

				display: flex;
				position: relative;
				flex-wrap: wrap;
				padding-bottom: 20px;

				@include when-xs {
					display: block;
					margin-bottom: 30px;
				}


				@include when-sm {
					margin-top: 30px;
					margin-bottom: 30px;
				}


				@include when-md {
					margin-top: 40px;
					margin-bottom: 40px;
				}


				&:after {
					content: '';
					border-bottom: 1px solid $color-close-white;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
				}

				.image, .details, .buttons {}

				.image {
					img {
						object-fit: cover;
					}

					@include when-xs {
						height: 0;
						padding-top: calc(254 / 450 * 100%);
						margin-bottom: 10px;
					}


					@include when-sm {
						width: 220px;
						height: 125px;
					}


					position: relative;

					@include when-md {
						width: 310px;
						height: 175px;
					}


					&.video::after {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);

						@include icon-play($color-nero);
					}

					.image-small {
						width: 100%;
						height: 100%;

						@include when-xs {
							position: absolute;
							top: 0;
							left: 0;
						}
					}

					.image-small[src=""], .image-small:not([src]) {
						background-color: $color-off-white !important;
						border: dashed 6px $color-closer-white;
						box-sizing: border-box;
						-moz-box-sizing: border-box;
						-webkit-box-sizing: border-box;
					}
				}

				.details {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					flex: 1;

					> :first-child {
						flex-grow: 1;
					}

					@include when-sm {
						padding-top: 5px;
						font-size: 16px;
						padding-left: 15px;
					}


					@include when-md {
						padding-top: 10px;
						padding-left: 20px;
					}


					.title {
						font-size: 18px;
					}

					.title, .price {
						font-weight: $font-weight-bold;
					}

					.datetime {
						display: flex;
						justify-content: flex-start;

						> div:not(:first-child) {}

						> div:not(:last-child) {
							&:after {
								content: '|';
								padding: 0 10px;
								display: inline;
							}
						}

						> :last-child {
							flex-grow: 1;
						}
					}
				}

				.buttons {
					text-align: right;
					padding-top: 20px;
					width: 100%;

					@include when-xs {
						position: relative;
						right: auto;
						width: auto;
					}


					a:not(.btn) {
						font-style: italic;
						color: $color-grey;
						font-weight: $font-weight-bold;
						top: 4px;
						position: relative;
						font-size: 12.5px;

						&:hover {
							color: $color-nero;
						}
					}

					a {
						font-size: 16px;
						padding-top: 6px;
						padding-bottom: 2px;
						padding-left: 13px;
						padding-right: 13px;

						@include when-sm {
							font-size: 14px;
						}


						@include when-md {
							font-size: 16px;
						}


						margin-right: 20px;

						&:last-child {
							margin-right: 0px;
						}
					}
				}
			}
		}
	}

	&.settings-webinars-drafts {
		.title-and-description {
			margin-bottom: 50px;

			@include when-sm {
				display: flex;
			}


			h1 {
				// font-family: "proxima", "Helvetica", "Arial", sans-serif;
				// font-weight: $font-weight-bold !important;
				// text-transform: uppercase;
				// font-size: 23px;

				@include when-sm {
					width: 220px;
				}


				@include when-md {
					width: 335px;
				}
			}

			p {
				margin-top: 20px;
				flex: 1;
			}
		}
	}

	&.settings-webinars-publish {
		.webinar-item {
			display: block;
			margin: auto;
			max-width: 320px;
			position: relative;

			.image {
				width: 100%;
				height: 180px;
				position: relative;

				&.video::after {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);

					@include icon-play($color-nero);
				}
			}

			.image-small {
				width: 100%;
				height: 100%;
			}

			.image-small[src=""], .image-small:not([src]) {
				background-color: $color-white !important;
				border: dashed 6px $color-closer-white;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
			}

			.details {
				padding: 15px 10px 27px;
				position: relative;
				font-size: 20px;

				@include when-md {
					padding: 25px 15px 48px;
				}


				.unset {
					font-style: italic;
				}
			}

			.title, .price {
				font-weight: $font-weight-bold;
			}

			.price, .time {
				display: inline-block;
			}

			.price {
				&:before {
					content: '-';
					font-weight: $font-weight-normal;
				}
			}
		}
	}
}
