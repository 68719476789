[data-to-root]#app-layout.profiles.guide {
  h3 {
    &.custom {
      margin-bottom: 15px;
    }
  }

  .single-profile {
    .shares {
      color: #4D4D4D;

      // font-size: 16px;
      // line-height: 20px;
      // margin-right: 15px;
  
    }

    .detail-lines {
      margin-top: 10px;
      margin-bottom: 20px;

      .nicename {
        color: #888D91;
        font-size: 22px;
        line-height: 17px;
      }

      span {
        color: #4D4D4D;
      }
    }

    .content-wrap {
      .btn-cust {
        span {
          .up {
            top: 0px;
          }
        }
      }

      .price_scheme {
        clear: both;

        // padding-top: 5px;
        .default_cost_per_duration_unit {
          color: #999999;

          span {
            color: #999999;
          }
        }
      }
    }
  }

  .about-me {
    p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 15px;

      &.review {
        margin-top: 0px;
      }
    }
  }

  #reviews {
    .review {
      padding-top: 15px;

      // border-bottom: solid 1px $color-off-white;
      padding-bottom: 15px;
    }

    .remark {
      margin-top: 15px;
    }
  }

  #main {
    padding-right: 40px;
    padding-top: 50px;

    .detail-container {
      margin-bottom: 60px;
    }
  }

  @include when-xs {
    #main {
      padding-right: 15px;
    }
  }


  p {
    font-size: 20px;
    line-height: 26px;

    .upper {
      font-size: 16px;
      line-height: 24px;
      color: #9E9E9D;
    }

    span {
      color: #3C3C3B;
      font-size: 16px;
      line-height: 24px;
    }
  }

  h3 {
    font-size: 22px;
    line-height: 16px;

    &:after {
      display: none;
    }
  }

  .location-search-map {
    margin-top: 20px;
  }

  #section-gallery {
    padding: 0;

    h3 {
      margin-bottom: 20px;
    }

    .media-item {
      &:nth-child(1) {
        width: 100%;
        margin-top: 10px;
      }

      &:nth-child(2) {
        width: 49%;
        height: 145px;
      }

      &:nth-child(3) {
        width: 49%;
        height: 84px;
      }

      &:nth-child(4) {
        width: 49%;
        height: 57px;
      }
    }
  }

  .modal-dialog {
    p {
      font-size: 12px;
      line-height: 14px;

      &.h5 {
        color: #DBC0C3;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        letter-spacing: 1px;
        font-size: 20px;
      }

      .upper {
        font-size: 12px;
        line-height: 14px;
      }

      span {
        font-size: 12px;
        line-height: 14px;
      }
    }

    .details-lines {
      .nicename {
        font-size: 14px;
      }
    }

    .item-details {
      padding-bottom: 20px;
    }
  }

  .dynamic {
    &.primary-hashtag {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    &.details {
      background-color: $color-light-pink;

      @include when-xs {
        padding-bottom: 15px;
      }


      @include when-sm {
        background-color: transparent;
      }


      .page-title {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: 50px !important;
        line-height: .9 !important;
        color: $color-white;

        &.key-message {
          font-size: 40px !important;
        }

        @include when-sm {
          font-size: 70px !important;
          color: $color-light-pink;
          margin-top: 0;

          &.key-message {
            font-size: 60px !important;
          }
        }
      }

      .keyword {
        font-family: "bebasNeuebook", "Helvetica", "Arial", sans-serif;
        font-size: 50px;
        line-height: .9;

        @include when-sm {
          font-size: 70px;
        }
      }
    }

    .small-name {
      font-family: "bebasNeuebook", "Helvetica", "Arial", sans-serif;
      font-size: 30px;
      line-height: 1;

      @include when-sm {
        font-size: 35px;
      }


      span {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: inherit !important;
      }
    }

    &.expert-certificate {
      @include when-xs {
        border-bottom: none;
      }
    }

    &.free-credit {
      margin-top: -18px;

      @include when-sm {
        margin-top: -26px;
        max-width: 320px;
        padding: 10px 0;
        margin-bottom: 30px;
      }


      p {
        padding: 10px;
        line-height: 1;
        font-size: 14px;
      }

      .call {}
    }
  }
}

.review {
  p {
    line-height: 15px;

    a {
      display: block;

      strong {
        color: #3C3C3B;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .sm-txtr {
      color: #999999 !important;
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
}

.hit-me + .review {
  @include when-xs {
    margin-top: 30px;
  }
}

.request-section {
  padding-top: 20px;
  padding-right: 0;

  .social-icons {
    text-align: right;

    li {
      &:last-child {
        padding-right: 17px;
      }
    }
  }

  .send-request {
    margin-bottom: 10px;
    cursor: pointer;

    span {
      margin-left: 5px;
      height: 10px;
      width: auto;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .block-report-wrapper {
    div {
      color: $color-nero;
      display: block;
      cursor: pointer;
      margin-top: 10px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.not_available {
  padding: 0 10px 20px 10px;
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
  color: $color-grey;
  font-size: 14px;

  .availability {
    margin-top: 20px;
    text-align: left;

    .availability_day {
      padding-right: 20px;
      font-size: 12px;
    }

    .availability_time {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-size: 12px;
      color: $color-nero;
    }

    .availability_timesep {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-size: 12px;
      color: $color-nero;
      width: 20px;
      text-align: center;
    }

    .availability_tz {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-size: 11px;
      color: $color-nero;
      padding-left: 5px;
      padding-bottom: 5px;
    }
  }
}

// #blockUserModal,
// #reportUserModal {
//   .modal-dialog {
//     width: 300px;
//     text-align: center;

//     textarea {
//       max-width: 100%;
//       width: 100%;
//       min-height: 150px;
//     }

//     .user-block-report-title {
//       margin-bottom: 15px;
//     }

//     .btn-block {
//       background: none;
//       background-color: $color-nero !important;
//       margin: 10px 0;

//       &:hover {
//         opacity: 0.7;
//       }
//     }
//   }
// }

.xmas-section {
  background: cdn("/images/home/xmas/bck_mob.jpg") no-repeat center;
  background-size: cover;
  padding: 20px;

  @include when-sm {
    margin-bottom: 30px;
    background: cdn("/images/home/xmas/bck.jpg") no-repeat center;
    background-size: cover;
  }


  .button {
    cursor: pointer;
  }

  .xmas-btn {
    padding: 10px;
    cursor: pointer;

    &:hover {
      h3 {
        color: $color-xmas !important;
      }
    }
  }
}

@media only screen and (max-width: 1085px) {
  [data-to-root]#app-layout.profiles.guide {
    .detail-container {
      .lg-text {
        h2 {
          font-size: 60px !important;
          line-height: 52px !important;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      .upper {
        font-size: 14px !important;
        font-size: 18px;
      }
    }

    .area-expertise,
    .expert-certificate {
      span {
        font-size: 14px !important;
        font-size: 18px;
      }

      button:nth-child(1),
      button:nth-child(2),
      button:nth-child(3),
      button:nth-child(4) {
        /* if the button is max 4th child -> list has max 2 categories, do not show the "more" button */
        display: none;
      }

      p {
        //display: none;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          /* show only the first 2 categoires by default (1st child is the title) */
          display: block;
        }
      }
    }

    #sidebar {
      .content-wrap {
        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      .bottom-line {
        border: none;
      }

      .detail-lines,
      .launguage-details {
        p {
          text-align: left;
        }
      }
    }

    .single-profile {
      .shares {
        margin-right: 15px;
      }
    }

    .request-section {
      padding-right: 15px;
      max-width: 320px;

      .social-icons {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 729px) {
  [data-to-root]#app-layout.profiles.guide {
    #main {
      padding-top: 20px;

      h1 {
        font-size: 60px;
        line-height: 50px;
      }

      h2 {
        font-size: 18px;
        line-height: 22px;
        padding: 0;
      }

      .expert-certificate {
        padding-top: 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      #section-location,
      #section-gallery,
      .about-me {
        padding-top: 20px !important;
      }

      ul.capabilities-list {
        padding-top: 40px;
      }

      #avatar-sidebar-holder, .content-wrap, .bottom-line {
        margin: auto;
      }

      .media-items .media-item {
        margin: 0 auto 5px !important;
      }
    }
  }
}
