[data-to-root].profiles {
  #profile {
    background-color: #F5F5F5;
    position: relative;
    padding: 25px 0 0;

    @include when-sm {
      padding: 40px 0 50px;
    }


    .container {
      @include clearfix;
    }

    .p-50 {
      padding: 50px 0;
    }

    p {
      font-size: 16px !important;
      line-height: 1.5;
      text-align: left;

      &.big {
        font-size: 20px !important;

        @include when-xs {
          font-size: 16px !important;
        }
      }

      &.small {
        font-size: 14px !important;
        line-height: 1.3;
      }

      &.xsmall {
        font-size: 12px !important;
        line-height: 1.4;
      }
    }

    &.dynamic {
      .key-message {
        padding: 0 0 20px;
        font-size: 42px;
        color: $color-lighter-grey;
        font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
        text-align: center;
        line-height: .9;

        @include when-sm {
          padding: 10px 0 30px;
          line-height: 1;
        }
      }

      .primary-category {
        font-size: 20px !important;
        color: #3C3C3B;

        .upper {
          color: $color-grey;
          font-weight: $font-weight-bold;
        }
      }

      .primary-hashtag {
        color: #3C3C3B;
        font-weight: $font-weight-bold;
      }
    }

    .sticky-call-btn-wrapper {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      color: $color-white;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 1px;
      display: block;
      z-index: 999;
      position: absolute;
      display: none;
      cursor: pointer;

      @extend .row;

      @include when-md {
        font-size: 22px;
      }


      a {
        color: inherit;
      }

      &.sticky {
        position: sticky;
        display: block;
        top: 55px;

        @include when-md {
          top: 76px;
        }
      }

      .ppm {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 14px;
        color: $color-lighter-grey;

        @include when-md {
          font-size: 16px;
        }
      }

      .sticky-call-btn {
        min-height: 46px;
        height: auto;
        text-align: center;
        position: relative;
        padding-top: 20px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;

        @extend .col-md-4;

        @extend .col-sm-5;

        @extend .col-xs-12;

        @include when-xs {
          background-color: $color-online;
          padding-top: 15px;
        }


        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 10px;
          width: 200%;
          height: 100%;
          z-index: -1;

          @include when-xs {
            content: none;
          }
        }

        &.online {
          &:before {
            background-color: $color-online;
          }
        }

        &.offline {
          &:before {
            background-color: $color-offline;
          }
        }

        &:hover {
          &:before {
            background-color: $color-nero;
          }
        }

        &[data-toggle="modal"] {
          @extend .online;

          @include when-xs {
            position: absolute;
          }
        }

        .icon-video {
          vertical-align: middle;
          margin: 0 10px;

          @include icon-video($color-white);

          height: 18.123px;
          width: auto;

          @extend .svg;

          @extend .no-margin;
        }
      }
    }

    // .cover-img {
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center;
    //   height: 187.5px;

    //   @include when-xs {
    //     @include make-row;
    //   }

    //   @include when-sm {
    //     padding-top: 192px;
    //     height: 0;
    //     margin-bottom: 20px;
    //     width: 100%;
    //   }

    //   @include when-md {
    //     height: 256px;
    //     padding-top: 0;
    //   }

    //   @include when-lg {
    //     height: 350px;
    //   }
    // }

    .tryout-credit {
      background-color: $color-white;
      border: 1px solid $color-offline;
      position: absolute;
      padding: 10px;
      bottom: 40px;
      max-width: 230px;

      @extend .normal;

      @include when-sm {
        min-width: 300px;
        width: 60%;
        max-width: 470px;
        bottom: 50px;
      }


      @include when-md {
        width: calc(50% - 7.5px);
        padding: 20px;
      }


      @include when-lg {
        bottom: 70px;
      }
    }

    .profile-content,
    .item-block-report,
    .guest-msg-wrapper {
      @extend .row;
    }

    .mobile {
      &.bottom {
        display: none;

        @include when-xs {
          display: block;
          position: relative;
        }
      }

      &.top {
        display: none;

        @include when-xs {
          padding: 0 10px;
          display: block;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            display: block;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color-white;
            top: 0;
          }
        }
      }
    }

    .left-section,
    .block-report-wrapper,
    .spacing {
      @extend .col-md-4;

      @extend .col-sm-5;
    }

    .right-section,
    .guest-msg {
      @extend .col-md-8;

      @extend .col-sm-7;
    }

    .right-section {
      @include when-xs {
        .item-description {
          display: none;
        }
      }
    }

    .content-title {
      text-transform: uppercase;
      padding: 0;
      line-height: 1;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      display: block;
      margin-top: 0;
      position: relative;
      text-align: left;

      &.small {
        color: $color-light-grey;
        font-weight: $font-weight-bold;
        font-size: 16px;
        margin-bottom: 20px;
      }

      &.big {
        font-weight: $font-weight-black;
        color: $color-light-pink;
        font-size: 23px;
        margin-bottom: 25px;
      }
    }

    .item-availability-title,
    .item-channels-title {
      @extend .content-title;

      @extend .content-title.small;
    }

    .item-recorded-webinar-title,
    .item-upcoming-webinar-title,
    .item-fixed-price-title,
    .item-expertise-title,
    .item-about-title,
    .item-articles-title,
    .item-reviews-title {
      @extend .content-title;

      @extend .content-title.big;
    }

    // .online-tag {
    //   position: absolute;
    //   top: 70px;
    //   left: 0;
    //   font-size: 16px;
    //   color: $color-white;
    //   font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
    //   text-align: center;
    //   line-height: 1;
    //   z-index: 1;
    //   min-width: 65px;
    //   padding: 9px 0 5px;

    //   &.online {
    //     background-color: $color-online;
    //   }

    //   &.offline {
    //     background-color: $color-offline;
    //   }
    // }

    // .avatar {
    //   padding-bottom: 100%;
    //   background-position: center center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   position: relative;
    //   height: 0;
    // }

    // .price-tag {
    //   font-family: "proxima", "Helvetica", "Arial", sans-serif;
    //   text-align: center;
    //   line-height: 1;
    //   max-width: fit-content;
    //   margin: auto;
    //   padding: 10px 15px;
    //   position: relative;

    //   &.pm {
    //     font-weight: $font-weight-black;
    //     font-size: 26px;
    //     color: $color-white;
    //     background-color: $color-nero;
    //     margin-top: -23px;

    //     .up {
    //       font-weight: $font-weight-bold;
    //       font-size: 16px;
    //       vertical-align: top;
    //       text-transform: uppercase;
    //     }
    //   }

    //   &.ph {
    //     color: $color-grey;
    //   }

    //   &.free {
    //     text-transform: uppercase;
    //   }
    // }

    .nicename {
      // font-size: 42px;
      // color: $color-light-pink;
      // font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
      // line-height: .9;
      // margin: 0;
      // position: relative;
      // width: fit-content;
      // margin-top: 40px;
      // position: relative;

      &.top {
        margin-top: 0;
        top: -15px;
        margin-bottom: -15px;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          height: 40px;
          width: calc(100% + 30px);
          top: -20px;
          left: -10px;
          background-color: $color-white;
          z-index: -1;
        }
      }
    }

    // .profession {
    //   font-size: 42px;
    //   color: $color-lighter-grey;
    //   font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
    //   line-height: .9;
    //   margin-bottom: 20px;
    //   position: relative;
    // }

    // .local-time {
    //   color: $color-lighter-grey;
    //   position: relative;

    //   span {
    //     font-size: inherit;
    //     color: inherit;
    //   }
    // }

    // .availability {
    //   color: $color-lighter-grey;
    //   text-align: center;
    //   margin-top: 30px;
    //   position: relative;
    // }

    // .location {
    //   margin-bottom: 0;
    //   position: relative;
    // }

    // .hit-me {
    //   font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    //   color: $color-nero;
    //   font-size: 28px;
    //   text-transform: uppercase;
    //   justify-content: center;
    //   align-items: center;
    //   cursor: pointer;
    //   line-height: 1;
    //   margin-bottom: 40px;
    //   position: relative;

    //   @extend .text-center;

    //   &.offline {
    //     color: $color-offline !important;
    //   }

    //   @include when-xs {
    //     max-width: 290px;
    //     margin: auto;
    //     font-size: 22px;
    //     line-height: .8;

    //     &.primary {
    //       margin-bottom: 30px;
    //       display: flex !important;
    //     }
    //   }

    //   .icon-video-circle {
    //     @extend .svg;

    //     @extend .no-margin;

    //     margin-left: 10px;
    //   }

    //   .icon-video-circle.big {
    //     height: 67px !important;
    //     width: auto !important;
    //   }

    //   &:hover {
    //     color: $color-nero !important;
    //     opacity: 1;

    //     .icon-video-circle.big {
    //       @include icon-video-circle($color-nero);
    //     }
    //   }
    // }

    // .review {
    //   text-align: center;
    //   display: block;
    //   color: $color-lighter-grey;
    //   position: relative;

    //   .score {
    //     position: relative;
    //     margin: 0px auto;
    //     width: 72px;
    //     display: inline-block;
    //     top: 2px;
    //     padding: 0 10px 0 0;
    //   }

    //   p {
    //     display: table;
    //     white-space: nowrap;
    //     text-transform: capitalize;

    //     &:before,
    //     &:after {
    //       content: '';
    //       display: table-cell;
    //       position: relative;
    //       width: 50%;
    //       background-image: url("https://cdn.calltheone.com/public/images/border.png");
    //       background-repeat: no-repeat;
    //       background-position: center;
    //     }

    //     &:before {
    //       right: 5px;
    //     }

    //     &:after {
    //       left: 5px;
    //     }
    //   }
    // }

    // .language-details {
    //   margin-top: 30px;
    //   position: relative;

    //   @extend .row;

    //   @include when-xs {
    //     margin-left: -10px !important;
    //     margin-right: -10px !important;
    //   }

    //   .lang,
    //   .grade {
    //     @extend .col-xs-6;

    //     margin-bottom: 0;
    //   }

    //   .grade {
    //     color: $color-light-grey;
    //   }
    // }

    // .expertise-certificate {
    //   margin: 45px auto 0px;
    //   position: relative;
    //   width: 90%;
    //   max-width: 225px;

    //   img {
    //     width: 100%;
    //     height: auto;

    //     &:nth-child(n+2) {
    //       padding-top: 10px;
    //     }
    //   }
    // }

    .item-send-msg {
      background-color: $color-offline;
      color: $color-white;
      text-align: right;
      font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
      line-height: 1;
      font-size: 16px;
      letter-spacing: 1px;
      padding: 9px 10px 5px;
      cursor: pointer;
      width: fit-content;
      min-width: 165px;

      &:hover {
        background-color: $color-nero;
      }

      @include when-xs {
        margin-left: -10px;
      }
    }

    // .item-availability {
    //   margin-top: 50px;

    //   .availability-table {
    //     margin: auto;
    //     width: calc(100% - 50px);

    //     @include when-lg {
    //       width: calc(100% - 90px);
    //     }

    //     td {
    //       @extend p;

    //       color: $color-lighter-grey;
    //       margin-bottom: 0;
    //     }

    //     .availability-day {
    //       width: 50%;
    //       color: $color-nero;

    //       @include when-md {
    //         width: 40%;
    //       }

    //       @include when-lg {
    //         width: 50%;
    //       }
    //     }

    //     .availability-timesep {
    //       width: 15px;
    //       text-align: center;
    //     }

    //     .availability-tz {
    //       padding-left: 5px;
    //     }
    //   }
    // }

    .item-channels {
      margin-top: 50px;

      a {
        color: inherit;
      }

      .item-channels-content {
        .channel {
          height: 40px;
          margin: auto;
          display: flex;
          align-items: center;
          background-color: $color-offline;
          margin-bottom: 1px;
          padding: 0 25px;

          @include when-lg {
            padding: 0 45px;
          }


          .channel-logo {
            width: 50%;
            display: flex;
            align-items: center;
          }

          .channel-detail {
            color: $color-white;
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold;
            font-size: 26px;
            text-transform: uppercase;
            line-height: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:hover {
            background-color: $color-nero;
            color: $color-white;

            .channel-detail {
              color: $color-white;
            }

            .channel-logo {
              .linkedin-logo {
                @include linkedin-logo($color-white);
              }

              .facebook-logo {
                @include facebook-logo($color-white);
              }

              .instagram-logo {
                @include instagram-logo($color-white);
              }

              .youtube-logo {
                @include youtube-logo($color-white);
              }

              .twitter-logo {
                @include twitter-logo($color-white);
              }
            }
          }
        }

        &.website {
          .channel-logo {
            width: auto;
            font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
            line-height: 1;
            font-size: 22px;
          }

          .channel-detail {
            color: $color-nero;
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold;
            font-size: 16px;
            text-transform: none;
            line-height: 1;
          }
        }
      }
    }

    .item-location .location-search-map #map {
      .gmnoprint {
        height: auto !important;

        div.gm-style-cc, a {
          padding: 17px 0;
          display: block;
          height: auto !important;
        }
      }
    }

    .item-share {
      display: flex;
      margin-top: 50px;

      @include when-xs {
        padding: 0 10px;
      }


      .share-icons {
        display: flex;

        li {
          margin-left: 1px;

          .profile {
            @include when-xs {
              &.icon-share-fb {
                height: 54px;
                width: auto;
              }

              &.icon-share-ig,
              &.icon-share-gplus,
              &.icon-share-linkedin,
              &.icon-share-twitter {
                height: 48px;
                width: auto;
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      p {
        margin-right: 20px;
      }
    }

    .item-details {
      position: relative;
      padding: 0 0 25px;

      @include when-sm {
        margin-top: -212px;
        padding: 40px 30px;
      }


      @include when-md {
        margin-top: -276px;
      }


      @include when-lg {
        margin-top: -370px;
      }


      &:before {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-white;

        @include when-xs {
          margin-left: -10px;
          margin-right: -10px;
          height: 100%;
        }


        @include when-sm {
          top: 162px;
        }


        @include when-md {
          top: 226px;
        }


        @include when-lg {
          top: 320px;
        }
      }
    }

    .item-alternatives {
      .item-alternatives-content {
        background-color: $color-light-grey;
        color: $color-white;
        text-align: right;
        font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        font-size: 16px;
        letter-spacing: 1px;
        padding: 9px 0 5px;
        cursor: pointer;
        width: fit-content;
        min-width: 245px;

        &:hover {
          background-color: $color-nero;
        }
      }

      @include when-xs {
        margin-left: -15px !important;
        margin-right: -15px !important;
      }
    }

    .item-custom-price {
      display: block;
      justify-content: space-between;

      @include when-xs {
        margin-left: -30px !important;
        margin-right: -30px !important;
      }


      @include when-md {
        display: flex;
      }


      .item-custom-price-content {
        background-color: $color-white;
        padding: 35px 30px;
        width: 100%;
        position: relative;

        @include when-xs {
          background-color: transparent;
          padding: 35px 15px;
        }


        @include when-md {
          width: calc(50% - 7.5px);
        }


        p {
          margin-bottom: 0;
        }

        .btn {
          position: absolute;
          bottom: -15px;
          right: 0;
          background-color: $color-offline;
          border-color: $color-offline;

          &:hover {
            background-color: $color-nero;
          }

          &.subscribed {
            background-color: $color-nero;
            cursor: auto;
          }
        }
      }
    }

    .item-recorded-webinar,
    .item-upcoming-webinar,
    .fixed-price,
    .item-description,
    .item-expertise,
    .item-media,
    .item-about,
    .item-articles,
    .item-reviews {
      background-color: $color-white;
      padding: 40px 20px 30px;
      margin-bottom: 20px;
      position: relative;

      @include when-xs {
        padding: 40px 10px 30px;
      }
    }

    .item-fixed-price {
      counter-reset: price-counter;

      @include when-xs {
        margin-top: 20px;
      }


      @include when-md {
        @include make-row;

        display: flex;
      }


      .fixed-price {
        counter-increment: price-counter;
        margin-bottom: 40px;

        @include when-md {
          margin-bottom: 60px;
          margin-left: 10px;
          margin-right: 10px;
          flex: 1;
        }


        &:not(:only-child) {
          .item-fixed-price-title {
            &:after {
              content: " #" counter(price-counter);
            }
          }
        }

        .price-details,
        .price-desc {
          @extend p;
        }

        .price-details {
          td:last-child {
            font-weight: $font-weight-bold;
            padding-left: 20px;
          }
        }

        .btn-more {
          position: absolute;
          bottom: -13px;
          right: 0;
        }
      }
    }

    .item-recorded-webinar,
    .item-upcoming-webinar {
      .webinar {
        margin-bottom: 15px;
        color: inherit;
        display: block;

        @include when-md {
          display: flex;
        }
      }

      .webinar-img {
        width: 100%;
        height: 200px;
        background-color: $color-lighter-grey;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include when-md {
          width: 200px;
          height: 110px;
        }
      }

      .webinar-desc {
        position: relative;
        line-height: 1.2;
        padding: 20px 10px;

        @include when-md {
          padding: 5px 20px 0;
          flex: 1;
        }


        p {
          @extend .big;

          line-height: 1.2;
          margin-bottom: 0;
        }
      }

      .title, .price {
        font-weight: $font-weight-bold;
      }

      .price, .time {
        display: inline-block;
      }

      .price {
        &:before {
          content: '-';
          font-weight: $font-weight-normal;
        }
      }

      .btn-more {
        @include when-sm-down {
          display: block;
          margin-top: 10px;
        }


        @include when-md {
          position: absolute;
          right: -20px;
          bottom: 0;
        }
      }
    }

    .item-description {
      padding: 40px 20px 0;
      margin-bottom: 0;
    }

    .one-liner {
      @extend .big;

      position: relative;
    }

    .item-expertise {
      .area-expertise {
        position: relative;
        text-align: center;

        p {
          color: #808080;
          text-align: left;
        }

        .show-more-btn {
          margin-bottom: -15px;
          background-color: $color-offline;
          border-color: $color-offline;

          &:hover {
            background-color: $color-nero;
          }

          &:before {
            content: '';
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            left: 0;
            display: block;
            position: absolute;
            bottom: 18px;
            height: 100px;
            z-index: 0;
            right: 0;
          }
        }
      }

      @include when-xs {
        padding: 0 0 80px;
        margin-bottom: 0;
      }
    }

    .item-articles {
      @include when-xs {
        @include make-row;
      }


      .item-articles-content {
        p {
          margin-bottom: 0;
        }
      }
    }

    .item-media {
      padding-top: 20px;
      padding-bottom: 20px;

      @include when-xs {
        padding-top: 45px;
        padding-bottom: 30px;
        margin-top: 20px;

        @include make-row;

        #section-gallery {
          padding: 0 !important;
        }
      }


      .item-media-content {
        .media-contents {
          position: relative;
          padding: 0;

          @include clearfix;

          &.mobile-all {
            .media-content {
              &:nth-child(n+4) {
                display: flex;
              }
            }
          }

          .show-more-wrapper {
            margin-top: -28px;

            &:before {
              content: '';
              background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
              left: 0;
              display: block;
              position: absolute;
              bottom: 0;
              height: 100px;
              z-index: 0;
              right: 0;
            }

            .show-more-btn {
              background-color: $color-offline;
              border-color: $color-offline;

              &:hover {
                background-color: $color-nero;
              }
            }
          }

          .media-content {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            float: left;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 0;
            padding-bottom: 24%;
            margin-right: 10px;
            margin-bottom: 5px;
            margin-top: 5px;
            cursor: pointer;
            position: relative;

            .play {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }

            img {
              display: none;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(5) {
              width: calc(40% - (20px / 3));
            }

            &:nth-child(3) {
              width: calc(20% - (20px / 3));
            }

            &:nth-child(4),
            &:nth-child(6) {
              width: calc(30% - (20px / 3));
            }

            &:nth-child(3),
            &:nth-child(6) {
              margin-right: 0;
            }

            &:nth-child(n+7) {
              display: none;

              @include when-xs {
                display: flex;
              }
            }

            &.full {
              width: 80%;
              padding-bottom: 45%;

              @include when-xs {
                width: 100%;
              }
            }

            &:hover {
              opacity: .7;
            }

            @include when-xs {
              padding-bottom: 52.75%;

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6) {
                width: 100%;
                margin-right: 0;
                float: none;
              }

              &:nth-child(1) {
                padding-bottom: 52.75%;
              }

              &:nth-child(2) {
                padding-bottom: 30%;
              }

              &:nth-child(3) {
                padding-bottom: 65.925%;
              }

              &:nth-child(n+4) {
                display: none;
              }
            }
          }

          .media-count {
            width: 60px;
            height: 60px;
            font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
            line-height: 1;
            font-size: 16px;
            color: $color-white;
            position: absolute;
            right: 15px;
            bottom: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .item-about {
      @include when-xs {
        padding: 40px 10px;
        margin-top: 20px;
      }


      p {
        margin-bottom: 0;
      }
    }

    .item-reviews {
      text-align: center;

      @include when-xs {
        @include make-row;

        padding-top: 45px;
        padding-bottom: 80px;
      }


      .item-reviews-title + .border {
        display: none;
      }

      p {
        margin-bottom: 0;
      }

      .border {
        margin: 25px 0;
      }

      .location {
        @extend .col-md-4;

        @extend .small;

        padding-left: 0;
      }

      .reviewer {
        @extend .bold;

        a {
          color: $color-nero;
        }

        + .row {
          @include when-xs {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }

      .rating-table {
        @extend .col-md-5;
      }

      .location,
      .rating-table {
        color: #999999;
      }

      .avr-rating {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;

        @include when-md {
          left: 50%;
          transform: translate(-50%, -50%);
          right: auto;
        }
      }

      .rating-table {
        @include when-xs {
          @include make-row;
        }
      }

      .remark {
        padding-top: 15px;

        @extend .small;
      }

      .show-more-btn {
        margin-bottom: -15px;
        background-color: $color-offline;
        border-color: $color-offline;
        margin-top: 20px;

        &:hover {
          background-color: $color-nero;
        }

        &:before {
          content: '';
          background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
          left: 0;
          display: block;
          position: absolute;
          bottom: 48px;
          height: 100px;
          z-index: 0;
          right: 0;
        }
      }
    }

    .item-block-report {
      .block-report-wrapper {
        @extend .flex;

        justify-content: center;
        top: 100%;
        z-index: 1;
        color: #999999;

        span {
          padding: 0 10px;
        }

        .block-report {
          cursor: pointer;
        }

        @include when-xs {
          padding: 20px 0;
        }
      }
    }

    .about-experience-wrapper {
      display: block;
      justify-content: space-between;

      @include when-xs {
        margin-left: -30px !important;
        margin-right: -30px !important;
        background-color: $color-white;
      }


      @include when-md {
        display: flex;
      }


      .content {
        width: 100%;

        @include when-xs {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }


        @include when-md {
          width: calc(50% - 7.5px);
        }


        &.full {
          width: 100%;
        }

        p {
          font-size: 14px !important;

          &.small {
            font-size: 12px !important;
          }
        }

        .item-experience {
          @include when-xs {
            padding: 40px 15px;
          }


          .item-experience-content {
            .border {
              @include when-xs {
                margin-left: -30px !important;
                margin-right: -30px !important;
                width: auto;
              }
            }

            &:last-child() {
              .border,
              .p-10 {
                display: none;
              }

              @include when-xs {
                .border {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .block-report-container {
      background-color: $color-white;

      @include when-xs {
        background-color: #f5f5f5;
      }
    }
  }
}

/* ----------------------- currency change page (where user accept/deny) --------------------- */

body.wallet-currency-change {
  background-color: #eee;

  .currency-change-container {
    background-color: $color-white;
    margin: 180px auto 0;
    width: 350px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;

    p {
      b {
        font-size: 16px;
      }

      &.intro {
        padding-bottom: 20px;
      }

      &.agree {
        padding-top: 20px;
      }
    }

    .dashline {
      height: 1px;
      background-image: linear-gradient(to right, #ddd 50%, rgba(255, 255, 255, 0) 0);
      background-size: 15px 1px;
      background-repeat: repeat-x;
      margin: 20px -15px;
    }

    > .row {
      padding: 30px 0;
    }

    .buttons {
      padding-top: 0;

      .col-xs-6 {
        padding: 0;
      }

      button {
        width: 170px;
        color: $color-nero;
        border: 0 none;
        font-size: 16px;

        &.btn-primary {
          background-color: $color-light-pink;
        }

        &.btn-secondary {
          background-color: $color-green;
          float: right;
        }
      }
    }
  }
}
