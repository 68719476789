[data-to-root]#app-layout.experts {
  &.with-map {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }

  // .grid-item {
  //   transition: none;
  // }

  #main-container {
    /* to override mobile */
    display: block !important;

    .ratio-image img {
      padding: 0px;
      margin: 0px;
      width: 100%;
      height: auto;
    }

    &.sticky {
      .blog-header .container {
        width: 100vw;
        max-width: 100vw;
        background-color: $color-off-white;
        position: fixed;
        top: 56px;
        padding-top: 10px;
        padding-right: 30px !important;
        padding-left: 30px !important;
        z-index: 203;

        .blog-menu {
          margin-bottom: 0;
        }

        @include when-md {
          top: 77px;

          .blog-menu {
            margin-bottom: 20px;
          }
        }


        @include when-lg {
          top: 101px;
        }
      }

      .experts-content {
        margin-top: 35px;
      }
    }

    .blog-menu-holder {
      &.sticky {
        width: 100vw;
        max-width: 100vw;
        background-color: $color-off-white;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 10px;
        z-index: 203;
        margin-left: 0 !important;
        margin-right: 0 !important;

        .blog-menu {
          margin-bottom: 0;
        }

        @include when-sm-down {
          top: 56px !important;
        }


        @include when-md {
          top: 76px;
          padding-right: 30px;
          padding-left: 30px;

          .blog-menu {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  /* make sure widths are fixed size */
  #main-container .container, .navbar .container {
    @include when-xs {
      max-width: $container-xs;
    }


    @include when-sm {
      max-width: $container-sm;
    }


    @include when-md {
      max-width: $container-md;
    }


    @include when-lg {
      max-width: $container-lg;
    }


    width: 100%;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #main-container .landing-page-footer .container {
    @include when-xs {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  #main-container .container {
    @include when-xs {
      max-width: 375px;
    }
  }

  .experts-header {
    background-color: $color-off-white;
    padding: 40px 0px 30px;
  }

  .blog-header {
    // padding: 50px 0px 30px;

    &.article {
      padding: 50px 0px 0px;
    }
  }

  .experts-content {
    margin-top: 0px;
    margin-bottom: 40px;

    &.no-header {
      padding-top: 40px;
    }

    &.with-map {
      overflow-y: scroll;

      @include when-xs {}


      @include when-sm {
        margin-top: 10px;
        margin-bottom: 10px;
        height: calc( 100vh - 185px);
      }


      @include when-md {
        height: calc( 100vh - 205px);
      }


      @include when-lg {
        height: calc( 100vh - 229px);
      }
    }
  }

  .blog-article-content {
    .experts-list-title {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .popular-articles-list {
      &.stamp {
        right: 0;
      }

      &.compact {
        .article-list-item {
          &:after,
          &:before {
            content: none;
          }

          .article-list-item-title {
            padding: 0 10px 5px;
          }

          .article-list-item-details {
            padding: 0 10px 0px;
            width: 100%;
            min-height: auto;

            .article-list-item-title {
              padding-left: 0;
              padding-right: 0;
            }

            .article-list-item-author {
              padding-top: 20px;
            }

            .article-list-item-author,
            .article-list-item-author + div {
              width: calc(100% - 80px);

              div {
                display: block;
                float: none;
              }
            }
          }

          .article-list-item-thumbnail {
            width: 80px;
            height: 80px;
          }

          .article-list-item-date:after {
            @include when-sm {
              content: none;
            }
          }
        }
      }
    }
  }

  // .landing-page-header, .landing-pages-header {
  //   background-color: $color-off-white;
  //   padding: 40px 0px 30px;

  //   h1 {
  //     color: $color-light-pink;
  //     font-size: 42px;
  //     line-height: 34px;
  //     text-transform: uppercase;
  //     font-family: bebasNeuebold;
  //     margin: 5px 0px;
  //   }

  //   .expertise-title {
  //     background-color: $color-nero;
  //     color: $color-white;
  //     font-family: bebasNeuebold;
  //     display: inline-block;
  //     position: relative;
  //     top: -15px;
  //     font-size: 16px;
  //     letter-spacing: 0.5px;
  //     padding: 3px 4px 0px;

  //     &:before {
  //       content: "#";
  //     }
  //   }

  //   a.landing-page-content-read-more {
  //     color: $color-nero;
  //     position: relative;
  //     top: -10px;
  //     text-decoration: underline !important;

  //     &:hover {
  //       color: $color-light-pink;
  //     }

  //     display: inline-block;
  //     margin-bottom: 5px;
  //   }
  // }

  // .landing-pages-header h1 {
  //   /* kinda weird todo here
  //   padding-left: 10px;

  //   @include when-xs {
  //     padding-left: 0px;
  //   }
  // }

  // .landing-page-footer {
  //   background-color: $color-off-white;
  // }
  a.landing-page-content-read-more {
    color: $color-nero;
    position: relative;
    top: -10px;
    text-decoration: underline !important;

    &:hover {
      color: $color-light-pink;
    }

    display: inline-block;
    margin-bottom: 5px;
  }

  .landing-page-content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .experts-header .container, .experts-content .container, .landing-page-header .container, .landing-page-content .container {
    padding-left: 0px !important;

    /* is this according to design? */
    padding-right: 0px !important;

    /* is this according to design? */
  }

  .filters {
    .filter {
      display: inline-block;

      label, .filter-option {
        font-family: bebasNeuebold;
        letter-spacing: 0.4px;
        font-size: 16px;
        color: $color-nero;
      }

      label {
        margin-right: 10px;
      }

      label.radio-select span::before {
        top: 1.5px;
      }

      label.radio-select span::after {
        top: 7.5px;
      }

      .bootstrap-select {
        top: 2px;
        width: auto;

        .dropdown-toggle {
          box-shadow: none !important;
        }
      }
    }
  }

  .landing-page-tags {
    display: block;
    list-style-type: none;
    margin: 0px;
    padding: 0;

    li {
      display: inline-block;
      list-style-type: none;
      padding: 0px;
      margin: 2.5px 0px;

      a.landing-page-tag {
        display: inline-block;
        border: solid 0.5px $color-lightest-grey;
        color: $color-lightest-grey;
        padding: 9px 5px;
        text-transform: lowercase;
        font-size: 12px;
        line-height: 8.4px;
        letter-spacing: 0.5px;

        &.more {
          border: none;

          &::after {
            content: "...";
          }
        }

        &:hover, &.active {
          border-color: $color-nero;
          color: $color-white;
          background-color: $color-nero;
        }
      }
    }
  }

  .experts-list-title,
  .landing-page-list-title {
    font-family: proxima;
    font-weight: $font-weight-bold !important;
    color: $color-primary;
    font-size: 22px;
    line-height: 1.1em;

    &.experts-filters {
      padding: 0 10px;
    }

    @include when-xs {
      margin-top: 30px;
    }


    @include when-sm {
      font-size: 24px;
    }


    @include when-md {
      margin-top: 100px;
      font-size: 26px;
    }


    @include when-lg {
      font-size: 28px;
    }
  }

  .landing-page-list-title {
    @include when-sm {
      margin-top: 100px;
    }
  }

  .experts-filters {
    .sort-form {
      @include when-sm {
        margin: 10px 0 20px;
      }


      @include when-md {
        margin: 30px 0 20px;
      }
    }
  }

  // .expert-result-items {
  //   @include make-row;
  // }

  // .expert-result-item, .become-one-item, .experts-list-title {
  //   @include make-xs-column(12);

  //   @include make-sm-column(4);

  //   @include make-md-column(3);

  //   @extend %col-lg-5ths;
  // }

  .expert-result-items.with-map {
    @include when-sm {
      margin-top: -10px;
      margin-right: 0;
    }


    .expert-result-item {
      @include make-xs-column(12);

      @include make-sm-column(6);

      @include make-md-column(4);

      @include make-lg-column(4);
    }
  }

  // .btn-become-one {
  //   display: block;
  //   background-color: $color-light-pink;
  //   color: $color-white;
  //   font-family: bebasNeuebold;
  //   font-size: 90px;
  //   line-height: 70px;
  //   text-align: center;
  //   padding: 30px 0px 10px;
  //   margin: 10px 0px;

  //   &:hover {
  //     background-color: $color-nero;
  //   }
  // }

  // .expert-result-item, .article-list-item {
  // background-color: $color-white;

  // .ender {
  //   border-bottom-left-radius: 10px;
  //   border-bottom-right-radius: 10px;
  //   border-bottom: rgba($color-lightest-grey, 0.7) solid 1px;
  //   border-left: rgba($color-lightest-grey, 0.7) solid 1px;
  //   border-right: rgba($color-lightest-grey, 0.7) solid 1px;
  //   height: 20px;
  //   clear: both;
  // }

  // .bordered {
  //   clear: both;
  //   position: relative;

  //   &::before, &::after {
  //     content: "";
  //     position: absolute;
  //     background-image: -webkit-gradient(linear, 0 0, 0 100, from(rgba($color-lightest-grey, 0)), to(rgba($color-lightest-grey, 0.7)));
  //     top: -80px;
  //     bottom: 20px;
  //     width: 1px;
  //   }

  //   &::before {
  //     left: 0px;
  //   }

  //   &::after {
  //     right: 0px;
  //   }
  // }
  // }

  .expert-result-item {
    // position: relative;
    // font-size: 16px;
    // font-family: proxima;
    // line-height: 21px;
    // margin: 10px 0px;

    // &[onclick] {
    // cursor: pointer;

    // &.hovering {
    //   .show-profile .divider-label {
    //     background-color: $color-primary;
    //     border-color: $color-primary;
    //     color: $color-white;
    //   }

    //   .name-and-price {
    //     background-color: $color-primary;
    //     color: $color-white;
    //   }

    //   .bordered::before, .bordered::after {
    //     background-image: -webkit-gradient(linear, 0 0, 0 200, from(rgba($color-primary, 0)), to(rgba($color-primary, 0.7)));
    //   }

    //   // .ender {
    //   //   border-color: rgba($color-primary, 0.7);
    //   // }

    //   // .no-image {
    //   //   border-color: rgba($color-primary, 0.7);
    //   // }

    // }

    // }

    // .divider {
    //   @extend .border;

    //   position: relative;
    //   text-align: center;

    //   &::before {
    //     top: 13px;
    //   }

    //   .divider-label {
    //     position: relative;
    //     top: -9px;
    //     border: solid 1px $color-nero;
    //     padding: 4px 8px 0px;
    //     font-family: bebasNeuebold;
    //     letter-spacing: 0.4px;
    //     display: inline-block;
    //     margin: auto;
    //     background-color: $color-white;
    //   }

    //   height: 30px;
    //   padding: 10px 0px;
    //   margin: 10px auto;

    //   &.show-profile {
    //     top: -7px;
    //   }
    // }

    // .online-status {
    //   position: absolute;
    //   right: 10px;
    //   top: 30px;
    //   z-index: 2;
    //   font-family: bebasNeuebold;
    //   font-size: 16px;
    //   background-color: $color-grey;
    //   padding: 4px 8px 1px;
    //   color: $color-white;

    //   &.is-online {
    //     &::before {
    //       content: "online";
    //     }

    //     background-color: $color-green;
    //   }
    // }

    // .image {
    // height:0px;
    // padding-bottom:100%;
    // overflow:hidden;
    // display:flex;
    // align-items:center;
    // justify-content: center;

    // }

    // .image img {
    //   min-width: 100%;
    //   max-width: 100%;
    // }

    // .no-image {
    //   height: 35px;
    //   border-top: solid 0.5px rgba($color-lightest-grey, 0.7);
    // }

    // .name-and-price {
    //   float: left;
    //   background-color: $color-nero;
    //   color: $color-white;
    //   display: inline-block;
    //   position: relative;
    //   margin-top: -35px;
    //   min-width: 150px;
    //   max-width: 250px;
    //   padding: 12px 10px;

    //   &:not(.no-rating) {
    //     max-width: calc(100% - 95px);

    //     @include when-sm {
    //       max-width: calc(100% - 85px);
    //       min-width: 145px;
    //     }

    //     @include when-md {
    //       max-width: calc(100% - 95px);
    //       min-width: 150px;
    //     }
    //   }

    //   .name {
    //     font-family: bebasNeuebook;
    //     font-size: 22px;
    //     line-height: 22px;
    //   }

    //   .price {
    //     font-family: proxima;
    //     font-weight: $font-weight-bold;
    //     font-size: 26px;
    //     line-height: 22px;
    //     text-transform: uppercase;

    //     .price-duration {
    //       font-weight: $font-weight-normal;
    //       position: relative;
    //       top: -7px;
    //       left: -3px;
    //       font-size: 16px;
    //       line-height: 16px;
    //     }
    //   }
    // }

    // .rating {
    //   float: right;
    //   margin-top: 10px;
    //   width: 95px;
    //   text-align: right;
    //   padding: 0px 5px;

    //   @include when-sm {
    //     width: 85px;
    //   }

    //   @include when-md {
    //     width: 95px;
    //   }

    //   .count {
    //     display: inline-block;
    //     font-size: 12px;
    //     top: -2px;
    //     position: relative;
    //   }

    //   .star-rating {
    //     display: inline-block;
    //     padding: 0px;
    //   }
    // }

    // .user-details {
    //   padding-top: 20px;
    // }

    // .tagline.sr {
    //   position: relative;
    //   padding-top: 5px;
    //   font-size: 15px;
    //   line-height: 1.3em;
    // }

    // .profession.sr {
    //   position: relative;
    //   font-family: bebasNeueBold;
    //   color: $color-primary;
    //   font-size: 22px;
    //   line-height: 1.1em;
    // }

    // .tagline,
    // .location, .languages, .tags {
    //   padding: 0px 10px;
    // }

    // .profession,
    // .location {
    //   position: relative;
    //   font-weight: $font-weight-bold;
    // }

    // .profession:not(.sr) {
    //   font-size: $font-size-large;
    //   font-weight: $font-weight-light;
    //   font-family: bebasNeueBold;
    //   color: $color-primary;
    // }

    // .tagline {
    //   .prefix {
    //     font-weight: $font-weight-bold;
    //   }
    // }

    .certificates {
      margin-top: 20px;
      text-align: center;
    }

    .certificate {
      max-width: calc(100% - 30px);
    }

    // .tag {
    //   &::before {
    //     content: "#";
    //     display: inline;
    //   }

    //   display: inline-block;
    //   color: $color-nero !important;
    // }

    // .tags {
    //   margin-bottom: 30px;
    // }

    // .availability {
    //   text-align: center;
    //   font-family: bebasNeuebold;
    //   color: $color-grey;
    //   margin: 0px 20px;
    // }

    // .call-button {
    //   display: flex;
    //   font-family: bebasNeuebold;
    //   color: $color-nero !important;
    //   font-size: 22px;
    //   margin-top: 20px;
    //   margin-bottom: 20px;
    //   position: relative;
    //   align-items: center;
    //   justify-content: center;

    //   .icon-video-circle {
    //     margin-left: 5px;
    //   }

    //   &.hovering {
    //     opacity: $alpha;
    //   }
    // }

  }

  .landing-page-list-wrapper {
    @include make-xs-column(12);

    @include make-sm-column(4);

    @include make-md-column(6);

    @extend %col-lg-2-5ths;

    @include when-sm-down {
      margin-bottom: 80px;
    }


    .landing-page-list-title {
      text-transform: uppercase;
      margin-bottom: 40px;
    }
  }

  .landing-page-list {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }

  .landing-page-list li {
    padding: 5px 0 5px 20px;
    position: relative;

    &:before {
      @include icon-right-arrow(#3C3C3B);

      position: absolute;
      width: 5px;
      height: auto;
      left: 0;

      @include when-md {
        top: 15px;
      }
    }

    &:after {
      content: '';

      @include make-row;

      border-bottom: 1px solid $color-close-white;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .landing-page-list-item {
      // margin: 10px 0px;
      // border-bottom: solid 0.5px $color-lightest-grey;
      // height: 80px;
      // position: relative;

      &[onclick] {
        cursor: pointer;

        &:hover {
          .landing-page-list-item-title {
            color: rgba($color-primary, 0.7);
          }

          border-bottom-color: rgba($color-primary, 0.7);
        }
      }
    }

    .landing-page-list-item-title {
      // font-size: 21px;
      // line-height: 25px;
      color: $color-nero;

      // font-family: proxima;

      // font-weight: $font-weight-bold;
      // float: left;
      // width: calc(100% - 40px);
      // padding-right: 10px;
  
    }

    .landing-page-list-item-image {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .landing-page-content-image,
  .landing-pages-header-image {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 20px;
    float: left;
    height: auto;
    margin-top: 50px;

    @include when-xs {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 100px;
    }


    @include when-sm {
      margin-top: 70px;
      width: 184px;
    }


    @include when-md {}


    @include when-lg {
      width: 196px;
    }


    img {
      width: 100%;
    }
  }

  // .landing-page-content-footer {
  //   @include make-xs-column(12);

  //   @include make-sm-column(8);

  //   @include make-md-column(9);

  //   @extend %col-lg-3-5ths;

  //   margin-top: 40px;
  //   margin-bottom: 40px;
  // }

  // .landing-page-content-footer-image {
  //   @include make-xs-column(12);

  //   @include make-sm-column(4);

  //   @include make-md-column(3);

  //   @extend %col-lg-5ths;

  //   margin-top: 40px;
  //   margin-bottom: 40px;

  //   img {
  //     width: 100%;
  //     height: auto;
  //   }
  // }

  .landing-page-content-header,
  .landing-pages-header {
    position: relative;
    padding-bottom: 40px;

    &:before {
      content: '';
      background-color: $color-off-white;
      position: absolute;
      right: -10px;
      top: 0;
      bottom: 0;
      z-index: -1;

      @include when-xs {
        left: -10px;
      }


      @include when-sm {
        left: calc(0px - 10px - (100vw - #{$container-sm}) / 2);
      }


      @include when-md {
        left: calc(0px - 10px - (100vw - #{$container-md}) / 2);
      }


      @include when-lg {
        left: calc(0px - 10px - (100vw - #{$container-lg}) / 2);
      }
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  .landing-page-content-title {
    @include when-xs {
      padding-top: 40px;
    }


    @include when-sm {
      padding-top: 50px;
    }


    @include when-lg {
      padding-top: 40px;
    }
  }

  .landing-page-content-post {
    @include when-xs {
      padding-top: 35px;
    }


    @include when-sm {
      padding-top: 50px;
    }


    @include when-lg {
      padding-top: 60px;
    }


    .landing-page-intro-content {
      font-weight: $font-weight-bold;
    }

    p, li {
      line-height: 1.5em;

      @include when-xs {
        font-size: 14px;
      }


      @include when-sm {
        font-size: 18px;
      }


      @include when-lg {
        font-size: 20px;
      }
    }

    p, ol, ul, table {
      margin-bottom: 1.6em;
    }

    i, em {
      background-color: $color-bright-light-pink;
      font-style: normal;
    }

    ul {
      margin-left: 0px;
      padding-left: 22px;
      list-style-position: outside;
    }

    ul, ol {
      margin-right: 0px;

      ul, ol {
        margin-bottom: 0px;
      }

      &.has-title {
        margin-top: -1.6em;
      }

      &.style-none {
        list-style: none;
        padding-left: 0;
      }

      &.style-indent {
        list-style: none;
        padding-left: 60px;
      }
    }

    p > img {
      margin: auto;
      display: block;
      max-width: 100%;
      height: auto;
    }

    h1 {
      line-height: 1.1em;
      font-weight: $font-weight-bold !important;
      font-size: 26px;
      margin-bottom: .8em;
      margin-top: 0;

      @include when-sm {
        font-size: 32px;
      }


      @include when-md {}


      @include when-lg {
        font-size: 34px;
      }
    }

    h2, h3, h4, h5, h6 {
      line-height: 1.1em;
      font-weight: $font-weight-bold !important;
      padding-bottom: 0px;
      text-transform: none;
      margin-bottom: .6em;

      @include when-xs {
        font-size: 22px;
      }


      @include when-sm {
        font-size: 26px;
      }


      @include when-lg {
        font-size: 28px;
      }
    }

    h3 {
      color: $color-primary;
    }

    a {
      color: $color-primary;
    }

    blockquote {
      border: none;
      padding: 0px;
      margin: 10px 0px;
      text-align: center;

      &:before {
        content: open-quote;
        font-size: 30px;
        color: $color-pink;
        position: relative;
        top: -10px;
      }

      &:after {
        content: close-quote;
        font-size: 30px;
        color: $color-pink;
        position: relative;
        top: 20px;
      }

      > * {
        display: inline;
      }
    }

    table {
      margin-top: 15px;
      margin-bottom: 15px;

      thead {
        border-bottom: solid 1px $color-light-pink;
      }

      tr th, tr td {
        vertical-align: top;
        padding: 2.5px;
      }

      tr td {
        border: solid 0.5px $color-lightest-grey;
        border-left-width: 0px;
        border-right-width: 0px;
      }
    }
  }

  .grid-sizer {
    @include make-xs-column(12);

    @include make-sm-column(4);

    @include make-md-column(3);

    @extend %col-lg-5ths;

    clear: both;
  }

  .with-map .grid-sizer {
    @include make-xs-column(12);

    @include make-sm-column(6);

    @include make-md-column(4);

    @include make-lg-column(4);

    clear: both;
  }

  .landing-page-wrapper {
    @include make-xs-column(12);

    @include make-sm-column(8);

    @include make-md-column(6);

    @extend %col-lg-3-5ths;

    padding-bottom: 45px;

    @include when-sm {
      padding-right: 40px !important;
    }


    @include when-md {
      padding-right: 80px !important;
    }


    &.stamp {
      left: 0px;
      top: 0px;

      @include when-xs {
        position: relative !important;
      }


      @include when-sm {
        // width: 66.5%;
      }


      @include when-md {
        // width: 75%;
      }


      @include when-lg {
        // width: 60%;
      }
    }

    .landing-page-content-sharing {
      display: flex;
      justify-content: center;
      margin: 100px 0px 30px;
      padding-top: 30px;
      border-top: 1px solid $color-close-white;

      .landing-page-content-sharing-title, ul.landing-page-content-sharing-icons, ul.landing-page-content-sharing-icons li {
        font-size: 18px;
        line-height: 33px;
        font-weight: $font-weight-bold;
        font-family: proxima;
        color: $color-lighter-grey;

        a {
          color: $color-lighter-grey;
        }

        a:hover {
          color: $color-primary;
        }
      }

      .landing-page-content-sharing-title {
        position: relative;
        top: 3px;
        padding-right: 10px;
      }

      ul.landing-page-content-sharing-icons {
        font-size: 25px;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
          font-size: 25px;
          display: inline;
          padding: 0px 10px;
        }
      }
    }
  }

  .article-list-wrapper {
    @include make-xs-column(12);

    @include make-sm-column(4);

    @include make-md-column(3);

    @extend %col-lg-5ths;

    &.stamp {
      @include when-xs {
        position: relative !important;
      }


      @include when-sm {
        top: 0;
        float: right;
        right: 0px;
      }
    }

    /* this is actually only for the show more button to center */
    display: flex;
    flex-direction: column;
  }

  .article-list-wrapper.full {
    @include make-xs-column(12);
  }

  .article-main-list-wrapper {
    @include make-xs-column(12);

    @include make-sm-column(8);

    @include make-md-column(6);

    @extend %col-lg-3-5ths;

    &.stamp {
      float: left;
      left: 0px;
      top: 0px;

      @include when-xs {
        position: relative !important;
      }
    }

    /* this is actually only for the show more button to center */
    display: flex;
    flex-direction: column;
  }

  .blog-content .article-list-wrapper {
    @include make-xs-column(12);

    @include make-sm-column(4);

    @include make-md-column(6);

    @extend %col-lg-2-5ths;

    &.stamp {
      float: right;
      right: 0px;
      top: 0px;

      @include when-xs {
        position: relative !important;
      }
    }
  }

  .article-list {
    &:not(.numbered):not(.compact) {
      // @include make-row;
  
    }

    list-style-type: none;
    padding: 0px;
    margin-top: 0;
    margin-bottom: 0;
  }

  ol.article-list {
    counter-reset: article-list-counter;

    li.article-list-item {
      counter-increment: article-list-counter;
      position: relative;
      padding-top: 20px !important;

      &::before {
        display: inline-block;
        content: counter(article-list-counter);
        font-size: 36px;
        line-height: 52px;
        font-family: proxima;
        text-align: center;
        color: $color-lightest-grey;
        width: 37px;
        position: absolute;
      }
    }
  }

  ul.article-list li.article-list-item {
    &::after {
      @include icon-right-arrow-bold($color-white);

      width: 7.586px;
      position: absolute;
      right: 22px;
      display: block;
      bottom: 34px;
      z-index: 5;
      width: 11px;
      height: auto;

      @include when-sm-down {
        right: 21px;
        bottom: 38px;
      }
    }

    &::before {
      content: "";
      background-color: $color-nero;
      position: absolute;
      right: 10px;
      display: block;
      bottom: 35px;
      z-index: 4;
      width: 35px;
      height: 35px;
    }

    &[onclick]:hover::before {
      background-color: $color-primary;
    }

    &.normal, &.full, &.small, &.compact {
      &::after,
      &::before {
        content: none;
      }
    }

    &.normal, &.medium {
      @include when-md {
        &::after {}
      }
    }

    &.below {
      @include when-sm-down {
        &::after,
        &::before {
          content: none;
        }
      }
    }
  }

  .article-list-header {
    margin: 0px;
    margin-bottom: .6em;
    padding: 0px;
    font-family: proxima;
    font-weight: $font-weight-bold !important;
    text-transform: none;
    color: $color-nero;
    clear: both;
    line-height: 1.1em;

    @include when-xs {
      font-size: 22px;
      margin-top: 30px;
    }


    @include when-sm {
      font-size: 26px;
      margin-top: 100px;
    }


    @include when-lg {
      font-size: 28px;
    }
  }

  .popular-articles-list {
    .article-list-header {
      @include when-xs {
        margin-top: 0;
      }


      @include when-sm {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }
  }

  .article-list-item {
    margin: 0px 0px 40px 0px;
    clear: both;
    position: relative;

    &.small, &.full {
      &::after, &::before {
        content: none;
      }
    }

    &.full, &.medium {
      margin: 0px 0px 50px 0px;
    }

    &.numbered {
      &:not(:first-child) {
        @extend .border-after;

        &:after {
          left: 37px;
          width: calc(100% - 37px);
        }
      }
    }

    .bordered::before, .bordered::after {
      top: 20px;
      bottom: 20px;
    }

    // .ender {
    //   border-bottom-left-radius: 5px;
    //   border-bottom-right-radius: 0px;
    // }

    &[onclick] {
      cursor: pointer;

      &:hover {
        .article-list-item-title {
          color: $color-primary;
        }

        .bordered::before, .bordered::after {
          background-image: -webkit-gradient(linear, 0 0, 0 200, from(rgba($color-primary, 0)), to(rgba($color-primary, 0.7)));
        }

        // .ender {
        //   border-color: rgba($color-primary, 0.7);
        // }

        .article-list-item-author {
          color: $color-primary;
        }

        .article-list-item-read-more {
          color: $color-primary;
        }
      }
    }

    .article-list-item-title {
      transition: none;
      font-family: proxima;
      text-transform: none;
      color: $color-nero;
      clear: both;
      display: inline-block;
      padding: 0 10px 5px;
      line-height: 1.2em;
      font-size: 21px;

      @include when-xs {
        padding: 20px 10px 5px;
      }
    }

    &:not(.numbered) .article-list-item-title {
      flex-grow: 1;
    }

    &.below .article-list-item-title {
      font-size: 24px;
      line-height: 30px;
      padding: 0px 0 20px 0px;
    }

    &.numbered .article-list-item-title {
      font-size: 24px;
      line-height: 30px;
      padding: 0px;
      margin: 13px 0px 10px 43px;
      font-weight: $font-weight-normal !important;
    }

    &.medium .article-list-item-title, &.full .article-list-item-title {
      font-size: 32px;
      line-height: 39px;
      padding: 0 0 20px;
    }

    &.small .article-list-item-title {
      float: left;
      width: calc(100% - 80px);

      @include when-md {}
    }

    .article-list-item-excerpt {
      transition: none;
      font-size: 16px;

      // line-height: 24px;
      line-height: 1;
      font-family: proxima;
      font-weight: $font-weight-normal;
      text-transform: none;
      color: $color-nero;
      clear: both;
      padding: 10px 0px;

      &:after {
        content: "...";
        display: inline;
        left: -4px;
        position: relative;
      }

      display: none;

      @include when-md {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
      }


      // @include when-lg {
      //  white-space: initial;
      //  overflow: auto;
      // }
  
    }

    .article-list-item-details {
      float: left;
      width: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.numbered .article-list-item-details {
      margin-left: 43px;
      margin-bottom: 10px;
      width: calc(100% - 43px);

      @include when-md {
        width: calc(100% - 80px);
      }
    }

    &:not(.numbered) .article-list-item-details {
      padding: 10px 10px 0px;

      // height: 80px;

      @include when-xs {
        padding: 20px 10px 0px;
      }
    }

    &.below .article-list-item-details {
      width: calc(100% - 160px);
      min-height: 140px;
    }

    &.normal .article-list-item-details {
      height: 80px;
    }

    &.medium .article-list-item-details, &.full .article-list-item-details {
      margin-left: 0px;
      padding: 10px 0px;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
    }

    &.full .article-list-item-details {
      float: none;
    }

    &.compact .article-list-item-details {
      padding: 20px 10px 0px;
    }

    &.medium .article-list-item-read-more, &.full .article-list-item-read-more {
      flex-grow: 1;
      text-align: right;
      font-size: 15px;
      line-height: 15px;
      font-family: proxima;
      font-weight: $font-weight-bold;
      color: $color-nero;
    }

    .article-list-item-author {
      font-size: 15px;
      line-height: 17px;
      padding-bottom: 5px;
      font-family: proxima;
      font-weight: $font-weight-bold !important;
      text-transform: none;
      color: $color-nero;

      @include when-sm-down {
        padding-top: 20px;
      }
    }

    &.compact .article-list-item-author {
      @include when-sm-down {
        padding-top: 0;
      }
    }

    .article-list-item-read-time, .article-list-item-date {
      font-size: 15px;
      line-height: 1.4em;
      font-family: proxima;
      color: $color-lighter-grey;
      display: inline;
      float: left;
      font-weight: $font-weight-light;
    }

    &.compact {
      .article-list-item-read-time, .article-list-item-date {
        @include when-sm {
          float: none;
          display: block;
        }
      }
    }

    &.medium .article-list-item-date, &.full .article-list-item-date {
      font-weight: $font-weight-bold;
    }

    .article-list-item-date:after {
      content: " | ";
      padding-left: 10px;
      padding-right: 10px;
    }

    &.compact .article-list-item-date:after {
      @include when-sm {
        content: none;
      }
    }

    &.below .article-list-item-date:after, &.numbered.article-list-item-date:after, &.full .article-list-item-date:after, &.medium .article-list-item-date:after {
      padding-left: 5px;
      padding-right: 7px;
    }

    .article-list-item-thumbnail {
      width: 80px;
      height: 80px;
      float: right;
      position: relative;
      top: 20px;
      right: 0;
      object-fit: cover;
      z-index: 1;
      font-size: 12px;
      line-height: 1;
    }

    &.compact .article-list-item-thumbnail {
      position: absolute;
      top: auto;
      bottom: 1px;
    }

    &.below .article-list-item-thumbnail {
      width: 160px;
      height: 160px;
      position: absolute;
      float: none;
      top: auto;
      bottom: 1px;
      z-index: 1;
    }

    .article-list-item-image {
      width: 100%;
      height: auto;
    }

    &:not(.with-thumbnail):not(.numbered) {
      .article-list-item-details {
        width: 100%;
      }

      // .ender {
      //   border-bottom-right-radius: 5px;
      // }
  
    }
  }

  .article-list-show-more {
    font-family: bebasNeuebold;
    color: $color-grey;
    font-size: 20px;
    line-height: 13px;
    letter-spacing: 0.4px;
    padding: 8px 10px 8px;
    display: inline-block;
    margin: auto;
    cursor: pointer;
    border-bottom: 1px solid $color-lightest-grey;

    &:hover {
      background-color: $color-nero;
      color: $color-white;
    }

    &[disabled], &[disabled]:hover {
      cursor: default;
      background-color: transparent;
      color: $color-grey;
    }

    margin-bottom: 60px;
  }

  .map-wrapper {
    @include when-xs {
      width: $container-xs;
      height: calc(100vh - 209px);
      padding: 10px;
    }


    @include when-sm {
      position: fixed;
      margin: auto;
      padding: 0 10px;
      display: flex;
      flex-flow: row-reverse;
      width: $container-sm;
      height: calc(100vh - 185px);
    }


    @include when-md {
      width: $container-md;
      height: calc(100vh - 205px);
    }


    @include when-lg {
      width: $container-lg;
      height: calc(100vh - 229px);
    }


    #map {
      height: 100%;
      background-color: $color-off-white;
      float: right;

      @include make-xs-column(12);

      @include make-sm-column(4);

      @include make-md-column(3);

      @extend %col-lg-2-5ths;
    }
  }

  .article-wrapper {
    @include make-xs-column(12);

    @include make-sm-column(6);

    @include make-md-column(9);

    @extend %col-lg-3-5ths;

    &.stamp {
      float: left;
      left: 0px;
      top: 0px;

      @include when-xs {
        position: relative !important;
      }


      @include when-sm {
        width: 66.5%;
      }


      @include when-md {
        width: 50%;
      }


      @include when-lg {
        width: 60%;
      }
    }

    /* this is actually only for the show more button to center */
    display: flex;
    flex-direction: column;

    h3.article-list-header {
      margin-top: 0;
    }
  }

  .experts-title-wrapper {
    &.stamp {
      float: right;
      right: 0px;
      top: 0px;

      @include when-xs {
        position: relative !important;
      }
    }

    .experts-title-content {
      background-color: $color-off-white;
      padding: 20px 15px 10px;
      margin: 0px 0px 10px 0px;
    }
  }

  .experts-title-wrapper:not(.experts-title-wrapper-main) {
    @include make-xs-column(12);

    @include make-sm-column(4);

    @include make-md-column(3);

    @extend %col-lg-5ths;
  }

  .experts-title-wrapper-main {
    @include make-xs-column(12);

    @include make-sm-column(8);

    @include make-md-column(6);

    @extend %col-lg-3-5ths;
  }

  .article-content {
    position: relative;

    @include when-sm {
      padding-right: 60px;
    }


    h1.article-title {
      font-size: 30px;
      line-height: 1.1em;
      font-weight: $font-weight-bold !important;
      font-family: proxima;
      color: $color-nero;
      padding: 0px;
      margin-top: 0;
      margin-bottom: 20px;

      @include when-xs {
        font-size: 26px;
      }


      @include when-lg {
        font-size: 34px;
      }
    }

    &.main-article h1.article-title {
      color: $color-light-pink;
      font-size: 78px;
      line-height: 70px;
      text-transform: uppercase;
      font-family: bebasNeuebold;
      margin: 0px 0px;
    }

    .article-blog {
      height: 40px;
      display: inline-block;
      right: 60px;
      position: absolute;
      transition: all .5s;

      @include when-xs {
        right: 0px;
      }


      img {
        height: 100%;
        width: auto;
      }
    }

    .article-author-avatar {
      clear: left;
      float: left;
      display: inline-block;
      margin-right: 10px;
      width: 80px;
      height: 80px;
      margin-top: 45px;
      margin-bottom: 50px;
      object-fit: cover;
      z-index: 4;
      position: relative;
      transition: all .5s;

      @include when-xs {
        margin-top: 50px;
      }


      @include when-lg {
        margin-top: 20px;
      }
    }

    .article-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      margin-top: 60px;

      @include when-xs {
        margin-top: 70px;
        height: 80px;
      }


      @include when-lg {
        margin-top: 40px;
      }
    }

    .article-author {
      font-size: 15px;
      line-height: 1.4em;
      font-family: proxima;
      font-weight: $font-weight-bold !important;
      text-transform: none;
      color: $color-nero;
      transition: all .5s;

      a {
        color: inherit;

        &:hover {
          color: $color-primary;
        }
      }
    }

    .article-read-time, .article-date {
      font-size: 15px;
      line-height: 1.4em;
      font-family: proxima;
      color: $color-lighter-grey;
      display: inline;
      float: left;
      font-weight: $font-weight-light;
    }

    .article-date:after {
      content: "|";
      padding: 0px 8px 0px 5px;
    }

    .article-main-categories {
      font-size: 16px;
      line-height: 24px;
      font-family: proxima;
      font-weight: $font-weight-normal;
      text-transform: none;
      color: $color-lightest-grey;
      display: inline-block;

      @include when-md {
        display: block;
      }


      a {
        white-space: nowrap;
        color: inherit;
        text-decoration: underline !important;
      }

      ul.wrote-about-menu {
        display: inline-block;
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        ul.child-menu {
          list-style-type: none;
          display: none;
          position: absolute;
          z-index: 100;
          top: 0px;
          margin: 0px;
          padding: 0px;
          margin-top: 15px;
          left: 0px;
          background-color: $color-white;
          border: solid 1px $color-off-white;
          box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.23);
          max-height: 250px;
          overflow-y: scroll;

          > li {
            margin: 0px;
            padding: 0px;

            &:not(:first-child) {
              border-top: solid 1px $color-lightest-grey;
            }

            a {
              display: block;
              padding: 5px 10px;
              text-decoration: none !important;
              font-size: 13px;
              color: $color-lighter-grey;

              &:hover {
                background-color: $color-off-white;
                color: $color-nero;
              }
            }
          }
        }

        > li.menu-item {
          list-style-type: none;
          display: inline-block;

          &:not(:first-child)::before {
            content: ", ";
          }

          &:last-child::before {
            content: "& ";
          }

          position: relative;

          &:hover, &:active {
            &:after {
              content: "";
              position: absolute;
              width: 100%;
              top: 100%;
              height: 2em;
              left: 0;
            }

            > ul.child-menu {
              display: inline-block;
              top: 1em;

              @include when-xs {
                position: fixed;
                width: 90vw;
                height: calc(75vh - 50px);
                max-height: calc(75vh - 50px);
                top: calc(12.5vh + 50px);
                left: 5vw;
                border: solid 1px $color-lightest-grey;
                box-shadow: 0px 7px 300px -2px rgba(0, 0, 0, 0.23);

                // &::before {
                //   content:"x";
                //   background-color:$color-nero;
                //   color:$color-white;
                //   position:fixed;
                //   right:5vw;
                //   top:2.5vh;
                // }
              }
            }
          }
        }
      }
    }

    .article-content-body {
      clear: both;

      &.spacer {
        padding-top: 90px;
      }
    }

    .article-rating {
      margin: 100px 0px 30px;
      padding-bottom: 30px;

      .star-rating {
        display: inline-block;
        padding: 0px;
      }
    }

    .article-sharing {
      display: flex;
      justify-content: center;
      margin: 70px 0px 20px;
      border-top: 1px solid $color-close-white;
      padding-top: 20px;

      .article-sharing-title, ul.article-sharing-icons, ul.article-sharing-icons li {
        font-size: 18px;
        line-height: 33px;
        font-weight: $font-weight-bold;
        font-family: proxima;
        color: $color-lighter-grey;

        a {
          color: $color-lighter-grey;
        }

        a:hover {
          color: $color-primary;
        }
      }

      .article-sharing-title {
        position: relative;
        top: 3px;
        padding-right: 10px;
      }

      ul.article-sharing-icons {
        font-size: 25px;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
          font-size: 25px;
          display: inline;
          padding: 0px 10px;
        }
      }
    }

    padding-bottom: 30px;
    margin-bottom: 30px;

    &:after {
      color: #e6e6e6;
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: calc(100% - 60px);
      margin: auto;
      min-width: 300px;
      display: block;
      overflow: hidden;
      height: 2px;
      background-image: url(https://cdn.calltheone.com/public/images/border.png);
      background-position: top;
      background-repeat: repeat-x;
    }
  }

  .article-content-post {
    div[data-fsid] {
      position: relative;

      @include when-xs {
        min-height: 500px;
      }


      @include when-sm {
        min-height: 550px;
      }


      @include when-lg {
        min-height: 760px;
      }


      iframe {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    p, div.paragraph, li {
      line-height: 1.5em;

      @include when-xs {
        font-size: 14px;
      }


      @include when-sm {
        font-size: 18px;
      }


      @include when-lg {
        font-size: 20px;
      }
    }

    i, em {
      background-color: $color-bright-light-pink;
      font-style: normal;
    }

    ul {
      margin-left: 0px;
      padding-left: 22px;
      list-style-position: outside;
    }

    ul, ol {
      margin-right: 0px;

      ul, ol {
        margin-bottom: 0px;
      }

      &.has-title {
        margin-top: -1.6em;
      }

      &.list-spacing {
        li {
          margin-bottom: 1.6em;
        }
      }

      &.style-check {
        list-style: none;
        padding-left: 0;

        li {
          padding-left: 22px;
          position: relative;

          &:before {
            @include icon-bullet-check($color-primary);

            position: absolute;
            left: 0;
          }
        }
      }

      &.list-bold {
        li {
          font-weight: $font-weight-bold !important;
        }
      }

      li .title {
        font-weight: $font-weight-bold !important;
        display: block;
      }
    }

    a {
      color: $color-primary;
    }

    p > img, p > a > img {
      margin: 50px auto 0;
      display: block;
      max-width: 100%;
      height: auto;
    }

    h2, h3, h4, h5, h6, q {
      line-height: 1.1em;
      font-weight: $font-weight-bold !important;
      padding-bottom: 0px;
      margin-bottom: 0;
      text-transform: none;
      font-size: 26px;

      @include when-xs {
        font-size: 22px;
      }


      @include when-lg {
        font-size: 28px;
      }
    }

    h2 {
      margin-bottom: .6em;
    }

    h3 {
      color: $color-primary;
      margin-bottom: .2em;
    }

    q {
      color: $color-grey;
      display: block;
      padding: 0 80px;
      max-width: 680px;

      @include when-xs {
        padding-right: 0;
      }


      &:before,
      &:after {
        content: none;
      }

      &.float-left {
        color: $color-primary;
        font-weight: $font-weight-normal !important;
        width: 200px;
        padding: 0 10px;
        max-width: none;
        margin-right: 20px;
        margin-bottom: 40px;
        margin-top: 7px;
        padding-top: 20px;
        border-top: 10px solid $color-close-white;
        float: left;

        @include when-md {
          width: 250px;
          margin-right: 40px;
        }
      }

      &.border-left {
        font-weight: $font-weight-normal !important;
        color: $color-darker-grey;
        line-height: 2em;
        border-left: 2px solid $color-darker-grey;
        margin: 50px 0;
        padding: 0;
        padding-left: 15px;
        max-width: none;

        @include when-xs {
          font-size: 14px;
        }


        @include when-sm {
          font-size: 18px;
        }


        @include when-lg {
          font-size: 20px;
        }
      }
    }

    .indent-left {
      padding-left: 75px;
    }

    .top-spacing {
      margin-top: 3em;
    }

    table {
      margin-top: 15px;

      thead {
        border-bottom: solid 1px $color-light-pink;
      }

      tr th, tr td {
        vertical-align: top;
        padding: 2.5px;
      }

      tr td {
        border: solid 0.5px $color-lightest-grey;
        border-left-width: 0px;
        border-right-width: 0px;
      }
    }

    iframe {
      max-width: 100%;
    }

    p, div.paragraph, ol, ul, table, q {
      margin-bottom: 1.6em;
    }

    blockquote {
      background-color: $color-bright-light-pink;
      border: none;
      padding: 0;
      margin: 3em 0;
      padding: 40px 30px;

      blockquote {
        padding: 0;
        margin: 0;
      }

      span, p {
        background-color: #FACBCC;
        display: inline;
        margin-bottom: 0;
      }

      em, i {
        background: none;
      }
    }
  }

  .experts-title-wrapper {
    h3.article-experts-title, h3.expert-results-title {
      font-family: bebasNeuebold;
      color: $color-light-pink;
      font-size: 42px;
      line-height: 36px;
      padding: 0px;
      margin: 0px;
    }

    .expertise-title, .expert-results-subtitle {
      background-color: $color-nero;
      color: $color-white;
      font-family: bebasNeuebold;
      display: inline-block;
      position: relative;
      top: -10px;
      font-size: 16px;
      letter-spacing: 0.5px;
      padding: 3px 4px 0px;

      &:before {
        content: "#";
      }
    }
  }

  .blog-header {
    .blog-page-follow {
      @extend %col-lg-3-5ths;

      @include make-md-column(9);

      @include make-sm-column(8);

      @include make-xs-column(12);

      display: flex;
      justify-content: center;
      margin: 20px 0px;

      .blog-page-follow-title, ul.blog-page-follow-icons, ul.blog-page-follow-icons li {
        font-size: 20px;
        line-height: 40px;
        font-weight: $font-weight-bold;
        font-family: proxima;
        color: $color-lighter-grey;

        a {
          color: $color-lighter-grey;
        }

        a:hover {
          color: $color-primary;
        }
      }

      .blog-page-follow-title {
        position: relative;
        top: 3px;
        padding-right: 10px;
      }

      ul.blog-page-follow-icons {
        font-size: 25px;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
          font-size: 25px;
          display: inline;
          padding: 0px 10px;
        }
      }
    }

    .blog-menu-holder {
      @include make-row;

      .blog-menu,
      .blog-menu-toggle {
        @include make-xs-column(12);
      }
    }

    .blog-menu {
      display: flex;
      justify-content: center;
      margin-bottom: 55px;

      @include when-sm {}


      @include when-md {
        margin-bottom: 80px;
      }


      @include when-lg {
        margin-bottom: 55px;
      }


      ul {
        border-bottom: 1px solid $color-lightest-grey;
      }

      ul, li {
        padding: 0px;
        margin: 0px;
        list-style-type: none;
      }

      > ul > li {
        display: inline-block;
        font-size: 18px;
        position: relative;

        @include when-sm {
          &:last-child {
            > ul {
              right: 0;
            }
          }
        }
      }

      li.selected > a {
        color: $color-primary;
      }

      > ul > li:not(:last-child)::after {
        content: "";
        position: relative;
        display: inline-block;
        height: 20px;
        width: 1px;
        background-color: $color-nero;
        vertical-align: middle;
      }

      > ul > li > a {
        font-family: proxima;
        font-size: 16px;
        line-height: 50px;
        color: $color-nero;
        display: inline-block;
        padding: 0px 20px;
        text-transform: uppercase;
        position: relative;
        vertical-align: middle;

        &:hover {
          color: $color-primary;
        }

        @include when-sm {
          font-size: 18px;
          padding: 0px 10px;
        }


        @include when-md {
          padding: 0px 15px;
        }


        @include when-lg {
          font-size: 20px;
          padding: 0px 20px;
        }
      }

      > ul > li {
        &:first-child a {
          padding-left: 0;
        }

        &:last-child a {
          padding-right: 0;
        }
      }

      > ul > li > ul {
        display: none;
        position: absolute;
        z-index: 100;
        top: 50px;
        width: 280px;
        max-height: 365px;
        overflow-y: scroll;
        background-color: $color-white;
        box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.23);

        @include when-lg {
          width: 400px;
        }


        > li:not(:last-child) {
          border-bottom: solid 0.5px $color-lightest-grey;
        }

        > li > a {
          display: block;
          padding: 7.5px 20px;
          font-size: 16px;
          line-height: 19px;
          font-family: proxima;
          color: $color-grey;

          &.selected, &:hover {
            color: $color-nero;
            background-color: $color-off-white;
          }
        }
      }

      > ul > li:hover ul {
        display: block;
      }
    }

    .blog-banner-holder {
      @include make-row;

      .blog-banner {
        @include make-xs-column(12);
      }
    }

    .blog-sub-menu-holder {
      @include make-row;

      ul.category-menu {
        @extend %col-lg-2-5ths;

        @include make-md-column(3);

        @include make-sm-column(4);

        @include make-xs-column(12);

        display: flex;
        justify-content: center;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
          padding: 0px;
          margin: 0px;

          a {
            padding: 15px 0px;
            display: inline-block;
            font-size: 24px;
            line-height: 40px;
            color: $color-lightest-grey;

            &:hover {
              color: $color-nero;
            }
          }
        }
      }
    }
  }

  .blog-content {
    .blog-title-wrapper {
      @include make-row;

      h1.blog-title {
        @include make-xs-column(12);

        @include make-sm-column(12);

        @include make-md-column(10);

        @extend %col-lg-3-5ths;

        color: $color-light-pink;
        font-size: 78px;
        line-height: 70px;
        text-transform: uppercase;
        font-family: bebasNeuebold;
        margin: 0px 0px;
      }
    }
  }

  .article-list-item.normal {
    @include make-xs-column(12);
  }

  .article-list-item.below {
    @include make-xs-column(12);

    @include when-sm-down {
      .article-list-item-thumbnail {
        width: 80px;
        height: 80px;
        float: none;
        position: absolute;
        bottom: 1px;
        top: auto;
        line-height: 1;
      }

      .article-list-item-details {
        width: 100%;
        padding: 20px 10px 0;
      }

      .article-list-item-title {
        padding: 0;
        font-size: 18px;
        line-height: 1.2;
      }

      .article-list-item-author,
      .article-list-item-author + div {
        width: calc(100% - 80px);
      }
    }
  }

  &.las_f {
    .blog-content {
      .article-list.full {}

      .article-list-item.full {
        @include make-xs-column(12);
      }
    }
  }

  &.lbs_n .blog-header {
    .blog-page-follow .blog-page-follow-title, .blog-page-follow ul.blog-page-follow-icons, .blog-page-follow ul.blog-page-follow-icons li {
      font-size: 18px;
      line-height: 33px;
    }

    .blog-menu {
      // margin-bottom: 0px;
  
    }

    .blog-menu > ul > li > a {
      font-size: 20px;
      padding-bottom: 17.5px;

      @include when-sm {
        font-size: 14px;
      }


      @include when-md {
        font-size: 18px;
        padding: 0px 10px 0;
      }


      @include when-lg {
        font-size: 20px;
        padding: 0px 20px 0;
      }
    }

    .blog-menu > ul > li {}

    .blog-menu > ul > li:not(:last-child)::after {
      height: 16px;
    }
  }

  /*normal layout style*/
  &.lbs_n {
    .blog-content {
      h1.blog-title, h1.article-title {
        font-size: 64px;
        line-height: 57px;
      }

      h3.article-list-header {
        font-size: 32px;
        line-height: 38px;
      }

      .article-list-item.normal {
        @include make-xs-column(12);
      }
    }
  }

  &.lap_m {
    .blog-content {
      h1.blog-title {
        margin-bottom: 20px;
      }
    }
  }

  &.las_m {
    .blog-content {
      .article-list.medium {
        display: flex;
        flex-wrap: wrap;
      }

      .article-list-item.medium {
        @include make-sm-column(6);

        // flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        display: flex;
        flex-direction: column;

        .article-list-item-title {
          font-size: 28px;
          line-height: 1.2;
          padding: 0 0 15px;

          @include when-md {
            font-size: 32px;
          }
        }

        .article-list-item-image {
          height: 380px;
          width: 100%;
          object-fit: cover;

          @include when-xs {
            max-height: 355px;
            height: auto;
          }


          @include when-sm {
            height: 236.67px;
          }


          @include when-md {
            height: 256.25px;
          }


          @include when-lg {
            height: 403px;
          }
        }
      }
    }
  }

  &.las_s {
    .blog-content {
      .article-list.small {}

      .article-list-item.small {
        @include make-sm-column(6);

        clear: none;
        width: 50%;
        min-height: 100px;
        max-height: 100px;
        height: 100px;

        @include when-xs {
          width: 100%;
        }


        .bordered {
          display: flex;
          flex-direction: column;
          height: 100%;

          &::before, &::after {
            background-image: -webkit-gradient(linear, 0 0, 0 40, from(rgba($color-lightest-grey, 0)), to(rgba($color-lightest-grey, 0.7)));
            top: 0px;
          }
        }

        @include when-sm {
          .article-list-item-title {
            font-size: 18px;
          }
        }


        @include when-md {
          .article-list-item-title {
            font-size: 21px;
          }
        }


        .article-list-item-thumbnail {
          bottom: 1px;
          top: auto;
          position: absolute;
        }
      }
    }
  }
}

/* mobile menu */

@include when-sm-down {
  [data-to-root]#app-layout.experts .blog-header {
    .blog-menu-holder .blog-menu-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      font-family: proxima;
      font-size: 20px;
      line-height: 20px;
      color: $color-nero;
      padding: 5px 20px;
      text-transform: uppercase;
      position: relative;
      width: 730px;
      margin: auto;
      float: none;

      &:before {
        content: '';
        background-color: $color-off-white;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        left: 10px;
        z-index: -1;
      }

      &:after {
        @include icon-menu($color-nero);

        height: 16.27px;
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;
      }

      &.active {
        &:after {
          @include icon-close($color-nero);
        }
      }
    }

    .blog-menu {
      position: absolute;
      background-color: $color-white;
      overflow: auto;
      width: 100%;
      z-index: 100;

      > ul {
        display: none;
        height: 0px;
        padding-top: 20px;
        overflow: auto;

        /* this is the same as .border-after, but cannot extend */
        &:after {
          content: "";
          color: #E6E6E6;
          top: 20px;
          position: relative;
          width: 100%;
          display: block;
          overflow: hidden;
          height: 2px;
          background-image: cdn("/images/border.png");
          background-position: top;
          background-repeat: repeat-x;
        }
      }

      > ul > li {
        display: block;
        padding: 0px !important;
        margin: 0px !important;

        &:not(:last-child)::after {
          display: none;
        }
      }

      > ul > li:hover ul {
        display: none;
      }

      > ul > li > a {
        line-height: 20px !important;
        padding: 10px 0px;
        font-size: 20px !important;
        text-align: center;
        display: block;
        top: 0px;
      }

      &.active > ul {
        display: block;
        height: calc(100vh - 90px);
      }
    }

    .blog-banner {
      margin: 20px 0px 0px;
    }

    .blog-page-follow .blog-page-follow-title, .blog-page-follow .blog-page-follow-icons {
      font-size: 17px;
    }
  }
}


@include when-xs {
  [data-to-root]#app-layout.experts .blog-header {
    .blog-menu-holder .blog-menu-toggle {
      width: 375px;
    }
  }
}


@include when-md {
  [data-to-root]#app-layout.experts .blog-header .blog-menu-toggle {
    display: none;
  }
}
