/*
 * login / registration / onboarding styles
 */

/* ----------------------------------- generic in these modals ------------------------------------- */

.vertical-align {
  display: flex;
  align-items: center;
}

.dashline {
  height: 1px;
  background-image: linear-gradient(to right, #ccc 50%, rgba(255, 255, 255, 0) 0);
  background-size: 32px 1px;
  background-position: -5px 0;
  background-repeat: repeat-x;
}

.btn-previous {
  background: transparent;
  border: none;
  border-right: 2px solid $color-lightest-grey;
  border-bottom: 2px solid $color-lightest-grey;
  width: 13px;
  height: 13px;
  transform: rotate(-225deg);
  padding: 0;
  margin-left: 3px;
}

.modal-flavor {
  position: absolute;
  left: 2px;
  bottom: -2px;

  .modal-flavor-anchor {
    width: 1px;
    height: 1px;
    overflow: visible;
    transform: rotate(-90deg);
  }

  .modal-flavor-text {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    font-size: 58px;
    line-height: 50px;
    color: $color-light-pink;
    width: 300px;
    user-select: none;
  }
}

#profile-became-expert.modal,
#login-user.modal,
#register-selfinvite.modal,
#register-simple-success.modal,
#register-user.modal,
#register-user-details.modal,
#register-user-emailconfirm.modal,
#register-user-onboarding.modal,
#register-user-onboarding-tags.modal,
#password-user.modal,
#reset-user.modal {
  .modal-dialog {
    position: relative;
    width: 730px;
  }

  .modal-content {
    width: 100%;
    background-color: transparent;
    border: none !important;
    overflow: hidden;

    @include box-shadow(none !important);
  }

  .modal-header {
    background-color: transparent;
    border: none !important;

    .close {
      opacity: $dark-alpha;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-body {
    width: 100%;
    height: 500px;
    padding: 0;
    background-color: white;
    background-image: none;

    form {
      .row {
        padding-bottom: 10px;
      }
    }

    .icon {
      padding-bottom: 20px;
      text-align: center;
    }

    .buttons {
      padding-top: 0;
      position: static;
    }
  }

  .modal-page {
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      position: absolute;
      left: 108px;
      width: 335px;
      height: 412px;
      background-color: $color-lighter-blue;
    }
  }

  .modal-main {
    text-align: center;
    color: $color-dark-grey;

    h5 {
      text-transform: uppercase;
      font-size: 16px;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-black !important;
      line-height: 15px;
      white-space: normal;
    }

    p {
      font-size: 14px;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      line-height: 16px;
    }
  }

  .has-error .form-control {
    border: solid 1px $color-warning !important;
  }

  .buttons {
    @include when-sm {
      min-width: 220px;
      float: right;
    }


    &.previous {
      z-index: 999;
      float: none;
      position: absolute;
      left: 0;
      top: 64px;
      display: none;
    }

    .bigbutton {
      margin-bottom: 10px;

      @include when-xs {
        width: 200px;
      }
    }
  }

  .buttons-alternative {
    clear: both;
    text-align: right;
    padding: 10px 0;
  }
}

.modal-registration-signup-progress {
  text-align: center;
  position: absolute;
  bottom: 25px;
  left: 108px;
  width: 335px;
}

.modal-registration-signup-progress-markers {
  display: inline-block;

  span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: $color-grey;
    border-radius: 50%;

    &.active {
      background-color: $color-black;
    }
  }
}

/* ----------------------------------- login modal ------------------------------------- */

#login-user.modal {
  .modal-registration-login {
    background: cdn("/images/onboarding/login.jpg") no-repeat center center;
    background-size: cover;

    &:before {
      display: none;
    }

    .modal-flavor-text {
      color: $color-white;
    }
  }

  .login-main {
    padding-top: 150px;
  }

  .login-email-main {
    padding: 40px 0;
  }

  .modal-registration-login-email {
    .buttons {
      padding-top: 40px;
    }
  }
}

/* ----------------------------------- forgot pw modal ------------------------------------- */

#reset-user.modal,
#password-user.modal {
  .reset-email-main {
    padding: 40px 0;

    @include when-xs {
      padding: 40px 30px;
    }
  }

  .modal-registration-reset {
    .buttons {
      padding-top: 40px;
    }

    @include when-xs {
      > div:last-child {
        display: flex;
        flex-direction: column;

        > div:last-child {
          margin-left: auto;
        }
      }

      .icon {
        padding-bottom: 0;
        padding-top: 20px;
      }

      .bigbutton {
        width: 100%;
      }
    }
  }
}

/* ----------------------------------- register modal steps ------------------------------------- */

#register-user.modal {
  .signup-main {
    padding-top: 40px;
  }

  .signup-email-main {
    padding: 40px 0;
  }

  .modal-registration-signup-email {
    .buttons {
      padding-top: 40px;
    }

    .form-row {
      padding-bottom: 10px;
    }
  }

  .modal-registration-signup {
    background: cdn("/images/onboarding/signup.jpg") no-repeat center center;
    background-size: cover;

    &:before {
      display: none;
    }

    .buttons {
      padding-top: 80px;
    }

    .modal-flavor-text {
      color: $color-white;
    }
  }

  .modal-registration-signup-choose {
    @include when-xs {
      .signup-email-main {
        padding: 20px 0;
      }

      .modal-flavor-text {
        br {
          display: none;
        }
      }
    }


    .signup-level-choose-container {
      display: flex;
      align-items: flex-end;

      @include when-xs {
        display: block;
      }
    }

    .signup-level-choose-box {
      flex: 1;
      text-align: center;
      margin-right: 10px;
      background-color: $color-white;

      @include when-xs {
        margin: 0 20px 10px 40px;
        width: 100%;
      }
    }

    .signup-level-choose-content {
      padding: 20px 10px;
      font-size: 14px;
      position: relative;

      p {
        margin-bottom: 10px;
        padding: 0 10px;
      }

      @include when-xs {
        padding: 10px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .signup-level-choose-title {
      text-transform: uppercase;
      font-weight: bold;
      color: $color-nero;
      margin-bottom: 10px;
    }

    .signup-level-choose-highlight {
      background-color: $color-bright-blue;
      padding: 10px 20px;
      margin: 0 10px;
    }

    .signup-level-choose-important {
      font-weight: bold;
      text-transform: uppercase;
    }

    .signup-level-choose-subtitle {
      text-transform: uppercase;
      font-weight: bold;
      color: $color-light-grey;
      margin-bottom: 10px;
      font-weight: 800 !important;
    }

    .signup-level-choose-secondtitle {
      text-transform: uppercase;
      font-weight: bold;
      color: $color-light-grey;
      margin-bottom: 10px;
    }

    .signup-level-choose-buttons {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;

      .btn-signup-choose {
        min-width: 70px;
        flex: 1;
        height: 35px;
        background-color: $color-lightest-grey;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 14px;
        outline: 0;
        border: 0 none;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }

      .btn-signup-choose-email {
        background-image: url("/images/icons/icon-mail-white.svg");
      }

      .btn-signup-choose-fb {
        background-image: url("/images/icons/icon-fb-white.svg");
        background-color: #4469b0;
        background-size: auto 18px;
      }
    }
  }

  .modal-registration-signup-simple {
    .modal-main {
      position: relative;
      padding-top: 40px;
      padding-bottom: 20px;
      max-width: 330px;
      margin: auto;

      p {
        margin-top: 10px;
      }
    }

    form {
      font-size: 14px;

      .row {
        div:first-child {
          padding-right: 0;
        }
      }

      .buttons {
        padding-top: 180px;
      }

      @include when-xs {
        .icon {
          display: none;
        }

        .buttons {
          padding-top: 0;
          float: right;
        }
      }
    }

    .radio-container {
      display: flex;
    }
  }

  .certified-signup {
    img {
      width: 100%;
    }
  }
}

/* -------------------------------------- confirm email notification -------------------------------------- */

#register-user-emailconfirm.modal {
  .modal-registration-signup-confirm {
    @include when-xs {
      display: flex;
      flex-direction: column;

      > div:nth-child(3) {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;

        > div:last-child {
          flex: 1;
        }
      }
    }


    .icon {
      padding-top: 50px;
      padding-bottom: 30px;
    }

    .buttons {
      padding-top: 120px;

      @include when-xs {
        padding-top: 0;
        position: absolute;
        right: 10px;
        bottom: 48px;
      }
    }

    .signup-confirm-main {
      padding: 0 20px;
    }
  }
}

/* ---------------------------------- simple signup confirm (same id, different class) ----------------------------------- */

#register-user-emailconfirm.modal {
  .modal-signup-simple-confirm {
    .signup-simple-confirm-main {
      padding: 40px 28% 30px;
      position: relative;

      h5 {
        padding-bottom: 70px;
      }

      p {
        color: $color-darkest-grey;
      }

      .icon {
        position: absolute;
        top: 150px;
        right: 100px;
      }

      @include when-xs {
        padding: 40px 40px 30px;

        .icon {
          display: none;
        }
      }
    }

    .simple-confirm-bottom {
      position: relative;
      margin-top: 130px;

      p {
        text-align: center;
        margin: 0;
      }

      @include when-xs {
        margin-top: 60px;
      }
    }

    .buttons {
      position: absolute;
      top: 0;
      right: 0;

      @include when-xs {
        position: static;
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

/* -------------------------------------- simple signup success -------------------------------------- */

#register-simple-success.modal {
  .modal-registration-simple-success {
    .simple-success-main {
      padding: 40px 28% 30px;

      h5 {
        padding-bottom: 60px;
      }

      p {
        color: $color-darkest-grey;
      }

      .la-i {
        cursor: help;
        vertical-align: middle;
      }

      @include when-xs {
        padding: 40px 40px 30px;

        h5 {
          padding-bottom: 20px;
        }
      }
    }

    .simple-success-bottom {
      position: relative;

      p {
        text-align: center;
        margin: 10px 0 0 0;
      }
    }

    .buttons {
      position: absolute;
      top: 0;
      right: 0;

      @include when-xs {
        position: static;
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

#register-user-details.modal {
  /* ------------------------- basics -------------------------- */

  .modal-registration-signup-basics {
    .signup-basics-opener {
      .ambient {
        margin-left: 108px;
        width: 335px;
        height: 412px;
        background: cdn("/images/onboarding/basics.jpg") no-repeat center center;
        background-size: cover;

        @include when-xs {
          margin-left: 0;
        }
      }

      p {
        margin-left: 108px;
        width: 335px;
        padding-top: 10px;

        @include when-xs {
          margin-left: 0;
        }
      }

      .certificate {
        margin-top: 130px;
        text-align: right;
      }
    }

    .signup-basics-main {
      padding-top: 66px;

      .buttons {
        padding-top: 80px;
      }
    }
  }

  .modal-registration-signup-basics-avatar {
    .signup-basics-avatar-main {
      padding-top: 30px;
      padding-bottom: 20px;
    }

    .avatar-container {
      margin-left: 128px;
      width: 315px;
      height: 315px;
      background-color: #F2F2F2;
      background-image: cdn("/images/onboarding/icon-avatar.png");
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      cursor: pointer;

      &.user_selected {
        background-size: cover;
      }

      a {
        background-color: $color-dark-grey;
        color: $color-white;
        display: block;
        position: absolute;
        top: 142px;
        padding: 8px 10px 4px 10px;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: 17px;
      }
    }

    input#avatar {
      display: none;
    }

    .buttons {
      padding-top: 240px;
    }
  }

  .modal-registration-signup-basics-info {
    .signup-basics-info-main {
      padding-top: 30px;
      padding-bottom: 30px;

      @include when-xs {
        padding-bottom: 20px;
      }
    }

    .form-row {
      padding-bottom: 10px;
    }

    .form-label {
      font-size: 12px;
      padding-left: 0;
      padding-right: 0;
    }

    label.radio-select {
      span {
        &:before {
          background-color: $color-white;
        }
      }
    }
  }

  .modal-registration-signup-basics-languages {
    .signup-basics-languages-main {
      padding-top: 30px;
      padding-bottom: 70px;
    }

    .language-selections {
      margin-left: 108px;
    }

    .language-controls {
      clear: both;
      margin-left: 108px;
    }

    .btn-add-another-language {
      background-color: $color-white;
      color: $color-black;
      display: block;
      width: 55%;
      text-align: left;
      border: 1px solid $color-off-white;
    }

    .avatar-container-masked {
      margin-bottom: 40px;
    }

    label.radio-select {
      top: -5px;

      span {
        &:before {
          border-color: $color-grey;
        }
      }
    }

    .radio-label {
      display: inline-block;
      width: 150px;
      line-height: 16px;
    }
  }

  .modal-registration-signup-basics-currency {
    @include when-xs {
      display: flex;
      flex-direction: column;

      > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        flex: 1;

        >div:last-child() {
          margin-top: auto;
          margin-bottom: 48px;
        }
      }
    }


    .signup-basics-currency-main {
      padding-top: 30px;
      padding-bottom: 70px;
    }

    .currency-selections {
      min-height: 50px;
      float: right;
      width: 230px;
    }

    .avatar-container-masked {
      @include when-xs {
        display: none;
      }
    }
  }

  .modal-registration-signup-basics-certificate {
    .signup-basics-certificate-main {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    .certificate {
      margin-left: 120px;
      text-align: right;
    }

    .certificate-rules {
      margin-bottom: 40px;
    }

    .certificate-commission {
      background-color: $color-off-white;
      padding: 15px 0 0 10px;
    }

    .certificate-decline {
      padding: 15px 0 0 10px;
    }

    label.radio-select {
      top: -5px;

      span {
        margin-left: 22px;

        &:before {
          border-color: $color-grey;
        }
      }
    }

    .radio-label {
      display: inline-block;
      width: 230px;
      line-height: 16px;
    }
  }

  /* avatar container, both on step info and step languages */

  .avatar-container-masked {
    width: 126px;
    height: 129px;
    overflow: hidden;
    position: relative;
    margin: auto;
    margin-bottom: 80px;

    .avatar-container-masked-mask {
      position: absolute;
      z-index: 10;
      width: 126px;
      height: 129px;
      background: cdn("/images/onboarding/avatar-mask.png") center center no-repeat;
      background-size: cover;
    }

    .avatar-container-masked-img {
      position: absolute;
      z-index: 5;
      width: 122px;
      height: 125px;
      top: 2px;
      left: 2px;
      background-color: $color-off-white;
      background-image: cdn("/images/onboarding/icon-avatar.png");
      background-position: center center;
      background-repeat: no-repeat;

      &.user_selected {
        background-size: cover;
      }
    }
  }

  .bootstrap-select {
    select.bs-select-hidden,
    select.selectpicker {
      display: none !important;
    }

    .dropdown-menu {
      min-width: 100%;

      @include box-sizing(border-box);

      &.inner {
        position: static;
        float: none;
        border: 0;
        padding: 0;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
      }

      li {
        position: relative;
        font-size: 16px;

        &.active small {
          color: #fff;
        }

        &.disabled a {
          cursor: not-allowed;
        }

        a {
          cursor: pointer;
          user-select: none;

          &.opt {
            position: relative;
            padding-left: 2.25em;
          }

          span.check-mark {
            display: none;
          }

          span.text {
            display: inline-block;
          }
        }

        small {
          padding-left: 0.5em;
        }
      }

      .notify {
        position: absolute;
        bottom: 5px;
        width: 96%;
        margin: 0 2%;
        min-height: 26px;
        padding: 3px 5px;
        background: rgb(245, 245, 245);
        border: 1px solid rgb(227, 227, 227);

        @include box-shadow(inset 0 1px 1px fade(rgb(0, 0, 0), 5));

        pointer-events: none;
        opacity: 0.9;

        @include box-sizing(border-box);
      }
    }

    &.day,
    &.month,
    &.year {
      width: 32%;
    }

    .btn {
      background-color: $color-white;
      border: 1px solid $color-off-white;
    }

    &.btn-group .dropdown-toggle .bs-caret {
      display: inline-block;
      float: right;
    }

    &.btn-group .dropdown-toggle .caret {
      right: 4px;
    }

    .dropdown-menu {
      opacity: 1;
    }

    & > .dropdown-toggle {
      max-height: 36px;
      padding-right: 9px;
      min-width: 65px;
    }

    &.language-selection {
      width: 55%;
      margin-right: 5px;
    }

    &.grade-selection {
      width: 35%;
      margin-left: 0px;
    }

    .filter-option {
      font-size: $font-size-small;
      color: $color-grey;
      padding-top: 4px;
      padding-left: 2px;

      &:hover {
        background-color: $color-white;
      }
    }

    .bs-placeholder {
      padding-left: 3px;
    }

    &.btn-group {
      margin-bottom: 5px;
    }

    &.form-control {
      margin-bottom: 0;
      padding: 0;
      border: none;

      &:not([class*="col-"]) button {
        width: 100%;
      }

      &.input-group-btn {
        z-index: auto;

        &:not(:first-child):not(:last-child) {
          > .btn {
            border-radius: 0;
          }
        }
      }
    }
  }

  .btn-remove-language {
    background-color: transparent;
    color: $color-grey;
    font-size: 26px;
    line-height: 1;
    padding: 2px;
    margin: 8px 0 0 5px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border: 0 none;
  }
}

#profile-became-expert.modal {
  .icon {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .modal-body .buttons {
    padding-top: 100px;
  }
}

/* -------------------------------------- static invite modal -------------------------------------- */

#register-selfinvite.modal {
  .modal-registration-signup-selfinvite {
    .signup-selfinvite-main {
      padding: 40px 0;

      h5 {
        color: $color-dark-grey;
        padding-bottom: 10px;
      }
    }

    .buttons {
      padding-top: 40px;
    }

    .form-row {
      padding-bottom: 10px;
    }

    #certificate-badge-container {
      img {
        width: 100%;
      }
    }

    @media only screen and (max-width: 729px) {
      .signup-selfinvite-main {
        padding: 15px 15px 0 15px;
      }

      .form-inputs {
        padding: 0 25px 0 95px;
      }

      form > .row > div {
        float: none;
        width: auto;
      }

      .form-row {
        display: block;

        & > div {
          float: none;
          width: auto;
        }
      }

      .icon {
        display: none;
      }

      .buttons {
        padding-top: 20px;
      }
    }
  }
}

/* ----------------------------------- register modal steps END ------------------------------------- */

/* ----------------------------------- onboarding modal steps ------------------------------------- */

#register-user-onboarding.modal {
  .modal-page {
    &:before {
      display: none;
    }
  }

  .modal-flavor {
    z-index: 1;
  }

  /* --------------- onboarding / start ------------------ */

  .modal-registration-onboarding-start {
    > div:nth-child(2) {
      @include when-xs {
        display: flex;
        height: 100%;
      }
    }

    .onboarding-start-opener {
      div {
        margin-left: 108px;
        width: 335px;
        height: 412px;
        background: cdn("/images/onboarding/expert.jpg") no-repeat center center;
        background-size: cover;

        @include when-xs {
          margin-left: 77px;
          opacity: .7;
        }
      }

      p {
        margin-left: 108px;
        width: 335px;
        padding-top: 10px;
      }
    }

    .onboarding-start-main {
      padding-top: 55px;

      @include when-xs {
        display: flex;
        flex-direction: column;

        h5, p, .icon {
          mix-blend-mode: difference;
        }
      }


      .text {
        p {
          padding: 0 10px 10px 10px;
        }

        a {
          display: inline-block;
          background: url(svg-encode(icon-play($color-dark-grey))) no-repeat left center;
          padding-left: 50px;
          height: 42px;
          padding-top: 8px;
          text-align: left;
          color: $color-black;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-bold !important;
          font-size: 14px;
          line-height: 14px;
        }
      }

      .buttons {
        padding-top: 20px;

        @include when-xs {
          margin-top: auto;

          .bigbutton {
            margin-left: auto;
          }
        }
      }
    }
  }

  /* --------------- onboarding / video ------------------ */

  .modal-registration-onboarding-movie {
    @include when-xs {
      > div:last-child,
      > div:last-child > div {
        height: 100%;
      }
    }
  }

  .onboarding-movie-main {
    &:after {
      display: block;
      content: " ";
      clear: both;
    }

    .onboarding-movie-main-text {
      float: left;
      width: 142px;
      margin-top: 135px;

      @include when-xs {
        float: none;
        margin-top: 50px;
        width: 100%;
      }
    }

    .onboarding-movie-main-full {
      float: left;
      width: 500px;
      height: 500px;
      background-color: #F6F6F6;
      display: flex;
      align-items: center;

      video {
        width: 100%;
      }

      @include when-xs {
        float: none;
        width: 100%;
        height: 0;
        padding-top: 57%;
        position: relative;

        video {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .buttons {
      float: right;
      width: auto;
      margin-top: 375px;
      min-width: unset;

      &.next {
        right: 10px;
      }
    }
  }

  /* --------------- onboarding / expertises ------------------ */

  .onboarding-expertise-main {
    position: relative;

    @include when-xs {
      height: 100%;
      display: flex;
      flex-direction: column;
    }


    &:after {
      display: block;
      content: " ";
      clear: both;
    }

    .onboarding-expertise-main-text {
      float: left;
      width: 293px;
      margin-top: 70px;
      text-align: left;
      padding: 0 20px;

      @include when-xs {
        float: right;
        margin-top: 20px;
        width: 100%;

        h5 {
          text-align: center;
          width: calc(100% - 60px);
          margin-left: auto;
          margin-right: auto;
          min-height: 3em;
        }
      }
    }

    .onboarding-expertise-main-full {
      float: left;
      width: 355px;
      height: 500px;
      display: flex;
      flex-direction: column;

      @include when-xs {
        height: auto;
        float: none;
        width: 100%;
        max-width: 355px;
        margin-bottom: 80px;
        margin-top: auto;
      }
    }

    .onboarding-expertise {
      width: 355px;
      height: 250px;
      background-size: 355px;
      background-repeat: no-repeat;
      padding: 5px 25px;
      display: flex;
      flex-direction: column;

      @include when-xs {
        width: 100%;
        height: 100px;
        padding: 5px 20px;
        padding-left: 0;
        background-size: 250px;
      }


      &.onboarding-expertise-first {
        background-position: bottom center;
        align-items: flex-start;
        justify-content: flex-end;

        @include when-xs {
          background-position: center left;
        }


        label.radio-select {
          &:before {
            content: '';
            width: 333px;
            height: 205px;
            bottom: -10px;
            left: -25px;
            position: absolute;

            @include when-xs {
              width: 250px;
              height: 100px;
              left: -77px;
            }
          }
        }
      }

      &.onboarding-expertise-second {
        background-position: top center;
        align-items: flex-end;
        justify-content: flex-start;

        @include when-xs {
          align-items: flex-start;
          background-position: center right;
        }


        label.radio-select {
          &:before {
            content: '';
            width: 330px;
            height: 200px;
            top: -5px;
            left: 0px;
            position: absolute;

            @include when-xs {
              width: 250px;
              height: 100px;
            }
          }
        }
      }

      &.onboarding-expertise-first .expertise-description,
      &.onboarding-expertise-first .expertise-select {
        @include when-xs {
          margin-left: 77px;
        }
      }

      &.onboarding-expertise-second {
        @include when-xs {
          padding-left: 77px;
        }
      }

      .expertise-description {
        background-color: $color-black;
        color: $color-white;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: 16px;
        padding: 5px 10px 3px 10px;
        text-align: left;

        @include when-xs {
          font-size: 12px;
        }
      }

      .expertise-select {
        background-color: $color-white;
        color: $color-black;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: 26px;
        width: 305px;
        height: 35px;
        display: flex;

        @include when-xs {
          width: auto;
          font-size: 20px;
          height: 30px;
        }


        .radio-select {
          span {
            @include when-xs {
              margin-left: 25px;
            }


            &:before {
              border-radius: 0;
              border: 2px solid #DADADA;
              width: 35px;
              height: 35px;

              @include when-xs {
                width: 30px;
                height: 30px;
              }
            }

            &:after {
              background-image: none;
              background-color: #DADADA;
              -webkit-mask-image: cdn("/images/icon-check-blue.svg");
              mask-image: cdn("/images/icon-check-blue.svg");
              width: 15px;
              height: 16px;
              top: 9px;
              left: 10px;
              opacity: .3;
              transition-property: none;

              @include when-xs {
                top: 8px;
                left: 8px;
              }
            }
          }

          input:checked + span:before {
            background-color: $color-black;
            border-color: $color-black;
          }

          input:checked + span:after {
            background-color: $color-white;
            opacity: 1;
          }

          &:hover input + span:after {
            opacity: 1;
          }

          &:hover {
            span {
              &:after {
                opacity: 1;
              }
            }
          }
        }

        .radio-label {
          padding: 2px 10px 0 5px;
          text-align: left;
        }
      }
    }

    .buttons {
      float: right;
      width: auto;
      margin-top: 375px;
      min-width: fit-content;

      &.buttons-over {
        float: none;
        position: absolute;
        right: 0;
        top: 0;

        @include when-xs {
          top: auto;
          bottom: 0;
          margin-top: 0;
        }


        .btn-submit {
          @include when-xs {
            min-width: 220px;
          }
        }
      }
    }
  }

  .modal-registration-onboarding-noexpertise {
    position: relative;

    > div:last-child {
      @include when-xs {
        height: 100%;
      }
    }

    .onboarding-expertise-main {
      .onboarding-expertise {
        width: 400px;

        &.onboarding-expertise-first {}

        &.onboarding-expertise-second {
          align-items: flex-start;
        }
      }
    }

    .buttons {
      float: none;
      position: absolute;
      top: 0;
      right: 0;

      @include when-xs {
        top: auto;
        bottom: 0;
        right: 10px;
        margin-top: 0;
      }
    }
  }

  .modal-registration-onboarding-expertise {
    .modal-registration-signup-progress {
      left: 300px;

      @include when-xs {
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 100px);
        bottom: 10px;
        z-index: -1;
      }
    }
  }

  .buttons .bigbutton {
    @include when-xs {
      &.btn-previous,
      &.btn-next {
        width: 50px;
        height: 35px;

        .bigbutton-word {
          text-align: center;
          padding: 7px 5px 0;
          line-height: 1;
          font-size: 25px;
        }
      }
    }
  }

  .btn-previous {
    background-image: none;
    border: none;
    transform: none;
    margin-left: 0;
  }

  .buttons.previous {
    @include when-xs {
      top: 54px;
    }
  }

  .buttons.next {
    // @include when-xs{
    float: none;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 0px;

    // }

  }
}

/* --------------- onboarding / tags ------------------ */

#register-user-onboarding-tags.modal {
  .modal-page {
    &:before {
      display: none;
    }

    display: flex;

    @include when-xs {
      flex-direction: column-reverse;
    }
  }

  /* avatar container, both on step info and step languages */

  .avatar-container-masked {
    width: 126px;
    height: 129px;
    overflow: hidden;
    position: relative;
    margin: auto;
    margin-bottom: 80px;

    @include when-xs {
      width: 103px;
      height: 102px;
      margin-top: 30px;
      margin-bottom: 0;
      display: none;
    }


    .avatar-container-masked-mask {
      position: absolute;
      z-index: 10;
      width: 126px;
      height: 129px;
      background: cdn("/images/onboarding/avatar-mask.png") center center no-repeat;
      background-size: cover;

      @include when-xs {
        width: 103px;
        height: 102px;
      }
    }

    .avatar-container-masked-img {
      position: absolute;
      z-index: 5;
      width: 122px;
      height: 125px;
      top: 2px;
      left: 2px;
      background-color: $color-off-white;
      background-image: cdn("/images/onboarding/icon-avatar.png");
      background-position: center center;
      background-repeat: no-repeat;

      @include when-xs {
        width: 100px;
        height: 100px;
      }


      &.user_selected {
        background-size: cover;
      }
    }
  }

  /* --------------------------- tags ------------------------------- */

  .onboarding-tags-category {
    width: 459px;
    padding: 20px;
    height: 500px;
    background-color: #F6F6F6;
    position: relative;

    @include when-xs {
      width: 100%;
      height: auto;
      flex: 1;
      padding-bottom: 0;
    }


    .onboarding-tags-head {
      display: flex;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 58px;
      line-height: 50px;
      padding-top: 2px;
      color: $color-light-pink;
      user-select: none;
      margin-bottom: 40px;

      @include when-xs {
        margin-bottom: 30px;
        font-size: 40px;
        line-height: .8em;
      }


      .onboarding-tags-title {
        flex: 1;
        text-align: left;
        position: relative;

        span {
          display: block;
          position: absolute;
          left: 0;
          top: 38px;
          font-size: 16px;
          line-height: 16px;
          color: $color-white;
          background-color: $color-black;
          padding: 6px 8px 2px;

          @include when-xs {
            top: 25px;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }

      .onboarding-tags-pager {
        width: 90px;
        text-align: right;
      }
    }

    .onboarding-category-form {
      .item {
        padding: 0 20px 0 0;
        float: none;
        width: 100%;
        text-align: left;
        display: none;

        &:first-child {
          display: block;
        }
      }

      h4 {
        font-size: 22px;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        color: #888D91;
        letter-spacing: 1px;
        line-height: 17px;
        margin-bottom: 3px;
      }

      .expertise-category.selected h4 {
        color: $color-light-pink;

        &:before {
          display: inline-block;
        }
      }

      .expertise-category {
        border-bottom: 0 none;
      }

      ul.tags {
        margin: 0;

        .btn {
          font-size: 12px;
          font-weight: $font-weight-normal;
          border: solid 1px #E5E5E5;
          text-transform: uppercase;
          color: #878787;
          background-color: $color-white;
          padding: 7px 18px 6px 6px;
          border-radius: 2px;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          letter-spacing: 1px;

          &.btn-add-another {
            font-size: 12px;
            line-height: 14px;
            padding: 7px 18px 6px 6px;
          }
        }

        li {
          padding: 0;
          margin: 0 2px 9px 0;
        }

        li.loader {
          margin: 0 0 0 10px;
        }

        li.tag {
          position: relative;
          text-transform: lowercase;

          .remove {
            position: absolute;
            right: 3px;
            top: 1px;
            opacity: 0.8;
            width: 6px;
            height: 6px;
            background-size: 6px 6px;
            cursor: pointer;

            &:hover {
              opacity: 1.0;
            }
          }

          input {
            font-size: 12px;
            padding: 5px 18px 3px 4px;
            width: 92px;
            text-transform: lowercase;
          }

          input:read-only:hover,
          input:focus {
            border-color: $color-lighter-grey !important;
          }

          .tagsearch {
            position: absolute;
            z-index: 10;
            width: 92px;
            background-color: $color-white;
            border: solid 1px $color-lighter-grey;

            li:not(:last-child) {
              border-bottom: solid 1px $color-off-white;
            }

            li {
              padding: 2px 3px;
              margin: 0px;
              cursor: pointer;
              color: $color-grey;

              &:hover {
                color: $color-nero;
              }

              font-size: 12px;
            }
          }

          .tag-text {
            padding: 7px 18px 6px 6px;
            border: solid 1px $border-color;
            font-size: 12px;
            color: $color-dark-grey;
            border-radius: 2px;
          }
        }
      }

      /* ul.tags */
    }

    /* .onboarding-category-form */

    .main-buttons {
      position: absolute;
      right: 0;
      bottom: 72px;

      @include when-xs {
        bottom: 60px;
        z-index: 1;
      }
    }
  }

  .onboarding-tags-main {
    position: relative;

    @include when-sm {
      flex: 1;
    }


    .tips {
      width: 100%;
      height: 68px;
      background-image: linear-gradient(#BAF2F0, #BAF2F0);
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 139px 68px;
      text-align: right;
      padding-right: 40px;
      padding-top: 10px;

      @include when-xs {
        height: 0;
        padding-top: 0;
        padding-bottom: 10px;
        padding-right: 0;
      }


      .tips-tooltip {
        position: absolute;
        top: 35px;
        right: 20px;
        width: 300px;
        height: auto;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.7);
        color: $color-white;
        font-size: 12px;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        text-align: left;
      }
    }

    .text {
      width: 100%;
      background-image: linear-gradient(#BAF2F0, #BAF2F0);
      background-position: top right;
      background-repeat: no-repeat;
      background-size: 139px 12px;
      padding: 5px 15px 0 20px;

      @include when-xs {
        padding: 20px;
        padding-bottom: 0;
      }


      h5 {
        margin-top: 0;
      }

      p {
        color: $color-darkest-grey;
        padding: 20px 0;

        @include when-xs {
          padding: 0;
        }


        &.onboarding-tags-example {
          padding-top: 0;
          color: $color-grey;
        }
      }
    }

    .buttons {
      position: absolute;
      right: 0;
      bottom: 22px;

      @include when-xs {
        bottom: auto;
        top: 400px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }

  .onboarding-tags-category .main-buttons .bigbutton {
    @include when-xs {
      width: 200px;
    }
  }

  /* --------------- onboarding / price ------------------ */

  .onboarding-price-form {
    width: 459px;
    height: 500px;
    padding: 129px 0 0 107px;

    @include when-xs {
      width: 100%;
      height: auto;
      padding: 0;
      flex: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 100px;
    }


    .onboarding-price-form-content {
      background-image: linear-gradient(#BAF2F0, #BAF2F0);
      background-repeat: no-repeat;
      background-size: 270px 135px;
      text-align: left;
      padding: 25px 30px 0 20px;

      @include when-xs {
        background-size: 270px 110px;
        padding: 0 30px 0 20px;
      }


      h4 {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        color: $color-black;
        font-size: 22px;
      }

      .onboarding-price-form-row {
        display: flex;

        .price-data {
          flex: 1;
          position: relative;

          input {
            width: 150px;
            height: 36px;
            padding: 2px 40px 0 10px;
          }

          .price-data-unit {
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-size: 12px;
            color: $color-grey;
            position: absolute;
            top: 4px;
            right: 7px;
            height: 30px;
            width: 26px;
            user-select: none;

            .price-data-unit-currency {
              text-transform: lowercase;
              position: absolute;
              right: 10px;
            }

            .price-data-unit-per {
              position: absolute;
              top: 4px;
              right: 10px;
              height: 20px;
              width: 1px;
              overflow: hidden;
              background-color: $color-grey;
              transform: rotate(45deg);
            }

            .price-data-unit-time {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }

        .price-data-currency {
          flex: 1;
          margin-left: 20px;

          select,
          .bootstrap-select {
            width: 100px;
            background-color: $color-white;
          }

          .bootstrap-select {
            .btn {
              background-color: $color-white;
              border: 1px solid $color-grey;
            }

            &.btn-group .dropdown-toggle .bs-caret {
              display: inline-block;
            }

            &.btn-group .dropdown-toggle .caret {
              right: 4px;
            }

            .dropdown-menu {
              opacity: 1;
              margin: 0;
            }
          }
        }
      }

      .price-data-minute {
        background-color: $color-black;
        color: $color-white;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-black !important;
        font-size: 18px;
        padding: 4px 10px 5px 10px;
        width: 150px;

        .price-data-minute-value {
          display: inline-block;
        }

        .price-data-minute-pm {
          font-size: 10px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-bold !important;
          display: inline-block;
          position: relative;
          top: -7px;
        }
      }
    }
  }

  .main-prices {
    margin-top: 20px;
  }

  .onboarding-price-main {
    padding: 60px 20px 0 15px;

    @include when-xs {
      padding: 20px;
      padding-bottom: 0;
    }


    @include when-sm {
      flex: 1;
    }


    p {
      text-align: left;
      padding-top: 10px;

      &.note {
        color: $color-grey;
      }
    }

    .buttons {
      position: absolute;
      right: 0;
      bottom: 22px;

      @include when-xs {
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  /* --------------- onboarding / tagline - about ------------------ */

  .modal-registration-onboarding-tagline,
  .modal-registration-onboarding-profession {
    display: block;

    &:before {
      display: block;
    }

    @include when-xs {
      display: flex;
      flex-direction: column;

      > div:nth-child(3) {
        flex: 1;
        display: flex;
        flex-direction: column;

        > div:last-child {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }


    .onboarding-tagline-main,
    .onboarding-profession-main {
      padding-top: 50px;
      padding-bottom: 10px;

      @include when-xs {
        padding-top: 20px;
      }


      h5 {
        margin-bottom: 20px;
      }
    }

    textarea {
      margin-left: 140px;
      width: 340px;
      height: 70px;

      @include when-xs {
        margin: auto;
        width: 100%;
        display: block;
      }
    }

    textarea + .note {
      margin-left: 140px;

      @include when-xs {
        margin-left: 0 !important;
      }
    }

    .textinput {
      margin-left: 210px;
      width: 270px;

      @include when-xs {
        margin: auto;
        width: 100%;
        display: block;
      }
    }

    p {
      margin-bottom: 5px;
    }

    .subtitle {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-bold !important;
    }

    .note {
      color: $color-lighter-grey;
      text-align: right;
      display: flex;
      margin-left: 210px;
    }

    .note-right {
      flex: 1;
    }

    .buttons {
      @include when-xs {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  .modal-registration-onboarding-about {
    display: block;

    @include when-xs {
      display: flex;
      flex-direction: column;

      > div:nth-child(3) {
        flex: 1;
        display: flex;
        flex-direction: column;

        > div:last-child {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }


    &:before {
      display: block;
    }

    .onboarding-about-main {
      padding-top: 50px;
      padding-bottom: 10px;

      @include when-xs {
        padding-top: 20px;
      }
    }

    p {
      width: 320px;
      margin: 0 auto 20px auto;

      @include when-xs {
        width: 100%;
      }
    }

    textarea {
      margin-left: 140px;
      width: 340px;
      height: 170px;

      @include when-xs {
        margin: auto;
        width: 100%;
        display: block;
        height: 100px;
      }
    }

    .buttons {
      @include when-xs {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  .modal-registration-onboarding-social {
    display: block;

    @include when-xs {
      display: flex;
      flex-direction: column;

      > div:nth-child(3) {
        flex: 1;
        display: flex;
        flex-direction: column;

        > div {
          flex: 1;
          display: flex;
          flex-direction: column;

          > div:last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: auto;
          }
        }
      }
    }


    &:before {
      display: block;
    }

    .onboarding-social-main {
      padding-top: 50px;
      padding-bottom: 10px;

      @include when-xs {
        padding-top: 20px;
      }
    }

    .onboarding-channels {
      margin-top: 10px;
      padding-left: 200px;

      @include when-xs {
        padding-left: 30px;
      }


      .onboarding-channels-header {
        display: flex;
      }

      .onboarding-channels-item {
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 90%;
        background-color: $color-closer-white;
        margin-bottom: 1px;

        @include when-xs {
          width: 100%;
        }
      }

      .onboarding-channels-input {
        display: flex;
        height: 30px;

        input {
          width: 90%;
          padding-left: 10px;

          @include when-xs {
            width: 100%;
          }
        }
      }

      .onboarding-channels-type {
        display: flex;
        font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        font-size: 22px;
        align-items: center;
        justify-content: flex-end;
        padding-top: 3px;
        padding-right: 0;

        &.empty {
          opacity: 0.3;
        }

        &.web {
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: bold;
          font-size: 16px;

          .web-logo {
            height: 20px;
          }
        }
      }

      .buttons {
        margin-top: 20px;
      }
    }
  }

  /* --------------- onboarding / call ------------------ */

  .modal-page.registration-onboarding-call {
    @include when-xs {
      flex-direction: column;
      overflow: auto;
    }
  }

  .onboarding-call-form {
    width: 459px;
    height: 500px;
    padding: 40px 10px 0 34px;

    @include when-xs {
      width: 100%;
      height: auto;
      padding: 20px;
      padding-bottom: 0;
    }


    .onboarding-call-form-content {
      text-align: left;

      h5 {
        @include when-xs {
          text-align: center;
        }
      }

      .p-10 {
        @include when-xs {
          padding: 2px 0 !important;
        }
      }
    }
  }

  .onboarding-call-main {
    flex: 1;
    padding: 60px 20px 0 15px;

    @include when-xs {
      padding: 20px;
      padding-bottom: 0;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
    }


    .app-video-allowed {
      @include when-xs {
        margin-top: 0;
        padding-top: 0;
      }
    }

    p {
      text-align: left;
      padding-top: 10px;

      @include when-xs {
        font-size: 12px;
      }
    }

    .buttons {
      position: absolute;
      right: 0;
      bottom: 22px;

      @include when-xs {
        margin-left: -20px;
        margin-right: -20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: auto;
      }
    }

    @include when-xs {
      .text {
        margin-bottom: 20px;
      }

      .text:not(.show) {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          left: -10px;
          right: -10px;
          z-index: 1;
          background: url(svg-encode(icon-arrow-down($color-nero))) no-repeat right 20px center;
          background-size: 16px;
        }

        p:not(.text-right) {
          height: 0;
          overflow: hidden;
          padding: 0;
          margin: 0;
        }

        p.text-right {
          padding: 0;
          margin: 0;

          .btn {
            background: transparent;
            border: 0;
            color: $color-nero;
          }
        }
      }
    }
  }

  /* --------------- onboarding / done ------------------ */

  .onboarding-done-form {
    width: 459px;
    height: 500px;
    padding: 129px 0 0 84px;

    @include when-xs {
      width: 100%;
      height: auto;
      flex: 1;
      padding: 0;
    }


    .onboarding-done-form-content {
      @include when-xs {
        img {
          height: 100px;
          margin-top: 20px;
        }
      }


      @include when-sm {
        text-align: left;
      }
    }
  }

  .onboarding-done-main {
    @include when-xs {
      padding: 20px;
      padding-bottom: 0;
    }


    @include when-sm {
      padding: 60px 20px 0 15px;
      flex: 1;
    }


    p {
      text-align: left;
      padding-top: 10px;
    }

    .buttons {
      position: absolute;
      right: 0;
      bottom: 22px;

      @include when-xs {
        bottom: 0;
      }
    }
  }
}

/* ----------------------------------- onboarding modal steps END ------------------------------------- */

/* ----------------------------------- modal notifications ------------------------------------- */

.modal-notification {
  position: absolute;
  z-index: 100;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  user-select: none;

  @include transition(0.4s all ease-in-out);

  @include when-xs {
    &.open {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  .modal-notification-content {
    padding: 20px;
  }

  .modal-notification-main {
    position: relative;
    height: 180px;
    overflow: hidden;

    @include when-xs {
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    span {
      color: $color-darkest-grey;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 160px;
    }

    .modal-notification-main-dash {
      position: absolute;
      top: 139px;
      width: 100%;
      height: 1px;
      overflow: hidden;
      opacity: 0.7;
      background-image: cdn("/images/border.png");
      background-repeat: repeat-x;
      background-position: center center;
    }
  }

  .modal-notification-main-long {
    height: 100px;

    span {
      font-size: 60px;
    }

    .modal-notification-main-dash {
      top: 50px;
    }
  }

  .modal-notification-text {
    color: $color-white;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-bold !important;
    font-size: 16px;
  }

  .modal-notification-buttons {
    padding-top: 15px;

    .notification-button {
      display: inline-block;
      border: 0 none;
      color: $color-black;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 20px;
      letter-spacing: 1px;
      padding: 7px 2px 2px 2px;
      width: 158px;

      &.notification-button-primary {
        background-color: $color-light-pink;
      }

      &.notification-button-green {
        background-color: $color-green;
      }
    }
  }
}

.modal-notification-right {
  right: -375px;
  width: 375px;
  height: 100%;

  &.open {
    right: 0;
  }

  .modal-notification-main {
    margin-top: 85px;
  }

  .modal-notification-main-long {
    margin-top: 125px;
  }
}

/* ----------------------------------- old styles below - TODO check what is still used ------------------------------------- */

[data-to-root]#app-layout.register {
  #signup-options .btn {
    border: none;
  }

  .bootstrap-select {
    &.day,
    &.month,
    &.year {
      width: 32%;
    }

    &.btn-group .dropdown-toggle .caret {
      right: 4px;
    }

    & > .dropdown-toggle {
      max-height: 36px;
    }

    &.language-selection {
      width: 55%;
      margin-right: 5px;
    }

    &.grade-selection {
      width: 35%;
      margin-left: 0px;
    }

    .filter-option {
      font-size: $font-size-small;
      padding-top: 4px;
      padding-left: 2px;
    }

    .bs-placeholder {
      padding-left: 3px;
    }

    &.btn-group {
      margin-bottom: 3px;
    }
  }

  #default_cost_per_duration_unit,
  #default_cost_per_distance_unit {
    border-right: none;
  }

  .unit-indicator {
    color: $color-grey;
    font-size: 13px;
    text-transform: lowercase;

    .sup {
      vertical-align: super;
    }

    .sub {
      vertical-align: sub;
    }

    border-left: none;

    .incomplete {
      opacity: 0;
    }
  }

  .selected-currency,
  .selected-distance {}

  .language-selections > .language-selection {}

  // .language-selections > .language-selection:first-child .bootstrap-select.language-selection {
  //    margin-right:0px;
  // }
  .btn.start {
    color: $color-white;
    background-color: $color-red;
    font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
  }

  .btn.start:hover {
    color: $color-red;
    background-color: $color-white;
    border: solid 1px $color-red;
  }

  #submit-form {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $color-blue;

    p {
      color: $color-white;
    }

    button {
      margin-top: 10px;
      border: solid 1px $color-white;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-blue;
      }
    }
  }

  #signup-options,
  #signup-alternative {
    .fa.fa-google-plus {
      color: transparent;
      background-image: cdn("/images/icon-google-color.svg");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;
    }

    .btn:hover .fa.fa-google-plus {
      color: $color-white;
      background-image: none;
    }

    li {
      margin: 10px 0px;
    }

    // input[type=text],input[type=email],input[type=password] {
    //  padding:20px;
    // }
    .btn {
      font-weight: $font-weight-light;
      padding: 10px;
      position: relative;

      i {
        position: absolute;
        left: 10px;
        top: 8px;
        font-size: 28px;
      }

      strong {
        font-weight: $font-weight-medium;
      }

      &.fb {
        color: $color-white;
        background-color: $color-facebook;
      }

      &.em {
        color: $color-white;
        background-color: $color-blue;
      }

      &.li {
        color: $color-white;
        background-color: $color-linkedin;
      }

      &.gl {
        color: $color-dark-grey;
        background-color: $color-google;
      }

      &.tw {
        color: $color-white;
        background-color: $color-twitter;
      }

      &.login {
        background-color: $color-white;
        color: $color-blue;
        border: solid 1px $color-blue;
      }

      &:not(.btn-fake):hover {
        background-color: $color-red;
        color: $color-white;
        border-color: $color-red;
      }

      &.btn-fake {
        cursor: default;
      }
    }

    button.btn.login {
      background-color: $color-red;
      color: $color-white;
      border: solid 1px $color-red;
    }

    button.btn.login:hover {
      background-color: $color-white;
      color: $color-red;
      border: solid 1px $color-red;
    }
  }

  #social,
  #email {
    display: none;

    &.active {
      display: block;
    }
  }

  .btn-ios-app {
    background-color: $color-green;
  }

  @include when-xs {
    .form-group-map {
      margin-bottom: 20px;
    }
  }
}

#modal-registration-login-email-form {
  .row.vertical-align div:first-child {
    padding-right: 0;
  }
}

@media only screen and (max-width: 729px) {
  .modal-flavor {
    .modal-flavor-text {
      font-size: 48px;
      line-height: 41px;
    }
  }

  .modal-registration-signup-progress {
    width: 100%;
    left: auto;
  }

  #register-selfinvite,
  #register-simple-success,
  #register-user-emailconfirm,
  #login-user,
  #register-user,
  #register-user-details,
  #register-user-onboarding,
  #register-user-onboarding-tags,
  #password-user,
  #reset-user {
    &.modal .modal-page {
      &:before {
        left: 79px;
      }
    }

    &.modal .modal-dialog {
      min-width: 290px;
      width: calc(100% - 30px);
      margin: auto;
      max-width: 355px;
      height: 100vh;

      .modal-header {
        padding: 15px 0;
      }

      .modal-content {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .modal-notification-right {
    max-width: 100%;

    .modal-notification-main {
      height: 100px;
      margin-top: 0;

      span {
        font-size: 100px;
        line-height: 1em;
      }

      .modal-notification-main-dash {
        top: auto;
        bottom: 40px;
      }

      &.modal-notification-main-long {
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        span {
          font-size: 70px;
        }

        .modal-notification-main-dash {
          bottom: 30px;
        }
      }
    }
  }

  #register-user,
  #login-user {
    &.modal.register {
      .modal-dialog {
        .modal-registration-signup {
          background-position: right -50px top;
        }

        .modal-registration-signup-email,
        .modal-registration-login-email {
          .icon {
            padding-top: 20px;
            padding-bottom: 0;
          }

          .bigbutton {
            width: 100%;
          }

          div.buttons-alternative {
            width: 200px;
            float: right;
          }
        }

        .modal-registration-signup-email > form > div,
        .modal-registration-login-email > div:last-child {
          display: flex;
          flex-direction: column;

          > div:last-child {
            margin-left: auto;
          }
        }

        .modal-registration-signup-confirm {
          p {
            padding: 0 20px;
          }

          > div:last-child() {
            position: absolute;
            height: 100%;
            top: 0;
            left: 25px;
            width: 100%;

            > div:last-child() {
              height: 100%;

              .buttons {
                position: absolute;
                right: 0;
                bottom: 48px;
                padding-top: 0;
              }
            }
          }
        }

        .modal-registration-login,
        .modal-registration-signup {
          > div:not(.modal-flavor) {
            flex-direction: column;
          }

          .right-section {
            margin-left: auto;
            margin-top: auto;
            padding-bottom: 10px;
          }
        }

        .modal-registration-login {
          background-position: left -20px top;

          .buttons {}
        }

        .modal-registration-signup {
          .right-section {
            padding-top: 20px;

            .top-section {
              padding-bottom: 110px;
            }
          }
        }
      }
    }
  }

  #register-user-details.modal {
    .bigbutton {
      margin-left: auto;
    }

    .modal-registration-signup-basics {
      .signup-basics-opener {
        div {
          margin-left: 84px;

          @include when-xs {
            margin-left: 77px;
          }
        }

        p {
          width: calc(100% + 124%);
          min-width: 207px;
        }
      }

      .signup-basics-main {
        padding-top: 26px;

        .text {
          padding: 0 10px;
        }

        .buttons {
          padding-top: 120px;
        }

        .icon img {
          width: 70px;
        }
      }
    }

    .modal-registration-signup-basics-avatar {
      .avatar-container {
        margin-left: calc((100% - 230px) / 2 );
        width: 230px;
        height: 230px;
      }

      .buttons {
        padding-top: 46px;
      }
    }

    .modal-registration-signup-basics-info {
      @include when-xs {
        display: flex;
        flex-direction: column;
      }


      .avatar-container-masked {
        display: none;
      }

      .vertical-align {
        > div:last-child() {
          padding-left: 0;
        }
      }

      > div:nth-child(3) {
        flex: 1;
        position: relative;

        > div:first-child() {
          z-index: 1;
        }

        > div:last-child() {
          height: 100%;
          position: absolute;
          z-index: 0;

          .buttons {
            position: absolute;
            right: 10px;
            bottom: 48px;
          }
        }
      }
    }

    .modal-registration-signup-basics-languages {
      .avatar-container-masked {
        display: none;
      }

      .language-selections,
      .language-controls {
        margin-left: 0;
      }

      > div:nth-child(3) {
        height: 350px;
        position: relative;

        > div:first-child() {
          z-index: 1;
        }

        > div:last-child() {
          height: 100%;
          position: absolute;
          z-index: 0;

          .buttons {
            position: absolute;
            right: 10px;
            bottom: 48px;
          }
        }
      }
    }

    .bootstrap-select.language-selection,
    .modal-registration-signup-basics-languages .btn-add-another-language {
      width: 50%;
      margin-bottom: 5px;
    }

    .bootstrap-select.grade-selection {
      width: 40%;
      margin-bottom: 5px;
    }

    .btn-remove-language {
      margin: 0;
    }
  }
}
