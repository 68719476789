[data-to-root]#app-layout.admin {
  #admin-sidebar {
    position: fixed;
    right: 0;
    display: flex;
    padding: 20px 0;
    top: 55px;
    height: calc(100vh - 55px);

    @include when-md {
      top: 77px;
      height: calc(100vh - 77px);
    }


    @include when-lg {
      top: 99px;
      height: calc(100vh - 99px);
    }


    &:before {
      content: '';
      background-color: $color-light-pink;
      height: 80px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      cursor: pointer;
      width: 30px;

      @include when-md {
        height: 100px;
        width: 40px;
      }
    }

    &:after {
      content: url(svg-encode(icon-right-arrow($color-white)));
      position: absolute;
      top: 50%;
      transform: rotate(180deg) translateY(50%);
      right: calc(100% + 10px);
      cursor: pointer;
      height: 19px;
      width: 10px;

      @include when-md {
        height: 28px;
        width: 15px;
      }
    }

    &:hover {
      &:before {
        background-color: $color-primary;
      }

      &:after {
        transform: translateY(-50%);
      }

      .settings-menu {
        display: block;
        visibility: visible;
        opacity: 1;
        width: 230px;
        -webkit-transition: width 0.5s, visibility 0.3s, opacity 0.3s linear;
        transition: width 0.5s, visibility 0.3s, opacity 0.3s linear;
      }
    }

    &.open {
      &:before {
        background-color: $color-primary;
      }

      &:after {
        transform: translateY(-50%);
      }
    }

    .settings-menu {
      overflow: auto;
      width: 0;
      margin: 0;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: width 0.5s, visibility 0.3s, opacity 0.3s linear;
      transition: width 0.5s, visibility 0.3s, opacity 0.3s linear;

      li {
        margin: 0 20px;

        a {
          white-space: nowrap;
        }
      }
    }
  }

  .back-btn {
    position: absolute;
    margin-top: 30px;
    z-index: 1;
  }

  .top {
    background: none;
    height: 20px;
  }

  ul.settings-menu {
    padding-top: 10px;

    li {
      list-style-type: none;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 20px;
      letter-spacing: 0.75px;

      &.sub-item {
        font-family: proxima;
        font-weight: bold;
        text-transform: lowercase;
        color: $color-lighter-grey !important;
        padding-left: 10px;
        font-size: 16px;
        letter-spacing: 0px;

        &.first {
          padding-top: 5px;
          margin-top: 5px;
          border-top: dashed 0.5px $color-lighter-grey;
        }

        &.last {
          padding-bottom: 5px;
          margin-bottom: 5px;
          border-bottom: dashed 0.5px $color-lighter-grey;
        }
      }

      &.active a {
        color: $color-nero !important;
      }
    }
  }

  #table-requests {
    margin-top: 20px;

    p {
      margin: 0;
    }

    .clickable {
      cursor: pointer;

      &:nth-child(even) {
        background-color: $color-off-white;
      }

      &:hover {
        background-color: $color-grey;
      }
    }
  }

  .table-request-details {
    td {
      border-bottom: 1px solid $color-lighter-grey;
    }

    .detail {
      td:first-child {
        padding-left: 28px;
      }
    }

    .detail.payout_method {
      td:first-child {
        padding-left: 8px;
      }
    }
  }

  .payout-status-new {}

  .payout-status-approved {
    color: $color-green;
  }

  .payout-status-on_hold {
    color: $color-blue;
  }

  .payout-status-denied {
    color: $color-red;
  }

  .btn-approve {
    background-color: $color-green;
  }

  .btn-hold {
    background-color: $color-blue;
  }

  .btn-deny {
    background-color: $color-red;
  }

  .modal-dialog {
    width: 500px;

    span {
      color: $color-dark-grey;
    }
  }

  .btn-no,
  .btn-yes {
    color: $color-light-pink;
    border-color: $color-nero;
    background: $color-white !important;
    line-height: 119%;
    padding: 10px 12px 6px;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    min-width: 75px;
  }

  .btn-no {
    background: #333 !important;
    color: $color-white;
    margin-left: 20px;
  }

  .btn-yes {
    &:hover {
      color: #bf8f95;
    }
  }

  .expert_certificates {
    margin-bottom: 20px;

    th {
      background-color: #aaa;
      padding: 3px 15px 2px 5px;
      border-bottom: 1px solid #000 !important;
    }

    td {
      background-color: #eee;
      padding: 3px 5px;
      border-bottom: 1px solid #333;
    }

    tr:nth-child(odd) {
      td {
        background-color: #e0e0e0;
      }
    }
  }

  h1.title {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    color: $color-grey;
    font-size: 80px;
    line-height: 40px;
    padding: 0px;
    margin: 0px;
  }

  h2.subtitle {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    color: $color-light-pink;
    font-size: 60px;
    line-height: 60px;
    padding: 0px;
    margin: 0px;
    margin-top: 40px;
  }

  h3.subtitle {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    color: $color-grey;
    font-size: 30px;
    line-height: 30px;
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
  }

  p.description {
    font-size: 16px;
    line-height: 20px;
    color: $color-grey;
  }

  &.statistics,
  #pending_registration,
  #tag_management,
  #tag_management_edit {
    .btn-primary {
      color: $color-white;
      background: $color-primary;
      border: none;
    }

    .basic-stats {
      border-bottom: solid 1px $color-lighter-grey;
      display: block;
      clear: both;
      float: none;
      margin-top: 20px;

      &.no-border {
        border-bottom: none;
      }

      p.description {
        position: relative;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        background-color: $color-nero;
        color: $color-white;
        padding: 5px 8px 2px;
        margin-bottom: 0px;
        letter-spacing: 0.2px;
      }

      p.value {
        position: relative;
        top: 23px;
        color: $color-primary;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: 80px;
        line-height: 80px;
        padding-bottom: -10px;
        margin-bottom: 0px;
      }

      .basic-stats-sub {
        display: inline-block;
        margin-right: 20px;
        text-align: center;
      }

      // .basic-stats-sub-number {
      //     display: flex;
      //     text-align: center;
      //     min-width: 120px;
      //     min-height: 30px;
      //     justify-content: center;
      //     align-items: center;
      //     margin-bottom: 25px;
      // }
  
    }

    .date-filter {
      margin-top: 50px;
    }

    table.top-list {
      width: 100%;

      tr {
        border-bottom: dashed 1px $color-grey;
      }

      td.key {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        color: $color-nero;
        padding: 10px 0px;
        min-width: 180px;
      }

      td.value {
        padding-left: 5px;
        text-align: right;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        color: $color-primary;
      }
    }
  }

  .admin-tabs {
    margin-top: 20px;
    margin-bottom: 20px;

    .admin-tab {
      a {
        display: block;
        background-color: $color-light-grey;
        color: $color-white;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        font-size: 24px;
        padding: 4px 5px 0 10px;

        &.active {
          background-color: $color-black;
        }
      }
    }
  }

  .invite-form-container {
    padding-top: 20px;
    background-color: $color-off-white;

    input[type=email] {
      margin: 5px 0;
      width: 100%;
    }

    input[type=submit] {
      display: block;
      background-color: $color-black;
      color: $color-white;
      margin-top: 20px;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 20px;
      text-align: center;
      padding: 4px 5px 0 5px;
      width: 100%;
    }
  }
}

.p-10 {
  padding: 10px 0;
}

#review_admin {
  th {
    text-align: center;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-bold !important;
    vertical-align: middle;
    border-bottom: solid 1px #e0e0e0 !important;
  }

  td {
    border-bottom: solid 1px #e0e0e0;
  }

  .review-id {
    width: 80px;
    text-align: center;
  }

  .tour-id {
    width: 80px;
    text-align: center;
  }

  .created-date {
    width: 100px;
    text-align: center;
  }

  .rating {
    width: 112px;
  }

  .delete {
    width: 61px;
    text-align: right;
  }
}

#pending_registration,
#profile_completions {
  th {
    border-bottom: solid 1px #e0e0e0 !important;
  }

  th.created-date {
    width: 90px;
  }
}

#review_admin,
#pending_registration {
  .delete-review-btn,
  .delete-registration-btn {
    cursor: pointer;
  }

  .delete-review.modal,
  .delete-registration.modal {
    .modal-dialog {
      width: fit-content;
    }

    .modal-body {
      padding: 30px;
    }

    .modal-footer {
      background-color: $color-white;

      .btn-primary {
        background: $color-white;
        color: $color-dark-grey;

        &:hover {
          color: #333;
          background-color: transparent;
        }
      }

      .btn-secondary {
        color: $color-white;
        border-color: $color-nero;
        background: $color-nero;
      }
    }
  }
}

#pending_registration {
  .filter {
    > div {
      display: inline-block;
      vertical-align: bottom;
      float: none;
    }
  }
}

#tag_management .expert-group-select, .article-edit-container .expert-group-select {
  select {
    width:100%;
  }
  .bootstrap-select {
    border: 1px solid #aaa;
    background-color: $color-white;
    .dropdown-menu {
      background-color: $color-white;
      right: 0;
      left: auto;
      li {
        font-size: 14px;
        a.configured {
          background-color: #eeffaa;
        }
        &.active {
          a.configured {
            background-color: $color-light-pink;
          }
        }
      }
    }
  }
}
  
.article-edit-container .expert-group-select {
  .bootstrap-select { 
    width:100% !important;
  }
}


#tag_management  .tag-management-user-list {
  right: 0px;
  top: 150px; 
  position: fixed;
  height: calc(100vh - 150px);
}

.article-edit-container .expert-hero-select {
  height:420px;

}


.article-edit-container .expert-hero-select {
  [class*="btn-article-hero"] {
      position:absolute;
      top:5px;
  }


  .btn-article-hero-user {
    left:5px;
  }

  .btn-article-hero-profession {
    right:25px;
  }

}
#tag_management  .tag-management-user-list, .article-edit-container .expert-hero-select {
  background-color: $color-off-white;
  overflow-y: scroll;
  margin:0px;
  padding:0px;
  .expert {

    user-select: none;
    cursor:zoom-in;
    display:grid;      
    grid-template-columns: 60px auto;
    grid-template-rows: auto auto auto auto auto auto auto auto;
    grid-column-gap: 4px;
    grid-row-gap: 0px;

    padding:2.5px;
    border-bottom:dashed 1px $color-lighter-grey;
    position:relative;

    &:hover,&.open {
      background-color:$color-light-pink;
    }

    &.rule-changed {
      border-left: 5px solid red;
    }

    b {
      font-weight: bold;
      color: $color-red;
    }

    .buttons {
      display:none;
      position:absolute;
      font-size:16px;
      top:44px;
      left:3px;
      width:65px;
      height:30px;
      cursor:pointer;

      &.include {
        .btn-include {
          display: none;
        }
      }
      &.exclude {
        .btn-exclude {
          display: none;
        }
      }


      &.buttons-profession {
        top: 16px;
        left: 100px;
        &.include {
          display: none;
          .btn-include {
            display: none;
          }
        }
      }
      
      .btn-include {
      }
      .btn-exclude {
        margin-left:0px;
      }

      [class*="btn"] {
        display: inline-block;
        color: $color-dark-grey;
        background-color: $color-white;
        border-radius: 3px;
        width: 18px;
        height: 18px;
        padding-top: 1px;
        padding-left: 2px;
        &:hover {
          color: $color-primary;
        }
      }

      &.include .btn-include-expert, &.exclude .btn-exclude-expert  {
          color:$color-primary;
      }
    }

    &:hover .buttons,
    &:hover .buttons-profession.include {
      display: block;
    }

    .thumbnail {
       width:60px;
       height:60px;
       object-fit:cover;
       &.thumbnail-hero {
        border: 3px solid $color-green;
       }
    }

    .profession {
       font-weight:bold;
       &.promoted {
        background-color: $color-green;
       }
    }

    &.rule-profile-promoted {
      .profession {
        text-decoration: underline;
        color: $color-green;
      }
    }

    &.rule-profile-banned {
      .profession {
        text-decoration: underline;
        color: $color-really-red;
      }
    }

    &.rule-expert-promoted {
      .thumbnail {
        border-bottom: 5px solid $color-green;
      }
    }

    &.rule-expert-banned {
      .thumbnail {
        border-bottom: 5px solid $color-really-red;
      }
    }

    .location,.languages {
      font-size:12px;
    }

    .languages {
      border-top:dashed 1px $color-lighter-grey;
    }

    .name > a { 
      color:$color-black;
      cursor:pointer;
      &:hover {
        text-decoration: underline !important;
      }
    }
    .thumbnail  { grid-column-start: 1; grid-column-end: 2; grid-row-start: 1; grid-row-end: 3; }
    .profession { grid-column-start: 2; grid-column-end: 3; grid-row-start: 1; grid-row-end: 2; }
    .tagline    { grid-column-start: 2; grid-column-end: 3; grid-row-start: 2; grid-row-end: 3; }
    .tags       { grid-column-start: 2; grid-column-end: 3; grid-row-start: 3; grid-row-end: 7; }

    .location   { grid-column-start: 1; grid-column-end: 2; grid-row-start: 4; grid-row-end: 5; }
    .languages  { grid-column-start: 1; grid-column-end: 2; grid-row-start: 5; grid-row-end: 6; }
    .price      { grid-column-start: 1; grid-column-end: 2; grid-row-start: 7; grid-row-end: 8; }
    .name       { grid-column-start: 2; grid-column-end: 3; grid-row-start: 7; grid-row-end: 8; }

    .tags > span {
      &:before {
        content:"#";
      }
      &:nth-child(n+4) {
        display:none;
      }
    }
    .location,.languages,.price,.name {
      display:none;        
    }

    &.open {
      cursor:zoom-out;
      .location,.languages,.price,.name {
        display:block;
      }
      .tags > span:nth-child(n+4) {
        display:inline;
      }
    }

    font-size:13px;
    line-height:15px;
  }    
}

#tag_management {

  &.expert-group-selected {
    h4 {
      margin: 0 20px 0 0;
    }
    .expert-group-details {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rule-clone-container {
      .bootstrap-select {
        border: 1px solid #aaa;
        .dropdown-menu {
          li {
            font-size: 14px;
          }
        }
      }
    }
  }

  .tag-management-expert-search {
    background-color: $color-primary;
    height: 75px;
    position: fixed;
    right:calc(100vw / 6);
    top: 75px;
    display:flex;
    align-items:center;
    justify-content: center;
    input[name="expert_search"] {
      width:100%;
    }
  }

  .tag-management-expert-selection {
    background-color: $color-off-white;
    height: calc(100vh - 150px);
    position: fixed;
    overflow-y: scroll;
    right:calc(100vw / 6);
    top: 150px;
    margin:0px;
    padding:0px;
  }

  .tag-management-expert-group {
    background-color: $color-primary;
    height: 75px;
    position: fixed;
    z-index: 10;
    right: 0px;
    top: 75px;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;

    

    .expert-group-info {
      display:flex;
      align-items:center;
      justify-content: center;
    }

  }



  .tag-management-wrapper {
    overflow: auto;

    .expert-group-professions {
      .bootstrap-select {
        border: 1px solid #aaa;
        .dropdown-menu {
          li {
            font-size: 14px;
          }
        }
      }
    }

    .template.input-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;

      .remove-button {
        margin-left: 10px;
        cursor: pointer;

        &:hover {
          color: $color-warning;
        }
      }
    }

    form {
      .filter > div {
        display: inline-block;
        vertical-align: bottom;
        float: none;
      }
    }

    > table {
      border-collapse: collapse;

      tr.child {
        td.title {
          padding-left: 20px;
        }
      }

      tr.parent {}

      th {
        height: 140px;
        white-space: nowrap;
        border-bottom: 1px solid #ccc !important;

        > div {
          transform: translate(17px, 1px) rotate(315deg);
          width: 20px;

          > span {
            border-bottom: 1px solid #ccc;
            padding: 5px 0;
          }

          &.expertise-excluded {
            > span {
              color: $color-red;
            }
          }
        }

        &.total-heading {
          div span {
            font-weight: 800;
          }
        }

        &:last-child {
          border-bottom: none !important;
        }
      }

      td {
        border: 1px solid #ccc;
        text-align: center;
        vertical-align: middle;

        position:relative;

        span.expert-groups {
          position:absolute;
          display:block;
          bottom:0px;
          right:0px;
          left:0px;

          span.expert-group {
            cursor:pointer;
            background-color:$color-light-pink;
            display:inline-block;
            float:right;
            width:10px;
            height:10px;
            border-radius:10px;
            margin:2px;
            border:solid 2px transparent;
            &:hover {
              border-color:$color-nero;
            }
            &.active {
              background-color:$color-green;
            }
            &.excluded {
              background-color:$color-red;
            }
            &.new,
            &.add {
              background-color:transparent;
            }
            &.topic {
              background-color: transparent;
              float: none;
              position: absolute;
              top: -25px;
              left: 0;
              &.topic-selected {
                background-color: $color-teal;
              }
            }
          }

        }
        
        &:first-child, &.title {
          text-align: left;
          font-weight: bold;
          text-transform: capitalize;
        }

        &.total-cell {
          background-color: #fafafa;
          font-weight: bold;
        }

        &.excluded-cell {
          background-color: #ddd;
        }

        &.landing-page-cell {
          background-color: rgba(172, 209, 55, 0.5);
          font-weight: bold;
          color: white !important;
          padding: 0;

          &:hover {
            background-color: rgba(172, 209, 55, 0.8);
          }

          a {
            display: block;
            color: white;
          }
        }

        &.expert-group-cell {
          background-color: $color-bright-blue;
          &.expert-group-active {
            background-color: #eeffaa;
          }
        }

        &:last-child {
          border: none;
          padding: 8px 30px 8px 15px;
        }
      }
    }
  }
}

#tag_management_edit {
  .tag-management-edit-table {
    th {
      border: 1px solid #ccc;
      border-bottom: 1px solid #ccc !important;
      padding: 8px;
    }

    td {
      padding: 8px;
      border: 1px solid #ccc;
      vertical-align: top;

      &:first-child {
        color: $color-primary;
        font-weight: bold;
      }

      .user {
        display: inline-block;

        .user-checkbox {
          .user-id {
            width: 50px;
            display: inline-block;
          }
        }
      }

      .move-tag div {
        display: inline-block;
        margin-bottom: 10px;

        &.move-tag__options {
          select {
            width: 200px;
            display: block;
          }

          label {
            padding-top: 10px;
          }
        }

        &.move-tag__submit {
          padding-left: 20px;
          vertical-align: top;
        }
      }
    }
  }
}

.report-mgmt-tbl {
  //
}

.report-mgmt-tbl__header {
  min-width: 30px;

  &.right {
    text-align: right;
  }

  &.smaller {
    width: 30px;
    max-width: 30px;
  }

  &.small {
    width: 60px;
    max-width: 60px;
  }

  &.medium {
    width: 120px;
    max-width: 120px;
  }

  &.large {
    width: 180px;
    max-width: 180px;
  }

  &.extra {
    width: 300px;
    max-width: 300px;
  }
}

.report-mgmt-tbl__row {
  position: relative;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }

  &.no-bg {
    &:nth-child(odd) {
      background-color: inherit;
    }
  }

  &:hover {
    background-color: #fff9fb;

    a {
      color: $color-nero;

      &:hover {
        color: #bf8f95;
      }
    }
  }

  &.tall {
    .report-mgmt-tbl__cell {
      height: 76px;
      min-height: 76px;
    }

    .overlay {
      &:after {
        content: ' ';
        position: absolute;
        left: 15px;
        right: 15px;
        z-index: 1;
        margin-top: -30px;
        height: inherit;
        cursor: pointer;
      }
    }
  }
}

.report-mgmt-tbl__cell {
  height: 60px;
  min-height: 60px;
  max-width: 180px;
  white-space: nowrap;
  vertical-align: middle !important;

  &.overlay {
    &:after {
      content: ' ';
      position: absolute;
      left: 15px;
      right: 15px;
      z-index: 1;
      margin-top: -20px;
      height: inherit;
      cursor: pointer;
    }

    // &:hover {
    //   color: $color-primary;
    // }

  }

  &.right {
    text-align: right;
  }

  &.avatar-wrapper {
    width: 53px;
  }

  .avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 37px;
    padding-bottom: 37px !important;
    display: block;
    background-size: cover;
    display: inline-block;
    vertical-align: text-bottom;
  }

  .certificate {
    max-width: 100%;
  }

  &.new {
    &:before {
      content: ' ';
      background-color: #CA4C84;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      left: 10px;
      position: absolute;
    }
  }

  &.unhandled {
    &:before {
      content: ' ';
      background-color: $color-light-pink;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      left: 10px;
      position: absolute;
    }
  }
}

.report-mgmt-tbl__cell--reason {
  overflow: hidden;
  text-overflow: ellipsis;

  &.show {
    overflow: visible;
    text-overflow: initial;
    height: auto;
    white-space: normal;
    max-width: inherit;
    display: table-cell !important;
  }
}

.report-mgmt-detail {
  padding: 10px 0px;
  border-bottom: solid 1px #efefef;
}

.report-mgmt__user {
  font-weight: bold !important;
  z-index: 2;
  position: relative;
}

.report-mgmt__link {
  cursor: pointer;
}

.report-mgmt__more {
  border: none !important;
  color: #303030 !important;
  display: inline !important;
  background-color: transparent !important;
}

.report-mgmt-actions {
  &.border-bottom {
    td {
      border-bottom: 1px solid $color-close-white;
      padding: 30px 0;

      &.no-padding {
        padding: 0;
      }
    }
  }

  &.hide {
    display: table-row !important;
    padding: 0;

    td {
      border-bottom: 1px solid $color-close-white;
      font-size: 0;
      padding: 0;

      button {
        padding-top: 0;
        padding-bottom: 0;
        height: 0;
        border: 0;
        overflow: hidden;
      }
    }
  }

  &.show {
    display: table-row !important;
  }
}

.report-mgmt-action {
  color: #ccc !important;
  border-color: #ccc !important;
  background-color: $color-white !important;

  &:hover {
    color: $color-darker-grey !important;
    border-color: $color-darker-grey !important;
  }
}

.report-mgmt-action--recommended {
  color: $color-white !important;
  border-color: $color-light-pink !important;
  background-color: $color-light-pink !important;

  &:hover {
    color: $color-light-pink !important;
    border-color: $color-light-pink !important;
    background-color: $color-white !important;
  }
}

.table-alternating {
  tr:nth-child(odd) {
    td {
      background-color: #e0e0e0;
    }
  }
}

.table-calls {
  th {
    border-bottom: 0.5px solid #ccc !important;
    border-top: none !important;
  }

  td {
    padding: 3px 2px !important;
    font-size: 11px;
  }
}

.checkbox-form {
  .checkbox-title {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    margin-right: 10px;
  }

  label {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 16px;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 20px;
    margin-bottom: 0;
  }

  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border: 2px solid $color-primary;
  }

  label:hover input ~ .checkmark {
    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 5px;
      top: 1px;
      width: 6px;
      height: 10px;
      border: solid $color-light-pink;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  label input:checked ~ .checkmark {
    background-color: $color-white;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  label input:checked ~ .checkmark:after {
    display: block;
  }

  label .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid $color-primary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/* diagonal table headers */
th.diagonal {
  // height: 140px;
  white-space: nowrap;

  // border-bottom: 1px solid #ccc !important;
  // padding:0px !important;
  > div {
    transform: translate(0px, 5px) rotate(315deg);
    width: 8px;

    > span {
      padding: 0px;
    }
  }
}

td a.simple {
  color: inherit;
}

td a.simple:hover {
  text-decoration: underline !important;
  text-decoration-style: dotted;
}

td.nowrap {
  white-space: nowrap;
}

td.squared {
  width: 8px;
}

td.bordered {
  border-left: 0.5px solid #ccc;
  border-right: 0.5px solid #ccc;
  border-collapse: collapse;
}

td.centered {
  text-align: center;
}

td.divided {
  border-left: 1.5px solid #999;
}

td.right {
  text-align: right;
}

td.ok {
  color: $color-green;
}

td.not-ok {
  background-color: rgba($color-red, $light-alpha) !important;
  color: $color-red;
}

.mono {
  font-family: "courier new",monospace;
  font-size: 10px !important;
}

.deleted {
  text-decoration: line-through;
  color: $color-grey;
}

.container.translations {
  h2.subtitle {
    cursor: pointer;

    span.icon-right-arrow {
      @include icon-right-arrow($color-light-pink);

      height: 44px;
      width: auto;
      margin-left: 10px;
    }
  }

  h3.subtitle {
    cursor: pointer;

    span.icon-right-arrow {
      @include icon-right-arrow(#DCDCDC);

      height: 20px;
      width: auto;
      margin-left: 10px;

      &.active {
        transform: rotate(90deg);
      }
    }
  }
}

.form-control.wide {
  width: 450px;
}

.container-admin-form {
  .remove-button {
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 0px;
    margin: 3px;
    color: $color-nero;
    font-weight: $font-weight-bold;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: $color-warning;
    }
  }

  .add-button {
    &:hover {
      color: $color-nero;
      border-color: $color-nero;
      background-color: $color-white;
    }
  }

  .input-group {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  input[type=radio] {
    width: 16px;
    margin: 3px;
  }

  label.label-radio {
    cursor: pointer;
    font-weight: $font-weight-bold;
    padding-top: 3px;

    &:hover {
      color: $color-primary;
    }
  }
}

.has-image::after {
  content: ' ';
  display: inline-block;
  position: absolute;
  border-radius: 16px;
  width: 8px;
  height: 8px;
  background-color: $color-pink;
  top: -3px;
  right: -3px;
  color: $color-white;
}

.options-autocomplete {
  background-color: $color-white;
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: $color-close-white;
  box-shadow: -2px 3px 3px rgba(#efefef, 0.5);
  position: absolute;
  z-index: 100;
  top: 34px;
  width: calc(100% - 20.5px);
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  li {
    list-style-type: none;
    margin: 0px;
    padding: 6px 10px 4px;
    font-size: $font-size-small;
    border-width: 0px 0px 0.5px;
    border-style: solid;
    border-color: $color-close-white;

    &:hover {
      background-color: rgba($color-light-pink, $light-alpha);
    }

    cursor: pointer;

    &.unselectable, &.unselectable:hover {
      background-color: $color-off-white;
      color: $color-grey;
      cursor: default;
    }
  }
}

table.table.landing-pages {
  tr {
    border-bottom: solid 0.5px $color-close-white;
  }

  td {
    vertical-align: middle;
  }

  th a {
    color: $color-grey;
  }

  th.asc:after {
    font-size: 8px;
    content: "▲";
  }

  th.desc:after {
    font-size: 8px;
    content: "▼";
  }

  .criteria-list:not(:last-child)::after {
    content: ',';
    color: $color-grey;
    font-weight: $font-weight-normal;
  }

  .language {
    font-size: 11px;
    display: inline-block;
    width: 20px;
    text-align: center;
    font-weight: $font-weight-bold;
    margin: 0px 1.5px 0px 0px;
    color: $color-white;
    background-color: $color-close-white;
    position: relative;

    &.active {
      color: $color-white;
      background-color: $color-green;
    }
  }

  .status {
    &.pending, &.draft {
      color: $color-close-white;
    }

    &.live, &.published {
      color: $color-green;
    }
  }

  .row-action-button {
    font-size: 9px;

    &:hover {
      color: $color-light-pink;
    }
  }

  tr td, tr th, .toggle {
    user-select: none;
  }

  tr.landing-page {
    cursor: pointer;
    background-color: rgba($color-light-pink, 0.1);

    &:hover {
      background-color: rgba($color-light-pink, 0.2);

      .toggle {
        color: $color-light-pink;
      }
    }

    &.list-group {
      cursor: default;
      background-color: #eee;
      border-bottom: 1px solid $color-nero;
    }

    .tiny {
      font-size: 10px;
      color: $color-lighter-grey;
    }
  }

  td.tag {
    color: $color-lighter-grey;
  }

  &.selectable {
    tr.landing-page {
      cursor: default;
    }
    tr td, tr th {
      user-select: auto;
    }
  }

  .list-group-details {
    tr {
      td:first-child {
        padding-left: 30px;
      }
    }
  }
}

.input-landing-page-content, .input-article-content {
  .landing-page-content-preview, .landing-page-intro-preview, .article-content-preview {
    padding-top: 38px;

    p, div.paragraph, li {
      line-height: 1.5em;

      @include when-xs {
        font-size: 14px;
      }


      @include when-sm {
        font-size: 18px;
      }


      @include when-lg {
        font-size: 20px;
      }
    }

    i, em {
      background-color: $color-bright-light-pink;
      font-style: normal;
    }

    ul {
      margin-left: 0px;
      padding-left: 22px;
      list-style-position: outside;
    }

    ul, ol {
      margin-right: 0px;

      ul, ol {
        margin-bottom: 0px;
      }

      &.has-title {
        margin-top: -1.6em;
      }

      &.list-spacing {
        li {
          margin-bottom: 1.6em;
        }
      }

      &.style-check {
        list-style: none;
        padding-left: 0;

        li {
          padding-left: 22px;
          position: relative;

          &:before {
            @include icon-bullet-check($color-primary);

            position: absolute;
            left: 0;
          }
        }
      }
    }

    a {
      color: $color-teal;
    }

    blockquote {
      background-color: $color-bright-light-pink;
      border: none;
      padding: 0;
      margin: 3em 0;
      padding: 40px 30px;

      span {
        background-color: #FACBCC;
        display: inline;
      }
    }

    p > img {
      margin: 50px auto 0;
      display: block;
      max-width: 100%;
      height: auto;
    }

    h2, h3, h4, h5, h6, q {
      font-size: 26px;
      line-height: 1.1em;
      font-weight: $font-weight-bold !important;
      padding-bottom: 0px;
      margin-bottom: 0;
      text-transform: none;

      @include when-xs {
        font-size: 22px;
      }


      @include when-lg {
        font-size: 28px;
      }
    }

    h2 {
      margin-bottom: .6em;
    }

    h3 {
      color: $color-primary;
      margin-bottom: .2em;
    }

    q {
      color: $color-grey;
      display: block;
      padding: 0 80px;
      max-width: 520px;

      &:before,
      &:after {
        content: none;
      }

      &.float-left {
        color: $color-primary;
        font-weight: $font-weight-normal !important;
        width: 250px;
        padding: 0;
        max-width: none;
        margin-right: 40px;
        margin-bottom: 40px;
        padding-top: 20px;
        border-top: 10px solid $color-close-white;
        float: left;
      }
    }

    .indent-left {
      padding-left: 75px;
    }

    .top-spacing {
      margin-top: 3em;
    }

    table {
      margin-top: 15px;

      thead {
        border-bottom: solid 1px $color-light-pink;
      }

      tr th, tr td {
        vertical-align: top;
        padding: 2.5px;
      }

      tr td {
        border: solid 0.5px $color-lightest-grey;
        border-left-width: 0px;
        border-right-width: 0px;
      }
    }

    iframe {
      max-width: 100%;
    }

    p, div.paragraph, ol, ul, table, q {
      margin-bottom: 1.6em;
    }
  }

  .landing-page-title-preview {
    color: rgba($color-black, 1);
    font-size: 42px;
    line-height: 40px;
    font-weight: $font-weight-bold;

    // font-family: bebasNeueBold;
    // color: $color-primary;
    // font-size: 70px;
    // line-height: 60px;

  }

  .button-hide .hidden {
    display: none;
  }
}

.checkbox-inline {
  margin: 4px 10px 4px 0px !important;
}

.partly {
  overflow: hidden;
  padding-bottom: 40px;
  height: auto;

  &.partly-shown {
    max-height: 145px;
  }

  .btn-more-toggle {
    position: absolute;
    bottom: 0px;
    margin: auto;
  }
}

.image-selector {
  cursor: pointer;

  .preview-holder {
    padding: 0px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 0px;
    background-color: rgba($color-primary, 0.5);

    &#cover_image {
      padding-bottom: calc(400 / 1755 * 100%);
    }

    &#cover_image_mobile {
      padding-bottom: calc(280 / 375 * 100%);
    }

    &#main_image {
      padding-bottom: calc(345 / 690 * 100%);
    }

    &#thumbnail_image {
      padding-bottom: 100%;
    }

    &#list_image {
      padding-bottom: 80px;
      background-size: contain;
    }

    &.preview-holder-social {
      width: 320px;
      height: 220px;
      background-size: contain;
    }

    span {
      position: absolute;
      right: 15px;
      top: 15px;
      background-color: rgba($color-nero, 0.5);
      color: $color-white;
      font-size: 9px;
      padding: 2px 3px 4px 3px;
      text-transform: uppercase;
      font-family: bebas;
    }
  }

  &:hover {
    .preview-holder {
      opacity: 0.8;
    }

    span {
      background-color: rgba($color-nero, 0.9);
    }
  }
}

.article_content_control {
  position: absolute;
  top: 0;
  right: 20px;
  user-select: none;
  transition: opacity 0.4s;
  opacity: 0;
  z-index: 100;
  display: none;

  .control_button {
    background-color: $color-light-grey;
    color: $color-white;
    border-radius: 5px;
    border: 2px solid white;
    padding: 2px 8px 0;
    cursor: pointer;
    display: inline-block;
  }

  .control_image {
    background-color: $color-blue;
    border-color: #09a;
  }

  .control_gallery {
    background-color: $color-red;
    border-color: #a9a;
  }

  .control_link {
    background-color: #fa6;
    border-color: #d9a;
  }

  .control_cta {
    background-color: #7b7;
    border-color: #6a6;
  }

  .control_hero {
    background-color: #b7b;
    border-color: #a6a;
  }
}

#to-image-library-modal,
#to-gallery-edit-modal {
  .modal-dialog {
    width: 90% !important;
  }

  .btn-add {
    display: inline-block;
    margin-left: 30px;
    background-color: $color-green;
    color: $color-white;
    padding: 1px 6px 0;
  }
}

.to-image-library-container {
  /*
  position: fixed;
  z-index: 100;
  top: 100px;
  left: 5%;
  width: 90%;
  height: 500px;
  background-color: #ddd;
  border: 1px solid #888;
  /* -- */

  .image-library-main {
    height: 440px;
  }

  .image-list {
    margin: 10px;
    height: 100%;
    overflow: scroll;

    .image-list-item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 20px;

      a.preview {
        display: block;
      }

      .image-list-item-path {
        cursor: pointer;
        padding: 2px 5px;
        background-color: #eeeef7;
      }

      .image-list-item-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .form-attachment-add-container {
    width: 300px;
    margin: auto;

    .form-bottom {
      margin-top: 10px;
      display: flex;

      input {
        padding: 3px 5px;
        flex: 1;
        margin-right: 20px;
      }
    }
  }

  .preview {
    width: 296px;
    height: 296px;
    background-color: $color-bright-light-pink;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.to-image-library-container {
  .image-list-group {
    .preview {
      position: relative;
      width: 200px;
      height: 200px;

      .item-controls {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    .image-list-item {
      span {
        font-size: 10px;
      }
    }
  }
}

#to-gallery-edit-modal {
  .image-list-group-title {
    font-size: 16px;
    font-weight: bold;
  }

  .add_gallery_form {
    margin: 10px 10px 0 40px;
  }

  .image-list-group {
    .image-list-group-head {
      margin-bottom: 20px;
    }
  }

  form {
    display: inline-block;

    input[type="file"] {
      display: inline-block;
    }
  }
}

.form-article-banner-container {
  margin-bottom: 20px;

  .preview {
    width: 100%;
    height: 150px;
    background-color: $color-light-pink;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  input {
    margin-bottom: 4px;
    width: 100%;
    display: block;
  }
}

#to-article-links-modal {
  form {
    display: flex;

    .main-input {
      flex: 1;
    }

    .buttons {}
  }

  .links-result-title {
    margin: 10px 0 5px 5px;
    font-weight: bold;
  }

  .links-result {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 20px;

    button {
      float: right;
      margin-left: 10px;
    }

    .link {
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
    }
  }
}

.article-content-helper {
  position: relative;
  height: 1px;
  width: calc(100% + 13px);
  overflow: visible;
}

.article-content-helper-render {
  position: absolute;
  top: 0;
  left: -13px;
  padding-left: 13px;
  width: 100%;
  height: 10px;
  overflow: scroll;
  opacity: 0;
}

.article-content-helper-checking {
  padding-left: 30px;
  color: $color-light-grey;

  img {
    width: 17px;
    height: 17px;
  }
}

.article-content-helper-marker {
  display: block;
  position: absolute;
  width: 13px;
  left: 0;

  &.valid {
    background-color: #0b0;
  }

  &.invalid {
    background-color: #f00;
  }
}

.submit-actions {
  border-top: solid 1px $color-light-grey;
  width: 100vw;
  left: 0;
  bottom: 0px;
  position: fixed;
  height: 50px;
  margin: 0px !important;
  padding: 7.5px 15px !important;
  background-color: $color-off-white;
  box-shadow: 10px 0px 10px $color-grey;
  transform: translateZ(1px);
  z-index: 1000;
}

.expertise-name.bordered:not(:last-child),.tag-name.bordered:not(:last-child),.profession-name.bordered:not(:last-child) {
  border-right:solid 1px $color-light-grey;  
  padding-left::3px;
  padding-right:3px;
}

.container-cta-boxes {
  .table {

    tbody {
      border: 0;
    }

    input[type=text] {
      width: 100%;
    }

    .content-lines-container {
      select {
        width: 100%;
      }      
    }

  }
}

// ------
// ------
// copying cta styles here (from _article-cta.scss) so admin preview looks ok
// ------
// ------

.cta_box {

  width: 100%;
  background-color: $color-close-white;
  padding: 20px 5px;
  font-family: proxima;

  .cta_box_title {
    font-size: 24px;
    font-weight: 800;
    line-height: 0.9em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 5%;
  } 
  .cta_box_contents {
    .cta_box_content {
      font-weight: bold;
      font-size: 16px;
      margin: 0 5% 5px 5%;
      padding-left: 20px;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 14px;
    }
  }

  .cta_box_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .cta_box_button {
      display: flex;
      align-items: center;
      font-size: 18px;
      background-color: white;
      height: 36px;
      padding: 0 18px;
      border-radius: 18px;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
  }

  div.search {
    padding: 10px 10px 0 10px;

    input {
      padding: 10px;
      font-size: 16px;
      width: 100%;
      border: 1px solid #b2b2b2 !important;
    }

    .smartsearch {
      top: 48px;
      width: calc(100% - 20px);
      margin: 0 10px;

      > ul {
        > li {
          li {
            font-size: 14px;
            line-height: 1em;
          }
        }
      }
    }

  }

  &.cta_box_template_green {
    background-color: #DDF3F1;

    .cta_box_title,
    .cta_box_button {
      color: #26A7A3;
    }

    .cta_box_contents {
      .cta_box_content {
        background-image: url("/images/icons/check-green.svg");
      }
    }
  }

  &.cta_box_template_blue {
    background-color: #EEEDF6;

    .cta_box_title,
    .cta_box_button {
      color: #7B95C6;
    }

    .cta_box_contents {
      .cta_box_content {
        background-image: url("/images/icons/check-blue.svg");
      }
    }
  }

  &.cta_box_template_pink {
    background-color: #F7DFE2;

    .cta_box_title,
    .cta_box_button {
      color: #CF9A9D;
    }

    .cta_box_contents {
      .cta_box_content {
        background-image: url("/images/icons/check-pink.svg");
      }
    }
  }

}
