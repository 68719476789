[data-to-root].requests {
  /* most of the styles are already inherited from _settings.scss */

  &.modal-open {
    #modal-remove .modal-dialog {
      .modal-content {
        &:before {
          position: absolute;
          content: "";
          width: 200vw;
          height: calc(100% + 100px);
          top: 50%;
          transform: translateY(-50%);
          background-color: $color-off-black;
          opacity: 0.5;
          margin-left: -100vw;
          z-index: -1;
        }

        box-shadow: none;
        border: none;
      }

      * {
        background-color: transparent;
      }

      .modal-header {
        border: none;
        position: relative;

        h4 {
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          font-size: 100px;
          color: $color-light-grey;
          position: relative;
          line-height: 1;
          border: none;
        }

        .border {
          position: absolute;
          bottom: 50px;
        }

        .close {
          float: none;
          display: block;
          margin-left: auto;
          opacity: 0.7;
          color: transparent;
          text-shadow: none;

          &:hover {
            opacity: 1;
          }
        }
      }

      .modal-body {
        p {
          color: $color-white;
        }
      }

      .modal-footer {
        border: none;
        display: flex;
        justify-content: center;

        .btn {
          border: none;
          width: 30%;
          min-width: fit-content;
          background-color: $color-yellow-green;
          color: $color-black;
          font-size: 22px;
          padding: 7px 12px 2px;

          &:first-child {
            background-color: $color-light-pink;
          }
        }
      }
    }
  }

  .backdrop {
    .item-details {
      padding-top: 15px;
    }
  }

  h5 {
    font-size: 18px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    text-transform: none;
    font-weight: $font-weight-bold;

    &.blue {
      color: $color-blue;
    }
  }

  .timezone-info {
    margin-top: 20px;

    .traveler-time, .guide-time, .traveler-location, .guide-location {
      color: $color-grey;
    }

    .traveler-time, .guide-time {
      display: inline-block;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-bold;
      font-size: 16px;
      padding-top: 10px;
    }

    .traveler-location, .guide-location {
      display: inline-block;
      position: relative;
      top: -5px;
      padding-bottom: 5px;
    }

    & > div:first-child {
      @extend .text-right;

      border-right: solid 1px $color-light-grey;
    }
  }

  #sidebar {
    .nicename {
      display: block;
    }

    .online .nicename {
      margin-right: 33px;
    }
  }

  #request-section {
    .date {
      position: relative;
      top: 15px;
    }

    background-color: $color-off-white;

    h3 {
      font-size: 18px;
      text-transform: none;
      padding-top: 0px !important;
    }
  }

  #requestmap-section {
    padding-bottom: 15px;
  }

  #messages-section {
    .date {
      position: relative;
      top: 10px;
    }

    .message-end {
      border-bottom: solid 1px $color-lighter-grey;
      padding-bottom: 15px;
    }

    h3 {
      font-size: 18px;
      text-transform: none;
      padding-top: 0px !important;
    }
  }

  #reply {
    h3:after {
      width: 120px;
    }
  }

  #reply-section {
    background-color: $color-off-white;
  }

  .request-report {
    background-color: $color-off-white;
    padding-bottom: 25px;
    margin-bottom: 25px;

    .user-block-wrap {
      margin-top: 0;
    }
  }

  .block-report-wrapper {
    display: flex;
    justify-content: center;
    color: $color-grey;

    .separator {
      border-left: 1px solid;
    }

    .block-report {
      width: calc(50% - 1px);
      padding: 0 15px;
      cursor: pointer;
    }

    .report:hover {
      color: $color-black;
    }

    .block:hover {
      color: $color-black;
    }
  }
}

table.costs-table {
  width: 100%;

  input.cost-item-value {
    width: 150px;
  }

  input.wide {
    width: 100%;
  }

  td, th {
    padding: 2px 5px;

    p.description, p.cost-item-subtotal {
      position: relative;
      top: 5px;
    }

    p.times {
      position: relative;
      top: 5px;
      font-size: 30px;
      line-height: 20px;
      color: $color-grey;
      font-weight: $font-weight-light;
    }
  }

  .btn.remove, .btn.add {
    @include border-radius(20px);

    width: 30px;
    height: 30px;
    position: relative;

    p {
      color: $color-red;
      line-height: 20px;
      font-size: 20px;
      position: relative;
      top: -1px;
      left: -6px;
    }

    background-color: $color-white;
    top: 0px;

    &:hover {
      background-color: $color-red;

      p {
        color: $color-white;
      }
    }
  }

  .btn.add {
    p {
      font-size: 25px;
      color: $color-green;
      top: 0px;
      left: -4px;
    }

    &:hover {
      background-color: $color-green;
    }
  }

  th.total {
    background: linear-gradient(to bottom, transparent, transparent calc(40% - 1px), $color-lighter-grey 1px, transparent calc(40% + 0px), transparent 100%);
    padding-right: 0px;

    p {
      float: right;
      background-color: $color-lighter-grey;
      margin-right: 0px;
      padding-right: 5px;
      padding-left: 15px;
    }
  }

  .cost-item-description-addon {
    width: 80px;
  }
}

.close-button {
  position: absolute;
  right: 20px;
  top: 12px;
  color: $color-grey;
  cursor: pointer;

  &:hover {
    color: $color-nero;
  }
}
