.la-search {
    background-image:cdn('/images/icon-search-blue.svg');
    background-size:24px 24px;
    background-repeat:no-repeat;
    background-position: 0px 0px;
    width:24px;
    height:24px;
    display:inline-block;
}

.la-search-small {
    background-image:cdn('/images/icon-search.svg');
    background-size:16px 16px;
    background-repeat:no-repeat;
    background-position: 0px 0px;
    width:16px;
    height:16px;
    display:inline-block;
}

.la-eye,.la-eye-open {
    background-image:cdn('/images/eye-open.svg');
    background-size:20px 14px;
    background-repeat:no-repeat;
    background-position: 0px 0px;
    width:20px;
    height:14px;
    display:inline-block;
}
.la-eye-closed {
    background-image:cdn('/images/eye-closed.svg');
    background-size:20px 14px;
    background-repeat:no-repeat;
    background-position: 0px 0px;
    width:20px;
    height:14px;
    display:inline-block;
}

.la-i {
    background-image:cdn('/images/vi-i.svg');
    background-size:14px 14px;
    background-repeat:no-repeat;
    background-position: 0px 0px;
    width:14px;
    height:14px;
    display:inline-block;

}

.la-close,.la-close-grey {
    width:16px;
    height:16px;
    background-size:16px 16px;
    background-repeat:no-repeat;
    background-position:contain;
    background-image:cdn('/images/icon-close-grey.svg') !important;
    background-color:transparent;
    cursor:pointer;
}

.la-cross {
    width:8px;
    height:8px;
    background-size:8px 8px;
    background-repeat:no-repeat;
    background-position:contain;
    background-image:cdn('/images/icon-cross.svg');
}

.la-cross-white {
    width:8px;
    height:8px;
    background-size:8px 8px;
    background-repeat:no-repeat;
    background-position:contain;
    background-image:cdn('/images/icon-cross-white.svg');
}

.la-cross-white-large {
    width:18px;
    height:18px;
    background-size:18px 18px;
    background-repeat:no-repeat;
    background-position:contain;
    background-image:cdn('/images/icon-cross-white.svg');
}

.la-bullet {
    width:6px;
    height:6px;
    margin-top:3px;
    display:inline-block;
    background-color:$color-grey;
    &.blue {background-color:$color-blue;}
    &.red {background-color:$color-red;}
    &.primary {background-color:$color-primary;}
    @include border-radius(6px);
}

.vi-btn,.vi,.vi-btn-sm,.vi-btn-lg {
    vertical-align: middle;
    margin:0px 3px;
    background-repeat:no-repeat;
    background-position: 0px 0px;
    background-size: 62px 62px;
    background-color:$color-nero;
    width: 62px; height:62px;
    display:inline-block;
    &.active {
        opacity:1.0;
    }
    &.inactive {
        opacity:0.5;
    }
    &.disconnect,&.disconnected {
        background-color:$color-red;
    }
    &.connect,&.connected {
        background-color:$color-green;
    }
    &.invert {
        background-color:transparent;
        filter: invert(80%);
        -webkit-filter: invert(80%);
    }

    &.vi-chat {         background-image:cdn('/images/vi-chat.png');}
    &.vi-hangup {       background-image:cdn('/images/video/vi-hangup.png');}
    &.vi-recording {    background-image:cdn('/images/vi-recording.png');}
    &.vi-volume {       background-image:cdn('/images/vi-volume.png');}
    &.vi-share {        background-image:cdn('/images/vi-share.png');}
    &.vi-close {        background-image:cdn('/images/vi-close.png');}

    &.vi-microphone {
        background-image: cdn('/images/video/vi-mic.png'), cdn('/images/video/vi-mic-off.png');
        background-position: 0px 0px, 0px 62px;
        &.off {
            background-position: 0px 62px, 0px 0px;
        }
    }
    &.vi-video {
        background-image: cdn('/images/video/vi-cam.png'), cdn('/images/video/vi-cam-off.png');
        background-position: 0px 0px, 0px 62px;
        &.off {
            background-position: 0px 62px, 0px 0px;
        }
    }
    &.vi-fullscreen {
        background-image: cdn('/images/vi-fullscreen.png'), cdn('/images/vi-fullscreen-off.png');
        background-position: 0px 0px, 0px 62px;
        &.off {
            background-position: 0px 62px, 0px 0px;
        }
    }
    &.vi-screen-sharing {
        background-image: cdn('/images/vi-screen-sharing-off.png'), cdn('/images/vi-screen-sharing.png');
        background-position: 0px 0px, 0px 62px;
        &.on {
            background-color: rgba($color-red,$light-alpha);
            border:solid 0.5px $color-white;
            background-position: 0px 62px, 0px 0px;
        }
        &.disabled {
            pointer-events:none;
            opacity:0.5;
        }
    }

}

.vi-btn-sm,.vi-sm {
    background-size: 31px 31px;
    width: 31px; height:31px;
}
