[data-to-root].certified {
  .color-primary {
    color: $color-mycom-primary !important;
  }

  .color-mycom-primary {
    color: $color-mycom-primary !important;
  }

  .smaller {
    font-size: 16px;
    line-height: 20px;
  }

  .small {
    font-size: 18px;
    line-height: 20px;
  }

  .large {
    font-size: 26px;
    line-height: 28px;
  }

  .larger {
    font-size: 36px;
    line-height: 40px;
  }

  .big {
    font-size: 41px;
    line-height: 41px;
  }

  .bold {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-bold !important;
  }

  .padding-small {
    padding: 10px;
  }

  .padding-normal {
    padding: 15px;
  }

  .btn {
    font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
    font-weight: $font-weight-bold !important;
    letter-spacing: initial;
  }

  .no-link {
    color: $color-nero;

    &:hover {
      color: $color-mycom-primary;
    }
  }

  @include when-sm {
    .sm-normal {
      font-size: 26px;
      line-height: 28px;
    }

    .sm-small {
      font-size: 16px;
      line-height: 20px;
    }
  }


  @include when-md {
    .md-larger, .sm-normal {
      font-size: 30px;
      line-height: 41px;
    }
  }


  @include when-lg {
    .md-larger, .sm-normal {
      font-size: 41px;
      line-height: 41px;
    }
  }


  .online-tag.online {
    background-color: #9adedb;
  }

  // .info-item:hover .price-tag, .info-item.selected .price-tag {
  //  // background-color:$color-mycom-primary !important;
  // }

  .navbar {
    position: relative !important;
  }

  #nav-holder {
    .certification-about {
      position: relative;
      overflow: visible;

      p {
        position: relative;
        background-color: $color-mycom-primary;
        font-size: 16px;

        @media only screen and (max-width: 835px) {
          font-size: 13px;
        }

        @media only screen and (max-width: 775px) {
          font-size: 11px;
        }

        line-height: 18px;
        padding: 10px 15px 20px 15px;
        margin: 0px 20px 0px 0px;
        top: -10px;
      }

      a.btn {
        position: absolute;
        bottom: 3px;
        right: 10px;
        color: $color-white !important;
        font-size: 14px;
        border-color: $color-nero !important;
        background-color: $color-nero !important;
        height: 25px !important;
        padding: 5px !important;

        &:hover {
          background-color: $color-grey !important;
          border-color: $color-grey !important;
        }
      }

      &.below {
        padding-top: 80px;
        background-color: $color-mycom-primary;
        width: 100%;

        a.btn {
          float: right;
          position: relative;
          top: -30px;
        }
      }
    }
  }

  #main-container {
    padding-top: 100px;
  }

  .certification-about.below {
    display: none;

    @include when-xs {
      display: block;
    }


    padding-top: 70px;
    background-color: $color-mycom-primary;
    width: 100%;
    padding-bottom: 5px;

    p {
      margin-right: 10px;
      margin-left: 10px;
      font-size: 12px;
    }

    a.btn {
      float: right;
      top: -25px;
      font-size: 12px;
    }
  }
}

[data-to-root].certified.theone {
  p {
    font-size: 20px;
    line-height: 22px;
  }

  .color-primary {
    color: $color-mycom-primary !important;
  }

  .color-mycom-primary {
    color: $color-mycom-primary !important;
  }

  .smaller {
    font-size: 16px;
    line-height: 20px;
  }

  .small {
    font-size: 18px;
    line-height: 20px;
  }

  .large {
    font-size: 26px;
    line-height: 28px;
  }

  .larger {
    font-size: 36px;
    line-height: 40px;
  }

  .big {
    font-size: 41px;
    line-height: 41px;
  }

  .bold {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-bold !important;
  }

  .padding-small {
    padding: 10px;
  }

  .padding-normal {
    padding: 15px;
  }

  .btn {
    font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
    font-weight: $font-weight-bold !important;
    letter-spacing: initial;
  }

  .no-link {
    color: $color-nero;

    &:hover {
      color: $color-mycom-primary;
    }
  }

  @include when-sm {
    .sm-normal {
      font-size: 26px;
      line-height: 28px;
    }

    .sm-small {
      font-size: 16px;
      line-height: 20px;
    }
  }


  @include when-md {
    .md-larger, .sm-normal {
      font-size: 30px;
      line-height: 41px;
    }
  }


  @include when-lg {
    .md-larger, .sm-normal {
      font-size: 41px;
      line-height: 41px;
    }
  }


  .online-tag.online {
    background-color: #9adedb;
  }

  .info-item:hover .price-tag, .info-item.selected .price-tag {
    background-color: $color-mycom-primary !important;
  }

  #nav-holder {
    .certification-about.theone {
      position: relative;
      overflow: visible;

      p {
        position: relative;
        background-color: $color-mycom-primary;
        font-size: 16px;

        @media only screen and (max-width: 800px) {
          font-size: 13px;
        }

        line-height: 18px;
        padding: 10px 15px 20px 15px;
        margin: 0px 20px 0px 0px;
        top: -10px;
      }

      a.btn {
        position: absolute;
        bottom: 3px;
        right: 10px;
        color: $color-white !important;
        font-size: 14px;
        border-color: $color-nero !important;
        background-color: $color-nero !important;
        height: 25px !important;
        padding: 5px !important;

        &:hover {
          background-color: $color-grey !important;
          border-color: $color-grey !important;
        }
      }
    }
  }
}

[data-to-root].certified.mycom.about {
  #main-container {
    display: block !important;
  }

  .container {
    min-height: 0px !important;
  }

  .container.new {
    height: 213px;
    min-height: 213px !important;
    margin-bottom: 100px;

    @include when-sm {
      margin-bottom: 50px;
    }


    @include when-md {
      margin-bottom: 100px;
    }


    background-color: $color-mycom-primary;

    .row > div:first-child {
      overflow: hidden;
      height: 213px;

      @include when-sm {
        padding-left: 0px;
        left: -10px;
      }


      @include when-md {
        padding-left: 0px;
        left: -15px;
      }


      @include when-lg {
        padding-left: 0px;
        left: 0px;
      }
    }

    .cta {
      position: relative;
      top: -20px;

      .btn {
        margin-top: 20px;

        @include when-sm {
          margin-top: 7.5px;
        }


        @include when-md {
          margin-top: 20px;
        }
      }
    }

    .diagonal {
      p:first-child {
        padding: 0;
        margin: 0;
        position: relative;
        top: 4px;
      }

      p:last-child {
        padding: 0;
        margin: 0;
        position: relative;
        top: 0px;
        padding-bottom: 4px;
      }

      transform: rotate(-45deg);
      position: relative;
      left: -90px;
      top: 50px;
      width: 350px;

      @include when-sm {
        left: -110px;
        top: 40px;
      }


      @include when-md {
        left: -90px;
        top: 50px;
      }
    }

    .padded {
      padding-top: 20px;
    }
  }

  .container.banner {
    min-height: 0px !important;
    height: 390px;

    @include when-sm {
      height: 230px;
    }


    @include when-md {
      height: 390px;
    }


    background-image: url("/assets-certified/mycom/images/meekijken.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .padded {
      padding-top: 100px;

      @include when-sm {
        padding-top: 50px;
      }


      @include when-md {
        padding-top: 100px;
      }
    }
  }

  .container.explanation {
    .padded {
      padding: 75px;
    }

    .img-the-one-app {
      height: 380px;

      @include when-xs {
        position: relative;
        bottom: auto;
        left: auto;
        top: auto;
        right: auto;
        height: 380px;
      }


      @include when-md {
        position: absolute;
        bottom: -200px;
        right: 0px;
        height: 555px;
      }
    }
  }

  .container.how-it-works {
    padding-top: 100px;
    padding-bottom: 100px;

    p.padding-large {
      padding-bottom: 30px;
    }

    p.padded {
      padding-bottom: 10px;
    }
  }

  .container.get-advice {
    background-image: url("/assets-certified/mycom/images/leuk-zon-router.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    p.padding-large {
      padding-top: 100px;
      padding-bottom: 0px;
    }

    .row:first-child {
      height: 325px;
    }

    .row:last-child {
      height: 500px;
      position: relative;

      .advice-example {
        position: absolute;
        bottom: 0px;
      }
    }
  }

  .container.seems-easy {
    .padding-large {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .linethrough {
      border-bottom: solid 1px #000;
      height: 18px;
      margin-bottom: 30px;
    }
  }

  .container.links {
    padding-top: 25px;
    padding-bottom: 25px;

    @include when-sm {
      .row > div {
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }
  }

  @media only screen and (max-width: 729px) and (min-width: 511px) {
    #main-container {
      padding-top: 0;

      .large {
        margin: 0;
      }

      .container {
        &.new {
          height: auto;
          margin-bottom: 0;

          .mobile-br {
            display: block;
          }

          .row {
            > div:first-child {
              overflow: visible;
              height: 30px;

              .diagonal {
                position: absolute;
                top: 30px;
                left: -115px;

                p {
                  &:first-child {
                    font-size: 34px;
                    line-height: 115%;
                    top: 2px;
                  }

                  &:last-child {
                    font-size: 16px;
                    line-height: 130%;
                  }
                }
              }
            }

            .padded {
              padding-bottom: 30px;

              p {
                font-size: 28px;
                line-height: 120%;
                width: 93%;
                margin: auto;
                margin-bottom: 10px;

                &.color-white {
                  font-size: 36px;
                  line-height: 120%;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }

        &.banner {
          background-size: auto 100%;
          background-position: center;
          height: auto;

          .padded {
            padding-top: 6vw;
            padding-bottom: 18vw;

            p {
              // font-size: 4.4vw;
              line-height: 120%;

              &.larger {
                // font-size: 7.5vw;

              }
            }
          }
        }

        &.explanation {
          .padded {
            padding: 15px 70px 45px;

            &:first-child {
              padding: 0;
            }

            > div:first-child {
              padding: 30px 0;

              .padding-normal {
                img {}
              }
            }
          }
        }

        &.how-it-works {
          padding: 50px 15px;

          .row {
            &:first-child {
              text-align: center;

              p {
                margin: 0;

                &.padded {
                  margin: 20px;
                }
              }
            }
          }
        }

        &.get-advice {
          .row {
            &:first-child {
              height: auto;
            }

            &:last-child {
              height: 66vw;
            }

            .padding-large {
              padding: 50px 0 0;

              br {
                display: none;
              }
            }

            .advice-example {
              display: none;
            }
          }
        }

        &.links {
          .row {
            > div {
              margin: 40px 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 510px) {
    #main-container {
      padding-top: 0;

      p {
        font-size: 16px;
        line-height: 120%;
      }

      .md-larger,
      .sm-normal {
        font-size: 8vw;
        line-height: 120%;
      }

      .big {
        font-size: 9vw;
      }

      .large {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        margin: 0;
      }

      .container {
        &.new {
          height: auto;
          margin-bottom: 0;

          .mobile-br {
            display: block;
          }

          .row {
            > div:first-child {
              overflow: visible;
              height: 30px;

              .diagonal {
                position: absolute;
                top: 20px;
                left: -132px;

                p {
                  &:first-child {
                    font-size: 25px;
                    line-height: 115%;
                    top: 2px;
                  }

                  &:last-child {
                    font-size: 11px;
                    line-height: 130%;
                  }
                }
              }
            }

            .padded {
              padding-bottom: 30px;

              p {
                font-size: 5.5vw;
                line-height: 120%;

                &.color-white {
                  font-size: 8vw;
                  line-height: 120%;
                }
              }
            }
          }
        }

        &.banner {
          background-size: auto 100%;
          background-position: center;
          height: auto;

          .padded {
            padding-top: 6vw;
            padding-bottom: 18vw;

            p {
              font-size: 16px;
              line-height: 120%;

              &.larger {
                font-size: 7.5vw;
              }
            }
          }
        }

        &.explanation {
          .padded {
            padding: 15px 15px 45px;

            &:first-child {
              padding: 0;
            }

            > div:first-child {
              padding: 30px 0;

              .padding-normal {
                img {
                  height: 18vw;
                }
              }
            }

            &.bg-color-off-white {
              > div:first-child {
                .padding-normal {
                  img {
                    height: 41px;
                  }
                }
              }
            }
          }
        }

        &.how-it-works {
          padding: 50px 15px;

          .row {
            &:first-child {
              text-align: center;

              p {
                margin: 0;

                &.padded {
                  margin: 20px;
                }
              }
            }
          }
        }

        &.get-advice {
          .row {
            &:first-child {
              height: auto;
            }

            &:last-child {
              height: 120vw;
            }

            .padding-large {
              padding: 50px 0 0;

              br {
                display: none;
              }
            }

            .advice-example {
              display: none;
            }
          }
        }

        &.links {
          .row {
            > div {
              margin: 40px 0;
            }
          }
        }
      }
    }
  }
}
