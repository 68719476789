.p-l-0 {
  padding-left: 0;
}

.row.flex-row {
  @include when-sm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;

    // -webkit-flex-wrap: wrap;
    // -ms-flex-wrap: wrap;
    // flex-wrap: wrap;
  }
}

.row.flex-row > [class*='col-'] {
  @include when-sm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

%flex,
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.fequal {
  > * {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.valign {
  @extend %flex;

  align-items: center;
}

.valign-sm {
  @include when-sm {
    display: flex;
    align-items: center;
  }
}

.halign {
  @extend %flex;

  justify-content: center;
}

.home .container-intro .social-icons {
  position: absolute;
  right: 30px;
  bottom: 0px;
}

@include when-xs {
  .home .container-intro .halign {
    display: block;
  }

  .home .container-intro .social-icons {
    position: relative;
    margin-right: 10px;
    top: 20px;
    right: auto;
  }
}


.calign {
  @extend %flex;

  justify-content: space-between;
  flex-direction: column;
}

.no-halign-xs {
  @include when-xs {
    display: block;
  }
}

.no-halign-sm {
  @include when-sm {
    display: block;
  }


  @include when-xs {
    display: block;
  }


  @include when-md {
    display: flex;
  }


  @include when-lg {
    display: flex;
  }
}

.falign {
  @extend %flex;

  align-items: stretch;
}

.balign {
  @extend %flex;

  align-items: flex-end;
  justify-content: center;
}

.sfealign {
  align-self: flex-end;
}

.justify-content-between {
  @extend %flex;

  justify-content: space-between !important;
}

.justify-content-around {
  @extend %flex;

  justify-content: space-around !important;
}

.row-eq-height {
  @extend %flex;

  @include when-xs {
    display: block !important;
  }
}

.reverse {
  flex-direction: row-reverse;

  @extend %flex;
}

.flex-column {
  @extend %flex;

  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}
