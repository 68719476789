$webinar-left-padding: 30px;
$webinar-right-padding: 20px;
$webinar-left-padding-sm: 25px;
$webinar-right-padding-sm: 25px;
$webinar-left-padding-xs: 20px;
$webinar-right-padding-xs: 20px;

@mixin webinar-container {
    @include when-xs {
        padding-left: $webinar-left-padding-xs !important;
        padding-right: $webinar-right-padding-xs !important;
    }


    @include when-sm {
        padding-left: $webinar-left-padding-sm !important;
        padding-right: $webinar-right-padding-sm !important;
    }


    @include when-md {
        padding-left: $webinar-left-padding !important;
        padding-right: $webinar-right-padding !important;
    }
}

@mixin webinar-row-left {
    @include when-xs {
        margin-left: -#{$webinar-left-padding-xs} !important;
    }


    @include when-sm {
        margin-left: -#{$webinar-left-padding-sm} !important;
    }


    @include when-md {
        margin-left: -#{$webinar-left-padding} !important;
    }
}

@mixin webinar-row-right {
    @include when-xs {
        margin-right: -#{$webinar-right-padding-xs} !important;
    }


    @include when-sm {
        margin-right: -#{$webinar-right-padding-sm} !important;
    }


    @include when-md {
        margin-right: -#{$webinar-right-padding} !important;
    }
}

@mixin container-width {
    @include when-xs {
        max-width: $container-xs;
    }


    @include when-sm {
        max-width: $container-sm;
    }


    @include when-md {
        max-width: $container-md;
    }


    @include when-lg {
        max-width: $container-lg;
    }
}

body[data-to-root].webinar.details > header + *,
body[data-to-root].my-webinars:not(.settings) > header + *,
body[data-to-root].my-webinars.edit > header + * {
    padding-top: var(--header-height);
}

[data-to-root].webinar,
[data-to-root].webinars {
    .top-section {
        margin-top: calc(var(--font-size-webinar-md) * 4 + 20px);

        @include when-xs {
            margin-top: 20px;
        }
    }
}

[data-to-root].webinar {
    --font-size-webinar-xl: 28px;
    --font-size-webinar-lg: 24px;
    --font-size-webinar-md: 17px;
    --font-size-webinar-sm: 16px;

    @include when-sm-down {
        --font-size-webinar-sm: 14px;
        --font-size-webinar-md: 16px;
        --font-size-webinar-lg: 22px;
    }


    > footer {
        @include when-xs {
            margin-top: 0;
        }
    }

    p {
        font-size: var(--font-size-webinar-sm);
    }

    &.settings h1,
    h1,
    h2 {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        color: $color-light-grey;
        text-transform: none;
        margin: 0 0 10px;
        font-size: var(--font-size-webinar-xl);

        span {
            display: block;
        }
    }

    h2 {
        color: #64686B;
    }

    h3 {
        text-transform: uppercase;
    }

    > *:not(footer) hr {
        border-top: 2px solid $color-close-white;
        position: relative;
        overflow: visible;
        margin: 0;

        &:before {
            @include icon-live-video($color-close-white);

            height: 59.854px;
            width: 85.054px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            @include when-sm-down {
                height: 40.015px;
                width: 56.861px;
            }
        }

        &:after {
            content: '';
            background-color: $color-white;
            height: 2px;
            width: 76px;
            position: absolute;
            top: -2px;
            left: calc(50% + -2px);
            transform: translateX(-50%);
            z-index: 1;

            @include when-sm-down {
                width: 50px;
            }
        }
    }

    .pagination-holder {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;

        .pagination {
            padding: 0px;
            margin: 0px;
        }

        li {
            font-size: 14px;
        }
    }

    .webinar-content {
        font-size: var(--font-size-webinar-sm);
        margin-bottom: 5px;
        line-height: 1.2;
        color: #4d4d4d;
        width: 100%;

        @include when-sm {
            @include make-sm-column(6);
        }


        @include when-md {
            @include make-md-column(4);

            // font-size: 20px;
            margin-bottom: 10px;
        }


        // @include when-lg {
        //     @include make-lg-column(3);
        // }

        &:hover {
            .webinar-desc {
                background-color: $color-light-pink;
            }

            .btn-more {
                visibility: unset;
            }
        }

        .btn-more {
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            margin: 0;
            position: absolute;
            bottom: 0;
            font-size: 24px;
            height: 48px;
            line-height: 48px;
            display: block;
            color: $color-white;
            background-color: $color-nero;
            padding: 0 20px;
            width: calc(100% - 30px);
            text-align: center;
            visibility: hidden;

            @include when-sm-down {
                padding: 0 13px;
                font-size: 13px;
                height: 27px;
                line-height: 27px;
                width: calc(100% - 20px);
            }
        }

        .webinar-img {
            width: 100%;
            height: auto;
            background-color: $color-lighter-grey;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            visibility: hidden;
            position: absolute;
        }

        .webinar-desc {
            padding: 15px 10px 27px;
            position: relative;

            @include when-sm {
                height: calc(100% - 194.45px);
            }


            @include when-md {
                height: calc(100% - 192.58px);
                padding: 25px 15px 48px;
            }


            // @include when-lg {
            //     height: calc(100% - 186px);
            // }
    
        }

        .title {
            line-height: 1;
            font-size: inherit;
        }

        .title,
        .date {
            margin-bottom: 3px;
        }

        .title,
        .price {
            font-weight: $font-weight-bold;
        }
    }

    &.preorder {
        .section1 {
            @include webinar-container;

            background-color: #F7E1E3;
            padding-top: 50px;
            padding-bottom: 90px;

            h1,
            h2 {
                margin-bottom: 0;
            }

            .img {
                @include when-xs {
                    margin-bottom: 30px;

                    @include webinar-row-left;
                    @include webinar-row-right;
                }


                @include when-xs {
                    height: 127px;
                    background-position: center;
                    background-image: cdn("/images/home/theone-webinars.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }


                img {
                    @include when-xs {
                        display: none;
                    }


                    display: block;
                    margin: auto;
                    margin-bottom: 30px;
                }
            }
        }

        .section2 {
            @include when-md {
                display: flex;
            }


            .left-content {
                @include webinar-container;

                padding-bottom: 30px;
                position: relative;
                z-index: 1;

                @include when-xs {
                    padding-top: 90px;
                    padding-bottom: 20px;
                }


                @include when-sm {
                    padding-top: 60px;
                    padding-bottom: 30px;
                    width: 80%;
                }


                @include when-md {
                    flex-basis: 55%;
                    width: auto;
                }


                // @include when-lg {
                //     flex-basis: 60%;
                // }

                &:before {
                    content: '';
                    background-color: #F7FE4D;
                    position: absolute;
                    top: -30px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                }

                img {
                    position: absolute;

                    @include when-xs {
                        top: -20px;
                        right: 10px;
                    }


                    @include when-sm {
                        top: 0;
                        right: 20px;
                    }


                    @include when-md {
                        top: auto;
                        bottom: 30px;
                        right: 30px;
                    }


                    // @include when-lg {
                    //     top: 55px;
                    //     bottom: auto;
                    // }
            
                }

                table {
                    @include when-xs {
                        font-size: 18px;
                    }


                    @include when-sm {
                        font-size: 20px;
                    }


                    @include when-md {
                        font-size: 24px;
                    }


                    tr {
                        &:nth-child(2) {
                            td {
                                padding-bottom: 15px;
                            }
                        }

                        &:last-child {
                            border-top: 1px solid $color-nero;

                            td {
                                padding-top: 15px;
                                padding-bottom: 15px;

                                &:last-child {
                                    font-weight: $font-weight-bold;
                                }
                            }
                        }

                        td {
                            &:last-child {
                                padding-left: 20px;
                            }
                        }
                    }
                }
            }

            .right-content {
                @include webinar-container;

                padding-top: 60px;
                padding-bottom: 30px;

                @include when-md {
                    flex-basis: 45%;
                }


                // @include when-lg {
                //     flex-basis: 40%;
                // }

                p {
                    @include when-md {
                        margin-bottom: 0;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 35px;

                    li {
                        padding-left: 20px;
                        position: relative;

                        @include when-xs {
                            padding-left: 10px;
                        }


                        &:before {
                            content: '_';
                            left: 0;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                        }
                    }
                }
            }
        }

        .section3 {
            @include make-container;

            font-size: 20px;

            .section-inner {
                @include make-row;

                padding-top: 50px;
                padding-bottom: 40px;
                display: flex;

                @include when-sm-down {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                }
            }

            .left-section {
                @include when-xs {
                    @include make-xs-column(12);
                }


                @include when-sm {
                    @include make-sm-column(12);
                }


                @include when-md {
                    @include make-md-column(7);
                }


                // @include when-lg {
                //     @include make-lg-column(6);
                // }

                .signup-email-form {
                    background-color: $color-off-white;
                    padding: 40px 20px 20px;
                    margin-top: 20px;
                    border: 1px solid $color-close-white;
                    border-radius: 5px;
                    font-size: 14px;

                    @include when-xs {}


                    @include when-sm {
                        width: 80%;
                    }


                    @include when-md {
                        width: 95%;
                    }


                    // @include when-lg {
                    //     width: 100%;
                    // }

                    .btn-subscribe {
                        height: 36px;
                        width: 100%;
                        max-width: 265px;
                        font-size: 20px;
                        line-height: 1.3;

                        &:not(:hover) {
                            background-color: #444443;
                        }
                    }

                    table {
                        width: 100%;
                    }

                    tr {
                        td {
                            padding: 2.5px 8px;

                            &:first-child {
                                width: 40%;
                                text-align: right;
                                padding-right: 15px;
                                line-height: 36px;
                            }

                            &:last-child {
                                padding-left: 0;

                                input {
                                    width: 100%;
                                    max-width: 235px;
                                    height: 36px;
                                    padding: 0 13px;
                                }
                            }
                        }

                        &:nth-child(4) {
                            td {
                                padding-top: 17.5px;
                            }
                        }

                        &:last-child {
                            td {
                                padding-top: 20px;
                                padding-bottom: 20px;
                                padding-right: 0;
                                text-align: right;

                                @include when-xs {
                                    padding-top: 20px;
                                    padding-bottom: 0;
                                    padding-right: 0;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .right-section {
                display: none;
                margin: 40px auto;

                @include when-xs {
                    @include make-xs-column(12);
                }


                @include when-sm {
                    @include make-sm-column(8);
                }


                @include when-md {
                    @include make-md-column(4);

                    margin: 0 auto;
                }


                // @include when-lg {
                //     @include make-lg-column(5);
                // }
        
            }

            .left-section .title {
                @include make-row;

                padding-left: $webinar-left-padding;
                margin-bottom: 40px;
                font-size: 14px;

                @include when-sm {
                    font-size: 16px;
                }


                @include when-md {
                    font-size: 20px;
                }


                strong {
                    text-transform: capitalize;
                }
            }

            .right-section {
                .title {
                    @include when-md {
                        height: 2.8em;
                    }
                }

                .payment-choose {
                    padding-top: 0;
                    margin-top: 20px;

                    @include when-md {
                        margin-top: 40px;
                    }
                }
            }

            .payment-choice {
                @include make-row;

                position: relative;
                justify-content: space-between;

                @include when-sm {
                    display: flex;
                }


                > div {
                    flex: 1;
                }

                .payment-item {
                    font-size: 20px;
                    font-weight: $font-weight-bold;
                    color: $color-nero;
                    border: 1px solid #ccc;
                    padding-right: $webinar-right-padding;
                    padding-left: $webinar-left-padding;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-align: center;
                    text-transform: uppercase;

                    @include when-sm {
                        margin-right: 20px;
                        max-width: 300px;
                    }


                    @include when-md {
                        margin-bottom: 30px;
                        padding-top: 40px;
                        padding-bottom: 40px;
                    }


                    span.price {
                        display: block;
                        margin-top: 10px;
                    }

                    span {
                        text-transform: none;
                        font-size: 44px;
                        line-height: 1;
                        color: $color-darker-grey;
                    }
                }

                .payment-agreement, .payment-agreement label {
                    font-weight: $font-weight-normal;
                    font-family: proxima;
                    color: $color-nero;
                    font-size: 14px;

                    @include when-sm {
                        font-size: 16px;
                    }


                    @include when-md {
                        font-size: 20px;
                    }
                }

                .payment-agreement {
                    padding-top: 20px;

                    @include when-xs {
                        padding-right: $webinar-right-padding;
                        padding-left: $webinar-left-padding;
                    }
                }

                .payment-agreement label {
                    text-transform: none;
                    margin-top: 20px;

                    @include when-sm {
                        padding: 0;
                    }


                    @include when-md {
                        margin-top: 40px;
                    }
                }

                label.payment-monthly,
                label.payment-yearly {
                    margin-top: 40px;
                    text-align: center;
                    position: relative;
                    cursor: pointer;

                    &.selected {
                        box-shadow: inset 0 0 0 10px $color-bright-light-pink;

                        .checkout-button {
                            color: $color-white;
                            background-color: $color-nero;
                            opacity: 1 !important;
                        }
                    }

                    @include when-xs {}


                    @include when-sm {
                        width: calc(50% - 15px);
                        padding: 50px 20px 30px;
                        background-color: $color-off-white;
                    }


                    input {
                        opacity: 0;
                        position: absolute;
                    }

                    b,
                    .yearly-btn,
                    .monthly-btn {
                        font-size: 20px;
                        font-weight: $font-weight-bold;
                        text-transform: uppercase;
                        margin-bottom: 30px;
                        color: $color-nero;

                        span {
                            font-size: 44px;
                            line-height: 2;
                            display: block;
                            color: $color-darker-grey;
                        }
                    }

                    .yearly-btn,
                    .monthly-btn {
                        height: 40px;
                        line-height: 40px;
                        width: 181px;
                        border: 1px solid $color-nero;
                        margin: 40px auto 0;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                        }

                        &:hover {
                            color: $color-white;
                            background-color: $color-nero;

                            &:before {
                                border: 10px solid $color-bright-light-pink;
                            }
                        }
                    }
                }

                label.payment-yearly {
                    margin-left: auto;
                }
            }
        }

        .section4 {
            padding-top: 90px;
            padding-bottom: 100px;

            @include when-md {
                padding-bottom: 200px;
                display: flex;
                align-items: flex-end;
            }


            .left-content {
                @include webinar-container;

                @include when-md {
                    flex-basis: 55%;
                }


                // @include when-lg {
                //     flex-basis: 60%;
                // }
        
            }

            .right-content {
                @include webinar-container;

                @include when-md {
                    flex-basis: 45%;
                }


                // @include when-lg {
                //     flex-basis: 40%;
                // }

                img {
                    max-width: 100%;
                    height: auto;
                    margin-top: 30px;

                    // @include when-lg {
                    //     margin-bottom: -20px;
                    // }
            
                }
            }
        }
    }

    &.pricing,
    &.preorder {
        #benefits {
            background-color: #F7E1E3;
            padding-top: 30px;
            padding-bottom: 20px;

            @include when-md {
                padding-top: 50px;
                padding-bottom: 50px;
                display: flex;

                > div {
                    flex: 1;

                    &.main-benefit {
                        flex: 3;
                    }
                }
            }


            .main-benefit {
                @include webinar-container;

                @include when-md {
                    padding-right: 0;
                }


                img {
                    height: auto;
                    margin-bottom: 30px;
                    max-width: 100%;
                }

                h1 {
                    color: $color-light-grey;

                    strong {
                        color: $color-darker-grey;
                    }
                }
            }

            .other-benefits {
                @include webinar-container;

                @include when-sm {
                    margin-top: 30px;
                    width: 50%;
                    margin-left: auto;
                    padding-left: 0;
                }


                @include when-md {}


                img {
                    height: auto;
                    width: 100px;
                    margin-bottom: 15px;

                    @include when-md {
                        width: 150px;
                        margin-bottom: 20px;
                    }
                }

                ul.benefit-list {
                    list-style: none;
                    padding-left: 0;

                    li {
                        padding-left: 20px;
                        position: relative;
                        color: $color-darkest-grey;

                        &:before {
                            content: '_';
                            left: 0;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                        }
                    }
                }
            }
        }

        #choices {
            .choices-title {
                padding: 40px 30px;

                h2 {
                    text-transform: none;
                    font-size: 20px;
                    font-weight: $font-weight-normal;
                    font-family: proxima;
                    color: $color-nero;
                }

                p {
                    padding-bottom: 20px;
                }

                p a {
                    font-weight: $font-weight-bold;
                }
            }

            .choice-list {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                @include when-md {}


                > div {
                    flex: 1 1 100%;
                    margin-bottom: 30px;

                    @include when-sm {
                        flex: 1 1 50%;
                    }


                    @include when-md {
                        flex: 1;
                    }
                }

                /* hack for collapse */
                > div {
                    position: relative;

                    &:nth-child(1) {
                        left: 1.5px;
                    }

                    &:nth-child(2) {
                        left: 0.5px;
                    }

                    &:nth-child(3) {
                        right: 0.5px;
                    }

                    &:nth-child(4) {
                        right: 1.5px;
                    }
                }

                > div {
                    border: solid 1px $color-close-white;
                    border-collapse: collapse;

                    h3 {
                        font-size: 20px;
                        font-weight: $font-weight-bold !important;
                        text-align: center;
                        padding-top: 30px;
                        margin-bottom: 0px;
                    }

                    ul {
                        list-style: none;
                        margin: auto;
                        padding: 0px 0px 30px;

                        li {
                            text-align: center;
                            padding: 10px 20px;

                            .price {
                                color: $color-darker-grey;
                                line-height: 1em;
                                font-size: 44px;
                                font-weight: $font-weight-bold;
                                text-transform: uppercase;
                                padding-bottom: 50px;

                                @include when-sm {
                                    font-size: 36px;
                                }


                                // @include when-lg {
                                //     font-size: 44px;
                                // }

                                .duration {
                                    text-transform: none;
                                }
                            }

                            position: relative;

                            &.addition::before {
                                content: '+';
                                position: absolute;
                                top: 0;
                                line-height: 0;
                                left: calc(50%);
                                transform: translateX(-50%);
                            }
                        }

                        li:first-child {
                            padding-top: 0px;
                        }
                    }

                    .actions {
                        position: relative;
                        padding: 30px 10px;

                        p {
                            text-align: center;
                            padding: 7.5px;
                            color: $color-darkest-grey;

                            &.savings-title {
                                color: $color-dark-grey;
                                text-transform: uppercase;
                            }
                        }

                        &.signup {
                            border-top: solid 1px $color-close-white;
                        }

                        &:not(.signup)::before {
                            content: '';
                            position: absolute;
                            height: 1px;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            background: repeating-linear-gradient(90deg, $color-close-white, $color-close-white 10px, transparent 10px, transparent 20px);
                            background-position: center;
                        }

                        a {
                            display: block;
                            margin: 10px auto;
                            max-width: 280px;
                            text-transform: uppercase;
                            border: solid 1px $color-darkest-grey;
                            font-family: proxima;
                            font-size: 20px;
                            text-align: center;
                            color: $color-darkest-grey;
                            font-weight: $font-weight-bold;
                            padding: 7px 10px;
                            line-height: 1em;

                            &:hover {
                                background-color: $color-bright-light-pink;
                                color: $color-nero;
                            }
                        }

                        &.disabled {
                            opacity: 0.6;

                            a {
                                cursor: default;
                            }

                            a:hover {
                                background-color: inherit;
                                color: $color-darkest-grey;
                            }
                        }
                    }
                }
            }
        }

        hr {
            margin: 50px 0;

            @include when-md {
                margin: 100px 0;
            }
        }
    }

    .share-icons {
        padding-top: 10px;

        .fa {
            color: $color-light-grey;
            padding-right: 5px;
        }
    }

    .webinar-menu-container {
        position: fixed;
        top: var(--expert-nav-height);
        z-index: 6;

        .webinar-menu.webinar-back-menu {
            left: 0px;
        }

        .webinar-menu.webinar-right-menu {
            float: right;
            right: 0px;
            margin-left: 0px !important;

            @include when-xs {
                margin-right: calc((100vw - #{$container-xs}) / 2 + 10px);
            }


            @include when-sm {
                margin-right: calc((100vw - #{$container-sm}) / 2 + 10px);
            }


            @include when-md {
                margin-right: calc((100vw - #{$container-md}) / 2 + 10px);
            }


            @include when-lg {
                margin-right: calc((100vw - #{$container-lg}) / 2 + 10px);
            }


            &::before {
                right: 0 !important;
                left: -30px !important;
            }
        }

        @include when-xs {
            display: block;
            height: 70px;

            .mobile-menu {
                position: fixed;
                left: 0;
                width: 30px;
                height: 48px;
                top: 56px;
                z-index: 22;

                @include when-xs {
                    left: calc((100vw - #{$container-xs}) / 2 + 10px);
                }


                @media (max-width: 374px) {
                    left: 10px;
                }

                @include when-sm {
                    left: calc((100vw - #{$container-sm}) / 2 + 10px);
                }


                @include when-md {
                    left: calc((100vw - #{$container-md}) / 2 + 10px);
                }


                @include when-lg {
                    left: calc((100vw - #{$container-lg}) / 2 + 10px);
                }


                &:after {
                    @include icon-menu;

                    display: inline-block;
                    margin-top: 15px;
                }

                &.close {
                    opacity: 1;

                    &:after {
                        @include icon-close;
                    }
                }
            }
        }
    }

    .webinar-menu {
        list-style: none;
        position: fixed;
        padding-left: 0;
        width: fit-content;

        // height: 48px;
        z-index: 6;

        @include when-xs {
            margin-left: calc((100vw - #{$container-xs}) / 2 + 10px);
            padding-left: 30px;
            min-width: 180px;

            &.active {
                height: auto;
            }
        }


        @media (max-width: 374px) {
            padding-left: 60px;
        }

        @include when-sm {
            display: flex;
            margin-left: calc((100vw - #{$container-sm}) / 2 + 10px);
        }


        @include when-md {
            // height: 60px;
            margin-left: calc((100vw - #{$container-md}) / 2 + 10px);
        }


        @include when-lg {
            margin-left: calc((100vw - #{$container-lg}) / 2 + 10px);
        }


        &:before {
            content: '';
            background-color: #F4DEE0;
            position: absolute;
            right: -20px;
            bottom: 0;
            height: 100%;
            z-index: -1;

            @include when-xs {
                left: calc(-#{$webinar-left-padding-xs} - ((100vw - #{$container-xs}) / 2));
                right: -15px;
            }


            @include when-sm {
                right: -20px;
                left: calc(-#{$webinar-left-padding-sm} - ((100vw - #{$container-sm}) / 2));
            }


            @include when-md {
                left: calc(-#{$webinar-left-padding} - ((100vw - #{$container-md}) / 2));
            }


            @include when-lg {
                left: calc(-#{$webinar-left-padding} - ((100vw - #{$container-lg}) / 2));
            }
        }

        li {
            &:not(:last-child) {
                &:after {
                    @include when-sm {
                        content: '|';
                    }
                }
            }

            &:not(:first-child) {
                a,
                > [onClick] {
                    @include when-sm {
                        padding-left: 15px;
                    }


                    @include when-md {
                        padding-left: 20px;
                    }
                }
            }

            a,
            > [onClick] {
                cursor: pointer;
                color: inherit;
                font-size: var(--font-size-webinar-md);
                line-height: 3em;
                display: inline-block;
                white-space: nowrap;

                @include when-sm-down {}


                @include when-sm {
                    padding-right: 15px;
                }


                @include when-md {
                    padding-right: 20px;
                }


                &:hover {
                    color: #8199C3;
                }
            }
        }
    }

    &:not(.upcoming):not(.recorded) {
        .webinar-menu-container {
            height: calc(var(--font-size-webinar-md) * 4);
        }
    }

    $menu-items: about, faqs, tips, preorder, pricing, upcoming, recorded, details;

    @each $menu-item in $menu-items {
        &.#{$menu-item} .webinar-menu .#{$menu-item} a {
            font-weight: $font-weight-bold;
        }
    }

    @include when-xs {
        @each $menu-item in $menu-items {
            @if $menu-item == preorder {
                &.#{$menu-item} .webinar-menu:not(.active) li:not(.pricing) {
                    visibility: collapse;
                    height: 0;
                }
            }
            @else if $menu-item == details {
                &.#{$menu-item} .webinar-menu:not(.active) li:not(:first-child) {
                    visibility: collapse;
                    height: 0;
                }
            }
            @else {
                &.#{$menu-item} .webinar-menu:not(.active) li:not(.#{$menu-item}) {
                    visibility: collapse;
                    height: 0;
                }
            }
        }
    }


    &.preorder .webinar-menu .pricing a {
        font-weight: $font-weight-bold;
    }

    @include when-xs {
        .webinar-menu:not(.active) li {
            a {
                pointer-events: none;
                cursor: default;
            }
        }
    }


    &.upcoming,
    &.recorded {
        .section-header,
        .section-filter,
        .section-content {
            @include make-container;
        }

        .section-inner {
            @include when-sm {
                @include make-row;

                &:before,
                &:after {
                    display: none;
                }
            }
        }

        .section-header {
            .section-inner {
                @include when-sm {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .left-content {
                position: relative;

                @include when-xs {
                    min-height: 100px;
                }


                @include when-sm {
                    @include make-sm-column(9);

                    flex: 1;
                }


                @include when-md {
                    @include make-md-column(9);
                }


                // @include when-lg {
                //     @include make-lg-column(9);
                // }

                &:before {
                    content: '';
                    background-image: cdn("/images/webinar/webinar-audience.jpeg");
                    background-repeat: no-repeat;
                    background-size: 105%;
                    background-position: top right;
                    position: absolute;
                    top: 0;
                    bottom: -60px;
                    right: 0;
                    left: 0;
                    z-index: -1;

                    @include when-xs {
                        @include make-row;
                    }


                    @include when-sm {
                        bottom: 20px;
                        left: 10px;
                        background-size: 134%;
                        background-position: left 75% top 7%;
                    }


                    @include when-md {
                        background-size: 178%;
                        background-position: left 14% top 22%;
                    }


                    // @include when-lg {
                    //     background-size: 158%;
                    //     background-position: left 15% top 21%;
                    // }
            
                }

                p {
                    font-weight: $font-weight-bold;
                }
            }

            .right-content {
                position: relative;

                a:hover {
                    color: $color-primary;
                }

                @include when-xs {
                    display: flex;
                    flex-direction: column-reverse;
                }


                @include when-sm {
                    padding-top: 40px;

                    @include make-sm-column(3);
                }


                @include when-md {
                    padding-top: 75px;

                    @include make-md-column(3);
                }


                // @include when-lg {
                //     @include make-lg-column(3);
                // }

                &:before {
                    content: '';
                    background-color: $color-bright-light-pink;
                    width: 200%;
                    position: absolute;
                    top: 25px;
                    bottom: 10px;
                    right: 10px;
                    z-index: -2;

                    @include when-xs {
                        width: auto;
                        top: 0;
                        bottom: 0;
                        right: 60px;
                        left: -10px;
                    }


                    @include when-md {
                        top: 30px;
                    }
                }

                a {
                    margin-bottom: 20px;
                    font-weight: $font-weight-bold;
                    text-align: right;
                    padding-right: 10px;
                    font-size: 20px;
                    color: inherit;
                    display: block;

                    @include when-sm-down {
                        font-size: 14px;
                    }


                    @include when-xs {
                        text-align: left;
                        padding-right: 70px;
                    }
                }

                img {
                    @include when-xs {
                        width: 150px;
                        margin-top: -20px;
                        align-self: flex-end;
                        object-fit: cover;
                        height: 100px;
                        object-position: left;
                        margin-right: -10px;
                    }


                    @include when-sm {
                        height: auto;
                        margin-left: -125px;
                        width: 250px;
                    }


                    @include when-md {
                        margin-left: -150px;
                        width: 300px;
                    }


                    // @include when-lg {
                    //     margin-left: -193px;
                    //     width: 386px;
                    // }
            
                }
            }

            .menu-wrapper {
                display: flex;
                position: relative;

                @include when-xs {
                    @include make-row;
                }


                @include when-sm {
                    width: min-content;
                }


                .logo {
                    position: absolute;
                    top: calc(100% + 30px);
                    right: 20px;

                    @include when-sm-down {
                        width: 100px;
                        height: auto;
                    }


                    @include when-xs {
                        top: calc(100% + 10px);
                        left: 10px;
                    }
                }

                .menu {
                    padding: 20px 15px 30px;
                    border: 1px solid $color-white;
                    border-left: none;
                    border-top: none;

                    @include when-xs {
                        &:last-child {
                            border-right: none;
                        }
                    }


                    @include when-sm {
                        max-width: min-content;
                        padding: 20px 15px 20px;
                        min-width: 180px;
                    }


                    @include when-md {
                        padding: 30px 20px 20px;
                        min-width: 240px;
                    }


                    // @include when-lg {
                    //     padding: 40px 20px 30px;
                    //     min-width: 270px;
                    // }

                    h1,
                    h2 {
                        line-height: 1;
                    }

                    p {
                        margin-bottom: 0;
                    }

                    h1,
                    h2,
                    p {
                        color: $color-white;
                        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.4);
                    }

                    &:hover,
                    &.active {
                        color: $color-nero;
                        background-color: $color-white;

                        h1,
                        h2,
                        p {
                            text-shadow: none;
                            color: $color-nero;
                        }
                    }
                }
            }
        }

        .section-filter {
            margin-top: 60px;

            .filter {
                margin-bottom: 25px;

                @include when-sm {
                    @include make-sm-column(6);
                }


                @include when-md {
                    @include make-md-column(3);

                    margin-bottom: 50px;
                }


                .title {
                    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
                    font-size: 18px;
                    color: $color-grey;
                    margin-bottom: 5px;
                    line-height: .8;

                    @include when-md {
                        margin-bottom: 10px;
                        font-size: 22px;
                    }
                }

                select {
                    width: 100%;
                    max-width: 280px;
                    height: 36px;
                    -webkit-appearance: none;
                    line-height: 36px;
                    padding: 0 10px;
                    border: 1px solid $color-close-white !important;
                    background: $color-white;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-image: linear-gradient(45deg, transparent 50%, $color-close-white 50%), linear-gradient(135deg, $color-close-white 50%, transparent 50%);
                    background-position: calc(100% - 18px) calc(1em + 0px), calc(100% - 10px) calc(1em + 0px);
                    background-size: 8px 8px, 8px 8px, 1px 1.5em;
                    background-repeat: no-repeat;
                    margin-bottom: 0;

                    @include when-xs {
                        max-width: none;
                    }


                    &:-ms-expand {
                        display: none;
                    }
                }
            }
        }

        .image-container {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: $color-nero;
            width: 100%;
            height: 0;
            padding-top: 56.367%;
            position: relative;

            &.video {
                &::after {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    @include icon-play($color-nero);
                }
            }
        }

        .section-content {
            margin-bottom: 100px;

            .section-inner {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &.recorded .section-header .menu-wrapper .menu.recorded,
    &.upcoming .section-header .menu-wrapper .menu.upcoming {
        color: $color-nero;
        background-color: $color-white;

        h1,
        h2,
        p {
            color: $color-nero;
            text-shadow: none;
            color: $color-nero;
        }
    }
}

[data-to-root].affiliate-pricing, [data-to-root].webinar.about {
    #main-container img {
        height: auto;
    }

    %tag,
    .tag,
    .tag-bg,
    %tag-bg {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        margin: 0;
        color: $color-nero;
        width: fit-content;
        position: relative;
        letter-spacing: 0;
        white-space: nowrap;
        font-size: 24px;
        height: 48px;
        line-height: 48px;
        display: block;

        @include when-sm-down {
            font-size: 13px;
            height: 27px;
            line-height: 27px;
        }
    }

    .tag-bg,
    %tag-bg {
        color: $color-white;
        background-color: $color-nero;
        padding: 0 20px;

        @include when-sm-down {
            padding: 0 13px;
        }
    }

    hr:not(.to-separator) {
        border-top: 2px solid $color-close-white;
        position: relative;
        overflow: visible;

        @include when-sm-down {
            margin: 70px 0 60px;
        }


        @include when-md {
            margin: 115px 0 90px;
        }


        &:before {
            @include icon-live-video($color-close-white);

            height: 59.854px;
            width: 85.054px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            @include when-sm-down {
                height: 40.015px;
                width: 56.861px;
            }
        }

        &:after {
            content: '';
            background-color: $color-white;
            height: 2px;
            width: 76px;
            position: absolute;
            top: -2px;
            left: calc(50% + -2px);
            transform: translateX(-50%);
            z-index: 1;

            @include when-sm-down {
                width: 50px;
            }
        }
    }

    h1,
    h2 {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        color: $color-nero;
        text-transform: none;
        margin: 0 0 10px;

        @include when-xs {
            font-size: 26px;
        }


        @include when-sm {
            font-size: 28px;
        }


        @include when-md {
            font-size: 36px;
        }


        // @include when-lg {
        //     font-size: 44px;
        // }

        span {
            display: block;
        }
    }

    h3 {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-light !important;
        margin: 0;
        line-height: 1;

        @include when-xs {
            font-size: 24px;
        }


        @include when-sm {
            font-size: 32px;
        }


        @include when-md {
            font-size: 40px;
        }


        // @include when-lg {
        //     font-size: 49.7px;
        // }

    }

    .section-banner,
    .section-po {
        @include webinar-container;

        @include make-container;

        margin-top: 110px;
        margin-bottom: 110px;

        @include when-sm-down {
            margin-top: 80px;
            margin-bottom: 80px;
        }


        .section-inner {
            @include when-sm {
                max-width: 500px;
            }


            @include when-md {
                max-width: 850px;
            }


            // @include when-lg {
            //     max-width: 1070px;
            // }
    
        }

        .top-content {
            position: relative;
            padding: 30px 0 20px;
            margin-bottom: 20px;

            @include when-xs {
                width: calc(100% - 55px);
            }


            &:before {
                content: '';
                background-color: #F8FF4D;
                right: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                z-index: -1;

                @include when-xs {
                    width: calc((100vw - #{$container-xs}) / 2 + 20px + #{$container-xs});
                }


                @include when-sm {
                    width: calc((100vw - #{$container-sm}) / 2 + 25px + #{$container-sm});
                }


                @include when-md {
                    width: calc((100vw - #{$container-md}) / 2 + 30px + #{$container-md});
                }


                @include when-lg {
                    width: calc((100vw - #{$container-lg}) / 2 + 30px + #{$container-lg});
                }
            }

            h2 {
                text-transform: uppercase;
            }

            p {
                padding-right: $webinar-right-padding;
                max-width: 250px;

                @include when-sm {
                    max-width: calc(100% - 200px);
                }


                @include when-md {
                    max-width: calc(100% - 250px);
                }


                // @include when-lg {
                //     max-width: calc(100% - 300px);
                // }

                strong {
                    text-transform: uppercase;

                    @include when-sm-down {
                        display: block;
                    }
                }
            }

            .btn {
                @extend %tag-bg;

                position: absolute;
                right: 0;
                bottom: 20px;
                text-align: left;

                &:hover {
                    color: $color-nero;
                    background-color: $color-white;
                    border: none;

                    @include when-sm-down {
                        box-shadow: 0px 0px 0px 1px $color-nero;
                    }
                }

                @include when-xs {
                    right: auto;
                    left: calc(100% - 25px);
                }


                @include when-sm {
                    width: 200px;
                }


                @include when-md {
                    width: 250px;
                }


                // @include when-lg {
                //     width: 300px;
                // }
        
            }
        }

        .bottom-content {
            p {
                @include when-sm {
                    padding-right: $webinar-right-padding;
                }
            }
        }
    }

    .section-banner {
        .section-inner {
            position: relative;
        }

        .top-content {
            &:before {
                content: '';
                background-color: #E7E6EC;
            }

            h2 {
                color: #8698C0;

                @include when-xs {
                    padding-right: 30px;

                    br {
                        display: none;
                    }
                }
            }

            p {
                strong {
                    text-transform: none;
                    display: block;
                }
            }
        }

        .balloon-wrapper {
            width: fit-content;
            position: absolute;
            top: -25px;
            right: -25px;

            @include when-xs {
                top: -50px;
                right: 0;
            }


            @include when-md {
                top: -50px;
                right: -30px;
            }


            // @include when-lg {
            //     top: -65px;
            //     right: -45px;
            // }

            .balloon {
                width: auto;
                position: relative;

                &:before {
                    @include bg-balloon-text(#FAFF6E);

                    width: 80px;
                    height: 81px;
                    content: '';
                    transform: rotate(-90deg);
                    display: block;

                    @include when-md {
                        width: 110px;
                        height: 111px;
                    }


                    // @include when-lg {
                    //     width: 144px;
                    //     height: 145px;
                    // }
            
                }

                .balloon-content {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 85%;

                    p {
                        font-family: "proxima", "Helvetica", "Arial", sans-serif;
                        font-weight: $font-weight-bold;
                        line-height: 1;
                        text-align: center;
                        margin: 0;

                        @include when-md {
                            font-size: 20px;
                        }


                        // @include when-lg {
                        //     font-size: 24px;
                        // }
                
                    }
                }
            }
        }
    }

    .section1 {
        @include webinar-container;

        @include make-container;

        .top-content {
            position: relative;

            @include when-xs {
                min-height: 420px;
            }


            @include when-sm {
                min-height: 292px;
            }


            @include when-md {
                min-height: 434px;
            }


            // @include when-lg {
            //     min-height: 560px;
            // }

            img {
                @include webinar-row-left;

                @include container-width;

                z-index: -1;
                position: absolute;
                top: 0;
                height: auto;

                @include when-xs {
                    width: 100vw;
                }
            }

            h1,
            p {
                width: 50%;
                display: block;

                @include when-xs {
                    width: 250px;
                }
            }

            h1 {
                color: #64686B;

                @include when-xs {
                    padding-top: 40px;
                }


                @include when-sm {
                    padding-top: 50px;
                }


                @include when-md {
                    padding-top: 100px;
                }


                // @include when-lg {
                //     padding-top: 150px;
                // }

                span {
                    color: #888D91;
                }
            }

            p {
                font-weight: $font-weight-bold;
                color: #4D4D4D;
            }
        }

        .bottom-content {
            position: relative;

            @include when-sm-down {
                margin-top: 60px;
            }


            @include when-md {
                margin-top: 80px;
                min-height: 230px;
            }


            // @include when-lg {
            //     min-height: 296px;
            // }

            img {
                @include webinar-row-left;

                height: auto;

                @include when-xs {
                    @include container-width;

                    margin-top: 60px;
                    width: 100vw;
                }


                @include when-sm {
                    position: absolute;
                    width: 440px;
                    top: 0;
                    left: 0;
                }


                @include when-md {
                    width: 680px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }

            h2,
            p {
                margin-left: auto;
                text-align: right;

                @include when-sm {
                    max-width: 250px;
                }


                @include when-md {
                    max-width: 350px;
                }


                // @include when-lg {
                //     max-width: 430px;
                // }
        
            }

            h2 {
                color: #8199C3;

                span {
                    color: #A7B7D3;
                }
            }
        }
    }

    .section2 {
        @include webinar-container;

        @include make-container;

        .section-inner {}

        .top-content {
            text-align: center;

            h3 {
                margin-left: auto;
                position: relative;
                margin-bottom: 0;
                color: $color-light-grey;

                @include when-xs {
                    max-width: calc((80% - 180px) / 2 + 180px);
                    padding: 30px 30px 30px 0;
                }


                @include when-sm {
                    max-width: calc((100% - 430px) / 2 + 430px);
                    padding: 60px calc((100% - 430px) / 2) 60px 0;
                }


                @include when-md {
                    max-width: calc((100% - 560px) / 2 + 560px);
                    padding: 100px calc((100% - 560px) / 2) 100px 0;
                }


                &:after {
                    @include webinar-row-right;

                    content: '';
                    background-color: #F6E1E3;
                    bottom: 0;
                    right: 0;
                    position: absolute;
                    z-index: -1;
                    opacity: .3;

                    @include when-sm-down {
                        left: -30px;
                        top: -30px;
                    }


                    @include when-md {
                        left: -120px;
                        top: -40px;
                    }
                }
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .bottom-content {
            position: relative;

            @include when-xs {
                padding: 60px 0 15px;
            }


            @include when-sm {
                padding: 50px 0 10px;
            }


            @include when-md {
                padding: 80px 0;
            }


            // @include when-lg {
            //     padding: 120px 0 100px;
            // }

            &:after {
                @include webinar-row-right;

                content: '';
                background-color: #F6E1E3;
                bottom: 0;
                top: 0;
                right: 0;
                position: absolute;
                z-index: -1;

                @include when-xs {
                    width: calc((100vw - #{$container-xs}) / 2 + 25px + #{$container-xs});
                }


                @include when-sm {
                    width: calc((100vw - #{$container-sm}) / 2 + 25px + #{$container-sm});
                }


                @include when-md {
                    width: calc((100vw - #{$container-md}) / 2 + 30px + #{$container-md});
                }


                @include when-lg {
                    width: calc((100vw - #{$container-lg}) / 2 + 30px + #{$container-lg});
                }
            }

            h2 {
                margin-bottom: 30px;
                color: #C99FA1;

                @include when-md {
                    max-width: 680px;
                }


                // @include when-lg {
                //     max-width: 825px;
                // }

                span {
                    color: #DBC0C3;
                }
            }

            p {
                @include when-md {
                    max-width: 600px;
                }


                // @include when-lg {
                //     max-width: 770px;
                // }
        
            }

            img {
                height: auto;

                @include when-sm-down {
                    max-width: 350px;
                    margin-left: auto;
                    display: block;
                    margin-top: 20px;
                }


                @include when-xs {
                    max-width: 240px;
                    margin-top: 40px;
                }


                @include when-md {
                    @include webinar-row-right;

                    max-width: 400px;
                    top: auto;
                    bottom: 30px;
                    position: absolute;
                    right: 0;
                }


                // @include when-lg {
                //     max-width: none;
                //     top: 100px;
                // }
        
            }

            .tag-bg {
                @include webinar-row-right;

                position: absolute;
                right: 0;
                bottom: 0;
                width: 110px;

                @include when-md {
                    width: 300px;
                }


                // @include when-lg {
                //     width: 372px;
                // }
        
            }
        }
    }

    .section3 {
        @include webinar-container;

        @include make-container;

        margin-top: 190px;

        @include when-sm-down {
            margin-top: 80px;
        }


        .section-inner {}

        .top-content {
            margin-bottom: 150px;
            position: relative;

            @include when-sm-down {
                margin-bottom: 120px;
            }


            img {
                height: auto;

                @include when-xs {
                    width: 100%;
                    height: auto;
                    margin-top: 20px;
                }


                @include when-sm {
                    position: absolute;
                    left: 0;
                    width: 415px;
                    top: 0;
                }


                @include when-md {
                    top: -30px;
                    width: 680px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }

            h2,
            .tag {
                margin-left: auto;
                text-align: right;

                @include when-sm {
                    max-width: 250px;
                }


                @include when-md {
                    max-width: 350px;
                }
            }

            h2 {
                color: #8199C3;

                span {
                    color: #A7B7D3;
                }
            }
        }

        .bottom-content {
            @include webinar-row-left;

            @include webinar-row-right;

            h3 {
                line-height: .9;
                color: $color-light-pink;
                font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;

                @include when-xs {
                    padding: 40px 0 30px;
                    font-size: 62px;
                }


                @include when-sm {
                    padding: 60px 0 20px;
                    max-width: 300px;
                    font-size: 89px;
                }


                @include when-md {
                    max-width: 380px;
                    font-size: 120px;
                }


                // @include when-lg {
                //     max-width: 430px;
                // }

                span {
                    color: $color-light-grey;
                    font-weight: $font-weight-bold;
                    display: block;
                    text-transform: none;
                    font-family: "proxima", "Helvetica", "Arial", sans-serif;

                    @include when-sm-down {
                        font-size: 14px;
                    }


                    @include when-md {
                        font-size: 20px;
                    }
                }
            }

            .content1,
            .content2,
            .content3 {
                @include webinar-container;

                border-bottom: 2px solid $color-close-white;

                @include when-sm-down {
                    border-bottom: 1px solid $color-close-white;
                }


                &:before {
                    content: '';
                    background-color: $color-white;
                    width: 38px;
                    height: 2px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                    top: 100%;
                    margin-left: -2px;

                    @include when-sm-down {
                        height: 1px;
                        width: 20px;
                        left: calc(50% + 1px);
                    }
                }

                &:after {
                    @include icon-right-arrow($color-close-white);

                    position: absolute;
                    transform: rotate(90deg) translateY(-50%);
                    left: 50%;
                    bottom: -16px;
                    width: 24px;

                    @include when-sm-down {
                        left: calc(50% - 4px);
                        bottom: -10px;
                        width: 12px;
                    }
                }
            }

            .content1 {
                border-top: 2px solid $color-close-white;
                position: relative;

                @include when-sm-down {
                    border-top: 1px solid $color-close-white;
                }


                .tag-bg {
                    position: absolute;
                    bottom: 100%;
                    left: $webinar-left-padding;
                }

                .desc {
                    position: absolute;
                    z-index: 2;

                    @include when-sm-down {
                        top: 68px;
                        left: 300px;
                    }


                    @include when-xs {
                        display: none;
                    }


                    @include when-md {
                        top: 88px;
                        left: 400px;
                    }


                    // @include when-lg {
                    //     left: 470px;
                    // }

                    .title {
                        font-size: 33.5px;
                        font-weight: bold;
                        color: $color-soft-blue;
                        position: relative;

                        @include when-sm-down {
                            font-size: 22px;
                        }


                        &:after {
                            content: '';
                            position: absolute;
                            width: 382px;
                            height: 70px;
                            top: 15px;
                            left: 20px;
                            background-color: $color-bright-blue;
                            z-index: -1;

                            @include when-sm-down {
                                width: 315px;
                                height: 60px;
                                top: 10px;
                            }
                        }
                    }

                    p:not(.title) {
                        width: 338px;
                        height: 65px;
                        background: white;
                        border: 1px solid $color-light-grey;
                        padding: 12px 20px;
                        font-weight: bold;
                        line-height: 1;
                        color: $color-light-grey;
                        margin-left: 55px;

                        @include when-sm-down {
                            width: 215px;
                            height: 52px;
                        }
                    }
                }
            }

            .content2 {
                position: relative;

                .desc {
                    background-color: $color-bright-pink;
                    position: absolute;

                    @include when-sm-down {
                        right: 100px;
                        bottom: 10px;
                    }


                    @include when-xs {
                        right: 0;
                        bottom: calc(100% - 20px);
                    }


                    @include when-md {
                        right: 20px;
                        height: 60px;
                        width: 400px;
                        bottom: 20px;
                    }


                    // @include when-lg {
                    //     height: 81px;
                    //     width: 544px;
                    // }

                    p {
                        font-weight: $font-weight-bold;
                        font-size: 30px;
                        color: $color-light-grey;
                        margin-bottom: 0;
                        position: relative;
                        text-align: center;

                        @include when-sm-down {
                            font-size: 22px;
                            line-height: 40px;
                            width: 200px;
                        }


                        @include when-xs {
                            font-size: 0;
                            line-height: 0;
                            width: 160px;
                        }


                        @include when-md {
                            line-height: 60px;
                        }


                        // @include when-lg {
                        //     line-height: 81px;
                        // }

                        &:before {
                            content: '';
                            background-image: cdn("/images/webinar/more-awesome-now.jpg");
                            background-size: contain;
                            position: absolute;
                            bottom: 100%;
                            width: 100%;
                            height: 0;
                            left: 0;
                            padding-top: 56.25%;
                        }
                    }
                }
            }

            .content3 {
                position: relative;

                .desc {
                    @include when-sm {
                        display: flex;
                        position: absolute;
                        top: 88px;
                        left: 400px;
                    }


                    @include when-md {
                        top: 116px;
                        left: 420px;
                    }


                    // @include when-lg {
                    //     left: 473px;
                    // }

                    p:not(.socmed) {
                        font-size: 30px;
                        font-weight: $font-weight-bold;
                        color: $color-light-grey;
                        line-height: 70px;
                        background-color: #DECCCF;
                        width: 363px;
                        text-align: center;
                        margin-bottom: 0;
                        display: none;

                        // @include when-lg {
                        //     display: block;
                        // }
                
                    }

                    p.socmed {
                        font-size: 33.5px;
                        color: $color-soft-blue;
                        font-weight: $font-weight-bold;
                        margin-bottom: 0;
                        display: block;

                        @include when-sm-down {
                            font-size: 22px;
                            width: 320px;
                        }


                        @include when-xs {
                            width: auto;
                            height: auto;
                            text-align: right;
                            margin-bottom: 20px;
                            margin-top: -15px;
                        }


                        @include when-sm {
                            height: 70px;
                            background-color: $color-bright-blue;
                        }


                        @include when-md {
                            width: 384px;
                        }


                        span {
                            display: inline-block;
                            margin: 0 12px;

                            &.text {
                                display: block;
                                white-space: nowrap;
                                line-height: 2;
                                margin-top: -35px;
                                margin-left: 17px;

                                @include when-sm-down {
                                    margin-top: -23px;
                                }


                                @include when-xs {
                                    display: none;
                                }
                            }

                            &.icon-email {
                                @include when-xs {
                                    width: 17.497px;
                                    height: 11.675px;
                                }


                                @include when-sm {
                                    margin-left: 100px;
                                }


                                @include when-md {
                                    margin-left: 150px;
                                }
                            }

                            &.icon-facebook {
                                @include when-xs {
                                    width: 6.404px;
                                    height: 13.781px;
                                }
                            }

                            &.icon-linkedin {
                                @include when-xs {
                                    width: 11.69px;
                                    height: 11.67px;
                                }
                            }

                            &.icon-twitter {
                                @include when-xs {
                                    width: 13.285px;
                                    height: 10.796px;
                                }
                            }
                        }
                    }
                }
            }

            > p {
                font-size: 33.5px;
                color: $color-soft-blue;
                font-weight: $font-weight-bold;
                margin: 50px 0;
                display: block;
                text-align: center;

                @include when-sm-down {
                    margin: 30px 0;
                    font-size: 22px;
                }


                @include when-xs {
                    font-size: 20px;
                }
            }
        }
    }

    .section4 {
        @include webinar-container;

        @include make-container;

        position: relative;

        @include when-sm-down {
            margin-top: 60px;
        }


        @include when-md {
            margin-top: 100px;
        }


        // @include when-lg {
        //     margin-top: 130px;
        // }

        .section-inner {}

        .left-content {
            position: relative;

            @include when-sm-down {
                padding-bottom: 12px;
                padding-top: 75px;
            }


            @include when-md {
                padding-bottom: 45px;
                padding-top: 120px;
                width: 700px;
            }


            // @include when-lg {
            //     width: 865px;
            // }

            &:before {
                @include webinar-row-left;

                content: '';
                background-color: $color-teal;
                opacity: .33;
                position: absolute;
                height: 100%;
                width: calc(100% + #{$webinar-left-padding});
                z-index: -1;
                top: 0;

                @include when-sm-down {
                    width: calc(100% + #{$webinar-left-padding-sm} + #{$webinar-right-padding-sm});
                }


                @include when-xs {
                    right: 0;
                    width: calc((100vw - #{$container-xs}) / 2 + 20px + #{$container-xs});
                }


                @include when-md {
                    @include webinar-row-right;
                }
            }

            .tag-bg {
                @include webinar-row-left;

                top: 0;
                position: absolute;
            }

            h2 {
                color: #66C7C2;
            }

            h2 + p {
                margin-bottom: 30px;
            }

            p {
                width: 612px;

                @include when-xs {
                    width: 100%;
                    padding-right: $webinar-right-padding-xs;
                }
            }

            .icon-people {
                @include when-sm-down {
                    width: 42px;
                    height: 29px;
                    display: block;
                    margin-left: auto;
                    margin-top: 35px;
                }


                @include when-xs {
                    margin-right: 10px;
                }


                @include when-md {
                    top: 25px;
                    right: 15px;
                    position: absolute;
                }
            }
        }

        .right-content {
            position: relative;

            @include when-xs {
                width: 100%;
                height: 75px;
            }


            @include when-md {
                position: absolute;
                top: 0;
                right: 0;
                width: 300px;
            }


            // @include when-lg {
            //     width: 400px;
            // }

            .title {
                font-size: 28px;
                color: #3C3C3B;
                font-weight: $font-weight-bold;
                margin-bottom: 30px;
                opacity: .66;

                @include when-sm-down {
                    font-size: 14px;
                    position: absolute;
                    top: -30px;
                    right: 60px;
                }


                @include when-xs {
                    right: 70px;
                }
            }

            .user-wrapper {
                @include when-xs {
                    width: 100vw;
                    position: relative;
                    overflow: hidden;
                    height: 62px;
                    margin-left: calc(-1 * (100vw - #{$container-xs}) / 2 - 25px);
                    margin-right: calc(-1 * (100vw - #{$container-xs}) / 2 - 25px);
                }


                @media (max-width: 374px) {
                    margin-left: -20px;
                    margin-right: -20px;
                }
            }

            span {
                @include when-sm-down {
                    display: flex;
                    width: 430px;
                    margin-left: auto;
                }


                @include when-xs {
                    width: auto;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .user {
                @include when-sm-down {
                    margin: 20px 10px 0;
                }


                @include when-xs {
                    margin: 10px 7.5px 0;
                }


                @include when-md {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                }


                img {
                    flex: 0;
                }

                .name {
                    font-size: 23.6px;
                    line-height: 52px;
                    margin-left: 25px;
                    flex: 1;

                    @include when-sm-down {
                        display: none;
                    }
                }
            }
        }
    }

    .section5 {
        @include webinar-container;

        @include make-container;

        margin-top: 107px;

        @include when-sm-down {
            margin-top: 80px;
        }


        .section-inner {}

        .top-content {
            padding-top: 35.556%;
            position: relative;

            @include when-xs {
                padding-top: 0;
                height: 220px;
            }


            &:before {
                @include webinar-row-left;

                content: '';
                background-image: cdn("/images/webinar/theone-webinar-keep-sharing-keep-learning.jpg");
                background-size: cover;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                right: calc(0px - (100vw - 100%) / 2 + 5px);
                z-index: -1;

                @include when-sm-down {
                    right: calc(0px - (100vw - 100%) / 2);
                }


                @include when-xs {
                    background-position: -120px;
                }
            }

            h3 {
                font-weight: $font-weight-light;
                color: $color-white;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                text-align: center;
                position: absolute;
                top: 0;

                @include when-sm-down {
                    padding-top: 70px;
                    width: 280px;
                    right: 30px;
                }


                @include when-xs {
                    padding-top: 40px;
                    width: 200px;
                    right: 0;
                }


                @include when-md {
                    right: 0;
                    width: 400px;
                    padding-top: 108px;
                    margin-right: 100px;
                }


                // @include when-lg {
                //     margin-right: 180px;
                // }
        
            }

            img {
                position: absolute;
                bottom: 25px;

                @include when-sm-down {
                    width: 95px;
                    height: auto;
                    right: 20px;
                }


                @include when-xs {
                    right: 0;
                }


                @include when-md {
                    right: 120px;
                }


                // @include when-lg {
                //     right: 215px;
                // }
        
            }
        }

        .bottom-content {
            display: flex;
            position: relative;
            padding-bottom: 80px;

            @include when-sm-down {
                padding-bottom: 40px;
            }


            @include when-xs {
                display: block;
            }


            &:before {
                @include webinar-row-left;

                content: '';
                position: absolute;
                background-color: #F1F0F7;
                left: 0;
                top: 0;
                height: 100%;
                right: calc(0px - (100vw - 100%) / 2 + 5px);
                z-index: -1;

                @include when-sm-down {
                    right: calc(0px - (100vw - 100%) / 2);
                }
            }

            .left-content {
                position: relative;

                @include when-sm {
                    width: 320px;
                }


                @include when-md {
                    width: 450px;
                }


                // @include when-lg {
                //     width: 530px;
                // }

                &:before {
                    @include webinar-row-left;

                    content: '';
                    position: absolute;
                    background-color: #F1F0F7;
                    left: 0;
                    bottom: 100%;
                    height: 42px;
                    right: 0;
                    z-index: -1;

                    @include when-xs {
                        height: 30px;
                        width: calc(100% - 120px);
                    }
                }

                > img {
                    float: left;
                    height: auto;

                    @include when-xs {
                        margin-right: 10px;
                        width: 73px;
                        margin-top: -10px;
                    }


                    @include when-sm {
                        margin-right: 20px;
                        width: 120px;
                    }


                    @include when-md {
                        margin-right: 30px;
                        width: auto;
                    }
                }

                .name,
                .profession {
                    @include when-sm {
                        font-size: 18px;
                    }


                    @include when-md {
                        font-size: 23.6px;
                    }
                }

                .name {
                    font-weight: $font-weight-bold;

                    @include when-sm {
                        margin-top: 30px;
                    }


                    @include when-md {
                        margin-top: 60px;
                    }
                }

                .rating img {
                    margin-left: 0;

                    @include when-sm-down {
                        height: 16px;
                        width: auto;
                    }


                    @include when-xs {
                        height: 10px;
                    }
                }
            }

            .right-content {
                flex: 1;

                h2 {
                    margin: 60px 0 10px;
                    color: #786B96;

                    @include when-sm-down {
                        margin: 40px 0 10px;
                    }
                }

                h2 + p {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .section6 {
        @include webinar-container;

        @include make-container;

        margin-top: 170px;

        @include when-xs {
            margin-top: 80px;
        }


        .section-inner {
            > img {
                width: 100%;
            }
        }

        .top-content {
            @include webinar-row-left;

            @include webinar-row-right;

            display: flex;
            align-items: center;
            justify-content: space-between;

            img.left {
                height: auto;
                margin: auto;

                @include when-xs {
                    width: 235px;
                }


                @include when-sm {
                    width: 250px;
                }


                @include when-md {
                    width: 350px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }

            img.right {
                height: auto;

                @include when-sm {
                    width: 440px;
                }


                @include when-md {
                    width: 680px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }
        }

        .bottom-content {
            padding: 45px 0 90px;
            position: relative;

            @include when-sm-down {
                padding: 70px 0 60px;
            }


            @include when-xs {
                padding: 40px 0 60px;
            }


            &:before {
                content: '';
                background-color: $color-teal;
                opacity: .33;
                left: calc(-1 * (100vw - 100%) / 2 - 5px);
                width: 100vw;
                top: -50px;
                bottom: 0;
                position: absolute;
                z-index: -1;

                @include when-sm-down {
                    left: calc(-1 * (100vw - 100%) / 2);
                }


                @include when-xs {
                    bottom: -90px;
                }
            }

            h2 {
                margin-bottom: 10px;
                color: #56A8A4;

                span {
                    color: #66C7C2;
                }
            }

            h2 + p {
                margin-bottom: 30px;
            }

            p {
                max-width: 85%;
            }

            .tag-bg {
                @include webinar-row-right;

                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        h3 {
            margin: 150px auto 70px;
            color: $color-nero;
            width: 470px;
            text-align: center;

            @include when-sm-down {
                width: 300px;
                margin: 70px auto 20px;
            }
        }
    }

    .section7 {
        @include webinar-container;

        @include make-container;

        margin-top: 40px;

        @include when-sm-down {
            margin-top: 100px;
        }


        .section-inner {}

        .top-content {
            position: relative;

            .left-content {
                @include when-sm-down {
                    width: calc(100% - 350px);
                    margin-left: auto;
                }


                @include when-xs {
                    width: 100%;
                }


                @include when-md {
                    margin-bottom: 20px;
                }


                // @include when-lg {
                //     margin-bottom: 50px;
                // }

                img {
                    z-index: 1;
                    height: auto;
                    margin-left: 0;

                    @include when-xs {
                        width: 100%;
                    }


                    @include when-sm {
                        position: absolute;
                        left: 0;
                        width: 320px;
                        top: 10px;
                    }


                    @include when-md {
                        top: auto;
                        left: auto;
                        position: relative;
                        width: 450px;
                    }


                    // @include when-lg {
                    //     width: auto;
                    // }
            
                }

                h2,
                p {
                    @include when-md {
                        width: 370px;
                    }


                    // @include when-lg {
                    //     width: 450px;
                    // }
            
                }

                h2 {
                    color: #9E6146;

                    @include when-xs {
                        margin: 50px 0 20px;
                    }


                    @include when-md {
                        margin: 20px 0 10px;
                    }
                }

                h2 + p {
                    margin-bottom: 30px;

                    @include when-xs {
                        margin-bottom: 10px;
                    }
                }
            }

            .right-content {
                img {
                    @include when-sm-down {
                        width: 460px;
                        height: auto;
                        margin: 30px 0 -50px auto;
                        display: block;
                    }


                    @include when-xs {
                        @include webinar-row-left;

                        margin: 40px 0 0;
                        width: $container-xs;
                    }


                    @media (max-width: 374px) {
                        max-width: 100vw;
                    }

                    @include when-md {
                        position: absolute;
                        right: 0;
                        top: 25px;
                        width: 640px;
                        height: auto;
                    }


                    // @include when-lg {
                    //     width: auto;
                    // }
            
                }
            }
        }

        .bottom-content {
            position: relative;
            padding: 90px 0 85px;

            @include when-sm-down {
                padding: 70px 0;
            }


            @include when-xs {
                padding: 40px 0;
            }


            &:before {
                @include webinar-row-left;

                content: '';
                position: absolute;
                background-color: $color-teal;
                opacity: .33;
                left: 0;
                top: 0;
                height: 100%;
                right: calc(0px - (100vw - 100%) / 2 + 5px);
                z-index: -1;

                @include when-sm-down {
                    right: calc(0px - (100vw - 100%) / 2);
                }
            }

            h2 {
                margin-bottom: 40px;
                color: #56A8A4;

                span {
                    color: #66C7C2;
                }
            }

            p {
                @include when-sm {
                    width: 85%;
                }
            }
        }
    }

    .section8 {
        @include webinar-container;

        @include make-container;

        .section-inner {}

        .top-content {
            > p {
                width: 760px;
                margin: 80px auto;
                text-align: center;

                @include when-sm-down {
                    margin: 60px auto;
                    width: 85%;
                }
            }

            h3 {
                text-align: center;
                margin: 80px auto 20px;
                width: 450px;
                font-weight: $font-weight-light;
                color: $color-light-grey;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;

                @include when-sm-down {
                    margin: 90px auto 0;
                    width: 340px;
                }


                @include when-xs {
                    width: 250px;
                }
            }
        }

        .bottom-content {
            @include when-sm {
                display: flex;
                justify-content: space-evenly;
            }


            .left-content,
            .right-content {
                text-align: center;

                @include when-xs {
                    width: 230px;
                    margin: auto;
                }


                @include when-sm {
                    width: 400px;
                }


                .top {
                    margin: 60px auto;
                    font-size: 16.23px;
                    text-align: left;
                    position: relative;
                    height: 110px;
                    padding-right: 90px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    @include when-sm-down {
                        margin: 60px auto 40px;
                        font-size: 12px;
                        height: auto;
                    }


                    @include when-xs {
                        margin: 60px auto 25px;
                    }


                    @include when-sm {
                        width: 275px;
                    }


                    &:after {
                        content: '';
                        height: 1px;
                        background-color: $color-close-white;
                        opacity: .33;
                        position: absolute;
                        bottom: 0;
                        left: 0;

                        @include when-xs {
                            bottom: -5px;
                            width: calc(100% - 25px);
                        }


                        @include when-sm {
                            width: calc(100% - 25px);
                        }


                        @include when-md {
                            width: calc(100% - 45px);
                        }
                    }

                    img {
                        display: block;

                        &:not(.icon) {
                            @include when-xs {
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .icon {
                        position: absolute;
                        right: 0;
                        bottom: -6px;
                        height: auto;

                        @include when-xs {
                            bottom: -10px;
                        }


                        @include when-sm {
                            width: 40px;
                        }


                        @include when-md {
                            width: auto;
                        }
                    }

                    span {
                        color: $color-primary;
                        position: absolute;
                        top: 12px;
                        left: 45px;
                    }
                }

                p {
                    width: 85%;
                    margin: 0 auto;

                    @include when-xs {
                        max-width: 230px;
                    }
                }
            }

            .left-content .top {
                img:not(.icon) {
                    @include when-xs {
                        width: 62px;
                        height: auto;
                    }
                }

                .icon {
                    @include when-xs {
                        width: 40px;
                    }
                }
            }

            .right-content .top {
                img:not(.icon) {
                    margin-left: 0;

                    @include when-xs {
                        width: 22px;
                        height: auto;
                    }
                }

                .icon {
                    @include when-sm-down {
                        width: 32px;
                    }
                }

                span {
                    @include when-xs {
                        top: 4px;
                        left: 30px;
                    }
                }
            }
        }

        .left-content {
            h2 {
                color: #C99FA1;

                span {
                    color: #DBC0C3;
                }
            }
        }

        .right-content {
            h2 {
                color: #8199C3;

                span {
                    color: #A7B7D3;
                }
            }
        }
    }

    .section9 {
        @include webinar-container;

        @include make-container;

        @include when-sm-down {
            margin-bottom: 100px;
        }


        .section-inner {}

        .top-content {
            text-align: center;
            position: relative;
            padding-bottom: 70px;

            @include when-sm-down {
                padding-bottom: 50px;
            }


            &:after {
                @include webinar-row-right;

                content: '';
                background-color: $color-light-lavender;
                bottom: 0;
                top: 50px;
                left: calc(0px - (100vw - 100%) / 2 - 5px);
                right: 0;
                position: absolute;
                z-index: -1;

                @include when-sm-down {
                    left: calc(0px - (100vw - 100%) / 2);
                    top: 28px;
                }
            }

            h3 {
                margin: 40px auto;
                color: $color-light-grey;

                @include when-xs {
                    max-width: 280px;
                }


                @include when-sm {
                    width: 480px;
                }
            }

            img {
                margin-left: 80px;

                @include when-xs {
                    margin-left: 0;
                    max-width: 100%;
                }
            }

            h2,
            p {
                text-align: left;
            }

            h2 {
                padding-top: 60px;
                color: #8199C3;

                @include when-sm-down {
                    padding-top: 50px;
                }
            }

            p {
                width: 85%;
            }
        }

        .bottom-content {
            position: relative;

            .right-content {
                margin-left: auto;
                padding-top: 25px;
                display: flex;
                flex-direction: column;
                position: relative;

                @include when-md {
                    @include webinar-container;

                    min-height: 480px;
                    width: 50%;
                }


                @include when-lg {
                    // width: 670px;
                }


                &:before {
                    content: '';
                    background-color: $color-white;
                    bottom: 100%;
                    height: 40px;
                    left: 0;
                    position: absolute;

                    @include webinar-row-left;

                    @include when-xs {
                        height: 20px;
                        width: 60%;
                    }


                    @include when-sm {
                        right: 50%;
                    }


                    @include when-md {
                        right: 0;

                        @include webinar-row-right;
                    }
                }

                h2 {
                    color: #C99FA1;

                    @include when-xs {
                        margin-top: 60px;
                    }


                    span {
                        color: #DBC0C3;
                    }
                }

                h2 + p {
                    @include when-xs {
                        margin-bottom: 20px;
                    }
                }

                .icon {
                    margin-top: auto;
                    display: flex;
                    align-items: center;

                    @include when-sm-down {
                        margin-top: 40px;
                    }


                    i {
                        margin-left: 25px;

                        @include when-xs {
                            margin-left: 15px;
                        }


                        &:before {
                            color: $color-primary;
                            height: 18px;
                            width: 18px;
                            font-size: 18px;

                            @include when-sm {
                                height: 24px;
                                width: 24px;
                                font-size: 24px;
                            }
                        }
                    }
                }

                .icon-cat {
                    @include icon-cat($color-primary);

                    width: 129px;
                    height: auto;

                    @include when-sm-down {
                        width: 58px;
                    }
                }
            }

            img {
                @include when-sm-down {
                    @include webinar-row-right;
                    @include webinar-row-left;
                    @include container-width;

                    height: auto;
                }


                @media (max-width: 374px) {
                    max-width: 100vw;
                }

                @include when-md {
                    position: absolute;
                    top: 0;
                    right: 50%;
                }


                // @include when-lg {
                //     right: 670px;
                // }
        
            }
        }
    }

    .section10 {
        @include webinar-container;

        @include make-container;

        margin-top: 150px;

        .section-inner {
            > img {
                max-width: 100vw;
            }
        }

        .visible-xs {
            @include webinar-row-left;
        }

        .top-content {
            @include webinar-row-left;

            @include webinar-row-right;

            display: flex;
            align-items: center;
            justify-content: space-between;

            img.left {
                height: auto;
                margin: auto;

                @include when-xs {
                    width: 235px;
                }


                @include when-sm {
                    width: 250px;
                }


                @include when-md {
                    width: 350px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }

            img.right {
                height: auto;

                @include when-sm {
                    width: 440px;
                }


                @include when-md {
                    width: 680px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }
        }

        .bottom-content {
            padding: 70px 0;
            position: relative;

            @include when-sm-down {
                padding: 50px 0 60px;
            }


            &:before {
                @include webinar-row-left;

                @include webinar-row-right;

                content: '';
                background-color: #F6E1E3;
                opacity: .33;
                left: 0;
                right: 0;
                top: -50px;
                bottom: 0;
                position: absolute;
                z-index: -1;

                @include when-xs {
                    bottom: -120px;
                }
            }

            h2 {
                color: #C99FA1;
            }

            h2 + p {
                margin-bottom: 30px;
            }

            p {
                @include when-md {
                    max-width: 550px;
                }


                // @include when-lg {
                //     max-width: 630px;
                // }
        
            }

            .tag-bg {
                @include webinar-row-right;

                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }

    .section11 {
        @include webinar-container;

        @include make-container;

        position: relative;

        @include when-sm-down {
            margin-top: 150px;
            margin-bottom: 130px;
        }


        @include when-md {
            margin-bottom: 180px;
            margin-top: 100px;
        }


        // @include when-lg {
        //     margin-top: 160px;
        // }

        .section-inner {}

        .left-content {
            @include when-md {
                width: 610px;
            }


            // @include when-lg {
            //     width: 700px;
            // }

            h2 {
                color: #C99FA1;

                span {
                    color: #DBC0C3;
                }
            }

            p {
                width: 85%;
            }

            h3 {
                margin-top: 20px;
                font-size: 30px;
                font-weight: $font-weight-black !important;
                color: $color-light-grey;

                span {
                    font-size: 22px;
                    display: block;
                    margin-top: 10px;
                }
            }

            .bottom-content {
                display: flex;
                justify-content: space-evenly;
                margin-top: 70px;

                @include when-sm-down {
                    margin-top: 40px;
                }


                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    @include when-md {
                        width: 220px;
                        height: 220px;
                    }


                    img {
                        margin: auto;

                        @include when-sm-down {
                            width: 70px;
                            height: auto;
                            margin-bottom: 20px;
                        }
                    }

                    h3 {
                        @include when-sm-down {
                            font-size: 16px;
                            max-width: 140px;
                            margin: auto;

                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .right-content {
            @include when-sm-down {
                position: relative;
                margin-top: 40px;
                text-align: center;
            }


            @include when-md {
                background-color: $color-white;
                position: absolute;
                right: 0;
                top: -250px;
                padding: 40px 30px;
                max-width: 450px;
            }


            // @include when-lg {
            //     top: -310px;
            //     padding: 40px 50px;
            //     max-width: none;
            // }

            img {
                max-width: 100%;
                height: auto;

                @include when-sm-down {
                    width: 336px;
                }


                @include when-xs {
                    width: 220px;
                }
            }

            .tag {
                color: $color-white;
                position: relative;
                z-index: 1;
                width: 100%;
                text-align: center;

                @include when-sm-down {
                    width: 336px;
                    height: 100%;
                    margin: auto;
                }


                @include when-xs {
                    width: 230px;
                    padding: 0 20px;
                }


                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    background-color: $color-nero;

                    @include when-sm-down {
                        height: 100%;
                        width: 100%;
                    }


                    @include when-xs {
                        width: auto;
                        right: calc(0px - (100vw - 230px) / 2);
                    }


                    @include when-md {
                        right: calc(-30px - ((100vw - #{$container-md}) / 2));
                    }


                    @include when-lg {
                        right: calc(-30px - ((100vw - #{$container-lg}) / 2));
                    }
                }
            }
        }
    }

    .section12 {
        @include webinar-container;

        @include make-container;

        position: relative;

        @include when-sm-down {
            margin-bottom: 100px;
            margin-top: 70px;
        }


        @include when-md {
            margin-top: 125px;
            margin-bottom: 100px;
        }


        // @include when-lg {
        //     margin-bottom: 180px;
        // }

        h3 {
            margin: auto;
            text-align: center;

            @include when-sm-down {
                padding-bottom: 60px;
            }


            @include when-md {
                margin-bottom: 100px;
                width: 760px;
            }


            span {
                display: block;
            }
        }

        .section-inner {
            > img {
                width: 100%;
            }
        }

        .left-content {
            position: relative;

            @include when-xs {
                margin-top: 111.943%;
                margin-bottom: 50px;
            }


            @include when-sm {
                padding-bottom: 50px;
                width: 440px;
            }


            @include when-md {
                padding-bottom: 40px;
                width: 680px;
            }


            // @include when-lg {
            //     padding-bottom: 90px;
            //     width: 878px;
            // }

            &:before {
                content: '';
                position: absolute;
                background-color: $color-teal;
                opacity: .33;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;

                @include when-xs {
                    width: $container-xs;
                    height: auto;

                    @include webinar-row-left;
                    @include webinar-row-right;

                    bottom: -120px;
                }


                @media (max-width: 374px) {
                    width: 100vw;
                }
            }

            h2 {
                color: #66C7C2;

                @include when-xs {
                    margin: 0 0 10px;
                    padding-top: 130px;
                }


                @include when-sm {
                    margin: 40px 0 10px;
                }


                @include when-md {
                    margin: 60px 0 10px;
                }
            }

            h2 + p {
                @include when-xs {}


                @include when-sm {
                    margin-bottom: 20px;
                }


                @include when-md {
                    margin-bottom: 40px;
                }
            }

            p {
                @include when-xs {
                    margin-bottom: 50px;
                }
            }

            h2,
            p {
                @include when-sm {
                    margin-left: 20px;
                    width: 300px;
                }


                @include when-md {
                    margin-left: 40px;
                    width: 480px;
                }


                // @include when-lg {
                //     margin-left: 80px;
                //     width: 580px;
                // }
        
            }

            img.top {
                max-width: 100%;
                height: auto;
            }

            .right {
                position: absolute;
                bottom: -30px;
                z-index: 1;
                height: auto;

                @include when-xs {
                    max-width: 235px;
                    top: -65px;
                    left: 50%;
                    transform: translateX(-50%);
                }


                @include when-sm {
                    right: -125px;
                    max-width: 250px;
                }


                @include when-md {
                    right: -175px;
                    max-width: 350px;
                }


                // @include when-lg {
                //     right: -235px;
                //     max-width: none;
                // }
        
            }

            &:after {
                content: '';
                background-image: cdn("/images/webinar/theone-webinar-dare-to-share.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;

                @include when-xs {
                    @include webinar-row-left;

                    width: $container-xs;
                    left: 0;
                    height: 0;
                    padding-top: 100%;
                    top: auto;
                    bottom: 100%;
                }


                @media (max-width: 374px) {
                    width: 100vw;
                }

                @include when-sm {
                    width: calc(#{$container-sm} - 440px - #{$webinar-left-padding-sm} + (100vw - #{$container-sm}) / 2);
                }


                @include when-md {
                    width: calc(#{$container-md} - 680px - #{$webinar-left-padding} + (100vw - #{$container-md}) / 2);
                }


                @include when-lg {
                    width: calc(#{$container-lg} - 878px - #{$webinar-left-padding} + (100vw - #{$container-lg}) / 2);
                }
            }
        }
    }

    .section-share {
        text-align: center;

        .logo {
            margin: 0 auto 20px;
        }

        .icon {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: $color-lighter-grey;
                margin: 0 12px;

                &:before {
                    height: 18px;
                    width: 18px;
                    font-size: 18px;

                    @include when-sm {
                        height: 24px;
                        width: 24px;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .section13 {
        @include webinar-container;

        @include make-container;

        margin-top: 125px;

        @include when-sm-down {
            margin-top: 90px;
        }


        .top-content {
            @include webinar-row-right;

            @include webinar-row-left;

            position: relative;

            h3 {
                text-align: center;
                color: $color-light-grey;

                @include when-xs {
                    margin: auto;
                    margin-bottom: 70px;
                    max-width: 300px;
                }


                @include when-sm {
                    position: absolute;
                    top: 60px;
                    width: 400px;
                    right: 20px;
                }


                @include when-md {
                    top: 105px;
                    width: 520px;
                    right: 70px;
                }


                // @include when-lg {
                //     right: 150px;
                // }
        
            }

            img {
                @include container-width;

                height: auto;

                @media (max-width: 374px) {
                    width: 100vw;
                }
            }
        }

        .bottom-content {
            position: relative;

            @include when-sm-down {
                padding: 50px 0;
            }


            @include when-md {
                padding: 70px 130px 60px 50px;
            }


            // @include when-lg {
            //     padding: 110px 130px 60px 50px;
            // }

            &:before {
                @include webinar-row-right;

                @include webinar-row-left;

                content: '';
                position: absolute;
                background-color: $color-bright-blue;
                opacity: .66;
                top: 0;
                left: 0;
                right: 0;
                z-index: -1;
                bottom: 0;

                @include when-sm {
                    bottom: -40px;
                }


                @include when-md {
                    bottom: -70px;
                }


                // @include when-lg {
                //     bottom: -140px;
                // }
        
            }

            h2 {
                margin-top: 110px 0 20px;
                color: #8199C3;
            }

            h2 + p {
                margin-bottom: 20px;
            }
        }

        .img {
            @include webinar-row-right;

            @include webinar-row-left;

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            img.right {
                height: auto;

                @include when-xs {
                    width: 100%;
                }


                @include when-sm {
                    width: 440px;
                }


                @include when-md {
                    width: 680px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }
        }

        .logo {
            @include when-sm {
                margin: 0 auto;
            }


            @include when-md {
                margin-left: 80px;
                margin-bottom: 35px;
            }
        }
    }

    .section14 {
        @include webinar-container;

        @include make-container;

        margin-top: 125px;
        margin-bottom: 205px;

        @include when-sm-down {
            margin-top: 140px;
            margin-bottom: 130px;
        }


        @include when-xs {
            margin-top: 240px;
        }


        .section-inner {
            position: relative;
            display: flex;

            @include when-xs {
                flex-direction: column;
            }


            @include when-sm {
                align-items: flex-end;
            }


            > img {
                width: 100%;
            }

            &:before {
                @include webinar-row-right;

                @include webinar-row-left;

                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #F6E1E3;
                opacity: .33;
                z-index: -1;

                @include when-xs {
                    top: 0;
                    bottom: 80px;
                }


                @include when-sm {
                    top: 100px;
                }


                @include when-md {
                    // top: 180px;
                }


                // @include when-lg {
                //     top: 245px;
                // }
        
            }
        }

        .left-content {
            @include when-xs {
                padding-bottom: 50px;
            }


            h2 {
                margin: 60px 0 10px;
                color: #C99FA1;

                @include when-xs {
                    margin: 100px 0 10px;
                }
            }

            p {
                margin-bottom: 20px;

                @include when-sm {
                    width: 400px;
                }


                @include when-md {
                    width: 500px;
                }


                // @include when-lg {
                //     width: 630px;
                // }
        
            }

            img:not(.right) {
                height: auto;

                @include when-sm {
                    width: 440px;
                }


                @include when-md {
                    width: 680px;
                }


                // @include when-lg {
                //     width: auto;
                // }
        
            }

            img.right {
                height: auto;
                position: absolute;

                @include when-xs {
                    max-width: 235px;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }


                @include when-sm {
                    max-width: 250px;
                    right: 0;
                    bottom: 0;
                }


                @include when-md {
                    max-width: 350px;
                }


                // @include when-lg {
                //     max-width: none;
                // }
        
            }
        }

        .right-content {
            position: relative;

            @include when-xs {
                width: 235px;
            }


            @include when-sm {
                @include webinar-row-right;

                width: 100%;
            }


            &:before {
                content: '';
                position: absolute;
                height: 65px;
                bottom: 0;
                right: 0;
                background-color: $color-white;
                z-index: -1;

                @include when-sm {
                    width: 300px;
                }


                @include when-md {
                    width: 440px;
                }


                // @include when-lg {
                //     width: 605px;
                // }
        
            }
        }

        > img {
            display: block;
            margin-left: auto;
        }
    }

    .section15 {
        @include webinar-container;

        @include make-container;

        margin-top: 125px;
        margin-bottom: 205px;

        @include when-sm-down {
            margin-top: 90px;
            margin-bottom: 90px;
        }


        .section-inner {}

        .top-content {
            h3 {
                width: 820px;
                text-align: center;
                margin: auto;
                padding-bottom: 80px;

                @include when-sm-down {
                    padding-bottom: 70px;
                    width: 530px;
                }


                @include when-xs {
                    width: 100%;
                }
            }
        }

        .bottom-content {
            display: flex;
            position: relative;
            margin: auto;
            flex-wrap: wrap;
            justify-content: center;

            @include when-xs {
                justify-content: space-around;
            }


            @include when-md {
                width: 1000px;
            }


            .icon {
                width: 245px;
                height: 60px;
                text-align: center;
                margin: 50px 30px;
                display: flex;
                flex-flow: column;
                position: relative;

                @include when-sm-down {
                    height: 40px;
                    width: 200px;
                    margin: 45px 0;
                }


                @include when-xs {
                    width: 45%;
                }


                div {
                    display: block;
                    margin: auto;
                }

                p {
                    margin-top: 20px;
                    font-weight: $font-weight-black;
                    text-transform: uppercase;
                    color: $color-lighter-grey;
                    margin-bottom: 0;
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .section16 {
        @include webinar-container;

        @include make-container;

        margin-top: 110px;

        .section-inner {
            position: relative;

            @include when-sm {
                max-width: 500px;
            }


            @include when-md {
                max-width: 850px;
            }


            // @include when-lg {
            //     max-width: 1070px;
            // }

            &:before {
                content: '';
                background-color: #F8FF4D;
                right: 0;
                bottom: 0;
                position: absolute;
                z-index: -1;

                @include when-xs {
                    right: 55px;
                    top: 30px;
                    width: calc((100vw - #{$container-xs}) / 2 + 20px + #{$container-xs});
                }


                @include when-sm {
                    top: 50px;
                    width: calc((100vw - #{$container-sm}) / 2 + 25px + #{$container-sm});
                }


                @include when-md {
                    top: 85px;
                    width: calc((100vw - #{$container-md}) / 2 + 30px + #{$container-md});
                }


                @include when-lg {
                    width: calc((100vw - #{$container-lg}) / 2 + 30px + #{$container-lg});
                }
            }

            img {
                margin-left: auto;
                margin-right: 20px;
                display: block;

                @include when-sm-down {
                    width: 440px;
                    height: auto;
                }


                @include when-xs {
                    width: 100%;
                }
            }
        }
    }

    .section16 + .section-po {
        margin-top: 0;
    }
}

[data-to-root].info.webinar.tips #main-container .blog-content {
    $webinar-left-padding: 30px;
    $webinar-right-padding: 20px;
    $webinar-left-padding-sm: 25px;
    $webinar-right-padding-sm: 25px;
    $webinar-left-padding-xs: 20px;
    $webinar-right-padding-xs: 20px;

    @mixin webinar-container {
        @include when-xs {
            padding-left: $webinar-left-padding-xs !important;
            padding-right: $webinar-right-padding-xs !important;
        }


        @include when-sm {
            padding-left: $webinar-left-padding-sm !important;
            padding-right: $webinar-right-padding-sm !important;
        }


        @include when-md {
            padding-left: $webinar-left-padding !important;
            padding-right: $webinar-right-padding !important;
        }
    }

    @mixin webinar-row-left {
        @include when-xs {
            margin-left: -#{$webinar-left-padding-xs} !important;
        }


        @include when-sm {
            margin-left: -#{$webinar-left-padding-sm} !important;
        }


        @include when-md {
            margin-left: -#{$webinar-left-padding} !important;
        }
    }

    @mixin webinar-row-right {
        @include when-xs {
            margin-right: -#{$webinar-right-padding-xs} !important;
        }


        @include when-sm {
            margin-right: -#{$webinar-right-padding-sm} !important;
        }


        @include when-md {
            margin-right: -#{$webinar-right-padding} !important;
        }
    }

    @mixin container-width {
        @include when-xs {
            max-width: $container-xs;
        }


        @include when-sm {
            max-width: $container-sm;
        }


        @include when-md {
            max-width: $container-md;
        }


        @include when-lg {
            max-width: $container-lg;
        }
    }

    hr {
        border-top: 2px solid $color-close-white;
        position: relative;
        overflow: visible;

        @include when-sm-down {
            margin: 70px 0 60px;
        }


        @include when-md {
            margin: 70px 0 100px;
        }


        &:before {
            @include icon-live-video($color-close-white);

            height: 59.854px;
            width: 85.054px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            @include when-sm-down {
                height: 40.015px;
                width: 56.861px;
            }
        }

        &:after {
            content: '';
            background-color: $color-white;
            height: 2px;
            width: 76px;
            position: absolute;
            top: -2px;
            left: calc(50% + -2px);
            transform: translateX(-50%);
            z-index: 1;

            @include when-sm-down {
                width: 50px;
            }
        }
    }

    h1,
    dt {
        font-weight: $font-weight-bold !important;

        @include when-xs {
            font-size: 26px;
        }


        @include when-sm {
            font-size: 28px;
        }


        @include when-md {
            font-size: 36px;
        }


        // @include when-lg {
        //     font-size: 44px;
        // }

    }

    h1 {
        color: #64686B;

        span {
            font-size: 20px;
            display: block;
            margin-top: 10px;
        }
    }

    counter-reset: tips;

    .section1 {
        @include webinar-container;

        @include make-container;

        @include when-xs {
            padding-top: 30px;
        }


        .top {
            @include when-sm {
                @include make-row;

                display: flex;
                align-items: center;
            }


            .left {
                @include when-sm {
                    @include make-sm-column(5);
                }


                @include when-md {
                    @include make-md-column(5);
                }


                // @include when-lg {
                //     @include make-lg-column(4);
                // }

                h1 {
                    @include when-xs {
                        text-align: center;
                        margin-bottom: 30px;
                    }
                }
            }

            .right {
                @include when-xs {
                    .big {
                        max-width: 100%;
                        height: auto;
                    }

                    .small {
                        width: 150px;
                        height: auto;
                        margin: auto;
                        display: block;
                        margin-top: -20px;
                    }
                }


                @include when-sm {
                    @include make-sm-column(7);

                    .big {
                        margin-left: auto;
                        display: block;
                        max-width: 100%;
                        height: auto;
                    }

                    .small {
                        position: absolute;
                        left: -30px;
                        bottom: -15px;
                        max-width: 100%;
                        height: auto;

                        @include when-sm {
                            width: 150px;
                        }


                        @include when-md {
                            width: 200px;
                        }


                        // @include when-lg {
                        //     width: 275px;
                        // }
                
                    }
                }


                @include when-md {
                    @include make-md-column(7);
                }


                // @include when-lg {
                //     @include make-lg-column(8);
                // }
        
            }
        }

        .bottom {
            position: relative;
            padding-top: 65px;

            @include when-xs {
                padding-top: 30px;
                text-align: center;
            }
        }
    }

    .section2 {
        @include webinar-container;

        @include make-container;

        dl {
            dt {
                color: #7F97C4;
                margin-bottom: 30px;
                line-height: 1;

                &:not(:first-child) {
                    margin-top: 100px;

                    @include when-sm-down {
                        margin-top: 60px;
                    }
                }

                &:before {
                    counter-increment: tips;
                    content: counter(tips, decimal-leading-zero);
                    display: block;
                    color: #A5B6D3;
                }
            }

            dd {
                font-size: 20px;

                @include when-sm-down {
                    font-size: 14px;
                }


                img {
                    @include webinar-row-left;

                    margin-top: 30px;
                    height: auto;
                    max-width: calc(100% + #{$webinar-left-padding-xs});

                    @include when-sm {
                        max-width: calc(100% + #{$webinar-left-padding-sm});
                    }


                    @include when-md {
                        margin-top: 50px;
                        max-width: calc(100% + #{$webinar-left-padding});
                    }
                }
            }
        }
    }

    .section3 {
        @include webinar-container;

        @include make-container;

        margin-bottom: 80px;

        @include when-sm {
            display: flex;
            align-items: center;
            margin-bottom: 130px;
        }


        @include when-md {
            margin-bottom: 200px;
        }


        .logo {
            @include when-xs {
                display: block;
                margin: auto;
                width: 150px;
                height: auto;
                margin-bottom: 30px;
            }
        }

        .btn {
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            margin-left: auto;
            color: $color-white;
            width: fit-content;
            position: relative;
            letter-spacing: 0;
            white-space: nowrap;
            font-size: 24px;
            height: 48px;
            line-height: 54px;
            display: block;
            padding: 0;
            padding-left: 20px;
            padding-right: 100px;
            border: none;
            background-color: transparent;

            @include when-sm-down {
                padding: 0 13px;
                font-size: 13px;
                height: 27px;
                line-height: 27px;
                padding-right: 70px;
            }


            // @include when-lg {
            //     padding-right: 140px;
            // }

            &:after {
                content: '';
                background-color: $color-nero;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: -1;

                @include when-xs {
                    right: calc(-#{$webinar-right-padding-xs} - ((100vw - #{$container-xs}) / 2));
                }


                @include when-sm {
                    right: calc(-#{$webinar-right-padding-sm} - ((100vw - #{$container-sm}) / 2));
                }


                @include when-md {
                    right: calc(-#{$webinar-right-padding} - ((100vw - #{$container-md}) / 2));
                }


                @include when-lg {
                    right: calc(-#{$webinar-right-padding} - ((100vw - #{$container-lg}) / 2));
                }
            }

            &:hover {
                color: $color-nero;

                &:after {
                    box-shadow: 0px 0px 0px 1px $color-nero;
                    background-color: $color-white;
                }
            }
        }
    }
}

[data-to-root].info.webinar.faqs #main-container .blog-content {
    $webinar-left-padding: 30px;
    $webinar-right-padding: 20px;
    $webinar-left-padding-sm: 25px;
    $webinar-right-padding-sm: 25px;
    $webinar-left-padding-xs: 20px;
    $webinar-right-padding-xs: 20px;

    @mixin webinar-container {
        @include when-xs {
            padding-left: $webinar-left-padding-xs !important;
            padding-right: $webinar-right-padding-xs !important;
        }


        @include when-sm {
            padding-left: $webinar-left-padding-sm !important;
            padding-right: $webinar-right-padding-sm !important;
        }


        @include when-md {
            padding-left: $webinar-left-padding !important;
            padding-right: $webinar-right-padding !important;
        }
    }

    @mixin webinar-row-left {
        @include when-xs {
            margin-left: -#{$webinar-left-padding-xs} !important;
        }


        @include when-sm {
            margin-left: -#{$webinar-left-padding-sm} !important;
        }


        @include when-md {
            margin-left: -#{$webinar-left-padding} !important;
        }
    }

    @mixin webinar-row-right {
        @include when-xs {
            margin-right: -#{$webinar-right-padding-xs} !important;
        }


        @include when-sm {
            margin-right: -#{$webinar-right-padding-sm} !important;
        }


        @include when-md {
            margin-right: -#{$webinar-right-padding} !important;
        }
    }

    @mixin container-width {
        @include when-xs {
            max-width: $container-xs;
        }


        @include when-sm {
            max-width: $container-sm;
        }


        @include when-md {
            max-width: $container-md;
        }


        @include when-lg {
            max-width: $container-lg;
        }
    }

    hr {
        border-top: 2px solid $color-close-white;
        position: relative;
        overflow: visible;

        @include when-sm-down {
            margin: 70px 0 60px;
        }


        @include when-md {
            margin: 70px 0 100px;
        }


        &:before {
            @include icon-live-video($color-close-white);

            height: 59.854px;
            width: 85.054px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            @include when-sm-down {
                height: 40.015px;
                width: 56.861px;
            }
        }

        &:after {
            content: '';
            background-color: $color-white;
            height: 2px;
            width: 76px;
            position: absolute;
            top: -2px;
            left: calc(50% + -2px);
            transform: translateX(-50%);
            z-index: 1;

            @include when-sm-down {
                width: 50px;
            }
        }
    }

    h1,
    dt {
        font-weight: $font-weight-bold !important;

        @include when-xs {
            font-size: 26px;
        }


        @include when-sm {
            font-size: 28px;
        }


        @include when-md {
            font-size: 36px;
        }


        // @include when-lg {
        //     font-size: 44px;
        // }

    }

    h1 {
        color: #64686B;
    }

    .section1 {
        @include webinar-container;

        @include make-container;

        @include when-xs {
            padding-top: 30px;
        }


        .top {
            @include when-sm {
                @include make-row;

                display: flex;
                align-items: center;
            }


            .left {
                @include when-sm {
                    @include make-sm-column(5);
                }


                @include when-md {
                    @include make-md-column(5);
                }


                // @include when-lg {
                //     @include make-lg-column(4);
                // }

                h1 {
                    @include when-xs {
                        text-align: center;
                        margin-bottom: 30px;
                    }
                }
            }

            .right {
                @include when-xs {
                    .big {
                        max-width: 100%;
                        height: auto;
                    }

                    .small {
                        width: 150px;
                        height: auto;
                        margin: auto;
                        display: block;
                        margin-top: -20px;
                    }
                }


                @include when-sm {
                    @include make-sm-column(7);

                    .big {
                        margin-left: auto;
                        display: block;
                        max-width: 100%;
                        height: auto;
                    }

                    .small {
                        position: absolute;
                        left: -30px;
                        bottom: -15px;
                        max-width: 100%;
                        height: auto;

                        @include when-sm {
                            width: 150px;
                        }


                        @include when-md {
                            width: 200px;
                        }


                        // @include when-lg {
                        //     width: 275px;
                        // }
                
                    }
                }


                @include when-md {
                    @include make-md-column(7);
                }


                // @include when-lg {
                //     @include make-lg-column(8);
                // }
        
            }
        }
    }

    .section2 {
        @include webinar-container;

        @include make-container;

        dl {
            dt {
                color: $color-primary;
                margin-bottom: 30px;
                line-height: 1;

                &:not(:first-child) {
                    margin-top: 30px;
                }

                @include when-sm-down {
                    margin-bottom: 20px;
                }
            }

            dd {
                font-size: 20px;
                display: block;
                margin-bottom: 70px;

                @include when-sm-down {
                    font-size: 14px;
                }
            }
        }
    }

    .section3 {
        @include webinar-container;

        @include make-container;

        margin-bottom: 80px;

        @include when-sm {
            display: flex;
            align-items: center;
            margin-bottom: 130px;
        }


        @include when-md {
            margin-bottom: 200px;
        }


        .logo {
            @include when-xs {
                display: block;
                margin: auto;
                width: 150px;
                height: auto;
                margin-bottom: 30px;
            }
        }

        .btn {
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            margin-left: auto;
            color: $color-white;
            width: fit-content;
            position: relative;
            letter-spacing: 0;
            white-space: nowrap;
            font-size: 24px;
            height: 48px;
            line-height: 54px;
            display: block;
            padding: 0;
            padding-left: 20px;
            padding-right: 100px;
            border: none;
            background-color: transparent;

            @include when-sm-down {
                padding: 0 13px;
                font-size: 13px;
                height: 27px;
                line-height: 27px;
                padding-right: 70px;
            }


            // @include when-lg {
            //     padding-right: 140px;
            // }

            &:after {
                content: '';
                background-color: $color-nero;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: -1;

                @include when-xs {
                    right: calc(-#{$webinar-right-padding-xs} - ((100vw - #{$container-xs}) / 2));
                }


                @include when-sm {
                    right: calc(-#{$webinar-right-padding-sm} - ((100vw - #{$container-sm}) / 2));
                }


                @include when-md {
                    right: calc(-#{$webinar-right-padding} - ((100vw - #{$container-md}) / 2));
                }


                @include when-lg {
                    right: calc(-#{$webinar-right-padding} - ((100vw - #{$container-lg}) / 2));
                }
            }

            &:hover {
                color: $color-nero;

                &:after {
                    box-shadow: 0px 0px 0px 1px $color-nero;
                    background-color: $color-white;
                }
            }
        }
    }
}

[data-to-root].webinar #main-container {
    .popup-elements {
        &.show {
            display: flex !important;
        }

        display: none;
        position: fixed;
        background-color: rgba($color-nero, $light-alpha);
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .popup-element {
            position: relative;
            width: 340px;
            background-color: $color-off-white;
            box-shadow: 2px 2px 10px rgba($color-nero, $alpha);
            padding: 20px;
            display: none;

            &.show {
                display: block;
            }

            .close {
                // @extend('.la-close');
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;

                i {
                    display: block;
                }
            }

            &#video-preview {
                padding: 5px;
                max-width: 95vw;
                width: 920px;

                @include when-xs {
                    max-width: 375px;
                    width: 100%;
                }


                .close {
                    top: -35px;
                    opacity: 1;
                    padding: 10px;
                    right: 0px;
                    background-color: $color-white;
                }
            }

            &#loader {
                width: 126px;
                height: 66px;
                padding: 0px 10px;
                text-align: center;

                img {
                    width: calc(100% - 40px);
                }
            }
        }
    }
}

[data-to-root].webinar.details #main-container {
    $webinar-left-padding: 30px;
    $webinar-right-padding: 20px;
    $webinar-left-padding-sm: 25px;
    $webinar-right-padding-sm: 25px;
    $webinar-left-padding-xs: 20px;
    $webinar-right-padding-xs: 20px;

    background-color: #F5F5F5;

    @mixin webinar-container {
        @include when-xs {
            padding-left: $webinar-left-padding-xs !important;
            padding-right: $webinar-right-padding-xs !important;
        }


        @include when-sm {
            padding-left: $webinar-left-padding-sm !important;
            padding-right: $webinar-right-padding-sm !important;
        }


        @include when-md {
            padding-left: $webinar-left-padding !important;
            padding-right: $webinar-right-padding !important;
        }
    }

    @mixin webinar-row-left {
        @include when-xs {
            margin-left: -#{$webinar-left-padding-xs} !important;
        }


        @include when-sm {
            margin-left: -#{$webinar-left-padding-sm} !important;
        }


        @include when-md {
            margin-left: -#{$webinar-left-padding} !important;
        }
    }

    @mixin webinar-row-right {
        @include when-xs {
            margin-right: -#{$webinar-right-padding-xs} !important;
        }


        @include when-sm {
            margin-right: -#{$webinar-right-padding-sm} !important;
        }


        @include when-md {
            margin-right: -#{$webinar-right-padding} !important;
        }
    }

    @mixin container-width {
        @include when-xs {
            max-width: $container-xs;
        }


        @include when-sm {
            max-width: $container-sm;
        }


        @include when-md {
            max-width: $container-md;
        }


        @include when-lg {
            max-width: $container-lg;
        }
    }

    h1 {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        color: $color-light-grey;
        text-transform: none;
        margin: 0 0 10px;

        // @include when-xs {
        //     font-size: 26px;
        // }

        // @include when-sm {
        //     font-size: 20px;
        // }

        // @include when-md {
        //     font-size: 30px;
        // }

        // @include when-lg {
        //     font-size: 44px;
        // }

        span {
            display: block;
        }
    }

    .top-section {
        @include make-container;

        &:last-child {
            padding-bottom: 50px;
        }

        .hit-me {
            width: fit-content;
            margin-bottom: unset;
            cursor: pointer;
            position: relative;

            @include when-xs {
                margin: 20px auto;
            }


            @include when-sm {}


            @include when-sm {
                margin-right: -20px;
            }


            &:hover {
                opacity: 1;

                .hit-me-text,
                .ppm {
                    background-color: $color-nero !important;
                    color: $color-white;
                }
            }

            .hit-me-text {
                position: relative;
                padding: 9px 13px 8px;
                line-height: .9;
                color: #808080;
                height: calc(var(--font-size-webinar-lg) * 1.7);

                // font-size: 20px;
                font-size: var(--font-size-webinar-lg);

                // height: 32px;
                background-color: #F5F5F5;

                @include when-md {
                    padding: 12px 13px 7px;

                    // height: 36px;
                    // font-size: 22px;
                }


                // @include when-lg {
                //     // height: 44px;
                //     // font-size: 28px;
                // }
        
            }

            .ppm {
                position: relative;
                color: $color-white;
                padding: 8px;
                line-height: 1;
                font-size: var(--font-size-webinar-lg);
                height: calc(var(--font-size-webinar-lg) * 1.7);

                // font-size: 16px;
                text-transform: none;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                font-weight: $font-weight-bold;

                // height: 32px;
                background-color: #9B9A9A;
                display: flex;
                align-items: center;

                @include when-md {
                    // padding: 12px 8px 7px;
                    // height: 36px;
                    // font-size: 16px;
                }


                // @include when-lg {
                //     // height: 44px;
                //     // font-size: 20px;
                // }

                .icon-video {
                    @extend .svg;

                    @include icon-video($color-white);

                    vertical-align: middle;
                    height: 12px;
                    width: auto;
                    margin-left: 5px;
                    margin-right: 5px;

                    @include when-md {
                        height: 18.123px;
                        margin-left: 10px;
                    }
                }
            }

            &.online.call .ppm {
                background-color: $color-online;
            }

            .user-availibility {
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                font-size: 12px;
                text-transform: none;
                width: fit-content;
                padding: 0 10px;
                position: absolute;

                @include when-sm-down {
                    top: 100%;
                    margin-top: 3px;
                }


                @include when-xs {
                    top: auto;
                    bottom: 100%;
                    margin-bottom: 3px;
                }


                @include when-md {
                    font-size: 16px;
                    margin-left: auto;
                    right: 100%;
                }


                // @include when-lg {
                //     margin-right: 10px;
                // }
        
            }
        }
    }

    .subscribed-msg {
        margin-bottom: 40px;
        background-color: $color-white;
        padding-top: 35px;
        padding-bottom: 30px;
        height: auto;

        @include when-sm-down {
            max-width: 50%;
        }


        @include when-xs {
            max-width: none;

            @include make-row;
        }


        @include when-md {
            width: 500px;
            margin-bottom: 50px;
        }


        // @include when-lg {
        //     width: 620px;
        //     margin-bottom: 60px;
        // }

        .title,
        p,
        .ppm,
        .top-up {
            @include webinar-container;

            margin-bottom: 1.2em;
        }

        .ppm table {
            @include when-md {
                font-size: 20px;
                line-height: 1.2;
            }


            tr td:last-child {
                padding-left: 10px;
                white-space: nowrap;
            }
        }

        .top-up {
            @include when-md {
                font-size: 20px;
                line-height: 1.2;
            }


            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            padding-right: 0 !important;

            .btn {
                @include when-md {
                    height: 36px;
                    width: 100%;
                    max-width: 250px;
                    font-size: 20px;
                    line-height: 1.3;
                    text-align: left;
                }


                &:not(:hover) {
                    color: $color-white;
                    background-color: $color-grey;
                }
            }
        }

        .title {
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-black;
            color: $color-lighter-grey;
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 10px;

            @include when-md {
                font-size: 23px;
            }
        }
    }

    .bottom-section {
        @include make-container;

        padding-bottom: 3em;

        @include when-xs {
            margin-top: 20px;
        }


        .section-inner {
            @include when-xs {
                @include make-row;
            }


            @include when-sm {
                margin-left: -5px;
                margin-right: -5px;
            }
        }

        .user-webinar {
            @include when-sm {
                @include make-sm-column(8);

                padding-left: 5px;
                padding-right: 15px;
            }


            @include when-md {
                @include make-md-column(9);

                padding-left: 5px;
                padding-right: 15px;
            }
        }

        .broadcast-block {
            margin-bottom: 40px;

            .extra-options {
                padding-top: 30px;

                &.off {
                    opacity: 0.3;

                    .call-ui-btn--panelbutton {
                        cursor: default;
                    }
                }
            }

            @include when-sm {
                @include make-sm-column(4);
            }


            @include when-md {
                @include make-md-column(3);
            }
        }

        .webinar-content-title,
        .webinar-content {
            @include when-xs {
                @include make-xs-column(12);
            }


            @include when-sm {
                @include make-sm-column(4);

                padding-left: 5px;
                padding-right: 5px;
            }


            @include when-md {
                @include make-md-column(3);

                padding-left: 5px;
                padding-right: 5px;
            }


            // @include when-lg {
            //     @include make-lg-column(3);

            //     padding-left: 5px;
            //     padding-right: 5px;
            // }
    
        }

        .grid-sizer {
            @include when-sm {
                @include make-sm-column(4);

                padding-left: 5px;
                padding-right: 5px;
            }


            @include when-md {
                @include make-md-column(3);

                padding-left: 5px;
                padding-right: 5px;
            }


            // @include when-lg {
            //     @include make-lg-column(3);

            //     padding-left: 5px;
            //     padding-right: 5px;
            // }

            clear: both;
        }

        > .webinar-content {
            @include when-xs {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .webinar-content {
            font-size: 14px;

            @include when-xs {
                float: none;
            }


            @include when-sm {
                font-size: 16px;
            }


            @include when-md {
                font-size: 18px;
            }


            // @include when-lg {
            //     font-size: 20px;
            // }

            .image-container {
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-color: $color-white;
                width: 100%;
                height: 0;
                padding-top: 56.367%;
                position: relative;

                &.video {
                    position: relative;
                    cursor: pointer;
                    background-color: $color-nero;

                    &::after {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);

                        @include icon-play($color-nero);
                    }
                }
            }

            .webinar-img {}

            .webinar-desc {
                padding: 10px 10px 22px;
                white-space: normal;
                height: auto;

                @include when-md {
                    padding: 15px 15px 30px;
                }


                .title,
                .date {
                    margin-bottom: 0;
                }
            }

            .btn-more {
                line-height: 27px;
                height: 22px;
                font-size: 16px;

                @include when-md {
                    line-height: 35px;
                    height: 30px;
                    font-size: 20px;
                }
            }
        }

        .section-title {
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-black;
            color: $color-lighter-grey;
            text-transform: uppercase;
            position: relative;

            &:before,
            &:after {
                content: '';
                border-top: 1px solid $color-dark-grey;
                width: calc(100% - 10px);
                position: absolute;
                top: 0;
                left: 0;

                @include when-xs {
                    left: 0;
                    width: 100%;
                }
            }

            &:after {
                top: auto;
                bottom: 0;
            }

            &.webinar-content-title {
                @include when-xs {
                    float: none;
                }


                &:before,
                &:after {
                    @include when-sm {
                        left: 5px;
                    }
                }
            }

            @include when-sm-down {
                line-height: 34px;
                font-size: 18px;
                margin-bottom: 25px;
            }


            @include when-md {
                line-height: 46px;
                font-size: 23px;
                margin-bottom: 35px;
            }
        }
    }

    .popup-elements + {
        .upcoming-webinar,
        .webinar-settings {
            .webinar-img[data-video] {
                cursor: pointer;

                &:hover:after {
                    @include icon-play($color-primary);
                }
            }
        }
    }

    .upcoming-webinar,
    .webinar-settings {
        display: flex;

        @include when-sm-down {}


        @include when-xs {
            flex-flow: column-reverse;

            @include make-row;
        }


        @include when-sm {
            background-color: $color-white;
        }


        .left {
            @include when-xs {}


            &[data-video] {
                position: relative;
                background-color: $color-nero;

                &::after {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    @include icon-play($color-nero);
                }
            }
        }

        .right:not(.tooltip) {
            position: relative;

            @include when-sm {
                padding-left: 20px;
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                flex: 1;
                padding-right: 20px;
            }


            @include when-md {}


            // @include when-lg {
            //     padding-left: 30px;
            // }
    
        }

        .webinar-img {
            background-color: $color-white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            img {
                visibility: hidden;
                max-width: 100%;
            }

            @include when-xs {
                width: 100%;
                max-height: 220px;
            }


            @include when-sm {
                width: 260px;
                height: 150px;
            }


            @include when-md {
                width: 450px;
                height: 254px;
            }


            // @include when-lg {
            //     width: 620px;
            //     height: 350px;
            // }
    
        }

        .webinar-title {
            position: relative;
            color: #3C3C3B;
            min-height: 2em;

            .tip {
                top: 11px;
            }

            @include when-xs {
                margin-top: 20px;
                font-size: 28px;

                @include webinar-container;
            }


            @include when-sm {
                line-height: 1;
                margin-top: 5px;
            }


            @include when-md {
                margin-top: 15px;
                min-height: 3em;
            }


            // @include when-lg {
            //     margin-top: 30px;
            // }
    
        }

        .webinar-desc {
            position: relative;
            margin-top: auto;
            margin-bottom: 7px;
            width: fit-content;
            font-size: var(--font-size-webinar-md);
            line-height: 1.4;

            @include when-xs {
                @include webinar-container;

                margin-bottom: 20px;
            }


            @include when-sm {
                display: flex;
            }


            @include when-md {
                // font-size: 18px;
                margin-bottom: 10px;
            }


            // @include when-lg {
            //     // margin-bottom: 15px;

            //     // font-size: 20px;
            // }

            .title {
                font-size: inherit;
            }

            > div {
                @include when-sm {
                    line-height: 1;
                }


                @include when-md {
                    line-height: 1.4;
                }


                &:first-of-type:after,
                &:last-child:before {
                    content: '';

                    @include when-sm {
                        margin: 0 10px;
                        height: 60%;
                        border-right: 1px solid $color-lightest-grey;
                    }
                }

                &:first-of-type,
                &:last-child {
                    @include when-sm {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .time {
                display: table;
                flex-flow: column;
                justify-content: center;

                &:not(.has-local) {
                    display: flex;
                }

                .host-time-holder {
                    display: flex;
                }

                .local-time-holder,
                .host-time-holder {
                    display: table-row;

                    div:first-of-type {
                        @include when-sm {
                            width: 86px;
                            margin-right: 10px;
                        }
                    }

                    > div {
                        display: table-cell;

                        &:last-child:not(:first-child) {
                            padding-left: 5px;

                            @include when-md {
                                padding-left: 10px;
                            }
                        }

                        &:first-child {
                            @include when-sm {
                                text-align: center;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .price {
                @include when-xs {
                    br {
                        display: none;
                    }
                }


                @include when-md {
                    br {
                        display: none;
                    }
                }
            }
        }

        .bottom {
            position: relative;
            margin: auto 0;

            @include when-xs {
                margin-top: 30px;
            }


            @include when-sm {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
            }


            @include when-md {
                margin-bottom: 15px;
            }


            // @include when-lg {
            //     margin-bottom: 30px;
            // }
    
        }

        .user {
            display: flex;
            align-items: center;

            &:not(:hover) {
                color: #3C3C3B;
            }

            .avatar-wrapper {
                height: 36px;
                width: 36px;

                // @include when-md {
                //     width: 63px;
                //     height: 64px;
                // }

                .avatar {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    clip-path: url(#avatarpathsmall);
                    width: 100%;
                    height: 100%;

                    // @include when-md {
                    //     clip-path: url(#avatarpath);
                    // }
            
                }
            }

            .name {
                font-size: var(--font-size-webinar-sm);

                // @include when-md {
                //     font-size: 20px;
                // }
        
            }

            .name-rating {
                display: flex;
                flex-flow: column;
                margin-left: 10px;
            }

            .rating {
                position: relative;
                z-index: 0;

                .jq-ry-container {
                    padding: 0;
                }
            }
        }
    }

    .subscription {
        position: relative;
        display: flex;

        @include webinar-container;

        @include when-xs {
            flex-flow: column;
            align-items: center;

            @include make-row;
        }


        @include when-sm {
            flex-wrap: wrap;
            padding-bottom: 30px;
        }


        @include when-md {
            padding-bottom: 50px;
        }


        .share,
        .subscription-desc {
            position: relative;
            flex-basis: 100%;
        }

        .subscription-desc.cancel {
            font-size: 14px;
            bottom: -25px;
            height: 0px;
        }

        .subscription-desc a:not(.btn) {
            color: inherit;

            &:hover {
                text-decoration: underline !important;
            }
        }

        .subscription-desc {
            font-size: var(--font-size-webinar-md);

            .separator {
                &:after {
                    content: '|';
                    padding: 0 5px;
                    display: inline;
                }
            }
        }

        .btn-subscribe,
        .btn-subscribed {
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold;
            height: fit-content;
            padding: 0;
            border-color: transparent;
            font-size: var(--font-size-webinar-lg);

            @include when-xs {
                line-height: 37px;
                text-align: center;
                width: calc(100% + 40px);
            }


            @include when-sm {
                @include webinar-row-left;

                padding: 0 10px;
                line-height: 35px;
            }


            @include when-md {
                width: 450px;
                line-height: 50px;
                padding: 0;
            }


            // @include when-lg {
            //     width: 526px;
            //     line-height: 60px;
            // }
    
        }

        .btn-subscribed {
            background-color: $color-online;
            color: $color-white !important;
            text-transform: uppercase;
            text-align: center;
            cursor: auto;
        }

        .btn-subscribe {
            cursor: pointer;
        }

        .countdown-timer {
            display: flex;

            .time {
                background-color: $color-white;
                color: $color-blue;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                font-weight: $font-weight-light;
                text-align: center;
                line-height: .8;
                border-radius: 0px 0px 3px 3px;
                padding: 5px;
                min-width: 56px;
                font-size: 26px;

                &:not(:last-child) {
                    margin-right: 6px;
                }

                @include when-xs {
                    padding: 10px 5px 8px;
                    min-width: 71px;
                    font-size: 50px;
                }


                @include when-md {
                    padding: 10px 5px 8px;
                    min-width: 70px;
                    font-size: 50px;
                }


                span:not([id]) {
                    display: block;
                    color: $color-lighter-grey;
                    font-weight: $font-weight-normal;
                    margin-top: 6px;
                    font-size: 12px;

                    @include when-xs {
                        font-size: 14px;
                    }


                    @include when-md {
                        font-size: 14px;
                    }
                }

                span[id] {
                    opacity: $alpha;
                }
            }
        }

        .share {
            font-size: 24px;
            display: flex;
            margin-top: 10px;

            @include when-xs {
                order: 4;
                margin-top: 40px;
                margin-bottom: 25px;
            }


            .title {
                text-align: right;
                position: absolute;
                right: 100%;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                font-weight: 700;
                font-size: 18px;

                @include when-xs {
                    margin-right: 10px;
                }


                @include when-md {
                    font-size: 24px;
                }
            }

            .icon {
                margin-left: auto;
                padding-left: 0;
                padding-right: 0;

                @include when-sm {
                    text-align: right;

                    @include make-sm-column(4);
                }


                @include when-md {
                    text-align: center;

                    @include make-md-column(3);
                }
            }

            a {
                &:not(:hover) {
                    color: $color-dark-grey;
                }

                @include when-sm-down {
                    margin: 0 10px;
                }


                @include when-md {
                    margin: 0 15px;
                }
            }

            .fa-facebook:before,
            .fa-linkedin:before,
            .fa-twitter:before {
                height: 18px;
                width: 18px;
                font-size: 18px;

                @include when-md {
                    height: 24px;
                    width: 24px;
                    font-size: 24px;
                }
            }
        }

        .subscription-desc {
            @include when-xs {
                width: 100%;
                margin-top: 50px;
            }
        }

        &.dark {
            .subscription-desc {
                color: rgba($color-black, $dark-alpha);
            }

            .share .icon a,
            .share .title {
                color: rgba($color-black, $dark-alpha) !important;
            }

            .share .icon a:hover {
                color: $color-black !important;
            }

            .btn-subscribe {
                background-color: rgba($color-black, $dark-alpha);
            }

            .btn-subscribe:hover {
                background-color: $color-black;
            }
        }

        &.light {
            .subscription-desc {
                color: rgba($color-white, $dark-alpha);
            }

            .share .icon a,
            .share .title {
                color: rgba($color-white, $dark-alpha) !important;
            }

            .share .icon a:hover {
                color: $color-white !important;
            }

            .btn-subscribe {
                background-color: rgba($color-white, $light-alpha);
            }

            .btn-subscribe:hover {
                background-color: $color-white;
                color: $color-nero;
            }
        }
    }

    .attend-check-wrapper {
        position: relative;

        @include when-xs {
            @include make-row;
        }


        @include when-sm {
            width: 360px;
        }


        @include when-md {
            width: 450px;

            // margin-top: 20px;
        }


        // @include when-lg {
        //     width: 530px;
        // }

        &.cancel {
            .attend-check {
                text-align: center;
            }
        }

        &.email {
            @include when-xs {
                width: 100%;
            }


            @include when-sm {
                min-width: 400px;
            }


            @include when-md {
                // min-width: 620px;
            }


            .attend-check {
                @include when-sm {
                    max-width: 300px;
                }


                @include when-md {
                    max-width: 450px;
                }


                // @include when-lg {
                //     max-width: 530px;
                // }
        
            }
        }

        &.mix {
            @include when-xs {}


            @include when-sm {
                min-width: 400px;
            }


            @include when-md {
                // min-width: 620px;
            }


            .attend-check-cancel {
                @include when-sm {}


                @include when-md {}


                // @include when-lg {
                //     left: 100%;
                // }
        
            }

            .choose-price {
                @include when-sm {
                    @include make-row;

                    margin-left: -5px;
                    margin-right: -5px;
                }


                .full-price,
                .ppm-price {
                    @include when-sm {}


                    @include when-sm {
                        @include make-sm-column(6);

                        padding-left: 5px;
                        padding-right: 5px;
                    }


                    p {
                        padding: 0 10px;

                        @include when-sm-down {
                            margin-top: 20px;
                            margin-bottom: 40px;
                        }


                        @include when-xs {
                            padding: 0 30px;
                        }


                        @include when-md {
                            padding: 0 15px;
                        }


                        span {
                            display: block;

                            @include when-md {
                                margin-top: 1.2em;
                            }


                            .tooltip-inner {
                                width: 150px;
                            }
                        }
                    }

                    .full-price-btn,
                    .ppm-price-btn {
                        width: 100%;
                        line-height: 1.6;
                        height: 33px;

                        &:not(:hover) {
                            background-color: $color-online;
                            color: $color-nero;
                        }

                        @include when-sm {}


                        @include when-md {
                            font-size: 24px;
                            height: 48px;
                            line-height: 1.7;
                        }


                        @include when-lg {}
                    }
                }
            }
        }

        &.cancel {
            @include when-xs {}


            @include when-sm {
                min-width: 400px;
            }


            @include when-md {
                // min-width: 620px;
            }


            // .attend-check-cancel {
            //     @include when-lg {
            //         left: 100%;
            //     }
            // }

            .cancel-subscription {
                @include when-sm {
                    @include make-row;

                    margin-left: -5px;
                    margin-right: -5px;
                }


                .cancel-yes,
                .cancel-no {
                    @include when-sm {
                        @include make-sm-column(6);

                        padding-left: 5px;
                        padding-right: 5px;
                    }


                    .cancel-yes-btn,
                    .cancel-no-btn {
                        width: 100%;
                        line-height: 1.6;
                        height: 33px;

                        &:not(:hover) {
                            background-color: $color-online;
                            color: $color-nero;
                        }

                        @include when-md {
                            font-size: 24px;
                            height: 48px;
                            line-height: 1.7;
                        }
                    }

                    .cancel-yes-btn {
                        &:not(:hover) {
                            background-color: transparent;
                            color: $color-grey;
                            border: 1px solid $color-grey;
                        }
                    }

                    .cancel-no-btn {
                        &:not(:hover) {
                            background-color: $color-grey;
                            color: $color-white;
                        }
                    }
                }
            }
        }

        &.full-price {
            @include when-md {
                // min-width: 500px;
            }
        }

        p,
        .login-btn a {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 30px;
            display: block;

            @include when-md {
                font-size: 20px;
            }
        }

        p {
            span {
                color: $color-grey;
            }

            &.text-left {
                text-align: left;
                padding-left: 30px;

                @include when-md {
                    padding-left: 50px;
                }
            }
        }

        .email-check,
        .attend-check {
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold;
            height: fit-content;
            padding: 0;
            border-color: transparent;
            background-color: #3C3C3B;
            color: $color-white;
            text-transform: uppercase;
            margin-bottom: 10px;

            @include when-xs {
                font-size: 20px;
                line-height: 38px;
                text-align: center;
            }


            @include when-sm {
                font-size: 20px;
                padding: 0 10px 0 30px;
                line-height: 40px;
            }


            @include when-md {
                padding: 0 10px 0 50px;
                font-size: 24px;
                line-height: 50px;
                margin-bottom: 20px;
            }


            // @include when-lg {
            //     line-height: 60px;
            // }

            &.grey {
                background-color: $color-lighter-grey;
            }
        }

        .email-check {}

        .email-check + p {
            text-align: left;

            @include when-xs {
                text-align: center;
            }


            @include when-sm {
                padding-left: 30px;
            }


            @include when-md {
                padding-left: 50px;
            }
        }

        .group-btns {
            text-align: center;
            margin-bottom: 20px;

            .btn {
                line-height: 1;
                margin: 5px;

                &:not(:hover) {
                    color: $color-nero;
                }

                @include when-xs {
                    height: 36px;
                    font-size: 18px;
                    padding-top: 10px;
                    width: calc(50% - 20px);
                }


                @include when-sm {
                    height: 40px;
                    font-size: 20px;
                    padding-top: 12px;
                    width: calc(50% - 20px);
                }


                @include when-md {
                    height: 48px;
                    font-size: 24px;
                    padding-top: 14px;
                    margin: 0 5px;
                    width: 200px;
                }


                // @include when-lg {
                //     width: 220px;
                // }
        
            }

            .btn-yes {
                &:not(:hover) {
                    background-color: $color-online;
                }
            }

            .btn-no {
                &:not(:hover) {
                    background-color: $color-bright-pink;
                }
            }
        }

        .attend-check-cancel {
            font-size: 24px;
            font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;

            &:not(:hover) {
                color: $color-offline;
            }

            @include when-xs {
                line-height: 38px;
                display: block;
                text-align: center;
                margin-bottom: 30px;
            }


            @include when-sm {
                height: 40px;
                line-height: 47px;
                padding-left: 20px;
                position: absolute;
                left: 100%;
                top: 0;
                font-size: 24px;
                white-space: nowrap;

                // @include when-lg {
                //     left: calc(100% + 90px);
                // }
            }


            @include when-md {
                line-height: 58px;
                height: 50px;
            }


            // @include when-lg {
            //     line-height: 60px;
            //     padding-left: 30px;
            // }
    
        }

        .signup-email-btn {
            height: 40px;
            line-height: 1;
            text-align: left;
            position: relative;
            padding-left: 45px;
            width: 100%;
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold;
            text-transform: none;

            &:not(:hover) {
                background-color: $color-lightest-grey;
            }

            &:before {
                @include icon-mail($color-white);

                height: 13.796px;
                margin-left: 10px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }

            &.active {
                background-color: $color-white;
                color: $color-nero;
                box-shadow: none;
                cursor: auto;

                &:before {
                    @include icon-mail($color-nero);
                }

                &:hover {
                    border-color: transparent;
                }
            }
        }

        .login-btn {
            a {
                &:not(:hover) {
                    color: $color-dark-grey;
                }
            }
        }

        .signup-email-form {
            background-color: $color-white;

            &:not(.active) {
                height: 0;
                overflow: hidden;
            }

            form {
                padding-top: 15px;
            }

            .btn-subscribe {
                height: 36px;
                width: 100%;
                max-width: 265px;
                font-size: 20px;
                line-height: 1.3;

                &:not(:hover) {
                    background-color: #444443;
                }
            }

            tr {
                td {
                    padding: 2.5px 8px;

                    &:first-child {
                        width: 120px;
                        text-align: right;
                        padding-right: 15px;
                        line-height: 36px;
                    }

                    &:last-child {
                        padding-left: 0;

                        input {
                            width: 100%;
                            max-width: 235px;
                            height: 36px;
                            padding: 0 13px;
                        }
                    }
                }

                &:nth-last-child(2) {
                    td:last-child {
                        display: flex;
                    }
                }

                &:nth-child(4) {
                    td {
                        padding-top: 17.5px;
                    }
                }

                &:last-child {
                    td {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-right: 0;
                        text-align: right;
                    }
                }
            }
        }

        .signup-guest {
            position: absolute;
            left: 100%;
            top: 50px;
            width: 340px;
            padding-left: 40px;

            input.guest-name {
                width: 160px;
            }

            .error {
                padding: 0 10px 20px 0;
            }

            @include when-md {
                top: 40px;
            }


            @include when-xs {
                position: static;
                padding-left: 0;
                top: 0;
            }
        }

        .payment-form {
            background-color: $color-white;
            padding: 20px 30px;

            @include when-md {
                padding: 35px 50px;
            }


            .checkout-button {
                width: 100%;
                margin-top: 20px;
            }

            .btn {
                font-size: 16px;

                @include when-md {
                    font-size: 20px;
                }
            }

            .payment-choose {
                .theone-credit-payment {
                    p {
                        text-align: left;
                        font-weight: $font-weight-bold;
                        margin: 0 0 10px;
                    }

                    .input-wrapper {
                        @include when-md {
                            display: flex;
                            justify-content: space-between;
                        }


                        label {
                            margin-top: 5px;
                            margin-bottom: 0;
                        }

                        label.radio-select > span {
                            display: block;
                        }

                        .btn {
                            line-height: 1;

                            &:not(:hover) {
                                background-color: $color-grey;
                            }

                            height: 28px;
                            text-align: left;

                            @include when-md {
                                line-height: 1.3;
                                height: 36px;
                            }
                        }

                        .btn-update-amount {
                            min-width: 150px;
                        }

                        #update-amount-container {
                            display: flex;

                            input {
                                height: 28px;
                                border: 1px solid rgba(0, 0, 0, 0.18);
                                width: 80px;
                                padding: 5px;
                                margin-right: 5px;

                                @include when-md {
                                    height: 36px;
                                }
                            }
                        }

                        .btn-update-amount-submit {
                            min-width: 50px;
                        }
                    }
                }
            }
        }
    }

    .upcoming-bottom {
        background-color: $color-white;

        @include webinar-container;

        @include make-row;

        .user {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            margin-top: 10px;

            &:not(:hover) {
                color: #3C3C3B;
            }

            .avatar-wrapper {
                height: 36px;
                width: 36px;

                .avatar {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    clip-path: url(#avatarpathsmallbottom);
                    width: 100%;
                    height: 100%;
                }
            }

            .name {
                font-size: 14px;
            }

            .name-rating {
                display: flex;
                flex-flow: column;
                margin-left: 10px;
            }

            .rating {
                .jq-ry-container {
                    padding: 0;
                }
            }
        }

        .hit-me {
            width: fit-content;
            margin-bottom: unset;
            cursor: pointer;
            margin: 20px -20px 0 auto;

            &:hover {
                opacity: 1;

                .hit-me-text,
                .ppm {
                    background-color: $color-nero !important;
                    color: $color-white;
                }
            }

            .hit-me-text {
                position: relative;
                padding: 9px 13px 8px;
                line-height: .9;
                color: #808080;
                font-size: 20px;
                height: 32px;
                background-color: #F5F5F5;
            }

            .ppm {
                position: relative;
                color: $color-white;
                padding: 8px;
                line-height: 1;
                font-size: 16px;
                text-transform: none;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                font-weight: $font-weight-bold;
                height: 32px;
                background-color: #9B9A9A;

                .icon-video {
                    @extend .svg;

                    @include icon-video($color-white);

                    vertical-align: middle;
                    height: 12px;
                    width: auto;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            &.online.call .ppm {
                background-color: $color-online;
            }
        }
    }

    .webinar-details {
        @include webinar-container;

        background-color: $color-white;
        padding-bottom: 20px;
        padding-top: 20px;

        @include when-md {
            padding-top: 50px;
        }


        .title {
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-black;
            color: $color-lighter-grey;
            padding-top: 10px;
            margin-bottom: 10px;
            font-size: 18px;

            @include when-xs {
                margin-bottom: 0;
            }


            @include when-md {
                font-size: 23px;
            }
        }

        p {
            margin-bottom: 1.2em;
        }

        .short-desc,
        .attendees,
        .benefits {
            .title {
                text-transform: uppercase;
            }

            p {
                font-weight: $font-weight-bold;
            }
        }

        .long-desc .title {
            font-weight: $font-weight-bold;
            line-height: 1.2;
            font-size: var(--font-size-webinar-xl);

            // @include when-md {
            //     font-size: 34px;
            // }
    
        }

        .long-desc h3 {
            font-size: var(--font-size-webinar-xl);

            // font-size: 20px;
            // line-height: 26px;
            line-height: 1.3em;
            font-weight: $font-weight-bold !important;
            text-transform: none;
        }
    }

    .other-info {
        margin-top: 20px;

        @include when-md {
            @include make-row;

            margin-left: -5px;
            margin-right: -5px;
            margin-top: 35px;
            display: flex;
        }


        .left-section {
            @include when-md {
                @include make-md-column(8);

                padding-left: 5px;
                padding-right: 5px;
            }


            // @include when-lg {
            //     @include make-lg-column(8);

            //     padding-left: 5px;
            //     padding-right: 5px;
            // }
    
        }

        .right-section {
            @include when-sm-down {
                margin-top: 20px;
            }


            @include when-md {
                @include make-md-column(4);

                padding-left: 5px;
                padding-right: 5px;
            }


            // @include when-lg {
            //     @include make-lg-column(4);

            //     padding-left: 5px;
            //     padding-right: 5px;
            // }
    
        }

        .additional-info-container,
        .webinar-rating-container {
            position: relative;
            background-color: $color-white;
            padding: 30px 20px 30px;
            height: 100%;
            z-index: 1;

            @include when-md {
                padding: 50px 20px 30px;
            }


            .title {
                font-weight: $font-weight-bold;
                line-height: 1.2;

                // font-size: 28px;
                font-size: var(--font-size-webinar-xl);
                margin-bottom: 15px;

                @include when-md {
                    // font-size: 34px;
                }
            }

            .submit-button {
                cursor: pointer;
                display: inline-block;
                position: relative;
                background-color: $color-green;
                color: $color-nero;
                font-family: bebas;
                font-size: 22px;
                letter-spacing: 1px;
                font-weight: $font-weight-bold;
                padding: 4px 10px 0px;
                text-align: center;
                min-width: 200px;
                float: right;
                clear: both;
                margin-bottom: 20px;
                margin-top: 20px;
                right: -20px;

                &:hover {
                    color: $color-white;
                    background-color: $color-nero;
                }
            }
        }

        .additional-info-container {
            @include webinar-container;
        }

        .webinar-rating-container {
            p {
                font-size: 14px;
                margin-bottom: 7px;
            }

            .rating {
                margin-bottom: 20px;

                .jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group > svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .recorded-webinar {
        margin-top: 80px;

        @include when-sm-down {
            margin-top: 50px;
        }


        .section-title {
            @include when-xs {
                padding-left: 10px;
                padding-left: 10px;
                line-height: 1;
                padding-bottom: 8px;
                padding-top: 8px;
            }


            @include when-sm-down {
                min-width: 100%;
            }
        }

        .webinar-contents {
            @include make-row;

            @include when-sm {
                margin-right: -5px;
                margin-left: -5px;
            }


            .webinar-content {
                margin-bottom: 5px;

                @include when-xs {
                    display: block;
                }


                @include when-sm {
                    @include make-sm-column(6);

                    padding-right: 5px;
                    padding-left: 5px;
                }


                @include when-md {
                    @include make-md-column(4);

                    padding-right: 5px;
                    padding-left: 5px;
                    font-size: 20px;
                    margin-bottom: 10px;
                }


                // @include when-lg {
                //     @include make-lg-column(4);

                //     padding-right: 5px;
                //     padding-left: 5px;
                // }
        
            }
        }
    }

    .user-profile {
        @include when-xs {
            @include webinar-container;
        }


        @include when-sm {
            @include make-row;

            margin-left: -5px;
            margin-right: -5px;
        }


        .left-section {
            position: relative;

            @include when-xs {
                margin-bottom: 30px;
            }


            @include when-sm {
                @include make-sm-column(6);

                padding-left: 5px;
                padding-right: 5px;
            }


            @include when-md {
                @include make-md-column(5);

                padding-left: 5px;
                padding-right: 5px;
            }


            // @include when-lg {
            //     @include make-lg-column(5);

            //     padding-left: 5px;
            //     padding-right: 5px;
            // }
    
        }

        .right-section {
            position: relative;

            @include when-xs {
                margin-bottom: 30px;
            }


            @include when-sm {
                @include make-sm-column(6);

                padding-left: 5px;
                padding-right: 5px;
            }


            @include when-md {
                @include make-md-column(7);

                padding-left: 5px;
                padding-right: 5px;
            }


            // @include when-lg {
            //     @include make-lg-column(7);

            //     padding-left: 5px;
            //     padding-right: 5px;
            // }
    
        }

        .item-details {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                display: block;
                right: 0;
                bottom: 0;
                left: 0;
                top: 0;
                background-color: $color-white;

                @include when-xs {
                    left: -20px;
                    right: -20px;
                    height: 100%;
                }
            }

            @include when-xs {
                padding-top: 20px;
                padding-bottom: 20px;
            }


            @include when-sm {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 10px;
                padding-right: 10px;
            }


            @include when-md {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px;
            }


            // @include when-lg {
            //     padding-top: 40px;
            //     padding-bottom: 30px;
            //     padding-left: 50px;
            //     padding-right: 50px;
            // }

            p {
                font-size: 14px;

                @include when-md {
                    font-size: 16px;
                }
            }

            .avatar-wrapper {
                width: 80px;
                height: 82px;
                display: block;

                @include when-md {
                    width: 121px;
                    height: 123px;
                }


                .avatar {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    clip-path: url(#avatarpathmedium);
                    width: 100%;
                    height: 100%;

                    @include when-md {
                        clip-path: url(#avatarpathbig);
                    }
                }
            }

            .price-tag {
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                text-align: center;
                line-height: 1;
                max-width: fit-content;
                margin: auto;
                padding: 0;
                position: relative;
                color: $color-grey;

                @include when-md {
                    padding: 10px 15px;
                }


                &.pm {
                    .up {
                        font-weight: $font-weight-bold;
                        font-size: 16px;
                        vertical-align: top;
                        text-transform: uppercase;
                    }
                }

                &.ph {}

                &.free {
                    text-transform: uppercase;
                }
            }

            .nicename {
                font-size: 36px;
                color: $color-lighter-grey;
                font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
                line-height: .9;
                margin: 0;
                position: relative;
                width: fit-content;
                margin-top: 20px;
                position: relative;

                @include when-md {
                    font-size: 42px;
                }


                &.top {
                    margin-top: 0;
                    top: -15px;
                    margin-bottom: -15px;
                    z-index: 1;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 40px;
                        width: calc(100% + 30px);
                        top: -20px;
                        left: -10px;
                        background-color: $color-white;
                        z-index: -1;
                    }
                }
            }

            .profession {
                font-size: 36px;
                color: $color-lighter-grey;
                font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
                line-height: .9;
                margin-bottom: 10px;
                position: relative;

                @include when-md {
                    font-size: 42px;
                }
            }

            .local-time {
                color: $color-lighter-grey;
                position: relative;
                margin-bottom: 40px;

                span {
                    font-size: inherit;
                    color: inherit;
                }
            }

            .availability {
                color: $color-lighter-grey;
                text-align: center;
                margin-top: 20px;
                position: relative;
            }

            .location {
                margin-bottom: 0;
                position: relative;
            }

            .hit-me {
                font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
                color: $color-nero;
                font-size: 28px;
                text-transform: uppercase;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                line-height: 1;
                margin-bottom: 0;
                position: relative;

                @extend .text-center;

                &.offline {
                    color: $color-offline !important;
                }

                @include when-xs {
                    max-width: 290px;
                    margin: auto;
                    font-size: 22px;
                    line-height: .8;

                    &.primary {
                        display: flex !important;
                    }
                }


                .icon-video-circle {
                    @extend .svg;

                    @extend .no-margin;

                    margin-left: 10px;
                }

                .icon-video-circle.big {
                    height: 40px !important;
                    width: auto !important;

                    @include when-md {
                        height: 67px !important;
                    }
                }

                &:hover {
                    color: $color-nero !important;
                    opacity: 1;

                    .icon-video-circle.big {
                        @include icon-video-circle($color-nero);

                        height: 40px !important;
                        width: auto !important;

                        @include when-md {
                            height: 67px !important;
                        }
                    }
                }
            }

            .review {
                text-align: center;
                display: block;
                color: $color-lighter-grey;
                position: relative;

                .score {
                    position: relative;
                    margin: 0px auto;
                    width: 72px;
                    display: inline-block;
                    top: 2px;
                    padding: 0 10px 0 0;
                    z-index: 1;
                }

                p {
                    display: table;
                    white-space: nowrap;
                    text-transform: capitalize;

                    &:before,
                    &:after {
                        content: '';
                        display: table-cell;
                        position: relative;
                        width: 50%;
                        background-image: url("https://cdn.calltheone.com/public/images/border.png");
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    &:before {
                        right: 5px;
                    }

                    &:after {
                        left: 5px;
                    }
                }
            }

            .language-details {
                margin-top: 30px;
                position: relative;

                @extend .row;

                @include when-xs {
                    margin-left: -10px !important;
                    margin-right: -10px !important;
                }


                .lang,
                .grade {
                    @extend .col-xs-6;

                    margin-bottom: 0;
                }

                .grade {
                    color: $color-light-grey;
                }
            }

            .expertise-certificate {
                position: relative;
                width: 90%;
                max-width: 225px;
                margin: 20px auto;

                @include when-md {
                    margin: 30px auto 40px;
                }


                img {
                    width: 100%;
                    height: auto;

                    &:nth-child(n+2) {
                        padding-top: 10px;
                    }
                }
            }

            .hit-me {
                @include when-sm-down {
                    font-size: 22px;
                }
            }
        }

        .item-tagline-about {
            position: relative;

            @include when-xs {
                padding-top: 20px;
                padding-bottom: 20px;
            }


            &:before {
                content: '';
                position: absolute;
                display: block;
                right: 0;
                bottom: 0;
                left: 0;
                top: 0;
                background-color: $color-white;

                @include when-xs {
                    height: 100%;
                    right: -20px;
                    left: -20px;
                }
            }

            @include when-sm {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 10px;
                padding-right: 10px;
            }


            @include when-md {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px;
            }


            // @include when-lg {
            //     padding-top: 40px;
            //     padding-bottom: 30px;
            //     padding-left: 20px;
            //     padding-right: 20px;
            // }

            .item-tagline,
            .item-about {
                position: relative;
            }

            .item-about {
                font-size: 14px;

                .item-about-title {
                    text-transform: uppercase;
                    padding: 0;
                    line-height: 1;
                    font-family: "proxima", "Helvetica", "Arial", sans-serif;
                    display: block;
                    position: relative;
                    text-align: left;
                    font-weight: $font-weight-black;
                    color: $color-light-grey;
                    font-size: 18px;
                    margin-bottom: 10px;
                    margin-top: 30px;

                    @include when-md {
                        margin-bottom: 15px;
                        margin-top: 50px;
                        font-size: 23px;
                    }
                }
            }
        }

        .btn-profile {
            margin: 10px auto;
            display: block;
            width: fit-content;
        }
    }

    .local-time-holder {
        display: none !important;

        &.show-local {
            display: table-row !important;
        }
    }
}

[data-to-root].webinar.affiliate-pricing {
    $webinar-left-padding: 30px;
    $webinar-right-padding: 20px;
    $webinar-left-padding-sm: 25px;
    $webinar-right-padding-sm: 25px;
    $webinar-left-padding-xs: 20px;
    $webinar-right-padding-xs: 20px;

    #main-container {
        margin-top: 0;
    }

    h1,
    h2,
    .big-text {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        color: $color-light-grey;
        text-transform: none;
        margin: 0 0 10px;

        @include when-xs {
            font-size: 26px;
        }


        @include when-sm {
            font-size: 28px;
        }


        @include when-md {
            font-size: 36px;
        }


        // @include when-lg {
        //     font-size: 44px;
        // }

        span {
            display: block;
        }
    }

    h2 {
        color: #64686B;
    }

    h1 {
        font-weight: $font-weight-bold !important;
        color: #64686B;

        @include when-xs {
            font-size: 26px;
        }


        @include when-sm {
            font-size: 28px;
        }


        @include when-md {
            font-size: 36px;
        }


        // @include when-lg {
        //     font-size: 44px;
        // }

    }

    p.big {
        font-weight: $font-weight-black;
        color: #3C3C3B;
        text-transform: uppercase;

        @include when-xs {
            font-size: 32px;
        }


        @include when-sm {
            font-size: 44px;
        }


        @include when-md {
            font-size: 60px;
        }


        // @include when-lg {
        //     font-size: 76px;
        // }

    }

    hr:not(.to-separator) {
        // width: 100vw;
        border-top: 2px solid $color-close-white;
        position: relative;
        overflow: visible;
        margin: 0;

        @include when-xs {
            margin-left: calc(-1 * (100vw - #{$container-xs}) / 2 - 20px);
        }


        @media (max-width: 374px) {
            margin-left: -20px;
        }

        @include when-sm-down {
            margin: 70px 0 60px;
        }


        @include when-md {
            margin: 115px 0 90px;
        }


        &:before {
            @include icon-live-video($color-close-white);

            height: 59.854px;
            width: 85.054px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            @include when-sm-down {
                height: 40.015px;
                width: 56.861px;
            }
        }

        &:after {
            content: '';
            background-color: $color-white;
            height: 2px;
            width: 76px;
            position: absolute;
            top: -2px;
            left: calc(50% + -2px);
            transform: translateX(-50%);
            z-index: 1;

            @include when-sm-down {
                width: 50px;
            }
        }
    }

    .webinar-container {
        @include webinar-container;
        @include make-container;
    }

    .video-container {
        position: relative;
        height: 0px;

        @include when-xs {
            margin-top: 25px;
            padding-bottom: 56.5%;
        }


        @include when-sm {
            padding-bottom: 65.25%;
            margin-top: 25px;
        }


        // @include when-lg {
        //     margin-top: 50px;
        // }

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
        }
    }

    .btn {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-black;
        text-align: center;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2em;
        border-radius: 1em;
        border-color: transparent;

        &.big {
            background-color: $color-nero;
            color: $color-white;
            width: fit-content;
            margin: auto;

            @include when-sm-down {
                font-size: 24px;
                padding: 0 30px;
            }


            @include when-xs {
                font-size: 22px;
            }


            @include when-md {
                font-size: 32px;
                padding: 0 40px;
            }


            // @include when-lg {
            //     padding: 0 60px;
            //     font-size: 38px;
            // }

            &:hover {
                color: $color-nero;
                background-color: $color-white;
            }
        }

        &.small {
            background-color: #F8FF4D;
            color: $color-nero;
            width: 100%;
            margin: 0 auto 10px;

            @include when-sm-down {
                font-size: 18px;
            }


            @include when-md {
                font-size: 20px;
            }


            // @include when-lg {
            //     font-size: 24px;
            // }

            &:hover {
                color: $color-white;
                background-color: #328CF6;
            }
        }
    }

    #header {
        position: relative;

        @include when-xs {
            min-height: 420px;
        }


        @include when-sm {
            min-height: 292px;
        }


        @include when-md {
            min-height: 434px;
        }


        // @include when-lg {
        //     min-height: 560px;
        // }

        img {
            @include webinar-row-left;

            @include container-width;

            z-index: -1;
            position: absolute;
            top: 0;
            height: auto;

            @include when-xs {
                width: calc(100% + 40px);
            }
        }

        h1,
        p {
            display: block;

            @include when-sm {
                width: 50%;
            }


            @include when-md {
                width: 51%;
            }


            // @include when-lg {
            //     width: 50%;
            // }
    
        }

        h1 {
            color: #64686B;

            @include when-xs {
                width: 165px;
                padding-top: 40px;
            }


            @include when-sm {
                padding-top: 40px;
            }


            @include when-md {
                padding-top: 100px;
            }


            // @include when-lg {
            //     padding-top: 150px;
            // }

            span {
                color: #888D91;
            }
        }

        p {
            font-weight: $font-weight-bold;
            color: #4D4D4D;
            max-width: 700px;

            @include when-xs {
                max-width: 200px;
            }
        }

        .balloon-wrapper {
            @include webinar-row-right;

            width: fit-content;
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;

            @include when-xs {
                width: 135px;
                height: 130px;
            }


            @include when-sm {
                width: 180px;
                height: 170px;
            }


            @include when-md {
                width: 246px;
                height: 226px;
            }


            // @include when-lg {
            //     width: 330px;
            //     height: 280px;
            // }

            .balloon {
                width: 100%;
                height: 100%;
                position: relative;

                &:before {
                    @include bg-balloon-text(#FAFF6E);

                    content: '';
                    transform: rotate(-90deg);
                    display: block;
                    position: absolute;
                    z-index: -1;

                    @include when-xs {
                        width: calc(100% + 30px);
                        height: calc(100% + 30px);
                        right: -30px;
                        top: -30px;
                    }


                    @include when-sm {
                        width: calc(100% + 20px);
                        height: calc(100% + 30px);
                        right: -20px;
                        top: -30px;
                    }


                    @include when-md {
                        width: calc(100% + 20px);
                        height: calc(100% + 40px);
                        right: -20px;
                        top: -40px;
                    }


                    // @include when-lg {
                    //     width: calc(100% + 30px);
                    //     height: calc(100% + 80px);
                    //     right: -30px;
                    //     top: -80px;
                    // }
            
                }

                .balloon-content {
                    display: block;
                    text-align: center;
                    margin-left: auto;

                    @include when-xs {
                        width: calc(100% - 20px);
                        padding: 10px;
                    }


                    @include when-sm {
                        width: calc(100% - 20px);
                        padding: 15px 10px;
                    }


                    @include when-md {
                        width: calc(100% - 20px);
                        padding: 20px;
                    }


                    // @include when-lg {
                    //     width: calc(100% - 30px);
                    //     padding: 30px;
                    // }

                    h2 {
                        color: #3C3C3B;
                        margin-bottom: 0;

                        @include when-xs {
                            font-size: 20px;
                        }


                        @include when-lg {}
                    }
                }
            }
        }
    }

    #short-movies {
        @include webinar-row-left;
        @include webinar-row-right;

        @include when-sm {
            display: flex;
        }


        h2 {
            color: #8097C4;
        }

        h2,
        p {
            @include when-xs {
                padding-right: $webinar-right-padding-xs;
                padding-left: $webinar-left-padding-xs;
            }
        }

        #setup,
        #host {
            margin-top: 40px;

            @include when-sm {
                margin-top: 70px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0 25px;
            }


            @include when-md {
                padding-left: 30px;
                padding-right: 20px;
            }


            // @include when-lg {
            //     padding-left: 30px;
            //     padding-right: 40px;
            // }

            .video-container {
                @include when-sm {
                    margin-left: -25px;
                    margin-right: -25px;
                }


                @include when-md {
                    margin-left: -30px;
                    margin-right: -20px;
                }


                // @include when-lg {
                //     margin-left: -30px;
                //     margin-right: -40px;
                // }
        
            }
        }

        #setup {
            @include when-sm {
                margin-right: 10px;
            }


            // @include when-lg {
            //     margin-right: 15px;
            // }

            .video-container {
                @include when-lg {}
            }
        }

        #host {
            @include when-sm {
                margin-left: 10px;
            }


            // @include when-lg {
            //     margin-left: 15px;
            // }

            .video-container {
                @include when-lg {}
            }
        }
    }

    #payment-plan {
        @include webinar-row-left;
        @include webinar-row-right;

        overflow: hidden;

        @include when-sm-down {}


        @include when-sm {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }


        @include when-md {
            justify-content: flex-start;
        }


        p.big {
            line-height: 1;
            display: block;
            text-align: center;
            margin-bottom: 0;
            position: relative;

            @include when-xs {
                margin: 60px 0;
            }


            @include when-sm {
                margin: 70px 0;
                flex: 1 100%;
            }


            @include when-md {
                margin: 110px 0;
            }


            img {
                position: absolute;

                @include when-sm-down {
                    width: 100px;
                    height: auto;
                }


                @include when-xs {
                    right: 0;
                    top: calc(100% + 5px);
                }


                @include when-sm {
                    left: $webinar-left-padding-sm;
                    top: 50%;
                    transform: translateY(-50%);
                }


                @include when-md {
                    left: $webinar-left-padding;
                }
            }
        }

        h2 {
            font-weight: $font-weight-black !important;
            text-transform: uppercase;
        }

        .content {
            @include when-sm {}


            @include when-md {}


            @include when-lg {}
        }

        #benefit img,
        #lite h2,
        #premium h2 {
            margin-top: 20px;
        }

        #benefit {
            position: relative;

            @include when-sm {}


            @include when-md {
                width: 365px;
            }


            // @include when-lg {
            //     width: 500px;
            // }

            .content {
                position: relative;

                @include when-xs {
                    padding-top: 40px;
                    padding-right: $webinar-right-padding-xs;
                    padding-left: $webinar-left-padding-xs;
                    padding-bottom: 30px;
                }


                @include when-sm {
                    display: flex;
                    padding-top: 40px;
                    padding-bottom: 30px;
                    padding-left: $webinar-left-padding-sm;
                    padding-right: $webinar-right-padding-sm;
                }


                @include when-md {
                    display: block;
                    padding-bottom: 30px;
                    padding-top: 120px;
                    padding-left: $webinar-left-padding;
                    padding-right: $webinar-right-padding;
                }


                // @include when-lg {
                //     padding-top: 128px;
                // }

                &:before {
                    content: '';
                    z-index: -2;
                    position: absolute;
                    background-color: #E6E5EC;
                    top: 0;
                    height: 100%;
                    left: 0;

                    @include when-xs {
                        width: 100%;
                    }


                    @include when-sm {
                        width: $container-sm;
                    }


                    @include when-md {
                        width: $container-md;
                    }


                    @include when-lg {
                        width: $container-lg;
                    }
                }

                ul {
                    padding-left: 20px;
                    margin-bottom: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include when-sm {
                        padding-right: 20px;
                    }


                    @include when-md {
                        padding: 170px 0;
                        padding-left: 30px;
                        padding-right: 0;
                    }


                    // @include when-lg {
                    //     padding: 175px 0;
                    //     padding-left: 30px;
                    // }

                    li {
                        position: relative;
                        margin-bottom: 1.5em;
                        font-weight: $font-weight-bold;

                        &:before {
                            content: '√';
                            font-family: "proxima", "Helvetica", "Arial", sans-serif;
                            color: #328CF6;
                            left: -20px;
                            position: absolute;

                            @include when-sm {
                                font-size: 18px;
                            }


                            @include when-md {
                                left: -30px;
                                font-size: 24px;
                            }


                            // @include when-lg {
                            //     font-size: 28px;
                            // }
                    
                        }
                    }

                    br {
                        display: none;

                        // @include when-lg {
                        //     display: block;
                        // }
                
                    }
                }
            }

            .img-wrapper {
                position: relative;
                display: flex;

                @include when-xs {
                    justify-content: center;
                    margin-bottom: 20px;
                    overflow: hidden;
                }


                @include when-md {
                    width: 100%;
                    justify-content: flex-end;
                }


                @include when-lg {}


                &:after {
                    content: '';
                    background-color: #3C3C3B;
                    height: calc(100% + 10px);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;

                    @include when-sm {
                        width: $container-sm;
                    }


                    @include when-md {
                        width: $container-md;
                    }


                    @include when-lg {
                        width: $container-lg;
                    }
                }

                img {
                    @include when-xs {
                        height: 90px;
                        width: auto;
                    }


                    @include when-sm {
                        height: auto;
                        width: 100%;
                    }


                    @include when-md {
                        height: 160px;
                        width: auto;
                    }


                    // @include when-lg {
                    //     height: auto;
                    // }
            
                }
            }

            .payments {
                max-width: 100%;
                height: auto;
                background: cdn("/images/webinar/payments.png") 0 0;
                background-size: cover;

                @include when-xs {
                    height: 50px;
                    margin-top: 30px;
                }


                @include when-sm {
                    width: 300px;
                    margin-top: auto;
                    height: 50px;
                }


                @include when-md {
                    width: auto;
                    width: 340px;
                    height: 60px;
                    margin-top: 155px;
                }


                @include when-md {
                    margin-top: 170px;
                }
            }
        }

        #lite,
        #premium {
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;

            @include when-xs {
                padding-right: $webinar-right-padding-xs;
                padding-left: $webinar-left-padding-xs;
            }


            @include when-sm-down {
                margin-top: 50px;
            }


            @include when-sm {
                padding: 0 #{$webinar-left-padding-sm};
                width: calc(50% - 10px);
            }


            @include when-md {
                padding: 0 #{$webinar-left-padding};
                margin-right: 20px;
                width: 340px;
            }


            // @include when-lg {
            //     width: 410px;
            //     margin-right: 30px;
            // }

            h2 {
                @include when-md {
                    color: $color-white;
                    margin-bottom: 20px;
                }
            }

            .content {
                position: relative;
                margin-top: 40px;
                width: 100%;

                @include when-md {
                    margin-top: 80px;
                }


                &:before {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    background-color: $color-white;
                    border: 1px solid #ccc;
                    top: 0;
                    height: 100%;

                    @include when-xs {
                        left: -#{$webinar-left-padding-xs};
                        right: -#{$webinar-left-padding-xs};
                    }


                    @include when-sm {
                        left: -#{$webinar-left-padding-sm};
                        right: -#{$webinar-left-padding-sm};
                    }


                    @include when-md {
                        left: -#{$webinar-left-padding};
                        right: -#{$webinar-left-padding};
                    }
                }
            }

            p, li {
                margin-bottom: 1em;
            }

            p.title {
                margin-top: 25px;

                @include when-sm {
                    margin-top: 40px;
                }


                span {
                    display: block;
                    line-height: 1.5em;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {}
            }

            a.monthly {
                position: relative;

                @include when-xs {
                    margin: 30px auto;
                }


                @include when-sm {
                    margin: 40px auto;
                }


                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -20px;
                    right: -20px;
                    height: 1px;
                    background-size: 22px 2px;
                    background-repeat: repeat-x;
                    background-image: linear-gradient(to right, #ddd 50%, rgba(255, 255, 255, 0) 10%);
                    z-index: -1;

                    // @include when-lg {
                    //     left: -30px;
                    //     right: -30px;
                    // }
            
                }
            }

            a.yearly {
                position: relative;

                @include when-xs {
                    margin: 40px auto;
                }


                @include when-sm {
                    margin: 50px auto;
                }
            }
        }
    }

    #hosting {
        position: relative;

        @include when-sm-down {
            margin: 80px 0;
        }


        @include when-md {
            margin: 100px 0;
            min-height: 230px;
        }


        // @include when-lg {
        //     margin: 150px 0;
        //     min-height: 296px;
        // }

        img {
            @include webinar-row-left;

            height: auto;

            @include when-xs {
                @include container-width;

                margin-top: 60px;
                width: calc(100% + 40px);
            }


            @include when-sm {
                position: absolute;
                width: 440px;
                top: 0;
                left: 0;
            }


            @include when-md {
                width: 680px;
            }


            // @include when-lg {
            //     width: auto;
            // }
    
        }

        h2,
        p {
            margin-left: auto;
            text-align: right;

            @include when-sm {
                max-width: 250px;
            }


            @include when-md {
                max-width: 350px;
            }


            // @include when-lg {
            //     max-width: 430px;
            // }
    
        }

        h2 {
            color: #8199C3;

            span {
                color: #A7B7D3;
            }
        }
    }

    #hosting + hr {
        width: 100vw;

        @include when-xs {
            margin-left: calc(-1 * (100vw - #{$container-xs}) / 2 - 25px);
            margin-right: calc(-1 * (100vw - #{$container-xs}) / 2 - 25px);
        }


        @media (max-width: 374px) {
            margin-left: -20px;
            margin-right: -20px;
        }

        @include when-sm {
            margin-left: calc(-1 * (100vw - #{$container-sm}) / 2 - 25px);
            margin-right: calc(-1 * (100vw - #{$container-sm}) / 2 - 25px);
        }


        @include when-md {
            margin-left: calc(-1 * (100vw - #{$container-md}) / 2 - 30px);
            margin-right: calc(-1 * (100vw - #{$container-md}) / 2 - 30px);
        }


        @include when-lg {
            margin-left: calc(-1 * (100vw - #{$container-lg}) / 2 - 30px);
            margin-right: calc(-1 * (100vw - #{$container-lg}) / 2 - 30px);
        }
    }

    #community {
        @include when-sm-down {
            margin-top: 60px;
        }


        @include when-md {
            margin-top: 90px;
            min-height: 230px;
        }


        // @include when-lg {
        //     margin-top: 120px;
        //     min-height: 296px;
        // }

        .top-content {
            text-align: center;

            h2 {
                margin-left: auto;
                position: relative;
                margin-bottom: 0;
                color: $color-light-grey;
                font-weight: $font-weight-light !important;
                text-transform: uppercase;

                @include when-xs {
                    max-width: calc((80% - 180px) / 2 + 180px);
                    padding: 30px 30px 30px 0;
                }


                @include when-sm {
                    max-width: calc((100% - 430px) / 2 + 430px);
                    padding: 60px calc((100% - 430px) / 2) 60px 0;
                }


                @include when-md {
                    max-width: calc((100% - 560px) / 2 + 560px);
                    padding: 100px calc((100% - 560px) / 2) 100px 0;
                }


                &:after {
                    @include webinar-row-right;

                    content: '';
                    background-color: #F6E1E3;
                    bottom: 0;
                    right: 0;
                    position: absolute;
                    z-index: -1;
                    opacity: .3;

                    @include when-sm-down {
                        left: -30px;
                        top: -30px;
                    }


                    @include when-md {
                        left: -120px;
                        top: -40px;
                    }
                }
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .bottom-content {
            position: relative;

            @include when-xs {
                padding: 60px 0 15px;
            }


            @include when-sm {
                padding: 50px 0 10px;
            }


            @include when-md {
                padding: 80px 0;
            }


            // @include when-lg {
            //     padding: 120px 0 100px;
            // }

            &:after {
                @include webinar-row-right;

                content: '';
                background-color: #F6E1E3;
                bottom: 0;
                top: 0;
                right: 0;
                position: absolute;
                z-index: -1;

                @include when-xs {
                    width: calc((100vw - #{$container-xs}) / 2 + 25px + #{$container-xs});
                }


                @include when-sm {
                    width: calc((100vw - #{$container-sm}) / 2 + 25px + #{$container-sm});
                }


                @include when-md {
                    width: calc((100vw - #{$container-md}) / 2 + 30px + #{$container-md});
                }


                @include when-lg {
                    width: calc((100vw - #{$container-lg}) / 2 + 30px + #{$container-lg});
                }
            }

            h2 {
                margin-bottom: 30px;
                color: #C99FA1;

                @include when-md {
                    max-width: 680px;
                }


                // @include when-lg {
                //     max-width: 825px;
                // }

                span {
                    color: #DBC0C3;
                }
            }

            p {
                @include when-md {
                    max-width: 600px;
                }


                // @include when-lg {
                //     max-width: 770px;
                // }
        
            }

            img {
                height: auto;

                @include when-sm-down {
                    max-width: 350px;
                    margin-left: auto;
                    display: block;
                    margin-top: 20px;
                }


                @include when-xs {
                    max-width: 240px;
                    margin-top: 40px;
                }


                @include when-md {
                    @include webinar-row-right;

                    max-width: 400px;
                    top: auto;
                    bottom: 30px;
                    position: absolute;
                    right: 0;
                }


                // @include when-lg {
                //     max-width: none;
                //     top: 100px;
                // }
        
            }

            .tag-bg {
                @include webinar-row-right;

                position: absolute;
                right: 0;
                bottom: 0;
                width: 110px;

                @include when-md {
                    width: 300px;
                }


                // @include when-lg {
                //     width: 372px;
                // }
        
            }
        }
    }

    #buy-now {
        @include webinar-container;
        @include make-container;

        position: relative;
        margin-bottom: 20px;
        text-align: center;

        @include when-xs {
            margin: 60px auto;
            padding: 30px 0 20px;
        }


        @include when-sm {
            margin: 90px auto;
            padding: 35px 0;
        }


        @include when-md {
            margin: 120px auto;
            padding: 50px 0;
        }


        &:before {
            content: '';
            background-color: #F8FF4D;
            right: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            z-index: -1;

            @include when-xs {
                width: calc((100vw - #{$container-xs}) / 2 + #{$webinar-left-padding-xs} + #{$container-xs});
            }


            @include when-sm {
                width: calc((100vw - #{$container-sm}) / 2 + #{$webinar-left-padding-sm} + #{$container-sm});
            }


            @include when-md {
                width: calc((100vw - #{$container-md}) / 2 + #{$webinar-left-padding} + #{$container-md});
            }


            @include when-lg {
                width: calc((100vw - #{$container-lg}) / 2 + #{$webinar-left-padding} + #{$container-lg});
            }
        }

        h2 {
            text-transform: uppercase;
            color: $color-nero;
            margin-bottom: 0;
        }

        p {
            line-height: .7em;

            @include when-xs {
                line-height: 1em;
            }
        }

        .btn {
            @include when-xs {
                margin-top: 20px;
            }


            @media (max-width: 374px) {
                font-size: 6vw;
            }

            @include when-sm {
                margin-top: 30px;
            }


            @include when-md {
                margin-top: 40px;
            }


            @include when-lg {}
        }
    }

    .section16 + #buy-now {
        margin-top: 0 !important;
    }

    #app {
        margin-bottom: 150px;
        position: relative;

        @include when-sm-down {
            margin: 70px 0;
            margin-bottom: 120px;
        }


        @include when-md {
            margin: 110px 0;
        }


        img {
            height: auto;

            @include when-xs {
                width: 100%;
                height: auto;
                margin-top: 20px;
            }


            @include when-sm {
                position: absolute;
                left: 0;
                width: 415px;
                top: 0;
            }


            @include when-md {
                top: -30px;
                width: 680px;
            }


            // @include when-lg {
            //     width: auto;
            // }
    
        }

        .tag {
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            margin: 0;
            width: fit-content;
            position: relative;
            font-size: 24px;
            display: block;

            @include when-sm-down {
                font-size: 16px;
            }


            @include when-xs {
                color: $color-white;
                letter-spacing: 0;
            }
        }

        h2,
        .tag {
            margin-left: auto;
            text-align: right;

            @include when-sm {
                max-width: 250px;
            }


            @include when-md {
                max-width: 350px;
            }
        }

        h2 {
            color: #8199C3;

            span {
                color: #A7B7D3;
            }
        }
    }
}
