.svg {
  display: inline-block;
  margin-right: 10px;
}

.no-margin {
  margin-right: 0;
}

.btn-border {
  border: 1px solid #3c3c3b;
  border-radius: 4px;

  &.gray {
    border: 1px solid #808080;
  }

  &.white {
    border: 1px solid $color-white;
  }
}

.btn-appstore {
  @include btn-appstore($color-nero);

  height: 37.792px;
}

.btn-appstore.gray {
  @include btn-appstore(#808080);

  height: 37.792px;
}

.btn-appstore.white {
  @include btn-appstore($color-white);

  height: 37.792px;
}

.btn-playstore {
  @include btn-playstore($color-nero);

  height: 37.792px;
}

.btn-playstore.gray {
  @include btn-playstore(#808080);

  height: 37.792px;
}

.btn-playstore.white {
  @include btn-playstore($color-white);

  height: 37.792px;
}

.btn-facebook {
  @include btn-facebook($color-nero);

  height: 37.792px;
}

.btn-email {
  @include btn-email($color-nero);

  height: 37.792px;
}

.icon-video-circle {
  @include icon-video-circle($color-nero);

  height: 50px;
}

.icon-video-circle.blue {
  @include icon-video-circle(#7190ba);

  height: 105.894px;
  width: 103.538px;
}

.icon-video-circle.online {
  @include icon-video-circle($color-online);

  height: 50px;
}

.icon-video-circle.offline {
  @include icon-video-circle($color-offline);

  height: 50px;
}

.icon-video-circle.pink {
  @include icon-video-circle(#D5808C);

  height: 50px;
}

.disabled .icon-video-circle {
  @include icon-video-circle($color-lighter-grey);

  height: 50px;
}

.icon-share-fb {
  @include icon-share-fb($color-nero);

  height: 41.016px;

  &.profile {
    width: auto;
    height: 26.035px;
  }
}

.icon-share-ig {
  @include icon-share-ig($color-nero);

  height: 32.776px;

  &.profile {
    height: 21.604px;
    width: auto;
  }
}

.icon-share-gplus {
  @include icon-share-gplus($color-nero);

  height: 32.776px;

  &.profile {
    height: 21.604px;
    width: auto;
  }
}

.icon-share-linkedin {
  @include icon-share-linkedin($color-nero);

  height: 32.776px;

  &.profile {
    height: 21.604px;
    width: auto;
  }
}

.icon-share-twitter {
  @include icon-share-twitter($color-nero);

  height: 32.776px;

  &.profile {
    height: 21.604px;
    width: auto;
  }
}

.icon-camera {
  @include icon-camera($color-nero);

  height: 17.049px;
}

.icon-video {
  @include icon-video($color-nero);

  height: 13.254px;
}

.icon-right-arrow {
  @include icon-right-arrow($color-nero);

  width: 4.805px;
}

.icon-right-arrow.white {
  @include icon-right-arrow($color-white);

  width: 4.805px;
}

%icon-right-arrow-bold {
  @include icon-right-arrow-bold($color-white);

  width: 7.586px;
}

.icon-live-video {
  @include icon-live-video($color-nero);

  height: 41.175px;
}

.btn-play {
  @include btn-play($color-nero);

  width: 40.259px;
}

.icon-cross {
  @include icon-cross($color-white);
}

.icon-live-video {
  @include icon-live-video($color-white);

  height: 46.526px;

  &.grey {
    @include icon-live-video($color-grey);

    height: 58.488px;
    width: auto;
  }

  &.light-grey {
    @include icon-live-video($color-light-grey);
  }
}

.icon-globe {
  @include icon-globe($color-white);

  height: 46.526px;

  &.grey {
    @include icon-globe($color-grey);

    height: 58.488px;
    width: auto;
  }

  &.light-grey {
    @include icon-globe($color-light-grey);
  }
}

.icon-247 {
  @include icon-247($color-white);

  height: 46.526px;

  &.grey {
    @include icon-247($color-grey);

    height: 58.488px;
    width: auto;
  }

  &.light-grey {
    @include icon-247($color-light-grey);
  }
}

.icon-ppm-circle {
  @include icon-ppm-circle($color-white);

  height: 46.526px;

  &.grey {
    @include icon-ppm-circle($color-grey);

    height: 58.488px;
    width: auto;
  }

  &.light-grey {
    @include icon-ppm-circle($color-light-grey);
  }
}

.icon-ppm {
  @include icon-ppm($color-grey);

  height: 82.446px;

  &.grey {
    @include icon-ppm($color-grey);

    height: 58.488px;
    width: auto;
  }
}

.icon-cat {
  @include icon-cat($color-white);

  width: 47.69px;

  &.grey {
    @include icon-cat($color-grey);

    height: 58.488px;
    width: auto;
  }

  &.light-grey {
    @include icon-cat($color-light-grey);
  }

  &.big {
    width: 74.115px;
    height: 75.312px;
  }
}

.icon-close {
  @include icon-close($color-nero);

  width: 15.64px;
}

.icon-mail {
  @include icon-mail($color-white);

  height: 13.796px;
}

.icon-fb {
  @include icon-fb($color-white);

  width: 10.09px;
}

.icon-google {
  @include icon-google($color-white);

  width: 18.58px;
}

.icon-linkedin {
  @include icon-linkedin($color-white);

  height: 18.697px;
}

.icon-twitter {
  @include icon-twitter($color-white);

  // height: 17.011px;
}

.icon-close.white {
  @include icon-close($color-white);
}

.icon-info {
  @include icon-info($color-white);
}

.icon-open-quote {
  @include icon-open-quote($color-light-pink);

  height: 33.227px;
}

.icon-close-quote {
  @include icon-close-quote($color-light-pink);

  height: 33.227px;
}

.icon-video-interaction {
  @include icon-video-interaction($color-light-pink);

  height: 71.758px;
}

.icon-pointer-right {
  @include icon-pointer-right($color-white);
}

.linkedin-logo {
  @include linkedin-logo($color-nero);

  height: 20.115px;
}

.facebook-logo {
  @include facebook-logo($color-nero);

  height: 15.37px;
}

.instagram-logo {
  @include instagram-logo($color-nero);

  height: 22.471px;
}

.youtube-logo {
  @include youtube-logo($color-nero);

  height: 18.199px;
}

.twitter-logo {
  @include twitter-logo($color-nero);

  height: 14.791px;
}

.icon-play {
  @include icon-play($color-nero);

  height: 52px;
}

.icon-menu {
  @include icon-menu($color-nero);

  height: 16.27px;
}

.icon-bullet-check {
  @include icon-bullet-check($color-primary);
}

.icon-email {
  @include icon-email($color-lighter-grey);
}

.icon-facebook {
  @include icon-facebook($color-lighter-grey);
}

.icon-twitter {
  @include icon-twitter($color-lighter-grey);
}

.icon-linkedin {
  @include icon-linkedin($color-lighter-grey);
}

.icon-people {
  @include icon-people($color-lighter-grey);
}

.icon-download {
  @include icon-download($color-lighter-grey);
  @include when-sm-down {
    width: 25.904px;
    height: 25.89px;
  }
}

.icon-invite {
  @include icon-invite($color-lighter-grey);
  @include when-sm-down {
    width: 26.384px;
    height: 29.488px;
  }
}

.icon-share {
  @include icon-share($color-lighter-grey);
  @include when-sm-down {
    width: 27.071px;
    height: 26.91px;
  }
}

.icon-url {
  @include icon-url($color-lighter-grey);
  @include when-sm-down {
    width: 27.49px;
    height: 27.49px;
  }
}

.icon-pricing {
  @include icon-pricing($color-lighter-grey);
  @include when-sm-down {
    width: 34.48px;
    height: 34.48px;
  }
}

.icon-broadcast {
  @include icon-broadcast($color-lighter-grey);
  @include when-sm-down {
    width: 33.11px;
    height: 33.11px;
  }
}

.icon-record {
  @include icon-record($color-lighter-grey);
  @include when-sm-down {
    width: 25.63px;
    height: 25.63px;
  }
}

.icon-screen {
  @include icon-screen($color-lighter-grey);
  @include when-sm-down {
    width: 24.67px;
    height: 23.48px;
  }
}

.icon-hand {
  @include icon-hand($color-lighter-grey);
  @include when-sm-down {
    width: 20.56px;
    height: 30.02px;
  }
}

.icon-webinar {
  @include icon-webinar($color-nero);

  &.light-grey {
    @include icon-webinar($color-light-grey);
  }
}
