[data-to-root].home {
  // padding-top: 54px;

  @include when-md {
    // padding-top: 75px;
  }


  @include when-lg {
    // padding-top: 98px;
  }


  %home-container {
    @include when-xs {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }


    @include when-md {
      padding-right: 30px !important;
      padding-left: 30px !important;
    }


    @include when-lg {
      padding-right: 50px !important;
      padding-left: 50px !important;
    }
  }

  %home-container-bg {
    @include when-xs {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }


    @include when-md {
      padding-right: 45px !important;
      padding-left: 45px !important;
    }


    @include when-lg {
      padding-right: 65px !important;
      padding-left: 65px !important;
    }
  }

  &.modal-open {
    overflow: hidden;

    #main-container > .banner {
      z-index: auto;
    }
  }

  .navbar {
    &.fixed {
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      transition: opacity 0.3s;
      opacity: 1;
      z-index: 1000;
      display: block;
    }

    @include when-xs {
      &.fixed {
        -webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        transition: opacity 0.3s;
        opacity: 1;
        z-index: 1000;
        display: block !important;

        .smartsearch {
          > ul > li
          p.group-title {
            font-size: 14px !important;
            font-weight: bold;
            padding: 5px 5px 5px 10px;
          }

          ul.results {
            li {
              white-space: normal !important;
              color: #333333 !important;
              padding: 5px 5px 5px 10px;
              font-size: 14px !important;

              &:hover {
                background-color: #F9F9F8 !important;
                color: inherit !important;
              }
            }
          }
        }
      }
    }
  }

  #main-container {
    position: relative;
    padding-top: 0;
    overflow-x: hidden;

    @include when-xs {
      // margin-top: 0;
    }


    @include when-sm {
      // margin-top: -54px;
    }


    @include when-md {
      //$ margin-top: -75px;
    }


    @include when-lg {
      // margin-top: -98px;
    }


    h1#welcome-title {
      display:table-row;
      padding-top:140px;
      > * {
        display:table-cell;
        position:relative;
      }
      > span:first-child {
        text-align: right;
        &::before {
          @include icon-live-video($color-light-grey,100px);
          top:-106px;
          right:0px;
        }
      }
      > span:last-child {
        text-align: left;
        &::before {
          @include icon-webinars($color-light-grey,100px);
          top:-116px;
        }
      }
      > span:first-child,span:last-child {
        font-family: bebasNeueBold;
        font-size: 22px;
        font-weight: $font-weight-black;
        width:50%;
        &::before {
          display:block;
          position:absolute;
        }
      }

      > span:nth-child(2) {
        font-weight:$font-weight-normal;
        font-family: proxima;
        text-transform: none;
        padding-left:35px;
        padding-right:75px;
        white-space: nowrap;
        position:relative;
        top:-80px;
        &::before,&::after {
          content:' - ';
        }
      }

      > span:last-child {
      }
    }

    h1,
    h2 {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-black !important;
      min-width: fit-content;
      font-size: 23px;
      margin: 0 auto 25px;
      color: $color-white;
      text-transform: uppercase;
      text-align: center;

      @include when-md {
        font-size: 34px;
      }
    }

    @include when-xs {
      h1 {
        padding-bottom: 20px;
        border-bottom: 1px solid $color-white;
        font-size: 16px;
        margin: 0 0 20px;
      }

      h2 {
        font-size: 22px;
        margin: 0 0 20px;
      }
    }


    h3,
    .section-title {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-bold !important;
      font-size: 28px;
      color: $color-nero;
      text-transform: none;
      margin: 0 0 20px;

      @include when-md {
        font-size: 36px;
      }


      @include when-lg {
        font-size: 44px;
      }
    }

    h4 {
      font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
      color: $color-white;
      line-height: .9;
      font-size: 80px;
      margin: 0;

      .h4-subtitle {
        font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
        background-color: $color-nero;
        color: $color-white;
        line-height: 1;
        font-size: 16px;
        height: 32px;
        padding: 10px;
        width: fit-content;
        display: block;
        margin-top: -18px;
        position: relative;
      }
    }

    %home-subtitle,
    .subtitle {
      font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
      color: $color-white;
      line-height: 1;
      font-size: 16px;
      height: 28px;
      padding: 8px;

      @include when-xs {
        font-size: 13px;
        min-height: 28px;
        height: auto;
        letter-spacing: 1px;
      }


      @include when-md {
        font-size: 20px;
        height: 36px;
        padding: 10px;
      }


      @include when-lg {
        font-size: 22px;
        height: 40px;
        padding: 12px;
      }
    }

    .section1 {
      z-index: 1000;
      position: relative;

      .bg-center {
        position: relative;

        @include when-xs {
          height: 629px;
          display: flex;
          flex-flow: column;
          background-image: cdn("/images/home/new-home-mobile/top3_mob.jpg") !important;
          background-position: center bottom 99px;
        }
      }

      .bg-center,
      .bg-left,
      .bg-right {
        @extend %home-container;

        @include make-container;

        background-size: cover;
        background-position: center top;
        max-width: 730px;

        @include when-md {
          max-width: 100%;
        }
      }

      .bg-left,
      .bg-right {
        height: 100%;
        position: absolute;
        z-index: 2;

        @include when-xs {
          background-color: $color-white;
          background-image: none !important;
        }
      }

      .bg-left {
        right: 100%;
      }

      .bg-right {
        left: 100%;
      }

      .mobile-header {
        border-bottom: 1px solid $color-white;
        padding-bottom: 10px;
        height: 52px;
        justify-content: space-between;
        align-items: flex-end;

        @include when-xs {
          display: flex !important;
        }
      }

      .home-navbar {
        position: relative;
        height: 54px;
        align-items: flex-end;
        padding-bottom: 12px;
        border-bottom: 1px solid $color-white;
        z-index: 9;

        @extend %flex;

        @include when-md {
          height: 75px;
          padding-bottom: 15px;
        }


        @include when-lg {
          height: 98px;
          padding-bottom: 22px;
        }


        &:before {
          content: '';
          background-color: #1D1D1B;
          opacity: .33;
          width: 100vw;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }

        ul.left-content {
          a {
            display: flex;

            .app-link {
              height: 18px;
              width: auto;

              @include when-md {
                height: 28px;
              }


              @include when-lg {
                height: 38px;
              }
            }
          }
        }

        .brand {
          position: relative;

          img {
            height: 18px;
            width: auto;

            @include when-md {
              height: 28px;
            }


            @include when-lg {
              height: 38px;
            }
          }
        }

        .navbar-content {
          margin-left: 45px;
          width: 100%;
          justify-content: space-between;
          position: relative;

          @extend %flex;

          ul {
            margin-bottom: 0;
            padding-inline-start: 0;
            list-style-type: none;
          }

          .left-content,
          .right-content {
            align-items: flex-end;
            display: flex;
          }

          .app-link {
            margin-right: 10px;
          }

          .right-content {
            font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
            color: $color-white;
            margin-left: auto;

            .badge,
            .avatar {
              vertical-align: baseline;
              margin-bottom: -3px;
            }

            .user-balance {
              display: inline-block;
              padding-right: 5px;
              vertical-align: text-bottom;
              font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
            }

            .avatar {
              width: 26px;
              padding-bottom: 26px !important;
              background-size: cover;
              display: inline-block;
              vertical-align: text-bottom;

              @include when-lg {
                width: 37px;
                padding-bottom: 37px !important;
              }
            }

            li {
              margin-right: 15px;
              font-size: 13px;
              line-height: .6;

              a {
                letter-spacing: 1px;
              }

              @include when-md {
                margin-right: 20px;
                font-size: 18px;
              }


              @include when-lg {
                margin-right: 30px;
                font-size: 22px;
              }


              &:last-child {
                margin-right: 0;
              }

              &:hover {
                color: $color-nero;
                position: relative;
                z-index: 0;

                &:before {
                  background: $color-white;
                  content: "";
                  bottom: -12px !important;
                  left: 0;
                  right: 0;
                  top: -12px !important;
                  width: 100%;
                  position: absolute;
                  z-index: -1;

                  @include when-md {
                    bottom: -15px !important;
                    top: -15px !important;
                  }


                  @include when-lg {
                    bottom: -22px !important;
                    top: -22px !important;
                  }
                }
              }
            }

            .language-select {
              button {
                background-color: transparent;
                border: none;
                padding: 0;
                text-align: left;
              }

              .icon-right-arrow {
                @include icon-right-arrow($color-white);

                transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                height: auto;
                width: 5.3px;
                margin: 0 7px;

                @include when-md {
                  width: 6.3px;
                }


                @include when-lg {
                  width: 8.3px;
                }
              }

              .dropdown-menu {
                border: none;
                padding: 0;
                position: absolute;
                left: 0;
                border-top: 2px solid #E5E5E5;
                top: calc(100% + 5px);
                color: $color-nero;
                width: max-content;
                min-width: 100%;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.06);

                .dropdown-item {
                  padding: 10px 5px 10px 0;
                  border-bottom: 1px solid $color-closer-white;
                  background-color: $color-white;
                  font-size: 12px;
                  font-family: "proxima", "Helvetica", "Arial", sans-serif;

                  @include when-md {
                    font-size: 14px;
                  }


                  &:hover {
                    background-color: $color-off-white;
                  }

                  .spacer {
                    font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
                    font-size: 13px;
                    margin-right: 23px;
                    visibility: hidden;

                    @include when-md {
                      font-size: 18px;
                    }


                    @include when-lg {
                      font-size: 22px;
                    }
                  }
                }
              }

              &.open,
              &:hover {
                color: $color-nero;
                position: relative;
                z-index: 0;

                &:before {
                  background: $color-white;
                  content: "";
                  bottom: -12px !important;
                  left: 0;
                  right: 0;
                  top: -12px !important;
                  width: 100%;
                  position: absolute;
                  z-index: -1;

                  @include when-md {
                    bottom: -15px !important;
                    top: -15px !important;
                  }


                  @include when-lg {
                    bottom: -22px !important;
                    top: -22px !important;
                  }
                }

                .icon-right-arrow {
                  @include icon-right-arrow($color-nero);

                  height: auto;
                  width: 5.3px;

                  @include when-md {
                    width: 6.3px;
                  }


                  @include when-lg {
                    width: 8.3px;
                  }
                }
              }
            }

            a {
              color: inherit;
            }
          }
        }
      }

      .input-location {
        width: 100%;
        float: none !important;
        top: auto;

        .navbar-left {
          height: auto;
        }

        .form-holder {
          text-align: center;

          .nav-bar-search-wrapper {
            max-width: 464px;
            height: 58px;
            background: $color-white;

            @include when-md {
              height: 85px;
              max-width: 690px;
            }


            .input-group {
              padding: 1px 15px;
              height: 100%;
              display: flex;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              flex-direction: row;

              @include when-xs {
                flex-direction: row-reverse;
              }


              .smartsearch {
                top: 100%;
                max-height: calc(100vh - 165px);
                height: fit-content;
                min-width: 100%;

                @include when-md {
                  max-height: calc(100vh - 263px);
                }


                ul {
                  margin-bottom: 0;
                }
              }

              .form-control {
                height: 100%;
                padding: 0px 0px;
                font-family: "proxima", "Helvetica", "Arial", sans-serif;
                font-size: 20px;
                font-weight: normal;
                color: $color-nero;
                line-height: 100%;
                height: 100%;

                ::-webkit-input-placeholder {
                  font-size: 20px;
                }

                ::-moz-placeholder {
                  font-size: 20px;
                }

                :-ms-input-placeholder {
                  font-size: 20px;
                }

                ::placeholder {
                  font-size: 20px;
                }

                @include when-lg {
                  font-size: 30px;

                  ::-webkit-input-placeholder {
                    font-size: 30px;
                  }

                  ::-moz-placeholder {
                    font-size: 30px;
                  }

                  :-ms-input-placeholder {
                    font-size: 30px;
                  }

                  ::placeholder {
                    font-size: 30px;
                  }
                }
              }
            }
          }
        }

        .form-text {
          background: $color-nero;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          color: $color-white;
          font-size: 13px;
          letter-spacing: 1px;
          padding-top: 6px;
          height: 27px;

          @include when-md {
            font-size: 22px;
            padding-top: 8px;
            height: 40px;
          }


          &.hide {
            display: block !important;
          }
        }

        &.mobile {
          margin-top: auto;
          margin-bottom: 70px;
        }
      }

      .section1-inner {
        height: 246px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include when-xs {
          height: auto;
          margin-top: auto;
        }


        @include when-md {
          height: 360px;
        }


        @include when-lg {
          height: 460px;
        }


        .scroll-container {
          display: flex;
          justify-content: flex-end;
          padding-top: 20px;

          .scroll {
            writing-mode: vertical-rl;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: vertical-rl;
            transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            white-space: nowrap;
            overflow: visible;
            cursor: pointer;
            height: 0;
            padding: 0;
            color: $color-white;
          }
        }

        .bottom-content {
          @include when-xs {
            margin-top: auto;
            display: flex;
            flex-direction: column;
          }


          h1 {
            background-color:#f00 !important;
            margin-bottom: 40px;
            position: relative;
            z-index: 1;
            width: fit-content;

            @include when-xs {
              margin-bottom: 20px;
              width: 100%;
            }


            .generic-wrapper,
            .expert-wrapper {
              &:before {
                content: " ";
                background-color: $color-nero;
                opacity: .33;
                width: 100vw;
                height: 168px;
                right: 1em;
                top: -20px;
                position: absolute;
                z-index: -1;

                @include when-md {
                  height: 239px;
                  top: -40px;
                }
              }
            }

            .expert-wrapper {
              &:before {
                height: 193px;

                @include when-md {
                  height: 276px;
                }
              }
            }
          }

          .bottom-text {
            padding-bottom: 25px;
            font-size: 16px;
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold !important;
            color: $color-white;
            letter-spacing: 1px;
          }

          .input-location {
            position: relative;
            z-index: 1;

            @include when-xs {
              order: -1;
              height: 57px;
              max-width: 345px;
              margin: 0 auto 97px;
            }
          }
        }
      }

      @include when-xs {
        .app-links {
          @include make-row;

          display: flex;
          align-items: stretch;

          .app-links-text {
            min-height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            min-width: min-content;
            padding: 5px;
            background-color: $color-nero;
            text-align: center;

            .icon-right-arrow {
              @include icon-right-arrow($color-white);

              width: 4.805px;
            }
          }

          ul {
            width: calc(100% - 70px);
            margin: 0;
            background: white;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            li {
              max-width: calc(50% - 10px);
              padding: 0;
            }

            a {
              width: 100%;
              display: block;
              line-height: 0;
            }

            .btn-appstore,
            .btn-playstore {
              width: 100%;
              height: auto;
            }
          }
        }

        .subtitle {
          @include make-row;

          display: block;
          background-color: $color-soft-blue;
          color: $color-nero;
          text-align: center;

          span {
            color: $color-white;
          }
        }
      }
    }

    .section2 {
      @extend %home-container;

      @include make-container;

      margin-top: 120px;
      margin-bottom: 50px;

      @include when-xs {
        margin-top: 100px;
      }


      @include when-md {
        margin-top: 140px;
      }


      .section2-inner {
        max-width: 500px;
        margin: auto;
        text-align: center;

        h2 {
          color: $color-light-grey;
          margin: 0 auto 20px;

          @include when-xs {
            font-size: 16px;
          }
        }

        @include when-md {
          max-width: none;
          display: flex;

          @include make-row;
        }


        .left-content,
        .right-content {
          @include make-md-column(6);
        }

        .left-content {
          @include when-md {
            display: flex;
            flex-flow: column;
            justify-content: center;
          }


          p {
            color: #4D4D4D;
          }

          .item-container {
            display: flex;
            justify-content: center;
            margin-top: 15px;

            @include when-xs {
              margin: 40px 0 60px;
              justify-content: space-around;
            }


            @include when-md {
              margin-top: 25px;
            }


            .item {
              margin: 0 13px;

              @include when-xs {
                margin: 0;
              }


              .icon-live-video,
              .icon-247,
              .icon-globe {
                height: 60px;
                width: auto;
                display: inline-block;

                @include when-xs {
                  height: 46.885px;
                }


                @include when-md {
                  height: 80px;
                }
              }

              .subtitle {
                margin-top: 5px;
                color: $color-light-grey;

                @include when-md {
                  margin-top: 10px;
                }
              }

              h1 {
                margin-bottom: 0;
                text-align: right;
              }
            }
          }
        }

        .right-content {
          text-align: center;
          margin: auto;
          margin-top: 60px;

          @include when-xs {
            margin-bottom: 60px;
          }


          @include when-md {
            margin-top: auto;
            max-width: fit-content;
          }


          img {
            width: 100%;
            height: auto;
            max-width: 70%;

            @include when-xs {
              max-width: 220px;
            }


            @include when-lg {
              max-width: max-content;
            }
          }

          .subtitle {
            position: relative;
            background-color: $color-nero;

            @include when-xs {
              &:after {
                content: '';
                height: 100%;
                background-color: $color-nero;
                width: 50vw;
                position: absolute;
                top: 0;
                left: 100%;
              }
            }


            @include when-md {
              background-color: none;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: $color-nero;
                z-index: -1;
                width: 300%;
              }
            }
          }
        }
      }

      &.webinar {
        .item-container {
          align-items: flex-end;

          .subtitle {
            padding: 0;
            margin-top: 30px !important;

            @include when-sm-down {
              margin-top: 40px !important;
            }
          }

          .item:first-child {
            text-align: right;

            .icon-live-video {
              @include when-sm-down {
                width: 71.9px;
                height: 49.53px;
              }
            }
          }

          .item:last-child {
            text-align: left;

            .icon-webinar {
              @include when-sm-down {
                width: 59.94px;
                height: 59.94px;
              }
            }
          }

          span {
            align-self: center;
            color: $color-light-grey;
            font-size: 20px;
            flex: 1;
            max-width: 100px;
            height: 96px;

            @include when-sm-down {
              font-size: 14px;
              height: 60px;
            }
          }
        }

        .section2-inner .right-content img {
          width: 312px;
          height: auto;

          @include when-sm-down {
            width: 220px;
          }
        }
      }
    }

    .section-webinar {
      @extend %home-container;

      @include make-container;

      margin-top: 120px;
      margin-bottom: 100px;

      @include when-xs {
        margin-bottom: 70px;
        margin-top: 80px;
      }


      @include when-md {
        margin-top: 140px;
        margin-bottom: 80px;
      }


      .section-inner {
        margin: auto;
        text-align: center;

        @include when-md {
          display: flex;
          @include make-row;
        }
      }

      .left-content,
      .right-content {
        @include make-md-column(6);
      }
      
      .left-content {
        @include when-sm-down {
          padding-bottom: 100px;
        }


        &:after {
          content: '';
          position: absolute;
          background: #F8FF4D;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: -1;

          @include when-sm-down {
            left: calc(-1 * (100vw - 100%) / 2);
            width: 100vw;
          }


          @include when-md {
            left: calc(0px - 30px - (100vw - #{$container-md}) / 2);
          }


          @include when-lg {
            left: calc(0px - 50px - (100vw - #{$container-lg}) / 2);
          }
        }

        img {
          width: 144px;
          height: auto;
          display: block;

          @include when-sm-down {
            margin-left: auto;
          }


          @include when-md {
            margin-right: auto;
            margin-bottom: 30px;
            width: auto;
          }
        }
      }

      .right-content {
        @include when-sm-down {
          padding-bottom: 50px;
        }


        &:after {
          content: '';
          position: absolute;
          background: #F8FF4D;
          top: 100%;
          height: 60px;
          z-index: -1;
          opacity: .5;
          right: calc(-1 * (100vw - 200%) / 2);
          width: 95vw;

          @include when-sm-down {
            right: calc(100% - 310px);
            left: calc(0px - 10px - (100vw - #{$container-sm}) / 2);
            width: auto;
            height: auto;
            top: 0;
            bottom: 0;
          }


          @include when-xs {
            left: calc(0px - 10px - (100vw - 100%) / 2);
            right: 60px;
          }

          @include when-md {
            content: none;
          }
        }

        .h2{
          display: block;
          
          &:hover {
            .btn.webinar{
              color: $color-nero;
              background-color: $color-white;
              box-shadow: 0px 0px 0px 1px $color-nero;
            }
          }
        }

        .btn.webinar {
          position: absolute;
          background-color: $color-nero;
          font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
          line-height: .4;
          bottom: 0;
          right: 0;

          

          @include when-sm-down {
            width: 90px;
            right: auto;
            left: 100%;
          }


          @include when-xs {
            font-size: 20px;
            width: 50px;
            height: 37px;
            text-align: center;
            line-height: 28px;
          }


          @include when-sm {
            font-size: 36px;
            padding: 20px 20px 11px;
          }


          @include when-lg {
            padding: 20px 20px 11px;
          }
        }

        .img {
          background-image: cdn("/images/home/theone-webinars.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 0;
          position: absolute;
          right: 10px;

          @include when-sm-down {
            width: 100%;
            bottom: calc(100% - 50px);
            padding-top: 18%;
            right: 50%;
            transform: translateX(50%);
          }


          @include when-xs {
            width: 100vw;
            padding-top: initial;
            height: 134px;
            background-position: center;
          }


          @include when-md {
            padding-top: 28%;
            width: calc(#{$container-md} - 300px);
            bottom: -40px;
          }


          @include when-lg {
            padding-top: 29%;
            width: calc(#{$container-lg} - 360px);
            bottom: -40px;
          }
        }
      }

      h2 {
        color: $color-nero;
        margin-bottom: 30px;

        @include when-md {
          margin-bottom: 70px;
        }
      }

      .h2 {
        color: $color-light-grey;
        padding-right: calc(100% - 380px);
        position: relative;
        padding-left: 30px;

        @include when-sm-down {
          max-width: 300px;
          padding-right: 60px;
          padding-left: 0;
          padding-top: 85px !important;
          color: #525252;
        }


        @include when-xs {
          margin-right: 50px;
          padding-right: 10px;
        }
      }

      h2, .h2 {
        padding-top: 35px;
        font-weight: $font-weight-bold !important;
        text-transform: none;
        text-align: left;

        @include when-md {
          padding-top: 60px;
        }
      }
      .bottom-content{

        $slider-btn-sm :35px;
        $slider-btn-md :50px;
        $slider-btn-lg :75px;
        margin-left: auto;

        @include when-sm {
          @include make-sm-column(12);
        }
        @include when-md {
          @include make-md-column(10);
        }

        @include when-lg {
          @include make-lg-column(9);
        }

        &:after {
          @include when-md {
            content: '';
            position: absolute;
            background: #F8FF4D;
            top: 0;
            z-index: -1;
            opacity: .5;
            width: 95vw;
            height: 55%;
            right: calc(0px - 40px - (100vw - #{$container-md}) / 2);
          }

          @include when-lg {
            height: 60%;
            right: calc(0px - 40px - (100vw - #{$container-lg}) / 2);
          }
        }

        .section-title{
          display: flex;
          width: auto;
          padding: 100px 0 50px;
          justify-content: flex-end;

          @include when-sm-down {
            max-width: none;
            padding-right: 0;
            padding-left: 0;
            padding-top: 20px !important;
            padding-bottom: 20px !important;
          }
          @include when-xs {
            display: block;
            margin-right: 0;
          }

          @include when-md {
            padding: 70px 0 20px;
          }

          @include when-lg {
            padding: 100px 0 50px;
          }

          .upcoming-btn{
            font-size: 18px;
            height: 35px;
            line-height: 40px;
            padding: 0 10px;
            background-color: $color-nero;
            margin-left: 30px;

            @include when-xs {
              margin-top: 20px;
              max-width: 250px;
              display: block;
            margin-left: auto;
            margin-right: auto;
            }
            @include when-md {
            height: 40px;
            font-size: 24px;
              margin-left: 90px;
            }

            
            &:hover{
              background-color: $color-white;
              color:  $color-nero;
            }

          }
        }

        .webinars-container{
          margin: auto;
          position: relative;

           @include when-xs {
            max-width: 250px;
           }
           @include when-sm {
              // padding-left:$slider-btn-sm;
              // padding-right:$slider-btn-sm;
              display: flex;
              margin: auto -5px;
            }

            @include when-md {
              // padding-left:$slider-btn-md;
              // padding-right:$slider-btn-md;
            }

            @include when-lg {
              // padding-left:$slider-btn-lg;
              // padding-right:$slider-btn-lg;
            }

          &:before,
          &:after{
            display: table;
            content: " ";
          }

          &:after{
            clear: both;
          }
        }

        .webinars {
          margin: auto;
          position: relative;

          @include when-xs {
            width: 100%;
          }
          @include when-sm {
            width: calc(100% - (#{$slider-btn-sm} * 2) );
          }

          @include when-md {
            width: calc(100% - (#{$slider-btn-md} * 2) );
          }

          @include when-lg {
            width: calc(100% - (#{$slider-btn-lg} * 2) );
          }
        }
      
        .slick-arrow{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: inline-block;
          cursor: pointer;
          border: none;
          font-size: 0;
          background: none;
          height: 100%;
          padding: 0;

          @include when-xs {
          }

          @include when-sm {
            right: -#{$slider-btn-sm};
            width: $slider-btn-sm;
          }

          @include when-md {
            right: -#{$slider-btn-md};
            width: $slider-btn-md;
          }

          @include when-lg {
            right: -#{$slider-btn-lg};
            width: $slider-btn-lg;
          }

          &:before{
            content: '';
            background-image: url(svg-encode(icon-right-arrow($color-grey)));
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            display: block;

            @include when-sm {
              background-size: 20px;
            }

            @include when-md {
              background-size: 25px;
            }

            @include when-lg {
              background-size: 30px;
            }
          }

          &:hover{
            &:before{
              background-image: url(svg-encode(icon-right-arrow($color-primary)));
            }
          }

          &.slick-prev{
            right: auto;

            @include when-sm {
              left: -#{$slider-btn-sm};
            }

            @include when-md {
              left: -#{$slider-btn-md};
            }

            @include when-lg {
              left: -#{$slider-btn-lg};
            }

            &:before{
              transform: rotate(180deg);
            }
          }
        }

        .slick-dots{

          position: absolute;
          bottom: -25px;
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          text-align: center;

          li{
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button{
              display: none;
            }

            &:before{
              font-family: 'slick';
              font-size: 20px;
              line-height: 20px;
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              content: '•';
              text-align: center;
              opacity: .25;
              color: $color-nero;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            &.slick-active{
              &:before{
                opacity: 1;
              }
            }
          }
        }

        .webinar-content {
          font-size: 14px;
          margin-bottom: 5px;
          line-height: 1.2;
          color: #4d4d4d;
          width: 100%;
          display: block;
          

          @include when-sm {
          float: left;
            padding-left: 5px;
          padding-right: 5px;
          }
          @include when-md {
            font-size: 18px;
          }

          @include when-lg {
          }

          &:hover {
            .webinar-desc {
              background-color: $color-light-pink;
            }

            .btn-more {
              visibility: unset;
            }
          }

          .btn-more {
            font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
            margin: 0;
            position: absolute;
            bottom: 0;
            font-size: 20px;
            height: 36px;
            line-height: 36px;
            display: block;
            color: $color-white;
            background-color: $color-nero;
            padding: 0 20px;
            width: calc(100% - 30px);
            text-align: center;
            visibility: hidden;

            @include when-sm-down {
              padding: 0 13px;
              font-size: 13px;
              height: 27px;
              line-height: 27px;
              width: calc(100% - 20px);
            }
          }

          .image-container {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: $color-nero;
            width: 100%;
            height: 0px;
            padding-bottom:calc( calc(350/620) * 100%);

            &.video {
              &::after {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                @include icon-play($color-nero);
              }
            }
          }

          .webinar-img {
            width: 100%;
            visibility: hidden;
          }

          .webinar-desc {
            padding: 15px 10px 27px;
            position: relative;
            height: calc(100% - 145px);
            text-align: left;

            @include when-md {
              padding: 20px 10px 38px;
              height: calc(100% - 155px);
            }

            @include when-lg {
              height: calc(100% - 175px);
            }
          }

          

          .title, .price {
            font-weight: $font-weight-bold;
          }

          .price, .time {
            display: inline;
          }

          .price {
            &:before {
              content: '-';
              font-weight: $font-weight-normal;
            }
          }
        }
      }
    }

    .section3 {
      @extend %home-container-bg;

      @include make-container;

      text-align: center;
      position: relative;

      .section3-inner {
        @include make-row;

        position: relative;

        @extend %flex;
      }

      .right-content {
        padding-top: 80px;
        position: relative;
        width: 400px;
        margin-left: auto;
        min-width: fit-content;

        &:before {
          content: '';
          background: cdn("/images/home/bg-generic-section3.jpg") no-repeat;
          background-size: cover;
          position: absolute;
          background-position: center right 60%;
          top: 0;
          bottom: 0;
          width: 100vw;
          z-index: -1;
          display: block;
          left: calc(-1 * (100vw - 100%) / 2);

          @include when-xs {
            background: cdn("/images/home/guy_mob.jpg") no-repeat;
            width: 100%;
            left: 0;
            background-position: center left;
            background-size: cover;
          }


          @include when-md {
            right: 0;
            left: auto;
          }
        }

        @include make-md-column(6);

        @include when-md {
          padding-top: 140px;
          margin-left: auto;
          min-width: auto;
        }


        img {
          margin: 35px auto 25px;
          max-width: 346px;
          width: 100%;
          height: auto;

          @include when-xs {
            max-width: 221px;
            margin: 50px auto 100px;
          }
        }

        .stats-container {
          display: flex;
          justify-content: center;
          position: relative;
          margin-top: 25px;

          @include when-xs {
            margin-left: auto !important;
            width: fit-content;
          }


          &:after {
            content: '';
            background-color: $color-white;
            position: absolute;
            bottom: -1px;
            height: 35px;
            z-index: -1;
            width: 70vw;
            left: 0;

            @include when-xs {
              width: auto;
              height: 28px;
              right: -11px;
              z-index: 0;
            }
          }

          .stats {
            font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
            font-size: 60px;
            color: $color-white;
            line-height: .7;
            margin-right: 30px;

            strong {
              font-weight: inherit;
            }

            @include when-xs {
              padding-left: 5px;
              font-size: 46px;
              line-height: .5;
              z-index: 1;
              margin-right: 0;
            }


            @include when-md {
              margin-right: 60px;
              line-height: .4;
            }


            @include when-lg {
              line-height: .3;
            }


            &:last-child {
              margin-right: 0;
            }

            .subtitle {
              color: $color-light-grey;
            }
          }
        }
      }
    }

    .section4 {
      @extend %home-container;

      @include make-container;

      margin-top: 100px;

      .section4-inner {
        max-width: 600px;
        margin: auto;

        @include when-md {
          @include make-row;

          max-width: none;
          display: flex;
        }
      }

      .left-content {
        @include make-md-column(8);
      }

      .right-content {
        @include make-md-column(4);
      }

      .left-content {
        align-content: center;
        display: flex;
        flex-direction: column;
        margin: auto;

        @include when-md {
          display: flex;
        }


        .section-title {
          text-align: left;
          color: $color-light-pink;

          span {
            color: $color-primary;
            display: block;
          }
        }

        p {
          @include when-md {
            @include make-row;
            @include make-md-column(8);
          }


          span {
            color: $color-primary;
            font-weight: $font-weight-bold;
          }

          &:after {
            @include when-md {
              content: '';
              background-color: $color-bright-pink;
              position: absolute;
              top: 1em;
              left: calc(100% + 60px);
              width: 70vw;
              height: 500px;
              font-size: 20px;
              z-index: -1;
              display: block;
            }
          }
        }
      }

      .right-content {
        @include make-md-column(4);
      }

      .img-wrapper {
        margin: 40px 15% 0 auto;
        max-width: 300px;
        position: relative;

        &:before {
          content: '';
          background-color: $color-bright-pink;
          position: absolute;
          bottom: 0;
          left: -30%;
          width: 100vw;
          height: 50%;
          z-index: -1;
        }

        @include when-xs {
          text-align: right;
          margin: 45px 20px 0 auto;

          &:before {
            left: 20%;
            height: 150px;
          }
        }


        @include when-md {
          margin: auto;
          max-width: none;

          &:before {
            content: none;
          }
        }


        img {
          width: 100%;
          height: auto;

          @include when-xs {
            max-width: 178px;
          }
        }
      }
    }

    .section5 {
      @extend %home-container;

      @include make-container;

      position: relative;

      &:before {
        background-color: #A7B7D3;
        content: '';
        width: 100vw;
        left: calc(-1 * (100vw - 100%) / 2);
        position: absolute;
        height: calc(100% - 10px);
        display: block;
      }

      .section5-inner {
        text-align: center;
        padding: 70px 0;
        margin-bottom: 10px;
        position: relative;

        @include when-md {
          padding: 100px 0;
        }
      }

      .btn-signup {
        position: absolute;
        left: 50%;
        margin: auto;
        transform: translateX(-50%);
        bottom: -20px;
        cursor: pointer;
        border: 1px solid $color-nero;
        z-index: 1;

        &:hover {
          background-color: $color-white;
          color: $color-nero;
        }

        @include when-md {
          bottom: -25px;
        }
      }
    }

    .section6 {
      position: relative;

      @include make-container;

      @extend %home-container;

      &:before {
        background-color: #F5F5F5;
        content: '';
        width: 100vw;
        left: calc(-1 * (100vw - 100%) / 2);
        position: absolute;
        height: calc(100% - 10px);
        display: block;
      }

      .section6-inner {
        @include make-row;

        display: flex;
        flex-flow: column;
        padding: 80px 0;

        @include when-xs {
          padding: 60px 10px;
        }


        @include when-md {
          padding: 110px 0 130px;
        }
      }

      .top-content {
        @include make-sm-column(12);
      }

      .bottom-content {
        @include when-md {
          line-height: 44px;
        }
      }

      .left-content,
      .right-content {
        @include make-sm-column(6);
      }

      .top-content {
        text-align: center;

        .icon-live-video {
          margin: auto;
          margin-bottom: 20px;

          @include when-sm {
            height: 80px;
            width: auto;
          }
        }

        p {
          span {
            display: block;
            font-weight: $font-weight-bold;
            color: $color-primary;
          }
        }
      }

      .bottom-content {
        padding-top: 50px;
        display: flex;

        @include when-xs {
          display: block;
        }


        @include when-md {
          padding-top: 100px;
        }


        .section-title {
          color: $color-light-grey;
          text-align: inherit;
        }

        .separator {
          white-space: nowrap;
          line-height: 30.8px;
          color: $color-light-grey;
          position: relative;

          @include when-xs {
            text-align: center;
            margin: 20px 0;
          }


          @include when-md {
            line-height: 44px;
          }
        }

        .left-content {
          @include when-sm {
            text-align: right;
          }
        }

        .right-content {
          @include when-xs {
            text-align: right;
          }
        }
      }
    }

    .section7 {
      padding-top: 50px;
      padding-bottom: 50px;
      position: relative;

      @include make-container;

      @extend %home-container;

      @include when-xs {
        padding-bottom: 0;

        h2 {
          font-size: 26px;
        }
      }


      @include when-md {
        padding-top: 145px;
        padding-bottom: 115px;
      }


      .section7-inner {
        @include make-row;

        display: flex;

        @include when-xs {
          flex-flow: column-reverse;
        }
      }

      .left-content,
      .right-content {
        @include make-md-column(6);
      }

      .left-content {
        position: relative;
        display: flex;

        &:before {
          content: '';
          background-color: #E7E6ED;
          position: absolute;
          top: 58%;
          right: 6%;
          width: 50vw;
          height: 400px;
          z-index: -1;

          @include when-xs {
            left: 10px;
            top: 0;
            bottom: 0;
            width: 65%;
            height: auto;
          }


          @include when-md {
            right: 3%;
          }
        }

        .img-wrapper {
          margin-right: -12%;
          margin-top: auto;

          @include when-xs {
            padding: 70px 0 55px;
          }


          img {
            width: 100%;
            height: auto;

            @include when-xs {
              max-width: 322px;
            }
          }
        }
      }

      .right-content {
        display: flex;
        flex-flow: column;

        @include when-xs {
          flex-flow: column-reverse;
        }


        .top-content {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-bottom: 60px;
          margin-top: auto;

          @include when-xs {
            margin-bottom: 0;
            padding: 45px 0 60px;
          }


          .big-btn {
            @extend %home-subtitle;

            background-color: #66C7C2;
            width: fit-content;
            margin-bottom: 12px;
            z-index: 2;
            font-size: 24px;
            height: 44px;
            padding: 12px;

            @include when-xs {
              font-size: 19px;
              height: 35.5px;
              padding: 10px 15px;
              margin-bottom: 7px;
            }


            @include when-md {
              font-size: 30px;
              height: 55px;
              padding: 16px;
            }


            @include when-lg {
              font-size: 36px;
              height: 66px;
              padding: 20px;
            }
          }

          .small-btn {
            @extend %home-subtitle;

            background-color: $color-nero;
            width: fit-content;
            position: relative;
            z-index: 1;
            cursor: pointer;
            border: 1px solid $color-nero;

            &:before,
            &:after {
              content: '';
              background-color: $color-nero;
              position: absolute;
              bottom: -1px;
              top: -1px;
              border: 1px solid $color-nero;
            }

            &:before {
              width: 110%;
              right: 100%;
              border-right: none;
            }

            &:after {
              width: 30vw;
              left: 100%;
              border-left: none;
            }

            &:hover {
              background-color: $color-white;
              color: $color-nero;
              border-left-color: $color-white;
              border-right-color: $color-white;

              &:before,
              &:after {
                background-color: $color-white;
              }
            }
          }
        }

        .bottom-content {
          @include when-sm {
            text-align: right;
          }


          .section-title {
            color: #A7B7D3;
            text-align: inherit;

            span {
              color: #8199C3;
            }
          }

          p {
            span {
              color: #8199C3;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }

    .section8 {
      background-image: url(https://cdn.calltheone.com/public/images/home/bg-generic-section8.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 0;
      padding-top: 47%;

      @include when-xs {
        background-image: url(https://cdn.calltheone.com/public/images/home/appgirl_mob.jpg);
        height: 511px;
        padding-top: 0;
        padding-bottom: 40px;
        background-size: 355px;
        background-position: center;

        h2 {
          width: 140px;
        }

        p {
          width: 180px;
        }
      }


      .section8-inner {
        @include make-container;

        @extend %home-container;

        margin-top: -47%;
        display: flex;
        flex-direction: row-reverse;

        @include when-xs {
          height: 100%;
          align-items: flex-end;
          margin-top: auto;
          justify-content: center;
        }


        .right-content {
          @include make-row;
          @include make-sm-column(6);

          text-align: center;

          .section-title,
          p,
          .app-links {
            margin-left: auto;
            margin-right: auto;
            max-width: 350px;
            color: $color-white;
          }

          @include when-sm {
            padding-top: 80px;
          }


          @include when-md {
            padding-top: 100px;
          }


          @include when-lg {
            padding-top: 130px;
          }


          .app-links {
            margin-top: 40px;

            .appstore{
              content: url("/images/playstore.svg");
            }

            .playstore{
              content: url("/images/playstore.svg");
            }

            .appstore,
            .playstore{
              border: 1px solid $color-black;
              border-radius: 4px;
              width: 127.546px;
              height: 37.8px;

              &:not(:hover){
                filter: invert(83%) sepia(100%) saturate(0%) hue-rotate(321deg) brightness(103%) contrast(101%);
              }

              &:hover{
                filter: invert(83%) sepia(4%) saturate(2034%) hue-rotate(308deg) brightness(81%) contrast(93%);
              }
            }
          }
        }
      }
    }

    .section9 {
      @include make-container;

      @extend %home-container;

      @include when-xs {
        h2 {
          font-size: 26px;
        }
      }


      .section9-inner {
        @include make-row;

        margin-top: 50px;
        margin-bottom: 50px;

        @include when-xs {
          margin-top: 70px;
        }


        @include when-md {
          margin-top: 120px;
          margin-bottom: 80px;
        }


        .left-content {
          @include make-sm-column(5);
          @include make-md-column(6);
        }

        .right-content {
          @include make-sm-column(7);
          @include make-md-column(6);
        }

        .left-content {
          .section-title {
            text-align: left;
            color: #9FDFDB;

            span {
              color: #66C7C2;
              display: block;
            }
          }

          @include when-md {
            margin-top: 25px;
          }
        }

        .right-content {
          @include when-xs {
            margin-top: 70px;
          }


          .movie-container {
            .to-player {
              position: relative;
              padding-bottom: 56.23%;
              height: 0;
              overflow: hidden;
              max-width: 100%;
              background: #9adedb;

              img {
                width: 100%;
                height: auto;
              }

              .play {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                cursor: pointer;
              }

              iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

[data-to-root].mobile-auth #main-container {
  text-align: center;

  .banner {
    height: 75px;
    background: url("/images/home/temp-banner.jpg") no-repeat;
    background-position: center;
    margin-bottom: 40px;
    background-size: cover;
  }

  .big-text {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-black !important;
    font-size: 16px;
    color: $color-light-grey;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .options,
  .option1 {
    margin-bottom: 25px;
  }

  .options {
    font-weight: $font-weight-bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 0;
  }

  .option2 {
    font-weight: $font-weight-bold;
    font-size: 14px;
    margin-top: 0;
    color: #768DB5;
  }

  .app-links {
    margin: 40px 0 30px;
  }

  .bottom-text {
    position: relative;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    color: $color-white;
    font-size: 13px;
    padding: 7px;
    line-height: 1;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      background: #A7B7D3;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      z-index: -1;
    }
  }
}
