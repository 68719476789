[data-to-root].settings,
[data-to-root].requests {
  &.profile {
    background-color: $color-white;
  }

  .col-md-8 {
    @include when-sm {
      padding-right: 30px;
    }
  }

  .detail-container {
    .lg-text {
      h2 {
        font-size: 100px !important;
        line-height: 88px;
      }
    }
  }

  .lg-text {
    &.expertise {
      h2 {
        font-size: 60px !important;
        line-height: 52px;

        @include when-xs {
          font-size: 46px !important;
          line-height: .9;
        }
      }
    }
  }

  .setting-profile,
  .my-detail-setting,
  .i-m-one,
  .keep-in {
    .lg-text {
      h2 {
        font-size: 60px !important;
        line-height: 52px;

        @include when-xs {
          font-size: 46px !important;
          line-height: .9;
        }
      }
    }

    h3 {
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .detail-wrap {
      h3 {
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }
  }

  .area-expertise {
    p {
      margin: 0;
    }
  }

  .profile-wrap-section {
    width: 320px;
    height: auto;

    /* display: flex; */
    display: inline-block;
    float: left;
    padding-right: 15px;
    margin-top: 15px;
  }

  .max {
    font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    color: #888D91;
    margin: 5px;
  }

  .photos-videos {}

  .row.top {
    background-image: cdn("/images/bg-small-settings.jpg");
  }

  .bottom-align {
    position: absolute;
    bottom: 17px;
    right: 0;

    h3 {
      margin-bottom: 10px;
    }
  }

  h2 {
    display: block;
    border-bottom: solid 1px $color-lighter-grey;
    font-size: 24px;
    font-weight: $font-weight-normal;
    padding-bottom: 10px;
    padding-top: 15px;
    margin-bottom: 15px;
    text-transform: lowercase;
  }

  h3 {
    font-size: 22px;
    line-height: 16px;
    color: $color-light-pink;
    display: block;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
    border: none;

    &:not(.line-half):not(.line-full):after {
      width: 0;
    }

    &.custom {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-bold !important;
      letter-spacing: 0;
    }

    &.alt {
      color: $color-nero;
    }
  }

  .backdrop {
    // padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }

  .menu-sidebar {
    .social-icons {
      li {
        &:first-child {
          &:before {
            bottom: -3.5px;
            left: 2px;
          }
        }
      }
    }

    .social-icons.list-inline {
      margin-bottom: 0;
    }

    .nicename {
      display: block;
    }

    .online .nicename {
      margin-right: 33px;
    }

    .request-section {
      color: #4D4D4D;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 50px;

      .list-inline > li {
        padding-left: 0px;
      }

      .send-req {
        color: #4D4D4D;
        font-size: 16px;
        line-height: 24px;
        text-transform: lowercase;

        .fa {
          margin-left: 10px;
          -webkit-text-stroke: 2px #fff;
          -moz-text-stroke: 2px #fff;
          -ms-text-stroke: 2px #fff;
          font-size: 13px !important;
        }
      }

      .light {
        color: #999999;
      }
    }
  }

  .avatar-wrap {
    background: #F9F9F8;
    width: 100%;
    height: 314px;
    padding: 0 !important;
  }

  #avatar-holder {
    background-size: cover;
    background-position: center;
    position: relative;
    width: calc(100% - 60px);
    height: 314px;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      .btn {
        opacity: 1;
      }
    }

    .btn {
      position: absolute;
      bottom: 0px;
      color: $color-white;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      left: -30px;
      text-align: left;
      top: 50%;
      height: 30px;
      width: auto;
      font-size: 18px;
      font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      opacity: 0.6;

      &:hover {
        background-color: rgba($color-black, $dark-alpha);
      }
    }
  }

  #square_avatar-holder {
    background-size: cover;
    background-position: center;
    position: relative;
    width: calc(100% - 5.555555555555555vw);
    height: 22.22222222222222vw;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      .btn {
        opacity: 1;
      }
    }

    .btn {
      position: absolute;
      bottom: 0px;
      color: $color-white;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      left: -30px;
      text-align: left;
      top: 50%;
      height: 30px;
      width: auto;
      font-size: 18px;
      font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      opacity: 0.6;

      &:hover {
        background-color: rgba($color-black, $dark-alpha);
      }
    }
  }

  input#avatar {
    display: none;
  }

  input#square_avatar {
    display: none;
  }

  #avatar-sidebar-holder {
    padding-right: 0px;
  }

  .main-container .avatar {
    padding-bottom: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .online-tag {
      position: absolute;
      right: 0;
      top: 20px;
      padding: 3px 8px 1px;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 16px;
      color: $color-white;
      background-color: $color-green;

      &.offline {
        background-color: $color-light-pink;
      }
    }
  }

  label.select {
    width: 100%;
    height: 120px;
    position: relative;
    display: inline-block;
    background-color: $color-light-grey;
    cursor: pointer;

    span {
      width: 100%;
      height: 120px;
      position: absolute;
      top: 0px;
      left: 0px;
      display: block;
      z-index: 1;
      background-color: #D9D9D9;

      &:after {
        content: " ";
        width: 7px;
        height: 7px;
        background: cdn("/images/icon-check.svg") no-repeat center center;
        background-size: 7px 7px;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 3;
        display: block;
        opacity: 0.0;

        @include transition(0.15s all ease-in-out);
      }

      @include transition(0.15s all ease-in-out);
    }

    input {
      display: none;
    }

    input + span {
      background-position: 0px 0px;
    }

    input:checked + span {
      background-color: $color-dark-grey;

      &:after {
        opacity: 1.0;
      }
    }

    &:hover input + span:after {
      opacity: 0.5;
    }
  }

  .location-image {
    padding-bottom: 100%;
    width: 100%;
    background-size: cover;
  }

  .capabilities-list {
    label {
      width: calc(#{$capability-size-midi} * 0.93) !important;
      height: calc(#{$capability-size-midi} * 0.93) !important;

      span {
        width: calc(#{$capability-size-midi} * 0.93) !important;
        height: calc(#{$capability-size-midi} * 0.93) !important;
      }
    }
  }

  &.expertise {
    .popover {
      width: 100%;

      // max-width: 100%;
      // left: 0px !important;
      font-size: 11px;

      @include when-xs {
        max-width: calc(100vw - 20px);
      }
    }

    .main-category {
      h3 {
        color: transparent;
        margin-bottom: 4px;
        height: 22px;

        &:after {
          padding-top: 0px !important;
          width: 100%;
          margin-top: -4px;
        }
      }

      .tips {
        text-transform: uppercase;
        color: $color-dark-grey;
        position: absolute;
        right: 15px;
        top: 21px;
        cursor: pointer;

        &:hover {
          color: $color-nero;
        }
      }
    }

    h4 {
      font-size: 22px;
      text-transform: uppercase;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      color: #888D91;
      letter-spacing: 1px;
      line-height: 20px;

      &:before {
        // content: " ";
        // width: 10px;
        // height: 10px;
        // background-size: 10px 10px;
        // background-repeat: no-repeat;
        // background-position: center center;
        // background-image: cdn('/images/icon-check-blue.svg');
        // display: none;
        // margin-right: 5px;
    
      }

      margin-bottom: 0px;
    }

    .expertise-category.selected h4 {
      color: $color-light-pink;

      &:before {
        display: inline-block;
      }
    }

    .expertise-category h4 {
      margin-bottom: 3px;
    }

    .expertise-category {
      border-bottom: solid 1px #E5E5E5;
    }

    ul.expert-tag-suggestions {
      li {
        display: block;
      }
    }

    ul.tags {
      margin: 0px;

      .btn {
        font-size: 12px;
        font-weight: $font-weight-normal;
        border: solid 1px #E5E5E5;
        text-transform: uppercase;
        color: #878787;
        background-color: $color-white;
        padding: 7px 18px 6px 6px;
        border-radius: 2px;
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        letter-spacing: 1px;

        &.btn-add-another-language {
          font-size: 12px;
          line-height: 14px;
          padding: 7px 18px 6px 6px;
        }
      }

      li {
        padding: 0px;
        margin-left: 0px;
        margin-right: 2px;
        margin-bottom: 9px;

        &.tag-suggestion {
          display: block;
        }
      }

      li.loader {
        margin: 3px 4px 2px 4px;
      }

      li.tag {
        position: relative;
        text-transform: lowercase;

        .remove {
          position: absolute;
          right: 3px;
          top: 1px;
          opacity: 0.8;
          width: 6px;
          height: 6px;
          background-size: 6px 6px;
          cursor: pointer;

          &:hover {
            opacity: 1.0;
          }
        }

        input {
          font-size: 12px;
          padding: 5px 18px 3px 4px;
          width: 92px;
          text-transform: lowercase;
        }

        input:read-only:hover,
        input:focus {
          border-color: $color-lighter-grey !important;
        }

        .spinner {
          // display: inline-block;
          // position: absolute;
          // margin-left: 6px;
          // padding-top: 2px;

          // i {
          //   position: relative;
          //   color: $primary-color;
          // }
      
        }

        .tagsearch {
          position: absolute;
          z-index: 10;
          width: 92px;
          background-color: $color-white;
          border: solid 1px $color-lighter-grey;

          li:not(:last-child) {
            border-bottom: solid 1px $color-off-white;
          }

          li {
            padding: 2px 3px;
            margin: 0px;
            cursor: pointer;
            color: $color-grey;

            &:hover {
              color: $color-nero;
            }

            font-size: 12px;
          }
        }

        .tag-text {
          padding: 7px 18px 6px 6px;
          border: solid 1px $border-color;
          font-size: 12px;
          color: $color-dark-grey;
          border-radius: 2px;
        }
      }
    }
  }

  &.wallet .topup-options,
  &.guide .profile-media,
  &.settings-myprofile .profile-media {
    li {
      padding-top: 20px;
      padding-bottom: 10px;
      position: relative;
      border-radius: 2px;
      border: 1px solid #f0f0f0;
      width: calc(50% - 10px);
      margin: 0px 0 10px;

      p {
        margin: 0px;
        padding: 0px;
      }

      .value {
        line-height: 42px;
        font-size: 42px;
        font-weight: $font-weight-light;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        color: $primary-color;

        &.smaller {
          font-size: 32px;
        }
      }

      .deal,
      .add {
        color: #DBC0C3;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
      }

      .deal {
        font-size: 26px;
      }

      .button-holder {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;

        .edit-button {
          background-color: rgba($color-nero, $light-alpha);
          border: none;
          color: $color-white;

          &:hover {
            background-color: $color-nero;
            color: $color-white;
          }
        }
      }

      &.with-content {
        padding: 0px;

        .preview-holder {
          height: 127.5px;
          width: 130px;

          // background-image: cdn('/images/bg-small-settings.jpg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;

          @include when-xs {
            width: auto;
          }
        }
      }

      &.empty {
        cursor: pointer;

        p:hover {
          color: $primary-active-color;

          > span {
            opacity: 1 !important;
          }
        }
      }

      @include when-xs {
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }
    }

    @include when-xs {
      display: block;
    }
  }

  @include when-xs {
    &.wallet .topup-options {
      width: 100%;
      margin: 0;
      align-items: inherit;
      display: block;
      overflow: hidden;

      li {
        display: block;
        width: 100%;
        margin: 2.5px 0;
        padding: 10px;
        text-align: left;

        .deal {
          display: none;
        }

        .value {
          display: block;
          height: 34px;
          line-height: 34px;
          padding-top: 2px;
        }

        .btn {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }


  &.guide .profile-media li.empty,
  &.settings-myprofile .profile-media li.empty {
    // border: 0 none;
    border: 1px solid #f0f0f0;

    // padding: 0;

    p {
      margin: auto;
      margin-top: 18.85px;
    }

    .add {
      // border: solid 1px $color-lighter-grey;
      // padding-top: 30px;
      background-repeat: no-repeat;
      background-position: center 5px;

      &:first-child {
        margin-bottom: 10px;
      }

      .icon-video,
      .icon-camera {
        display: block;
        margin: auto;
        opacity: 0.7;
      }
    }

    // .add-photo {
    //   background-image: cdn("/images/icon-photo.png");
    // }

    // .add-video {
    //   background-image: cdn("/images/icon-video.png");
    // }
    // .add-photo,
    // .add-video {
    //     background-repeat: no-repeat;
    // }

  }

  &.guide {
    .form-group {
      margin-bottom: 3px;
    }

    #location_address {
      width: calc(100% - 45px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      top: -50px;
      left: 5px;
      border: none !important;
      background-color: transparent;
      color: $color-grey;
    }

    .bootstrap-select {
      width: 100%;

      &.day,
      &.month,
      &.year {
        width: 32%;
      }

      &.btn-group .dropdown-toggle .caret {
        right: 4px;
      }

      & > .dropdown-toggle {
        max-height: 36px;
      }

      &.language-selection {
        width: 55%;
        margin-right: 5px;
      }

      &.grade-selection {
        width: calc(100% - 55% - 25px);
        margin-left: 0px;

        @media only screen and (max-width: 1399px) {
          width: calc(100% - 55% - 21px);
        }
      }

      .filter-option {
        padding-top: 0px;
        padding-left: 2px;
      }

      .bs-placeholder {
        padding-left: 3px;
      }

      &.btn-group {
        margin-bottom: 3px;
      }
    }

    .distance-holder .bootstrap-select,
    .currency-holder .bootstrap-select {
      width: 120px;
    }

    .btn-remove-language {
      border: none !important;
      color: $color-nero;

      &:hover {
        color: $primary-active-color;
        background-color: inherit;
      }
    }

    .btn-add-another-language {
      position: relative;
      background-color: $color-white;
      text-transform: uppercase;
      border-color: $border-color;
      color: $primary-active-color;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      width: 55%;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 18px 6px 6px;

      &:hover {
        border-color: $color-dark-grey;
        color: $primary-active-color;
      }
    }
  }

  table.sortable-table {
    width: 100%;

    th {
      border-bottom: solid 1px $color-lighter-grey !important;
    }

    td {
      border-bottom: solid 1px $color-off-white;
      vertical-align: middle;
    }

    td,
    th {
      padding: 12.5px 10px 12.5px 0px;

      &:last-child {
        padding: 12.5px 0px 12.5px 0px;
      }

      &.tiny-cell {
        width: 0px;
        padding: 0px;
        border: none;
        vertical-align: top;

        i {
          position: relative;
          left: -10px;
          top: 12px;
        }
      }

      &.huge-cell {
        width: 100%;
      }
    }

    i {
      display: block;
    }

    td {
      color: $color-dark-grey;

      .blue {
        color: $color-blue !important;
      }
    }

    .clickable {
      cursor: pointer;

      &:hover {
        background-color: $color-off-white;
      }
    }

    margin-bottom: 50px;

    .r {
      text-align: right;
    }

    em {
      background-color: rgba($primary-color, $light-alpha);
      font-style: normal;
    }

    h5,
    .h5 {
      padding: 0px;
      margin: 0px;
    }

    a {
      color: inherit !important;
    }

    .btn {
      font-size: 12px;
      color: $color-white !important;

      &:hover {}
    }
  }

  #tours form.sort-form {
    margin: 0px 20px 40px;

    label {
      padding: 0px 5px;
    }

    .input-group {
      input {
        border: solid 1px $color-lighter-grey !important;
        border-right: 0px !important;

        @include box-shadow(none !important);
      }

      .input-group-addon {
        border: solid 1px $color-lighter-grey !important;
        border-left: 0px !important;
        color: $color-grey;
      }
    }
  }

  .lookalong-modal-bigger {
    .modal-content {
      @include box-shadow(none !important);

      background-color: transparent;
      border: none !important;

      .modal-header {
        background-color: transparent;
        border: none !important;

        .close {
          opacity: $dark-alpha;

          &:hover {
            opacity: 1;
          }
        }
      }

      .modal-body {
        background-color: $color-off-white;
        padding-bottom: 15px;

        input {
          width: 100%;
        }
      }

      .modal-footer {
        background-color: $color-off-white;
        border: 0 none;
        padding-top: 0;
      }

      h3 {
        text-align: center;
        font-size: 26px;
      }

      h3:after {
        width: 250px;
        margin: 0 auto 20px;
      }

      .alert {
        display: none;
        margin-bottom: 0;
        margin-top: 10px;
        padding: 2px 10px;

        span {
          display: none;
        }
      }
    }

    .loader {
      background-image: cdn("/images/blocks.svg");
      background-size: contain;
      width: 32px;
      height: 32px;
      display: none;
      position: relative;
      top: -5px;
    }
  }

  .user-media-modal {
    .modal-content {
      .modal-body {
        label {
          font-size: 18px;
        }

        .old-photo {
          width: 200px;
          height: 200px;
          border: 1px solid $color-lighter-grey;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          margin: auto;
          margin-bottom: 30px;
        }

        .old-photo-c {
          padding-bottom: calc(100% * 0.27);
          width: 100%;
          height: 100%;
          background-size: cover;
        }
      }

      .modal-footer {
        .btn-remove-media {
          float: left;
          background-color: $color-black;
          border-color: $color-black;
          color: $color-white;
        }
      }
    }
  }

  .payout-modal-request {
    .modal-content {
      input {
        padding: 3px 5px;

        &.radio-custom {
          width: 120px;
          position: relative;
          top: -1px;

          @include when-xs {
            max-width: 50%;
          }
        }
      }

      .custom-currency {
        padding-left: 10px;
        font-size: 12px;
        color: $color-lighter-grey;
      }
    }

    .modal-footer {
      text-align: left;
    }

    label.radio-select {
      span {
        padding-top: 4px;
        padding-left: 10px;
        display: block;
        font-weight: bold;

        &:after {
          width: 20px;
          height: 20px;
        }

        &:before {
          width: 28px;
          height: 28px;
          background-color: $color-white;
        }

        &.comment {
          padding-top: 0;
          line-height: 1em;

          p {
            font-size: 12px;
            font-weight: normal;
            margin: 0;
            color: $color-grey;
          }
        }

        &.radio-custom {
          background-color: $color-white;
          border: 1px solid $color-lighter-grey;
          height: 28px;
          padding-right: 8px;
          margin-left: 35px;
          margin-right: -3px;
        }

        &.unimportant {
          margin: 0;
          padding: 0;
          font-weight: normal;
        }
      }
    }

    h4 {
      font-size: 18px;
      color: $color-nero;

      &:after {
        content: " ";
        border-bottom: solid 1px $color-nero;
        width: 140px;
        display: block;
        padding-top: 8px;
        margin-bottom: 0px;
      }

      .comment {
        font-size: 10px;
        color: $color-grey;
        padding-left: 15px;
        text-transform: none;
      }
    }

    .description {
      margin: 0;
      position: relative;
      font-size: 12px;
      color: $color-grey;
      cursor: pointer;
    }

    .payout-method-details {
      position: relative;
      display: none;

      p {
        margin: 10px 0;
      }

      .btn-holder {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .btn-primary {
        width: 100%;
      }
    }
  }

  #section-gallery {
    padding-right: 0;

    @include when-xs {
      padding-right: 15px;
    }
  }

  .media-items {
    .media-item {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;

      @include when-xs {
        float: none;
        width: 315px !important;
        max-width: 100%;
        height: 177px !important;
        margin-bottom: 5px !important;
      }


      img {
        display: none;
      }

      img.play {
        max-height: 60px;
        display: inline;
        height: 40%;
      }
    }

    .media-item:nth-child(1) {
      width: 315px;
      height: 169px;

      /* 177 - 164 = 13 */
      margin-bottom: 4px;
    }

    .media-item:nth-child(2) {
      width: 177px;
      height: 129px;
      margin-right: 5px;
    }

    .media-item:nth-child(3) {
      width: 133px;
      height: 72px;
    }

    .media-item:nth-child(4) {
      width: 133px;
      height: 53px;
      margin-top: 4px;
    }
  }

  .media-items-1 {
    .media-item:nth-child(1) {
      height: 315px;
      margin-bottom: 0;
    }
  }

  .media-items-2 {
    .media-item:nth-child(2) {
      width: 315px;
      margin-right: 0;
    }
  }

  .media-items-3 {
    .media-item:nth-child(3) {
      height: 129px;
    }
  }

  .call-history {
    .call-history-nav {
      a {
        display: inline-block;
        padding: 1px 6px;
        margin-right: 10px;
        border: 1px solid $color-light-pink;
        text-decoration: none;
        text-transform: uppercase;
        color: $color-light-pink;

        &.active {
          background-color: $color-light-pink;
          color: $color-white;
        }
      }
    }

    .call-history-table {
      .user-details {
        display: none;

        &.open {
          display: table-row;
        }

        img {
          display: block;
          float: left;
          margin-right: 20px;
        }

        .user-data {
          float: left;
        }
      }
    }
  }
}

[data-to-root].settings {
  .menu-sidebar {
    @include when-xs {
      position: relative;
      top: auto;
    }
  }

  .profile-wrap-section {
    height: auto;

    #avatar-holder .btn {
      line-height: 1.28;
    }
  }
}

.media-gallery-modal {
  .modal-dialog {
    @media (max-width: $screen-sm-min) {
      width: 90%;

      /* otherwise this is auto*/
    }

    @include when-md {
      width: 960px;
    }


    @include when-lg {
      width: 1430px;
    }
  }

  .media-gallery-nav {
    position: absolute;
    background-repeat: no-repeat;
    width: 20px;
    height: 40px;
    top: 45%;
    cursor: pointer;
    background-color: $color-white;
    background-size: 80%;
    background-position: center;

    @include when-xs {
      width: 10px;
      height: 20px;
    }
  }

  .media-gallery-prev {
    z-index: 10;
    left: 0px;
    background-image: cdn("/images/icon-previous.svg");

    @include when-xs {
      left: -12px;
    }
  }

  .media-gallery-next {
    z-index: 11;
    right: 0px;
    background-image: cdn("/images/icon-next.svg");

    @include when-xs {
      right: -12px;
    }
  }

  .modal-content {
    border: 0 none;
    position: relative;
  }

  .modal-body {
    width: 100%;
    height: 100%;
    min-height: 200px;
    position: absolute;
    background-color: #3C3C3B;

    img {
      position: absolute;
      left: calc(50% - 15px);
      top: calc(50% - 15px);
    }

    .modal-main {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      iframe {
        display: none;
        width: 100%;
        height: 100%;
      }
    }
  }
}

#step-2,
#tours,
.settings-expertise,
.general-settings,
.call-history {
  &.payments {
    h3 {
      color: #3C3C3B !important;
    }
  }

  h4.small {
    color: #DBC0C3;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
  }

  h3 {
    margin: 0;
  }

  p {
    color: #333;
    font-size: 16px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    line-height: 24px;
  }

  .add-another-tag {
    .btn {
      background-color: $color-white;
      text-transform: uppercase;
      border-color: $border-color !important;
      color: $primary-active-color !important;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
      letter-spacing: 1px;
    }
  }

  .selected {
    .smaller {
      color: $color-nero;
    }
  }

  .smaller {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #9E9E9D;
  }

  h2.subtitle {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    padding: 0px;
    padding: 0px;
    font-size: 20px;
    line-height: 26px;
    border: none !important;

    .strong {
      font-weight: $font-weight-bold;
    }

    text-transform: inherit;
  }

  .lg-text {
    h1, h2 {
      font-size: 100px;
      line-height: 88px;
      border: none !important;
      margin-top: 0;
      padding-top: 0;
      vertical-align: top;
      padding: 0;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    }
  }

  input.form-control {
    border: 1px solid #E5E5E5 !important;
    box-shadow: none;
  }

  .form-group {
    .col-sm-3 {
      padding-right: 0;
    }
  }

  label,
  .form-control,
  .filter-option {
    color: #333;
    font-size: 14px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    line-height: 18px;
  }

  .bootstrap-select .dropdown-toggle .bs-caret {
    display: block;
    position: relative;

    .caret {
      display: none;
    }

    &:after {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f078";
      right: -20px;
      position: ABSOLUTE;
      position: absolute;
      top: -20px;
      color: #E5E5E5;
      -webkit-text-stroke: 2px #fff;
      -ms-text-stroke: 2px #fff;
      -moz-text-stroke: 2px #fff;
      font-size: 18px;
    }
  }

  .dropdown-toggle {
    border: 1px solid #E5E5E5 !important;
    border-radius: 2px;
  }

  .profile-save-btn {
    font-size: 23px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-black !important;
    letter-spacing: 1px;
    position: relative;
    top: -45px;
    margin: 0 auto;
    width: auto;
    min-width: 214px;
    padding-top: 6px !important;

    @media only screen and (min-width: 1069px) {
      display: none;
    }
  }

  .group-save-btn {
    .profile-save-btn {
      margin: 10px auto;
    }
  }

  p {
    a {
      color: $color-light-pink;
    }
  }

  textarea.form-control {
    color: #4D4D4D;
    font-size: 16px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    line-height: 20px;
    border: 1px solid #E5E5E5 !important;
    box-shadow: none !important;
    border-radius: 2px !important;
  }

  .sm-txt {
    color: #9E9E9D;
    font-size: 14px;
    text-transform: none !important;
  }

  .hit-me {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

    /* font-weight: 600 !important; */
    color: #333 !important;
    font-size: 22px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 35px;
    cursor: pointer;

    @extend .flex-class;

    .fa {
      background: #333;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      text-align: center;
      margin-left: 10px;

      @extend .flex-class;

      justify-content: center;
      align-items: center;
      position: relative;

      &:before {
        content: cdn("/images/search-result/video.png");
        top: 2px;
        position: relative;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 3px;
        border-width: 0 0 20px 15px;
        border-style: solid;
        border-color: transparent #333;
        display: block;
        width: 0;
        transform: rotate(35deg);
      }
    }
  }

  .expert-certificate {
    border-bottom: 1px solid #e6e6e6;
    padding-top: 20px;

    .certificate-name {
      text-transform: uppercase;
      color: #9D9D9D;

      // font-weight: bold;
  
    }

    .hit-me {
      margin-top: 10px;
      margin-bottom: 50px;

      img {
        @include when-sm {
          padding-right: 10px;
        }
      }
    }

    .title {
      padding-left: 0;

      h4 {
        color: $color-light-pink;
        display: inline-block;
        padding-right: 20px;
        padding-bottom: 2px;
        vertical-align: middle;
      }

      img {
        max-height: 25px;
        display: inline-block;
      }
    }

    .smaller {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}

#tours {
  .hit-me {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.lg-text.expertise {
  display: table;

  h2 {
    display: table-cell !important;
  }

  span {
    background: #888D91;
    color: $color-white;
    padding: 8px 8px 4px 8px;
    font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    position: relative;
    top: 20px;
    margin-left: 15px;
  }
}

.p-30 {
  padding: 30px 0px;
}

.group-save-btn {
  .profile-save-btn {
    min-width: 280px !important;
    margin: 10px auto;
    top: 0 !important;
    width: auto;

    &.custom {
      background: none !important;
      color: #888D91 !important;
      min-width: 280px;
      width: auto !important;

      a {
        color: #888D91 !important;
      }
    }
  }
}

.call-me {
  .dropdown-toggle {
    position: relative;
    height: 75px;
    padding: 10px;

    .filter-option {
      text-align: right !important;
      height: auto;
      position: relative;
      padding: 18px 10px;
      padding-right: 70px;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 0.5px;
      border: none;
      font-size: 22px;
      color: #333;

      .filter-option-inner-inner {
        text-align: right;
        padding-right: 40px;
      }

      &:after {
        background: #333;
        width: 50px;
        height: 50px;
        border-radius: 25px;

        /* text-align: center; */
        margin-left: 10px;
        -ms-flex-pack: center;

        /* justify-content: center; */
        -ms-flex-align: center;

        /* align-items: center; */
        position: relative;
        content: cdn("/images/search-result/video.png");
        top: 2px;
        position: absolute;
        right: 0px;
        text-align: center;
        padding-top: 15px;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 6px;

        /* left: 3px; */
        border-width: 0 0 20px 15px;
        border-style: solid;
        border-color: transparent #333;
        display: block;
        width: 0;
        transform: rotate(35deg);
        right: 32px;
      }
    }

    .bs-caret {
      &:after {
        right: auto !important;
        left: 5px;
        top: auto !important;
      }
    }
  }

  .dropdown-menu {
    a {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      border: none;
      font-size: 22px;
      color: #333;
      border-bottom: 1px solid #f5f5f5;
    }
  }
}

.instant h3 {
  color: #3C3C3B !important;

  &:after {
    display: none;
  }
}

.color-black {
  color: $color-nero !important;
}

#topup-options-container {
  img {
    max-width: 100%;
  }
}

.topup-options {
  flex-flow: wrap;

  li {
    width: 170px;
    height: 170px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 5px !important;

    .value {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
    }

    .deal {}

    .btn {
      align-self: center;
      align-content: flex-end;
      justify-content: center;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      min-width: 80px;
      font-size: 20px;
      padding: 5px 0px 0px 0px;
    }
  }
}

#call-history {
  td {
    a {
      color: $color-light-pink;
    }
  }
}

[data-to-root].profiles {
  .avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .details {}
}

.inline-text {
  font-size: 22px;
  color: #888D91;
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
  letter-spacing: 1px;
  border: none;
}

.location-search-map {
  position: relative;

  .tag-map {
    position: absolute;
    top: 20px;
    z-index: 100;
    background: #fff;
    min-width: 140px;
    width: fit-content;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    color: #333;
    cursor: pointer;
    user-select: none;
  }
}

#avatar-sidebar-holder {
  // background: #f2f2f2;

  .avatar {
    /* height: 33.958333333333336vw; */
  }
}

.main-img-holder {
  position: relative;

  .btn {
    position: absolute;
    bottom: 0px;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    left: 0px;
    text-align: left;
    top: 40%;
    height: 25px;
    width: auto;
    font-size: 12px;
    font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
  }
}

.extra-img-conatiner {
  @extend .flex-class;

  flex-flow: wrap;
  position: relative;

  img {
    margin: 5px;
  }

  .main-img-holder {
    img {
      background: #f5f5f5;
      border: none !important;
    }

    .btn {
      background-color: #fff;
      color: #333;
      left: 5px;
    }
  }
}

.profile-media {
  li {
    position: relative;
    height: auto !important;
    display: inline-block;
    padding: 7.5px !important;
    cursor: pointer;

    &:hover {
      .edit-button {
        opacity: 1;
      }
    }

    &:nth-child(odd) {
      float: right;
    }

    img {
      width: 100%;
    }

    .preview-holder {
      width: 100% !important;
      height: 146px !important;
      background-size: 100% !important;
      background-size: cover !important;
    }

    .edit-button {
      position: absolute;
      bottom: 0px;
      color: $color-white;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      left: 0px;
      text-align: left;
      top: 40% !important;
      height: 25px;
      width: 80%;
      font-size: 12px;
      font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      min-width: fit-content;
      opacity: 0.6;
    }
  }

  &.first {
    li {
      width: 100% !important;
      height: 100% !important;

      // display: block;
      display: flex;
      flex-direction: column;

      &.empty {
        p {
          margin-top: auto;
        }
      }

      .preview-holder {
        width: 100% !important;
        height: 100% !important;
      }

      .edit-button {
        position: absolute;
        bottom: 0px;
        color: #FFFFFF;
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        left: 0px;
        text-align: left;
        top: 40% !important;
        height: 25px;
        width: auto;
        font-size: 12px;
        font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
        letter-spacing: 1px;
      }

      &[data-media-status=c] .preview-holder {
        height: 100% !important;
        padding-bottom: calc(100% * 0.27) !important;
      }
    }
  }
}

.single-profile {
  .content-wrap {
    position: relative;

    &:after,
    &:before {
      content: "";
      position: absolute;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(230, 230, 230, 0.01)), to(rgba(230, 230, 230, 0.8)));
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(230, 230, 230, 0.01)), to(rgba(230, 230, 230, 0.8)));
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(230, 230, 230, 0.01)), to(rgba(230, 230, 230, 0.8)));
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(230, 230, 230, 0.01)), to(rgba(230, 230, 230, 0.8)));
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(230, 230, 230, 0.01)), to(rgba(230, 230, 230, 0.8)));
      top: 0px;
      bottom: -2px;
      width: 2px;
      z-index: 99;
    }

    &:after {
      right: 0px;
    }

    &:before {
      left: 0px;
    }

    .btn-cust {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      line-height: 15px;
      font-size: 20px;
      background: #333;
      display: flex;
      min-width: 100px;
      width: max-content;
      padding: 0 15px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: -20px;
      height: 40px;

      span {
        align-items: center;
        display: flex;
        padding-top: 5px;

        .up {
          font-size: 16px;
          position: relative;
          top: 0px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          left: 2px;
        }
      }
    }

    .nicename {
      color: #888D91;
      display: block;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      font-size: 20px;
    }
  }

  .bottom-line {
    border: 2px solid #E6E6E6;
    border-top: 0px;
    border-radius: 0px 0px 8px 8px;
    position: relative;
  }
}

.setting-profile {
  .avtar-img {
    h3 {
      display: inline-block;
      float: left;
      position: relative;
      top: 5px;
      margin: 0px !important;
    }

    #square_avatar-holder {
      width: 120px !important;
      height: 120px !important;

      &.empty {
        background: #f2f2f2;
      }

      .btn {
        left: 0 !important;
        text-align: left;
        top: 37% !important;
        height: 26px !important;
        width: auto;
        font-size: 12px !important;
      }
    }
  }
}

@media (min-width: 1085px) and (max-width: 1399px) {
  #step-2,
  #tours,
  .settings-expertise,
  .general-settings,
  .call-history {
    .profile-save-btn {
      font-size: 18px;
    }
  }
}

[data-to-root] {
  &.payments,
  &.instant,
  &.wallet,
  &.blacklist,
  &.settings,
  &.calls {
    .lg-text {
      h2 {
        font-size: 60px !important;
        line-height: 52px;

        @include when-xs {
          font-size: 46px !important;
          line-height: .9;
          padding-bottom: 0;
        }
      }
    }
  }
}

[data-to-root] {
  &.payments {
    h3 {
      margin-top: 40px;
    }

    .unimportant {
      &.min {
        margin-top: 10px;
        color: #888D91;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

[data-to-root] {
  &.instant {
    label.radio-select span {
      color: #3C3C3B;
    }
  }

  #notification-form {
    .col-sm-6 {}

    .form-group {
      margin: 0 !important;
    }
  }

  &.settings {
    .settings-menu {
      .log-out-warning {
        .profile-save-btn {
          width: auto !important;
          height: 40px !important;
          font-size: 20px !important;
          font-size: 22px;
          line-height: 20px;
          min-width: 135px !important;

          a {
            font-size: 22px;
            line-height: 20px;
          }
        }
      }
    }

    .btn.btn-primary {
      padding-top: 10px;
    }
  }
}

.hamburger-btn {
  display: none;

  span {
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    top: -2px;
    margin-right: 5px;
  }
}

[data-to-root].auth-guide {
  .group-save-btn {
    .profile-save-btn {
      min-width: 280px !important;
      margin: 10px auto;
      top: 0 !important;
      width: fit-content;

      &.custom {
        background: none !important;
        color: #888D91 !important;
        min-width: fit-content !important;
        width: 280px !important;

        a {
          color: #888D91 !important;
        }
      }
    }
  }
}

.instant {
  .call-me {
    .filter-option {
      font-size: 22px !important;
      text-transform: uppercase;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
    }

    .bs-caret {
      top: -6px;
      position: relative;
    }
  }
}

#section-location,
.currency-holder,
.distance-holder {
  h3 {
    padding-bottom: 10px !important;
  }
}

#section-location {
  @media only screen and (max-width: 1084px) {
    padding-bottom: 30px;
  }
}

.time-select {
  .btn.dropdown-toggle {
    height: 25px;
    padding-top: 4px !important;
  }
}

.expertise-category-wrap {
  @extend .flex-class;

  flex-wrap: wrap;
  padding: 0;
  margin-top: 15px;

  .col-sm-6 {
    &.item {
      @extend .flex-class;

      flex-wrap: wrap;
      padding-left: 0;

      @include when-xs {
        padding-right: 0;
      }
    }

    .expertise-category {
      width: 100%;
    }

    .smaller.description {
      line-height: 14px !important;
    }
  }
}

.birth-date-holder {
  .combodate {
    -webkit-justify-content: space-around;

    @extend .flex-class;

    justify-content: space-around;

    .bootstrap-select {
      @extend .flex-class;

      button {
        border: 1px solid $color-lighter-grey;
      }
    }
  }
}

.hit-me {
  .icon-video-circle {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.profile-media {
  display: flex;
  justify-content: space-between;
  flex-flow: column wrap;
  flex-direction: row;
}

@media only screen and (min-width: 1069px) {
  .menu-sidebar .profile-save-btn {
    display: block;
  }
}

.user-media-modal {
  .modal-title {
    margin: 20px 0 40px;
  }

  .modal-help {
    text-align: center;
    font-family: proxima;
    color: $color-grey;
    font-size: $font-size-small;
  }

  .modal-content {
    max-width: 510px;
    margin: auto;
  }

  .input-holder form {
    input {
      padding: 5px;
    }

    label {
      font-size: 16px !important;
    }
  }
}

.max-size {
  margin-top: 5px;
  font-size: 12px;
}

.delete-account-btn {
  &:hover {
    background-color: #F8E1E3 !important;
    border-color: #F8E1E3 !important;
    color: $color-black !important;
  }

  &.active {
    background-color: #F8E1E3;
    border-color: #F8E1E3;
    color: $color-black;
    cursor: auto;
    box-shadow: none !important;
  }
}

.delete-account-btn.active + .delete-account.show {
  display: block !important;
}

.delete-account {
  margin-top: 20px;
  display: none;

  .btns {
    @include when-sm {
      display: flex;
      margin: 20px 0;
    }
  }
}

.delete-my-account {
  background: $color-white !important;
  margin-right: 10px;

  @include when-xs {
    white-space: normal;
    text-align: left;
    margin: 10px 0;
  }
}

.allow-calls-intro {}

.app-settings {
  background-color: $color-light-pink;
  margin-top: 30px;
  padding-top: 10px;
}

.app-video-allowed {
  background-color: rgba($color-teal, $alpha);
  margin-top: 30px;
  padding-top: 10px;
}

#test-video-settings {
  cursor: pointer;

  &:hover {
    background-color: $color-teal;

    .btn-primary {
      border-color: $color-primary;
      background-color: $color-primary;
    }
  }
}

.app-download {
  @extend .list-inline;

  .app-link {
    @extend .svg;

    @extend .no-margin;

    @extend .btn-border;
  }
}

.availability-form-content {
  select {
    width: 90px;
  }

  table {}

  .bootstrap-select {
    width: 90px !important;

    .dropdown-toggle {
      border: 1px solid #E5E5E5 !important;
      border-radius: 2px;

      .bs-caret {
        display: block;
        position: relative;

        .caret {
          display: none;
        }

        &:after {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f078";
          right: -20px !important;
          position: absolute;
          top: -20px;
          color: #E5E5E5;
          -webkit-text-stroke: 2px #fff;
          -ms-text-stroke: 2px #fff;
          -moz-text-stroke: 2px #fff;
          font-size: 18px;
        }
      }
    }
  }
}

/* ================================= new payment styles ================================= */

[data-to-root].settings {
  .pending-payments {
    padding-top: 40px;

    th {
      padding-right: 10px;
    }

    td {
      padding-right: 10px;
    }

    .amount {
      text-align: right;
    }
  }

  .payment-choose {
    padding-top: 50px;

    .payment-option {
      padding: 10px 0 15px 0;
      user-select: none;
    }

    .btn {
      background-color: $color-black;
    }
  }
}

/*
 *
 * ================================= new settings =================================
 *
 */

[data-to-root].settings {
  /* ----------------------- generic styles ----------------------- */

  h1 {
    font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
    font-size: 42px;
    color: $color-nero;
    margin: 20px 0;

    @include when-xs {
      font-size: 38px;
    }
  }

  h2 {
    border-bottom: 0 none;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  p {
    font-size: 14px;
    line-height: 1.3em;

    &.light {
      line-height: 1.5em;
    }
  }

  .btn-settings-generic-save {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;

    .btn {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-black !important;
      color: $color-white;
      background-color: $color-black;
    }
  }

  .settings-menu {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    letter-spacing: 1px;
    color: $color-dark-grey;
    padding-bottom: 30px;

    a {
      color: $color-dark-grey;
      display: block;
      padding: 10px 20px;

      &:hover {
        text-decoration: none;
      }
    }

    > li > a:hover {
      color: $color-nero;
    }

    > li.active {
      > a {
        width: auto;
        display: table;
        color: $primary-active-color;
      }

      &:not(.active-sub) {
        > a {
          background: $color-bright-pink;
        }
      }
    }

    ul {
      padding-left: 0;
    }

    .submenu {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      font-size: 16px;
      padding-top: 0px;

      a {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        padding: 10px 10px 10px 30px;
        background-size: 95px 100%;
        background-repeat: no-repeat;
        color: $color-nero;
        letter-spacing: 0px;
        position: relative;
        z-index: 2;

        &:hover {
          color: $color-primary;
        }
      }

      .active {
        > a {
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-bold !important;
        }

        &::before {
          content: " ";
          display: inline-block;
          position: absolute;
          width: 90px;
          height: 42px;
          background: $color-bright-pink;
        }
      }
    }

    .unimportant {
      > a {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 16px;
        color: $color-grey;
        letter-spacing: 0px;
      }
    }

    .badge {
      background-color: $color-red;

      // display: inline;
      margin-left: 5px;
      font-size: 11px;
    }

    .logout {
      a {
        position: relative;
        display: table;

        p {
          font-size: 14px;
          margin-left: 10px;
          background: transparent;
          position: ABSOLUTE;
          left: 100%;
          width: 241px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .log-out-warning {
      display: none;
    }

    .log-out-btn {
      @include when-xs {
        margin: 45px 0px;
      }


      @include when-sm {
        margin: 35px 0px;
      }


      @include when-md {
        margin: 15px 0px;
      }


      .btn {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
        font-size: 22px !important;
        width: auto !important;
        display: inline-block;
        float: right;
        margin: 0px 10px 7.5px 0px;
        top: 0 !important;
        padding-top: 12px !important;

        &.custom {
          background: #fff;
          color: #333;
          margin-top: 0px;
        }

        a {
          padding: 0 !important;
        }
      }
    }
  }

  .subnav-back {
    border-bottom: 2px solid #E6E6E6;

    a {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      color: $color-nero;
      font-size: 22px;
      padding-left: 20px;
      position: relative;

      i {
        background-image: cdn("/images/icon-next-thick.svg");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: left center;
        background-color: transparent;
        filter: invert(30%);
        transform: rotate(180deg);
        display: block;
        position: absolute;
        width: 16px;
        height: 28px;
      }
    }
  }

  .help-block {
    color: $color-really-red;
  }

  .settings-error {
    display: none;
    background-color: #fee;
    text-align: right;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .loader {
    background-image: cdn("/images/blocks.svg");
    background-size: contain;
    width: 32px;
    height: 32px;
    display: none;
    position: relative;
    top: -5px;
  }

  #profile-became-expert.modal {
    .modal-dialog {
      position: relative;
      width: 730px;
    }

    .modal-content {
      width: 100%;
      background-color: transparent;
      border: none !important;
      overflow: hidden;

      @include box-shadow(none !important);
    }

    .modal-header {
      background-color: transparent;
      border: none !important;

      .close {
        opacity: $dark-alpha;

        &:hover {
          opacity: 1;
        }
      }
    }

    .modal-body {
      width: 100%;
      height: 500px;
      padding: 0;
      background-color: white;
      background-image: none;

      .icon {
        padding-bottom: 20px;
        text-align: center;
      }

      .buttons {
        padding-top: 40px;
        position: static;
      }
    }

    .modal-page {
      width: 100%;
      height: 100%;

      &:before {
        content: "";
        position: absolute;
        left: 108px;
        width: 335px;
        height: 412px;
        background-color: $color-lighter-blue;
      }
    }

    .modal-main {
      text-align: center;
      color: $color-dark-grey;
      padding: 80px 27% 0;

      h5 {
        text-transform: uppercase;
        font-size: 16px;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-black !important;
        line-height: 15px;
        white-space: normal;
      }

      p {
        font-size: 14px;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        line-height: 16px;
      }
    }

    .buttons {
      @include when-xs {
        min-width: 220px;
        float: right;
      }


      .bigbutton {
        margin-bottom: 10px;
      }
    }

    @media only screen and (max-width: 729px) {
      .modal-page {
        &:before {
          left: 79px;
        }
      }

      .modal-dialog {
        min-width: 290px;
        width: calc(100% - 30px);
        margin: auto;
        max-width: 355px;
        height: 100vh;

        .modal-header {
          padding: 15px 0;
        }

        .modal-content {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  /* end profile-became-expert modal */

  /* ----------------------- profile summary page ----------------------- */

  .settings-group-link::hover {
    .settings-button, .content-button .settings-button {
      background-color: $color-light-pink;
    }
  }

  .settings-group {
    background-color: $color-off-white;
    padding-top: 30px;
    padding-bottom: 45px;
    margin-bottom: 30px;

    @include when-xs {
      padding-top: 20px;
      padding-bottom: 0;
      margin-bottom: 20px;
    }


    h1 {
      margin: 0 22px 65px 22px;

      @include when-xs {
        margin: 0;
        padding: 0 20px 70px;
      }
    }
  }

  .embed-profile[onclick], .settings-group[onclick], .settings-group-item[onclick] {
    cursor: pointer;

    &:hover {
      background-color: rgba($color-light-pink, $verylight-alpha);

      .settings-button {
        background-color: $color-primary !important;
      }
    }
  }

  .settings-group-item {
    background-color: $color-white;
    border: 1px solid $color-closer-white;
    height: 285px;
    position: relative;

    @include when-xs {
      height: auto;
      min-height: 240px;
      margin-bottom: 20px;
    }


    .settings-group-item-title {
      padding: 0 10px;

      h2 {
        padding-top: 50px;
        padding-bottom: 0px;
        margin-bottom: 0px;

        @include when-xs {
          padding-top: 40px;
        }
      }
    }

    .settings-group-item-image {
      img {
        max-width: 100%;
      }
    }

    .settings-group-item-content {
      padding: 0 0 0 10px;
      display: flex;

      .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 10px;

        .content-main-title {
          display: flex;
          align-items: center;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-black !important;
          color: $color-light-grey;
          text-transform: uppercase;

          img {
            height: 12px;
            padding-left: 5px;
          }
        }

        .content-main-subtitle {
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          color: #4D4D4D;
        }
      }

      .content-button {
        width: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px 0 1px 0;
        }

        .settings-completion {
          background-color: $color-primary;
          color: $color-white;
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          font-size: 16px;
        }

        .settings-button {
          background-color: $color-nero;
          color: $color-white;
          height: 23px;
          padding-bottom: 3px;
          cursor: pointer;

          img {
            height: 10px;
          }

          &::hover {
            background-color: $color-light-pink;
          }
        }

        &::hover .settings-button {
          background-color: $color-light-pink;
        }
      }
    }

    .settings-group-item-text {
      .border {
        margin: 10px 0 15px 0;
      }

      .content-main {
        padding: 10px;
        font-size: 14px;

        p {
          font-size: 14px;
          line-height: 1.3em;
          color: $color-light-grey;
        }
      }

      .content-button {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          background-color: $color-teal;
          color: $color-black;
          border: solid 1px $color-teal;

          &:hover {
            border: solid 1px $color-nero;
          }
        }
      }
    }
  }

  .settings-group-item-medium {
    height: 255px;

    @include when-xs {
      height: auto;
      min-height: 200px;
    }


    .settings-group-item-title {
      h2 {
        padding-top: 20px;
      }
    }
  }

  .settings-group-item-large {
    height: auto;
    padding-bottom: 30px;

    .settings-group-item-title {
      h2 {
        padding-top: 20px;
      }
    }
  }

  .settings-group-item-blank {
    height: auto;
    padding-bottom: 30px;
    background-color: transparent;
    border: 0 none;

    @include when-xs {
      min-height: auto;
    }


    img {
      @include when-xs {
        margin: auto;
        display: block;
      }
    }

    .settings-group-item-content {
      padding-top: 45px;
      padding-bottom: 210px;

      h3 {
        font-size: 36px;
      }
    }
  }

  .settings-subtitle {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: $color-white;
    background-color: $color-black;
    padding: 5px 10px 3px 10px;
    position: relative;
    top: -14px;
    display: inline-block;

    @include when-xs {
      top: -10px;
      padding: 4px 10px 0px 10px;
    }
  }

  .settings-group-item-avatar {
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    transform: scale(0.8);

    .sgi-avatar {
      width: 125px;
      height: 130px;
      margin: auto;

      svg {
        width: 0;
      }

      .sgi-avatar-img {
        width: 100%;
        height: 100%;
        background-color: $color-closer-white;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        clip-path: url(#settingsavatarpath);

        .placeholder {
          width: 97%;
          height: 100%;
          background-image: cdn("/images/onboarding/icon-avatar.png");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 40%;
        }
      }
    }
  }

  /* ----------------------- my profile page ----------------------- */

  .row-alt {
    background-color: $color-off-white;
  }

  .input-rule {
    padding-top: 5px;
    color: $color-light-grey;
    text-align: right;
  }

  &.myprofile {
    .form-group {
      margin-bottom: 3px;
    }

    #location_address {
      width: calc(100% - 45px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      top: -50px;
      left: 5px;
      border: none !important;
      background-color: transparent;
      color: $color-grey;
    }

    .bootstrap-select {
      width: 100%;

      &.day,
      &.month,
      &.year {
        width: 32%;
      }

      &.btn-group .dropdown-toggle .caret {
        right: 4px;
      }

      & > .dropdown-toggle {
        max-height: 36px;
      }

      &.language-selection {
        width: 55%;
        margin-right: 5px;
      }

      &.grade-selection {
        width: calc(100% - 55% - 25px);
        margin-left: 0px;

        @media only screen and (max-width: 1399px) {
          width: calc(100% - 55% - 21px);
        }
      }

      .filter-option {
        padding-top: 0px;
        padding-left: 2px;
      }

      .bs-placeholder {
        padding-left: 3px;
      }

      &.btn-group {
        margin-bottom: 3px;
      }
    }

    .btn-remove-language {
      border: none !important;
      color: $color-nero;

      &:hover {
        color: $primary-active-color;
        background-color: inherit;
      }
    }

    .btn-add-another-language {
      position: relative;
      background-color: $color-white;
      text-transform: uppercase;
      border-color: $border-color;
      color: $primary-active-color;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      letter-spacing: 1px;
      width: 55%;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 18px 6px 6px;

      &:hover {
        border-color: $color-dark-grey;
        color: $primary-active-color;
      }
    }
  }

  .settings-category-title {
    margin-bottom: 0;
  }

  .settings-category-subtitle {
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    color: $color-light-pink;
    font-size: 42px;
    margin: -5px 0 0 0;
    padding: 0;

    &.subpage-title {
      text-align: right;
      margin-top: 35px;
    }
  }

  .settings-myurl-main {
    display: flex;

    .btn, .btn:hover {
      display: block;
      width: 100%;
      max-width: 150px;
      margin-bottom: 5px;
      background-color: $color-black;
      color: $color-white;
      font-size: 14px;
      text-align: left;
      outline: 0;
      line-height: 1em;
      padding-bottom: 4px;
    }
  }

  .settings-myurl-info {
    background-color: $color-lighter-blue;
    padding: 15px 17px;
    margin-right: 5px;
    margin-bottom: 5px;
    flex: 1;

    @include when-sm {
      padding: 15px 10px;
      flex: 8;
    }


    .content-main-title {
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-black !important;
      color: $color-light-grey;
      font-size: 16px;
      text-transform: uppercase;
      word-break: break-word;
    }
  }

  .settings-myurl-buttons {
    @include when-sm {
      flex: 4;
    }
  }

  .settings-myurl-form {
    .settings-myurl-input {
      background-color: $color-lighter-blue;
      padding: 23px 0;
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .myurl-domain {
      padding-top: 2px;
      margin-right: 5px;
    }

    input {
      height: 40px;
      width: 210px;
      padding: 8px 10px 6px 10px;
    }

    .btn, .btn:hover {
      display: inline-block;
      background-color: $color-black;
      color: $color-white;
      font-size: 20px;
      height: 40px;
      padding-bottom: 4px;
      outline: 0;
    }
  }

  .settings-myurl-description {
    margin-top: 10px;
  }

  .settings-input {
    p {
      min-height: 35px;
      color: $color-light-grey;
    }

    input,
    textarea {
      border-color: $color-lighter-grey !important;
    }

    .call-me {
      .bootstrap-select {
        > .dropdown-toggle {
          width: auto;
          max-height: none;
          background-color: $color-white;
          border: 1px solid $color-lighter-grey;
          padding-right: 5px;
          padding-top: 5px;
        }

        .filter-option {
          padding-top: 18px;
          width: auto;
        }
      }
    }

    label {
      margin: 5px 0 0 0;
    }
  }

  .gender-select {
    span:before {
      border: 1px solid $color-lighter-grey;
    }
  }

  .settings-experience-list {
    .settings-experience-block {
      border-left: 1px solid $color-light-grey;
      border-right: 1px solid $color-light-grey;
      background-color: $color-white;
      padding: 30px 10px;
      position: relative;

      &:first-child {
        border-top: 1px solid $color-light-grey;
      }

      &:last-child {
        border-bottom: 1px solid $color-light-grey;

        &::after {
          display: none;
        }
      }

      &::after {
        content: " ";
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(to right, $color-lighter-grey 50%, rgba(255, 255, 255, 0) 0);
        background-size: 10px 1px;
        background-repeat: repeat-x;
      }

      &.before-form {
        border-bottom: 1px solid $color-light-grey;

        &::after {
          display: none;
        }
      }

      .settings-experience-subject {
        font-weight: bold;
        font-size: 14px;
      }

      .settings-experience-place {
        font-size: 14px;
      }

      .settings-experience-date {
        font-size: 12px;
        color: $color-grey;
      }

      .settings-experience-button {
        background-color: $color-black;
        width: 50px;
        height: 25px;
        position: absolute;
        right: 0;
        top: 30px;
        padding: 2px 0 0 10px;

        img {
          height: 10px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .settings-experience-form {
    display: none;
    padding: 0 10px;
    margin: 10px 0 10px 0;

    .experience-type {
      display: none;
    }

    .row {
      padding-bottom: 10px;

      &:first-child {
        padding-bottom: 0;
      }
    }

    .form-close {
      text-align: right;
      line-height: 1;
      padding-top: 5px;

      .icon-close {
        display: inline-block;
        cursor: pointer;
        width: 10px;
      }
    }

    .settings-experience-subject {
      text-align: right;
      padding-right: 0;
      padding-top: 2px;
    }

    .settings-experience-year-until {
      display: inline-block;
      margin: 0 10px;
    }

    input {
      width: 95%;
      padding: 2px 5px;
    }

    .bootstrap-select {
      width: auto;
      min-width: 60px;
      height: auto;
      border: 1px solid $color-grey;

      .dropdown-toggle {
        padding: 2px 5px;

        .bs-caret {
          display: inline-block;
        }
      }
    }

    .form-buttons {
      float: right;
      margin-right: -10px;

      .btn-save {
        float: right;

        &.disabled, &.disabled:hover, &.disabled:active, &.disabled.active {
          background-color: $color-light-grey !important;
          color: $color-white !important;
        }
      }

      .btn-remove {
        float: right;
        margin-top: 5px;
        margin-right: 30px;
        color: $color-grey;
      }

      .loader {
        float: right;
        top: -2px;
      }
    }
  }

  .settings-experience-form + .settings-experience-block + .settings-experience-block {
    /* when form is shown -> first sibling is hidden -> second sibling becomes the "top" in the list after the form */
    border-top: 1px solid $color-light-grey;
  }

  .settings-experience-add-buttons {
    margin-top: 30px;
    text-align: center;
  }

  .settings-channels {
    margin: 20px;

    @include when-xs {
      margin-right: 0;
    }


    .settings-channels-header {
      display: flex;
    }

    .settings-channels-item {
      display: flex;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 90%;
      background-color: $color-closer-white;
      margin-bottom: 1px;

      @include when-xs {
        width: 100%;
      }
    }

    .settings-channels-input {
      display: flex;
      height: 30px;

      input {
        width: 90%;
        padding-left: 10px;

        @include when-xs {
          width: 100%;
        }
      }
    }

    .settings-channels-type {
      display: flex;
      font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
      line-height: 1;
      font-size: 22px;
      align-items: center;
      justify-content: flex-end;
      padding-top: 3px;
      padding-right: 0;

      &.empty {
        opacity: 0.3;
      }

      &.web {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: bold;
        font-size: 16px;

        .web-logo {
          height: 20px;
        }
      }
    }
  }

  .has-error {
    input, textarea {
      background-color: $color-soft-red;
    }
  }

  /* ----------------------- payments page ----------------------- */

  &.settings-payments {
    .price-inputs {
      margin: 20px 0 40px 0;

      @include when-xs {
        width: 50%;
        margin: 20px auto 40px;
        display: block;
      }
    }

    .main-prices {
      color: $color-lighter-grey;
      margin-bottom: 30px;
    }

    .payment-price {
      position: relative;

      input {
        width: 150px;
        height: 36px;
        padding: 2px 40px 0 10px;
      }

      .payment-price-unit {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-size: 12px;
        color: $color-grey;
        position: absolute;
        z-index: 10;
        top: 4px;
        right: 7px;
        height: 30px;
        width: 26px;
        user-select: none;

        .payment-price-unit-currency {
          text-transform: lowercase;
          position: absolute;
          right: 10px;
        }

        .payment-price-unit-per {
          position: absolute;
          top: 4px;
          right: 10px;
          height: 20px;
          width: 1px;
          overflow: hidden;
          background-color: $color-grey;
          transform: rotate(45deg);
        }

        .payment-price-unit-time {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .payment-price-minute {
      background-color: $color-black;
      color: $color-white;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-black !important;
      font-size: 18px;
      padding: 2px 10px 3px 10px;
      width: 150px;

      .payment-price-minute-value {
        display: inline-block;
      }

      .payment-price-minute-pm {
        font-size: 10px;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        display: inline-block;
        position: relative;
        top: -7px;
      }
    }

    .payment-currency {
      select,
      .bootstrap-select {
        width: 120px;
        background-color: $color-white;
      }

      .bootstrap-select {
        .btn {
          background-color: $color-white;
          border: 1px solid $color-grey;
        }

        &.btn-group .dropdown-toggle .bs-caret {
          display: inline-block;
        }

        &.btn-group .dropdown-toggle .caret {
          right: 4px;
        }

        .dropdown-menu {
          opacity: 1;
          margin: 0;
        }
      }
    }
  }

  /* ----------------------- payments page / modal ----------------------- */

  .modal#change-currency {
    .modal-dialog {
      position: relative;
      width: 730px;

      @include when-xs {
        margin: 0px;
        width: 100vw !important;
        height: 100vh;
      }
    }

    .modal-content {
      width: 100%;
      background-color: transparent;
      border: none !important;
      overflow: hidden;

      @include box-shadow(none !important);
    }

    .modal-header {
      background-color: transparent;
      border: none !important;

      .close {
        opacity: $dark-alpha;

        &:hover {
          opacity: 1;
        }
      }
    }

    .modal-body {
      width: 100%;
      height: 500px;
      padding: 0;
      background-color: white;
      background-image: none;

      .buttons {
        padding-top: 0;
        position: static;
      }
    }

    .modal-page {
      width: 100%;
      height: 100%;

      &:before {
        content: "";
        position: absolute;
        left: 108px;
        width: 335px;
        height: 412px;
        background-color: $color-lighter-blue;
      }
    }

    .modal-main {
      text-align: center;
      color: $color-dark-grey;
      padding: 0 155px;

      h5 {
        text-transform: uppercase;
        font-size: 16px;
        font-family: proxima;
        font-weight: $font-weight-black !important;
        line-height: 15px;
      }

      p {
        font-size: 14px;
        font-family: proxima;
        line-height: 16px;
      }

      .currency-select {
        padding: 30px 70px 0 70px;

        .bootstrap-select {
          margin: 10px 0 20px 0;
          border: 1px solid $color-dark-grey;

          .bs-caret {
            display: inline-block;
          }
        }
      }

      .currency-disclaimer {
        padding: 0 0 20px 0;
      }
    }

    .has-error .form-control {
      border: solid 1px $color-warning !important;
    }

    .buttons {
      width: 220px;
      float: right;

      &.previous {
        z-index: 999;
        float: none;
        position: absolute;
        left: 0;
        top: 64px;
        display: none;
      }

      .bigbutton {
        margin-bottom: 10px;
      }
    }
  }

  /* ----------------------- call history page ----------------------- */

  &.settings-call-history {
    .balance-summary {
      float: right;

      td {
        padding-left: 10px;

        &.main-currency {
          font-weight: bold;
        }

        &.custom-currency {
          color: $color-lighter-grey;
        }
      }
    }

    .call-history-wrapper {
      @include when-xs {
        overflow: auto;
        display: block;
      }
    }

    .call-history {
      margin-top: 10px;
      width: 100%;

      th {
        background-color: $color-off-white;
        padding: 5px 10px;

        @include when-xs {
          &:nth-child(odd) {
            min-width: 150px;
          }

          &:nth-child(even) {
            min-width: 120px;
          }
        }
      }

      td {
        border-bottom: 1px solid $color-off-white;
        padding: 5px 10px;

        .call-earned {
          font-weight: bold;
        }

        .call-paid {
          color: $color-red;
        }

        .custom-currency {
          color: $color-lighter-grey;
        }
      }
    }

    .user-name {
      display: inline-block;
      padding-top: 5px;
      font-weight: bold;
      color: $color-nero;
    }

    .avatar-container-masked {
      width: 47px;
      height: 48px;
      overflow: hidden;
      position: relative;
      float: left;
      margin-right: 5px;

      .avatar-container-masked-mask {
        position: absolute;
        z-index: 10;
        width: 47px;
        height: 48px;
        background: cdn("/images/onboarding/avatar-mask.png") center center no-repeat;
        background-size: cover;
      }

      .avatar-container-masked-img {
        position: absolute;
        z-index: 5;
        width: 47px;
        height: 48px;
        background-color: $color-off-white;
        background-image: cdn("/images/onboarding/icon-avatar.png");
        background-position: center center;
        background-repeat: no-repeat;

        &.user_selected {
          background-size: cover;
        }
      }
    }
  }

  /* ----------------------- change password page ----------------------- */

  .settings-password-main-container {
    .row {
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      max-width: 200px;
    }

    .settings-password-buttons {
      margin-top: 10px;
    }
  }

  /* ----------------------- wallet pages ----------------------- */

  .wallet-buttons {
    padding: 20px 0 40px;
  }

  .payout-info {
    margin: 20px 0;
    width: 100%;

    .info {
      text-align: right;
    }

    .main {
      white-space: nowrap;
      padding: 0 5px;
    }

    .custom {
      font-size: 10px;
    }
  }

  .payout-request-container {
    background-color: $color-off-white;

    .payout-title {
      display: flex;
      margin-top: 10px;

      h3 {
        font-size: 36px;
        width: 40px;
      }

      p {
        padding: 9px 0 4px 0;
        line-height: 1.2em;
      }
    }

    .currency-input {
      position: relative;
      margin-bottom: 10px;

      .currency-prefix {
        position: absolute;
        left: 27px;
        top: 8px;

        &.nosign {
          left: 20px;
          top: 10px;
          font-size: 12px;
        }
      }

      input {
        padding-left: 40px;
        background-image: linear-gradient(to left, $color-grey 100%, rgba(255, 255, 255, 0) 0);
        background-size: 1px 35px;
        background-repeat: no-repeat;
        background-position: 32px 0;
      }

      .custom-currency {
        color: $color-lighter-grey;
        padding-left: 10px;
      }
    }

    input {
      height: 35px;
    }

    .dashline {
      height: 1px;
      background-image: linear-gradient(to right, $color-grey 50%, rgba(255, 255, 255, 0) 0);
      background-size: 10px 1px;
      background-repeat: repeat-x;
    }

    .comment {
      padding-top: 5px;
      line-height: 1em;
      font-weight: bold;
      display: block;

      p {
        font-size: 12px;
        font-weight: normal;
        margin: 0;
        color: $color-grey;
      }
    }

    .payout-method-details {
      position: relative;
      display: none;

      p {
        margin: 10px 0;
      }

      .btn-holder {
        margin: 20px 0;
      }

      .btn-primary {
        float: right;
      }
    }
  }

  .payout-modal {
    .modal-dialog {
      width: 500px;
    }

    .modal-title {
      margin: 20px 0;
    }
  }
}

/* bright blue seemingly-random background parts */
.eye-candy {
  position: relative;
  width: 100%;
  display: inline-block;

  > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: ' ';
    position: absolute;
    background-color: $color-bright-blue;
  }
}

#my-call-settings,
#my-profile,
#my-payments {
  h2 {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}

#my-profile-profile-pic {}

#my-profile-media {}

#my-profile-tagline {
  #input-tagline.eye-candy::before {
    width: 140px;
    height: 100px;
    top: -30px;
    left: -10px;
  }
}

#my-profile-profession {
  #input-profession.eye-candy::before {
    width: 30px;
    height: 100px;
    top: -60px;
    right: -10px;
  }
}

#my-profile-about-me {
  #input-about-me.eye-candy::before {
    background-color: $color-closer-white;
    width: calc(100% - 20px);
    height: 230px;
    top: 20px;
    left: -10px;
  }
}

#my-profile-call-button {}

#settings-experiences {
  .eye-candy::before {
    width: 78%;
    height: 100%;
    right: 0;
  }
}

#settings-channels {
  .eye-candy::before {
    width: 78%;
    height: 100%;
    right: 0;
    top: 18px;

    @include when-xs {
      width: 100%;
      right: -10px;
    }
  }
}

#my-profile-languages {
  #input-languages.eye-candy::before {
    width: calc(50% + 10px);
    height: calc(100% + 50px);
    top: -10px;
    right: -10px;
  }
}

#my-profile-location-gear {
  #input-gear.eye-candy::before {
    width: 90px;
    height: calc(100% + 40px);
    top: -10px;
    right: 0px;
  }
}

#my-profile-personal-details {
  #personal-details-2.eye-candy::before, #personal-details-1.eye-candy::before {
    width: 50%;
    height: calc(100% + 20px);
    top: -10px;
    left: -15px;
  }

  .combodate .btn-group {
    background-color: $color-white;
  }

  .radio-select span::before {
    background-color: $color-white;
  }
}

#my-profile-cost {
  #input-cost.eye-candy::before {
    width: calc(100% - 40px);
    height: calc(100% + 40px);
    top: -10px;
    right: -10px;

    @include when-xs {
      height: calc(100% + 30px);
      right: -30px;
    }
  }

  .currency-info {
    @include when-xs {
      width: 50%;
      display: block;
      margin: auto;
    }
  }

  .btn-group {
    background-color: $color-white;
  }

  .input-group {
    input.currency {
      padding-left: 30px;
    }

    .currency-prefix {
      position: absolute;
      left: 7px;
      top: 11px;
      color: $color-lighter-grey;
      z-index: 10;
      font-size: 10px;
    }
  }
}

#my-profile-availability {
  #input-availability.eye-candy::before {
    background-color: $color-off-white;
    width: calc(90%);
    height: calc(100% - 10px);
    top: 31px;
    left: -10px;
  }

  .btn-group {
    background-color: $color-white;
  }

  .radio-select span::before {
    background-color: $color-white;
  }

  h2 {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}

#my-call-settings {
  .allow-calls-intro {
    margin-bottom: 10px;
  }

  .app-download {
    @include when-xs {
      margin: 20px auto 10px;
    }
  }
}

.embed-profile {
  margin-bottom: 30px;
  background-color: transparent;

  .settings-group-item-title {
    padding: 0 10px;

    h2 {
      color: $color-teal;
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  .embed-icons {
    padding-top: 15px;
    margin-top: 20px;

    .embed-wp,
    .embed-html {
      img {
        width: 100%;
        height: auto;

        @include when-xs {
          max-width: 120px;
          margin: auto;
          display: block;
        }
      }

      .content-main-title {
        font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
        padding: 15px 0;
      }
    }
  }

  .embed-profile-desc {
    padding: 0 10px;
    flex: 1;
  }

  .content-button {
    width: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 0 1px 0;
    }

    .settings-button {
      background-color: $color-nero;
      color: $color-white;
      height: 23px;
      padding-bottom: 3px;
      cursor: pointer;

      img {
        height: 10px;
      }

      &::hover {
        background-color: $color-light-pink;
      }
    }

    &::hover .settings-button {
      background-color: $color-light-pink;
    }
  }
}

[data-to-root].community {
  .faqs {
    .faq {
      .question {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
        font-size: 16px;
        text-transform: initial;
        cursor: pointer;

        @include when-xs {
          margin: 15px 0;
        }


        &:hover {
          color: $color-light-pink;
        }

        &::after {
          display: inline-block;
          content: ' ';
          background-image: cdn("/images/icon-next.svg");
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          left: 4px;
          position: relative;
          top: 3px;
          background-repeat: no-repeat;
          background-position: center;

          @include when-xs {}
        }
      }

      .answer {
        padding: 10px 20px;
        display: none;
      }

      &.active {
        .question {
          &::after {
            transform: rotate(90deg);
          }
        }

        .answer {
          display: block;
        }
      }
    }
  }

  .affiliates {
    .affiliate-link:hover {
      .see-profile-button {
        background-color: $color-grey;
        color: $color-white;
      }
    }

    .affiliate {
      & > .row {
        border-top: solid 0.5px $color-grey;
        margin: 0px 0px;
        padding: 20px 0px;
      }

      .avatar {
        width: 64px;
        height: 64px;
        padding-bottom: initial;
        position: relative;

        .avatar-image {
          width: 64px;
          height: 64px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &::after {
          content: ' ';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 64px;
          height: 64px;
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 145 145' style='enable-background:new 0 0 145 145;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,0v145h145V0H0z M72.5,141.4h-0.1c-16.4,0-29.9-4.7-40.4-12.3L5.1,141.4l12.5-27.2C9.3,102.1,5.1,87.2,5.1,72 c0-32.8,20.3-68.4,67.1-68.4h0.4c45,0,67.3,33.7,67.3,68.8C139.7,107.9,117.1,141.4,72.5,141.4z'/%3E%3C/svg%3E");
          background-size: 64px 64px;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .name {
        font-family: bebas;
        font-weight: $font-weight-bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: $color-grey;
      }
    }

    .see-profile-button {
      border: none;
      color: $color-grey;
      background-color: transparent;
    }
  }

  .social-icons {
    zoom: 1.5;
  }

  .read-toc:hover {
    color: $color-light-pink;
  }

  .important-link {
    color: $color-violet;
    font-weight: bold;
    font-size: 24px;
    position: relative;
    top: 3px;
    word-break: break-all;
  }

  .settings-group-item.community-intro {
    background-color: $color-grey;
    background-image: cdn("/images/community-bg-asset-01.png");
    background-size: contain;
    background-position: right bottom;
    background-repeat: no-repeat;

    .padded {
      padding: 20px;
    }

    h2 {
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .community-options {
    margin-top: 30px;

    .community-option {
      margin-bottom: 3px;

      .accept-sidebar {
        padding-bottom: 15px;

        .btn-primary {
          position: relative;
          right: -10px;
        }
      }

      &#community-option-3 {
        .preview-email {
          .language {
            .wide {
              margin-bottom: 0;
              margin-right: 10px;
            }

            button {
              width: 150px;
              border: 1px solid $color-lightest-grey;
            }
          }

          .preview {
            right: -10px;

            @include when-sm {
              bottom: -15px;
            }
          }
        }

        .invite-email {
          .emails-wrapper {
            @include when-xs {
              flex-direction: column;
            }
          }

          input {
            border: 1px solid $color-lightest-grey;
          }

          .invite-btn-wrapper {
            right: -10px;
            justify-content: flex-end;
            align-items: flex-end;

            @include when-sm {
              bottom: 20px;
            }


            .btn {
              border: none;
              font-size: 20px;
              letter-spacing: 1px;
              padding: 10px 16px 6px;
            }
          }
        }

        .email-lang {
          padding: 18px;

          .form-control {
            border: 1px solid $color-close-white;
          }
        }

        h4 {
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          font-size: 22px;
          color: $color-light-grey;
        }

        .btn-remove-language {
          border: none !important;
          color: #333333;
        }
      }

      &#community-option-3,
      &#community-option-5 {
        .section {
          margin-bottom: 10px;

          .section-inner {
            padding: 18px;
          }
        }
      }

      &#community-option-5 {
        .community-option-content-item .flex {
          @include when-xs {
            flex-direction: column;
          }
        }

        .embedded-profile {
          margin-bottom: 20px;

          img {
            max-width: 300px;

            @include when-sm {
              margin-top: -15px;
            }
          }
        }

        .section {
          .embed-icon-wrapper {
            margin-bottom: 30px;
          }

          .embed-icon {
            margin-right: 30px;

            img {
              max-width: 93px;
            }

            .subtitle {
              margin-top: 15px;
              font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
              font-size: 12px;
            }
          }

          button {
            margin-top: 40px;
            width: fit-content;
            min-width: 290px;
            text-align: left !important;

            img {
              width: 6px;
            }
          }

          .code-wrapper {
            max-width: 300px;
            margin-top: 30px;

            .form-control {
              text-transform: none;
              -webkit-appearance: none;
              background: cdn("/images/icon-next-thick.svg") no-repeat right $color-nero;
              background-size: 9px;
              background-position: right 10px top 9px;
            }
          }

          code {
            white-space: normal;
            color: #c7254e;
          }
        }
      }

      &#community-option-6 {
        .btn {
          margin-bottom: 10px;
          width: fit-content;
          min-width: 210px;

          img {
            width: 6px;
            height: 10px;
            margin-left: 10px;
          }
        }

        .spacer {
          margin-bottom: 50px;

          @include when-xs {
            margin-bottom: 30px;
          }
        }

        img.thumb {
          width: 100%;
        }

        .short-movie-thumb {
          flex-basis: calc( (100% + 10px)/4 - 10px);
        }

        .valign {
          @include when-xs {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .movies > div:last-child {
          @include when-xs {
            margin-top: 20px;
          }
        }
      }

      &#community-option-7 {
        .img-wrapper {
          position: relative;

          &:before {
            content: ' ';
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: repeating-linear-gradient(60deg, rgba(200, 200, 200, 0.2), rgba(200, 200, 200, 0.2) 10px, rgba(230, 230, 230, 0) 10px, rgba(230, 230, 230, 0) 20px);
            position: absolute;
          }

          .coming-soon {
            color: $color-grey;
            font-size: 60px;
            font-family: bebas;
            font-weight: $font-weight-bold;
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            text-align: right;
          }
        }
      }

      &#community-option-8 {
        .community-option-content-item img {
          width: 320px;
          height: auto;
          max-width: 100%;
        }
      }

      .community-option-title {
        cursor: pointer;
        background-color: $color-off-white;
        color: $color-nero;
        font-family: bebas;
        font-weight: $font-weight-light;
        font-size: 42px;

        @include when-xs {
          font-size: 26px;
          padding-top: 6px;
          padding-bottom: 6px;
        }


        &.coming-soon {
          cursor: default;
          color: #D9D9D9;
        }

        .community-option-title-index {
          background-color: $color-violet;
          font-weight: $font-weight-normal;
          color: $color-white;
          text-align: center;

          span {
            text-align: center;
            top: -3px;
            left: -7px;
            position: relative;
          }
        }

        &.coming-soon .community-option-title-inde {
          background-color: #D9D9D9;
        }

        .community-option-title-text {
          span {
            top: 4px;
            position: relative;
          }

          span.tiny {
            font-size: 16px;
          }

          background-color: transparent;
          background-color: transparent;
        }

        .community-option-title-indicator {
          background-image: cdn("/images/icon-next.svg");
          background-size: 24px 24px;
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          background-position: center 16px;

          @include when-xs {
            background-size: 10px;
            width: auto;
            height: 37px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &:not(.coming-soon):hover {
          background-color: rgba($color-violet, $light-alpha);

          .community-option-title-index {
            color: $color-soft-red;
          }
        }
      }

      .community-option-content {
        display: none;

        & > div.community-option-content-item {
          padding: 40px 20px;
        }
      }

      &.active {
        .community-option-title-indicator {
          background-image: cdn("/images/icon-next-thick.svg");
          background-position: 20px 16px;
          transform: rotate(90deg);

          @include when-xs {
            width: 26px;
            height: 26px;
            background-position: bottom 5px center;
          }
        }

        .community-option-title {
          background-color: $color-dark-grey;
          color: $color-white;

          &:hover {
            background-color: $color-grey;
          }
        }

        .community-option-content {
          display: block;
        }
      }

      .social-share-example {
        padding: 10px;
        border: solid 0.5px $color-lighter-grey;

        .social-share-example-title {
          font-size: 24px !important;
          font-family: Times,serif;
          color: $color-black;
        }

        .social-share-example-description {
          font-size: 13px !important;
          color: $color-nero;
        }

        .social-share-example-url {
          text-transform: uppercase;
          color: $color-grey;
        }
      }
    }
  }
}

[data-to-root].settings.webinar {
  $webinar-left-padding: 20px;
  $webinar-right-padding: 20px;
  $webinar-left-padding-sm: 25px;
  $webinar-right-padding-sm: 25px;
  $webinar-left-padding-xs: 20px;
  $webinar-right-padding-xs: 20px;

  @mixin webinar-container {
    @include when-xs {
      padding-left: $webinar-left-padding-xs !important;
      padding-right: $webinar-right-padding-xs !important;
    }


    @include when-sm {
      padding-left: $webinar-left-padding-sm !important;
      padding-right: $webinar-right-padding-sm !important;
    }


    @include when-md {
      padding-left: $webinar-left-padding !important;
      padding-right: $webinar-right-padding !important;
    }
  }

  @mixin webinar-row-left {
    @include when-xs {
      margin-left: -#{$webinar-left-padding-xs} !important;
    }


    @include when-sm {
      margin-left: -#{$webinar-left-padding-sm} !important;
    }


    @include when-md {
      margin-left: -#{$webinar-left-padding} !important;
    }
  }

  @mixin webinar-row-right {
    @include when-xs {
      margin-right: -#{$webinar-right-padding-xs} !important;
    }


    @include when-sm {
      margin-right: -#{$webinar-right-padding-sm} !important;
    }


    @include when-md {
      margin-right: -#{$webinar-right-padding} !important;
    }
  }

  @mixin container-width {
    @include when-xs {
      max-width: $container-xs;
    }


    @include when-sm {
      max-width: $container-sm;
    }


    @include when-md {
      max-width: $container-md;
    }


    @include when-lg {
      max-width: $container-lg;
    }
  }

  &.settings-webinars-subscribe {
    .top-section {
      @include make-container;
      @include webinar-container;

      .section-inner {
        @include make-row;

        background-color: #F7E1E3;
        padding-top: 50px;
        padding-bottom: 40px;
        display: flex;
      }

      .left-section {
        @include when-xs {
          @include make-xs-column(12);
        }


        @include when-sm {
          @include make-sm-column(7);
        }


        @include when-md {
          @include make-md-column(7);
        }


        @include when-lg {
          @include make-lg-column(7);
        }


        h1 {
          padding-left: $webinar-left-padding;
          margin-bottom: 0;

          b {
            color: #65686B;
          }
        }

        .img {
          padding-left: $webinar-left-padding;
          margin: 0 0 30px;

          @include when-xs {
            margin-bottom: 30px;

            @include webinar-row-left;
            @include webinar-row-right;
          }


          @include when-xs {
            height: 127px;
            background-position: center;
            background-image: cdn("/images/home/theone-webinars.png");
            background-repeat: no-repeat;
            background-size: cover;
          }


          img {
            display: block;
            margin: auto;
            margin-bottom: 30px;
            max-width: 100%;
            height: auto;
          }
        }
      }

      .right-section {
        margin-left: auto;
        padding-left: $webinar-right-padding;

        @include when-xs {
          @include make-xs-column(12);
        }


        @include when-sm {
          @include make-sm-column(5);
        }


        @include when-md {
          @include make-md-column(5);
        }


        @include when-lg {
          @include make-lg-column(3);
        }


        img {
          margin-bottom: 15px;

          @include when-sm-down {
            width: auto;
            height: 40px;
          }
        }

        ul {
          list-style: none;
          padding-left: 15px;

          li {
            position: relative;

            &:before {
              content: '_';
              position: absolute;
              left: -15px;
            }
          }
        }
      }
    }

    .bottom-section {
      @include make-container;
      @include webinar-container;

      font-size: 20px;

      .section-inner {
        @include make-row;

        padding-top: 50px;
        padding-bottom: 40px;
        display: flex;

        @include when-sm-down {
          display: flex;
          flex-flow: column;
          align-items: center;
        }
      }

      .left-section {
        @include when-xs {
          @include make-xs-column(12);
        }


        @include when-sm {
          @include make-sm-column(12);
        }


        @include when-md {
          @include make-md-column(7);
        }


        @include when-lg {
          @include make-lg-column(6);
        }
      }

      .right-section {
        display: none;
        margin: 40px auto;

        @include when-xs {
          @include make-xs-column(12);
        }


        @include when-sm {
          @include make-sm-column(8);
        }


        @include when-md {
          @include make-md-column(4);

          margin: 0 auto;
        }


        @include when-lg {
          @include make-lg-column(5);
        }
      }

      .left-section .title {
        padding-left: $webinar-left-padding;
      }

      .right-section {
        .title {
          @include when-md {
            height: 2.8em;
          }
        }

        .payment-choose {
          padding-top: 0;
          margin-top: 20px;

          @include when-md {
            margin-top: 40px;
          }
        }
      }

      .payment-choice {
        @include make-row;

        display: flex;
        position: relative;
        justify-content: space-between;

        .payment-monthly,
        .payment-yearly {
          margin-top: 40px;
          text-align: center;
          position: relative;
          cursor: pointer;

          &.selected {
            box-shadow: inset 0 0 0 10px $color-bright-light-pink;

            .checkout-button {
              color: $color-white;
              background-color: $color-nero;
              opacity: 1 !important;
            }
          }

          @include when-xs {}


          @include when-sm {
            width: calc(50% - 15px);
            padding: 50px 20px 30px;
            background-color: $color-off-white;
          }


          input {
            opacity: 0;
            position: absolute;
          }

          b,
          .yearly-btn, .monthly-btn {
            font-size: 20px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            margin-bottom: 30px;
            color: $color-nero;

            span {
              font-size: 44px;
              line-height: 2;
              display: block;
              color: $color-darker-grey;
            }
          }

          .yearly-btn,
          .monthly-btn {
            height: 40px;
            line-height: 40px;
            width: 181px;
            border: 1px solid $color-nero;
            margin: 40px auto 0;

            &:before {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }

            &:hover {
              color: $color-white;
              background-color: $color-nero;

              &:before {
                border: 10px solid $color-bright-light-pink;
              }
            }
          }
        }

        .payment-yearly {
          margin-left: auto;
        }
      }
    }
  }

  #payment-success .bottom-section {
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;

      a {
        font-size: 28px;
        margin: 10px auto;
        width: auto;
        background-color: $color-white;
        border: solid 1px $color-nero;
        padding: 10px;
        font-weight: $font-weight-bold;
        color: $color-nero;
        text-transform: uppercase;

        &::after {
          display: inline;
          content: ' >';
        }

        &:hover {
          color: $color-primary;
          border: solid 1px $color-primary;
        }
      }
    }
  }

  &.settings-webinars-published, #payment-success {
    .top-section {
      @include make-container;
      @include webinar-container;

      .section-inner {
        @include make-row;

        background-color: #F7E1E3;
        padding-top: 50px;
        padding-bottom: 40px;
      }

      h1 {
        margin-top: 30px;
        text-align: center;
        margin-bottom: 0;

        b {
          color: #65686B;
          display: block;
        }
      }

      .img {
        display: flex;
        margin: auto;
        width: fit-content;
        position: relative;

        @include when-xs {
          @include webinar-row-left;
          @include webinar-row-right;
        }


        @include when-xs {
          height: 127px;
          background-position: center;
          background-image: cdn("/images/home/theone-webinars.png");
          background-repeat: no-repeat;
          background-size: cover;
        }


        .logo {
          width: 0;
          height: auto;
          display: flex;
          align-items: center;
        }

        img {
          display: block;
          margin: auto;
        }
      }
    }

    .bottom-section {
      padding-top: 50px;
      padding-bottom: 40px;

      .webinar-item {
        display: block;
        margin: auto;
        max-width: 320px;
        position: relative;

        .image {
          width: 100%;
          height: 180px;
          position: relative;

          &.video::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include icon-play($color-nero);
          }
        }

        .image-small {
          width: 100%;
          height: 100%;
        }

        .image-small[src=""], .image-small:not([src]) {
          background-color: $color-white !important;
          border: dashed 6px $color-closer-white;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
        }

        .details {
          padding: 15px 10px 27px;
          position: relative;
          font-size: 20px;

          @include when-md {
            padding: 25px 15px 48px;
          }


          .unset {
            font-style: italic;
          }
        }

        .title, .price {
          font-weight: $font-weight-bold;
        }

        .price, .time {
          display: inline-block;
        }

        .price {
          &:before {
            content: '-';
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }
}
