
.sequence {
	.thumbnail { margin-top: 10px; margin-bottom:5px; } 
	.current .thumbnail { border-color:$color-red;} 
	.caption h6 { font-size:11px; padding:2px; margin:0px; text-align:right;} 
}


@include when-xs { }
@include when-sm { }
@include when-md { }
@include when-lg { }

[data-to-root]#app-layout.virtual-tour {

	@include when-xs {
		padding-top: 0;
	}

	background-color: $color-black;

	.navbar {
		display: none;
	}

	.full-container {
		background-color: $color-black;
		padding-top: $navbar-height;

		@include when-xs {
			padding-top: 0;
		}
	}

	.container-virtual-tour {

		width: auto !important;
		max-width: none !important;
		height: auto !important;
		padding: 0 !important;
		background-color: #111111;

		@include when-xs {
			margin-top: calc((100vh - 211px - 51px) / 2);
		}

		#video-container {
			max-width: 800px;
			margin: auto;
			position: relative;
		}

		#access-explanation {
			display:none;
			&.show { display:block; }
		}

		#videos {
			width: 100%;
			min-height: 200px;
			max-height: 100%;
			overflow: hidden;
			position: relative;
			background-color: $color-off-black;
		}

		&.webinar-call {
			#videos {
				min-height: auto;
			}
			#access-explanation {
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 1000;
			}

			&.group-call-fullsize {
				#video-container {
					align-items: flex-start;
				}
				#video-banner {
					z-index: 58; /* why is .group-videos-container 56? probably just some random num... */
				}
				#videos {
					position: relative;
					width: 100%;
					height: calc(100% - 62px); /* $call-ui-button-size-lg: 62px; */

					#tour-view {
						position: absolute;
						top: 0;
						left: 0;
						padding: 0;
						width: auto !important;
						height: auto !important;
						z-index: 57; /* why is .group-videos-container 56? probably just some random num... */

						&.grid-1 {
							width: 100% !important;
							height: 100% !important;
						}
						&.grid-2 {
							width: 50% !important;
							height: 100% !important;
						}
						&.grid-3,
						&.grid-4 {
							width: 50% !important;
							height: 50% !important;
						}
						&.grid-5,
						&.grid-6 {
							width: 33% !important;
							height: 50% !important;
						}
						&.grid-7,
						&.grid-8,
						&.grid-9 {
							width: 33% !important;
							height: 33% !important;
						}
						&.grid-10,
						&.grid-11,
						&.grid-12 {
							width: 25% !important;
							height: 33% !important;
						}
						&.grid-13,
						&.grid-14,
						&.grid-15,
						&.grid-16 {
							width: 25% !important;
							height: 25% !important;
						}
						&.grid-17,
						&.grid-18,
						&.grid-19,
						&.grid-20 {
							width: 20% !important;
							height: 25% !important;
						}
						&.grid-21,
						&.grid-22,
						&.grid-23,
						&.grid-24,
						&.grid-25 {
							width: 20% !important;
							height: 20% !important;
						}
					}
				}
			}
		}

		#tour-view {
			@extend .embed-responsive;
			@extend .embed-responsive-16by9;
			//height: calc(100vh - 51px - #{$navbar-height});
			z-index: 10;
			margin:auto;
			background-color: $color-off-black;
			width: 100%!important;

			.video-preview {
				width:100%;
			}
		}

		#tour-view .OT_subscriber {
			@extend .embed-responsive;
			@extend .embed-responsive-16by9;
			height: 100%;
		}

		#my-view {
			position: absolute;
			right: 20px;
			bottom: 20px;
			z-index: 100;
			width: $video-width-small !important;
			height: $video-height-small !important;
			border: 1px solid $color-white;
		}

		.secondary-view-container {
			position: absolute;
			right: 20px;
			bottom: 20px;
			z-index: 90;
			width: 200px !important;
			height: 150px !important;
			border: 1px solid $color-white;

			#my-view {
				position: relative;
				right: 0;
				bottom: 0;
				width: 100% !important;
				height: 100% !important;
				border: 0 none;

				@include when-xs{
					bottom: 0 !important;
				}
			}

			.stream-overlay {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 110;
				width: 100%;
				height: 100%;
			}

			.stream-label {
				width: 100%;
				height: 22px;
				background-color: $color-panel-bg;
				position: absolute;
				bottom: 0;
				left: 0;
				color: $color-white;
				display: flex;
				align-items: center;
				user-select: none;

				@include when-xs {
					background-color: rgba(0,0,0,.5);
				}

				.stream-label-text {
					flex: 1;
					height: 100%;
					padding-left: 10px;
					padding-top: 1px;
					overflow: hidden;
					text-align: right;
				}
				.stream-label-button {
					width: 30px;
					height: 100%;
					background-image: cdn("/images/icons/icon-tips.png");
					background-position: center center;
					background-repeat: no-repeat;
					background-size: 15px;
					background-color: transparent;
					border: 0 none;
					overflow: hidden;
				}
			}

			.stream-close {
				width: 22px;
				height: 22px;
				background-color: $color-panel-bg;
			 	background-image: cdn("/images/icon-cross-white.svg");
				background-repeat: no-repeat;
				background-size: 8px;
				background-position: center;
				position: absolute;
				top: 0;
				right: 0;
				border: 0 none;
			}
		}

		&.webinar-call-host {
			.secondary-view-container {
				z-index: 210; /* higher than than #video-controlpanel (200) so .stream-label-button is clickable */
				/* note: for the viewer participant, it's lower to not overlap endcall button when splitscreen */
				&.options-while-splitscreen {
					z-index: 199;
					/* special case: when the options panel is open (which is part of controlpanel), must go under it */
				}
			}
		}

		&.webinar-call-participant {
			.group-videos-container {
				/* as long as they only see themselves in group call, show self stream similar to normal call */
				right: 20px;
				top: auto;
				bottom: 63px; /* normal control button height is 62px, see $call-ui-button-size-lg */
				z-index: 90;
				width: 200px;
				height: 152px;
				border: 1px solid $color-white;
				.group-stream {
					> div {
						width: 200px !important;
						height: 150px !important;
						min-width: 180px;
					}
				}

				@include when-xs {
					right: 0;
					bottom: calc(6px + #{$navbar-xs-height});
				}
			}
			#video-controlpanel {
				&.video-controlpanel-webinar {
					@include when-xs {
						#video-controlpanel-buttons {
							.video-controlpanel-buttons-block {
								&.video-controlpanel-buttons-middle {
									justify-content: flex-end;
									align-items: flex-end;
								}
								&.video-controlpanel-buttons-right {
									display: none;
								}
							}
						}
					}
				}
			}
		}

		.group-videos-container {
			display: none;
			position: absolute;
			z-index: 56;
			right: 0;
			top: 0;
			width: 50%;
			height: 100%;
			background-color: $color-nero;

			.group-videos {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-flow: row wrap;
				overflow: hidden;
				height: 100%;

				.group-stream-container {
					position: relative;
					width: 20%;
					height: 20%;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: rgba(100, 100, 100, 0.3);
					overflow: hidden;

					&:first-child:nth-last-child(1) {
						width: 100%;
						height: 100%;
					}
					&:first-child:nth-last-child(2),
					&:first-child:nth-last-child(2) ~ .group-stream-container {
						width: 100%;
						height: 50%;
					}
					&:first-child:nth-last-child(3),
					&:first-child:nth-last-child(3) ~ .group-stream-container {
						width: 100%;
						height: 33%;
					}
					&:first-child:nth-last-child(4),
					&:first-child:nth-last-child(4) ~ .group-stream-container {
						width: 50%;
						height: 50%;
					}
					&:first-child:nth-last-child(5),
					&:first-child:nth-last-child(5) ~ .group-stream-container,
					&:first-child:nth-last-child(6),
					&:first-child:nth-last-child(6) ~ .group-stream-container {
						width: 33%;
						height: 50%;
					}
					&:first-child:nth-last-child(7),
					&:first-child:nth-last-child(7) ~ .group-stream-container,
					&:first-child:nth-last-child(8),
					&:first-child:nth-last-child(8) ~ .group-stream-container,
					&:first-child:nth-last-child(9),
					&:first-child:nth-last-child(9) ~ .group-stream-container {
						width: 33%;
						height: 33%;
					}
					&:first-child:nth-last-child(10),
					&:first-child:nth-last-child(10) ~ .group-stream-container,
					&:first-child:nth-last-child(11),
					&:first-child:nth-last-child(11) ~ .group-stream-container,
					&:first-child:nth-last-child(12),
					&:first-child:nth-last-child(12) ~ .group-stream-container {
						width: 33%;
						height: 25%;
					}
					&:first-child:nth-last-child(13),
					&:first-child:nth-last-child(13) ~ .group-stream-container,
					&:first-child:nth-last-child(14),
					&:first-child:nth-last-child(14) ~ .group-stream-container,
					&:first-child:nth-last-child(15),
					&:first-child:nth-last-child(15) ~ .group-stream-container,
					&:first-child:nth-last-child(16),
					&:first-child:nth-last-child(16) ~ .group-stream-container {
						width: 25%;
						height: 25%;
					}
					&:first-child:nth-last-child(17),
					&:first-child:nth-last-child(17) ~ .group-stream-container,
					&:first-child:nth-last-child(18),
					&:first-child:nth-last-child(18) ~ .group-stream-container,
					&:first-child:nth-last-child(19),
					&:first-child:nth-last-child(19) ~ .group-stream-container,
					&:first-child:nth-last-child(20),
					&:first-child:nth-last-child(20) ~ .group-stream-container {
						width: 25%;
						height: 20%;
					}
					&:first-child:nth-last-child(21),
					&:first-child:nth-last-child(21) ~ .group-stream-container,
					&:first-child:nth-last-child(22),
					&:first-child:nth-last-child(22) ~ .group-stream-container,
					&:first-child:nth-last-child(23),
					&:first-child:nth-last-child(23) ~ .group-stream-container,
					&:first-child:nth-last-child(24),
					&:first-child:nth-last-child(24) ~ .group-stream-container,
					&:first-child:nth-last-child(25),
					&:first-child:nth-last-child(25) ~ .group-stream-container {
						width: 20%;
						height: 20%;
					}

					&.stream-connected {
						border: 2px solid $color-really-red;
					}

				}

				.group-stream-controls {
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 22px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: rgba(100, 100, 100, 0.2);
					font-size: 12px;
					color: white;
				}

				.group-stream-label {
					max-width: 60%;
					overflow: hidden;
					padding: 2px 10px;
				}

				.group-stream-buttons {
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					button {
						width: 22px;
						height: 22px;
						background-size: 22px;
						background-position: center center;
						background-repeat: no-repeat;
						border: 0 none;
					}

					.stream-connect {
						background-image: cdn("/images/icons/icon-logo.png");
						background-size: 12px;
						background-color: transparent;
						&.active {
							background-image: cdn("/images/icons/webinar/icon-cam-red.svg");
						}
					}

					.stream-muted {
						padding: 0;
						opacity: 0.2;
						cursor: default;
						display: flex;
						align-items: center;
						justify-content: center;
						&.active {
							opacity: 1;
							cursor: not-allowed;
						}
						span {
							display: block;
							width: 100%;
							height: 2px;
							background-color: $color-really-red;
							border-radius: 1px;
							transform-origin: center;
							transform: rotate(45deg);
						}
					}

					.stream-close {
						background-color: $color-panel-bg;
						background-image: cdn("/images/icon-cross-white.svg");
						background-size: 8px;
					}
				}

				.group-stream {
					min-width: 170px;
					min-height: 100px;
					width: 100%;
					height: 100%;
					background-color: $color-nero;
					> div {
						width: 100% !important;
						height: 100% !important;
					}
				}
			}

			&.fullsize {
				/* the more regular groupcall layout, host is just the first stream box */
				width: 100%;

				.group-videos {
					.group-stream-container {
						/* only definitions for those different from when not fullsize */
						&:first-child:nth-last-child(2),
						&:first-child:nth-last-child(2) ~ .group-stream-container {
							width: 50%;
							height: 100%;
						}
						&:first-child:nth-last-child(3),
						&:first-child:nth-last-child(3) ~ .group-stream-container,
						&:first-child:nth-last-child(4),
						&:first-child:nth-last-child(4) ~ .group-stream-container {
							width: 50%;
							height: 50%;
						}
						&:first-child:nth-last-child(5),
						&:first-child:nth-last-child(5) ~ .group-stream-container,
						&:first-child:nth-last-child(6),
						&:first-child:nth-last-child(6) ~ .group-stream-container {
							width: 33%;
							height: 50%;
						}
						&:first-child:nth-last-child(10),
						&:first-child:nth-last-child(10) ~ .group-stream-container,
						&:first-child:nth-last-child(11),
						&:first-child:nth-last-child(11) ~ .group-stream-container,
						&:first-child:nth-last-child(12),
						&:first-child:nth-last-child(12) ~ .group-stream-container {
							width: 25%;
							height: 33%;
						}
						&:first-child:nth-last-child(17),
						&:first-child:nth-last-child(17) ~ .group-stream-container,
						&:first-child:nth-last-child(18),
						&:first-child:nth-last-child(18) ~ .group-stream-container,
						&:first-child:nth-last-child(19),
						&:first-child:nth-last-child(19) ~ .group-stream-container,
						&:first-child:nth-last-child(20),
						&:first-child:nth-last-child(20) ~ .group-stream-container {
							width: 20%;
							height: 25%;
						}
					}
				}

			}
		}

		#tour-view:before {
			display:block;
			position:absolute;
			text-align:center;
			width:100%;
			@extend .embed-responsive;
			@extend .embed-responsive-16by9;
			background-color: $color-blue;
			color: $color-white;
		}
		&.opentok #tour-view:before {
			z-index:1;
		}
		&.vidyo #tour-view:before {
			z-index:-1;
		}
		&.opentok #tour-view:after {
			display:block;
			position:absolute;
			text-align:center;
			vertical-align:middle;
			width:100%;
			@extend .embed-responsive;
			@extend .embed-responsive-16by9;
			z-index:20;
			white-space: pre; 
			// content:"\A\A\A\A\AWaiting for connection...";
			color:rgba($color-white,0.7);

		}

		#tour-view.OT_subscriber:before {
			content: "";
			display:none;
		}

		#tour-view .OT_subscriber {
			z-index:1;
		}

		#tour-view .OT_widget-container {
			background-color: $color-off-black;
		}

		.rating-quality {
			> div { display:inline-block; }
			.rating-attribute { padding:5px 0px 3px 10px;}
			.rating-stars { position:relative;top:6px;}
			color: $color-white;
		}

		#video-controlpanel {
			width: 100%;
			background-color: $color-off-black;
			position: relative;

			#video-controlpanel-buttons {
				width: 100%;
				padding: 10px 22px 10px 18px;
				margin: 0;

				@include when-xs{
					padding: 10px 20px;
					display: flex;
					justify-content: space-between;
				}

				.vi-btn {
					transition: unset;
				}

				.vi-hangup {
					position: absolute;
					left: 50%;

					@include when-xs{
						position: relative;
						left: auto;
					}
				}
				.vi-microphone, .vi-screen-sharing {
					margin-left: 22px;

					@include when-xs{
						margin-left: 0;
					}
				}

				.vi-screen-sharing {
					@include when-xs{
						order: 1;
					}
				}

				.vi-fullscreen {
					float: right;

					@include when-xs{
						float: none;
						order: 2;
					}
				}
			}

			.video-controlpanel-window {
				position: absolute;
				background-color: $color-panel-bg;
				color: $color-light-grey;
				box-sizing: border-box;
				padding: 10px 8px;
				user-select: none;
			}

			#video-controlpanel-viewer-options {
				z-index: 101;
				right: 10px;
				bottom: 200px;
				width: 250px;
				height: auto;

				.viewer-options-close {
					text-align: right;

					.panel-close {
						display: inline-block;
						cursor: pointer;
					}
				}
				.viewer-options-title {
					font-weight: bold;
				}
				.viewer-options-separator {
					width: 100%;
					height: 10px;
					overflow: hidden;
					margin-bottom: 10px;
					border-bottom: 1px solid $color-light-grey;
				}
				.viewer-options-button {
					cursor: pointer;
					padding: 10px 5px 8px 50px;
					height: 22px;
					box-sizing: content-box;
					background-position: 14px center;
					background-repeat: no-repeat;
					background-size: 17px;
				}
				.viewer-options-delete {
					padding: 15px 5px 15px 50px;
					background-image: cdn("/images/icons/webinar/icon-delete.svg");
				}
				.viewer-options-block {
					background-image: cdn("/images/icons/webinar/icon-block.svg");
				}
				.viewer-options-report {
					background-image: cdn("/images/icons/webinar/icon-report.svg");
				}
				.viewer-options-split {
					background-image: cdn("/images/icons/webinar/icon-bigscreen.svg");
					&.active {
						background-image: cdn("/images/icons/webinar/icon-smallscreen.svg");
					}
				}
			}

			#video-controlpanel-bigpanel {
				z-index: 100;
				left: 10px;
				bottom: 100%;
				width: $color-panel-size-lg;
				min-height: 250px;
				max-height: 600px;

				@media (max-width: $container-lg) {
					width: $color-panel-size-md;
				}

				@include when-xs{
					max-width: $color-panel-size-md;
					width: calc(100% - 20px);
				}

				.title {
					font-weight: bold;
					padding-bottom: 5px;
					margin-bottom: 5px;
					border-bottom: 1px solid #aaa;
					font-size: 20px;
					@media (max-width: $container-lg) {
						font-size: 14px;
					}
				}

				.title-container {
					display: flex;
					.title {
						flex: 1;
						margin-bottom: 0;
						padding-bottom: 10px;
					}
					.title-button {
						align-self: flex-end;
						border: 0 none;
						background-color: $color-light-pink;
						color: $color-black;
						text-transform: uppercase;
						font-family: bebasNeuebold, Helvetica, Arial, sans-serif;
						line-height: 1em;
						padding-top: 5px;
					}
				}

				.webinar-participants {
					max-height: 500px;
					overflow: scroll;
					margin: 0;
					padding: 0;

					li {
						list-style-type: none;
						max-width: 100%;
						margin: 0;
						padding: 8px 0;
						border-bottom: 1px solid $color-light-grey;
						font-size: 12px;

						&:last-child {
							border-bottom: 0px none;
						}

						.participant-header {
							display: flex;
							width: 100%;
							height: 31px;
							align-items: center;

							.avatar {
								height: 31px;
								width: 31px;
								overflow: hidden;
								.avatar-img {
									background-position: 0px 0px;
									background-repeat: no-repeat;
									background-size: 22px;
									clip-path: url(#participant-avatarpath);
									width: 31px;
									height: 31px;
								}
							}
							.name {
								flex: 1;
								font-size: 14px;
							}
							.control {
								width: 60px;
								display: flex;
								align-items: center;
								justify-content: flex-end;

								.participant-connect {
									background-image: cdn("/images/icons/icon-logo.png");
									background-position: center center;
									background-repeat: no-repeat;
									background-size: 26px;
									width: 31px;
									height: 31px;
									overflow: hidden;
									cursor: pointer;
									&.active {
										background-image: cdn("/images/icons/webinar/icon-cam-red.svg");
									}
								}
							}
						}
						.participant-main {
							width: 100%;
							min-height: 31px;
							margin-top: 5px;
							position: relative;

							.message {
								color: $color-white;
								padding-right: 31px;
								padding-bottom: 10px;
							}
							.control {
								display: none;
								height: 100%;
								min-width: 31px;
								min-height: 31px;
								padding-right: 40px;
								position: absolute;
								right: 0;
								bottom: 0;

								.participant-btn {
									background-position: center top;
									background-repeat: no-repeat;
									background-size: 20px;
									overflow: visible;
									cursor: pointer;
									width: 31px;
									height: 35px;
									margin: 0 5px;
									position: relative;

									span {
										color: $color-white;
										position: absolute;
										display: none;
										width: 140px;
										left: -53px; /* ~ -(width / 2) + .participant-btn(width / 2) */
										bottom: -4px;
										font-size: 11px;
										text-transform: uppercase;
										text-align: center;
										pointer-events: none;
									}

									&.active {
										span {
											display: block;
										}
									}
								}

								.participant-thanks {
									background-image: cdn("/images/icons/webinar/icon-thankyou.svg");
								}

								.participant-notime {
									background-image: cdn("/images/icons/webinar/icon-notime.svg");
								}

								.participant-later {
									background-image: cdn("/images/icons/webinar/icon-later.svg");
								}

								.participant-delete {
									background-image: cdn("/images/icons/webinar/icon-delete.svg");
								}

								.participant-block {
									background-image: cdn("/images/icons/webinar/icon-block-on.svg");
								}

								.participant-allow-join {
									background-image: cdn("/images/icons/webinar/icon-thankyou.svg"); //TODO
								}

								.participant-refuse-join {
									background-image: cdn("/images/icons/webinar/icon-delete.svg"); //TODO
								}

								.participant-remove-join {
									background-image: cdn("/images/icons/webinar/icon-delete.svg"); //TODO
								}

								.participant-more {
									background-image: cdn("/images/icons/webinar/icon-more.svg");
								}
							}

							&.active {
								.message {
									opacity: 0.33;
								}
								.control {
									display: flex;
								}
							}
						}
					}
				}

				.video-bigpanel-viewer {
					color: $color-light-grey;

					.title {
						text-align: right;
						padding-right: 2px;
					}
					.subtitle {
						padding-right: 2px;
						font-weight: bold;
					}
					.panel-close {
						display: inline-block;
						cursor: pointer;
					}
					textarea {
						width: 100%;
						margin-top: 10px;
						color: $color-black;
					}
					a {
						color: $color-light-grey;
					}
					button {
						display: inline;
						padding: 0;
						background: none;
						border: 0 none;
						text-decoration: underline;
					}

					&.important {
						.title {
							background-color: #D0DA58;
							margin: -8px -8px 0 -8px; /* weird hack to make the bg fill the container */
							padding: 8px 8px 0px 8px;
							border-bottom: 0 none;
							color: $color-black;
						}
						.subtitle {
							background-color: #D0DA58;
							margin: 0 -8px 0 -8px;
							padding: 0 8px 8px 8px;
							font-weight: bold;
							font-size: 12px;
							color: $color-black;
						}
					}
				}

				.webinar-viewer-question-cleared {
					padding: 15px 5px;
					color: $color-white;
					text-align: right;
				}
				.webinar-viewer-question-response {
					padding: 15px 5px;
					color: $color-white;
					text-align: right;
					div {
						font-weight: bold;
					}
				}
			}

			.video-controlpanel-confirm {
				position: absolute;
				width: 300px;
				min-height: 90px;
				bottom: 150px;
				left: calc(50% - 150px);
				z-index: 199;
				background-color: $color-panel-bg;
				color: $color-light-grey;
				padding: 12px 10px;
				text-align: center;

				.confirm-buttons {
					margin-top: 10px;
					.btn {
						min-width: 100px;
					}
				}
				.select-option {
					width: 100%;
					color: white;
					cursor: pointer;
					&.selected {
						border: 1px solid $color-green;
					}
					&:hover {
						background-color: rgba(255, 255, 255, 0.3);
					}
				}
			}

			.video-guest-stream {
				position: absolute;
				width: 300px;
				min-height: 200px;
				bottom: 25vh;
				left: calc(50% - 150px);
				z-index: 199;
				background-color: $color-white;
				color: $color-light-grey;
				padding: 8px 10px;
				text-align: center;
				color: $color-off-black;

				.header {
					text-align: right;
					.panel-close {
						color: $color-off-black;
						cursor: pointer;
						display: inline-block;
						width: 12px;
						height: 12px;
						background-size: 12px;
					}
				}

				.title {
					font-family: proxima, Helvetica, Arial, sans-serif;
					font-weight: bold;
					font-size: 14px;
					text-transform: uppercase;
					margin-bottom: 10px;
				}

				.subtitle {
					font-family: proxima, Helvetica, Arial, sans-serif;
					font-size: 14px;
				}

				.buttons {
					display: flex;
					justify-content: space-around;
					margin-top: 15px;
					margin-bottom: 15px;
				}

				button {
					color: $color-nero;
					width: 110px;
					height: 110px;
					padding: 10px 12px 0 12px;
					border: 0 none;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;
					background: linear-gradient(#cfd861, #8dc449);
					font-family: bebasNeuebold, Helvetica, Arial, sans-serif;
					font-size: 16px;
					line-height: 1em;

					.icon {
						color: $color-white;
						border-bottom: 2px solid $color-nero;
						width: 90%;
						min-height: 50px;
						background-repeat: no-repeat;
						background-position: center 5px, center 100px;
					}
					&.on {
						.icon {
							background-position: center 100px, center 5px;
						}
					}

					&.video-control-cam-and-mic {
						.icon {
							background-image: cdn("/images/icons/webinar/icon-mic-cam-white.svg"), cdn("/images/icons/webinar/icon-mic-cam-black.svg");
							background-size: 70px;
						}
					}
					&.video-control-mic-only {
						.icon {
							background-image: cdn("/images/icons/webinar/icon-mic-white.svg"), cdn("/images/icons/webinar/icon-mic-black.svg");
							background-size: 20px;
						}
					}
				}
			}

			.webinar-message-history-container {
				.webinar-message-history-header {
					text-align: center;
					margin: 10px;
				}
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					max-height: 450px;
					overflow: scroll;

					li {
						display: block;
						padding-bottom: 0px;
						border-bottom: 1px solid $color-darkest-grey;
						margin-bottom: 5px;
						color: $color-white;
						font-size: 12px;

						&:last-child {
							border-bottom: 0 none;
						}

						.webinar-message-history-sender {
							font-style: italic;
							color: $color-lightest-grey;
							padding-left: 5px;
						}
						.webinar-message-history-message {
							line-height: 1.1em;
						}
						.webinar-message-history-action {
							text-align: right;
							color: $color-lightest-grey;
						}
					}
				}
			}

			&.video-controlpanel-webinar {
				#video-controlpanel-buttons {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 10px;
					overflow: hidden;

					.button-help-holder {
						width: 1px;
						height: 100%;
						overflow: visible;
						position: relative;
						.button-help {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							width: 110px;
							padding: 3px 6px;
							font-size: 12px;
							background-color: $color-panel-bg;
							color: $color-white;
							user-select: none;

							@include when-xs{
								width: 65px;
								line-height: 1;
							}
						}
					}

					.video-controlpanel-buttons-block {
						flex: 1;
						display: flex;
						height: 100%;
						&.video-controlpanel-buttons-middle {
							justify-content: center;
							align-items: center;
						}
					}

					.video-controlpanel-buttons-expand {
						width: $color-panel-size-lg;
						display: flex;
						justify-content: center;
						height: 100%;

						@media (max-width: $container-lg) {
							width: $color-panel-size-md;
						}

						@include when-xs {
							width: 60px;
						}

						&.active {
							background-color: $color-panel-bg;

							@include when-xs{
								width: 140px;
								justify-content: flex-start;
							}

							.call-ui-btn {
								background-color: transparent;
							}

							.call-ui-labeled {
								@include when-xs{
									margin: 0 5px;
								}
							}
						}

						&.video-controlpanel-buttons-standard {
							button {
								width: $call-ui-labeled-height;
								height: $call-ui-labeled-height;
							}
						}
					}

					&.video-controlpanel-buttons-viewer {
						height: $call-ui-labeled-height;
					}
				}
			}

		}		

		#video-banner {
			width: 100%;
			height: 40px;
			padding: 12px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 11;
			background-color: rgba(0, 0, 0, 0.2);
			color: $color-white;
		}

		&.webinar-call {
			#video-banner {
				width: auto;
				height: auto;
				padding: 5px 12px;
			}
		}

		#video-alert {
			display: none;
			position: absolute;
			z-index: 100;
			top: 0;
			left: 0;
			width: 100%;
			height: 90%;

			#video-alert-flex {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;

				#video-alert-box {
					width: 250px;
					height: 220px;
					padding: 25px 15px 10px 15px;
					color: $color-white;
					background-color: rgba(0, 0, 0, 0.8);
					position: relative;
					font-size: 1.3em;
				}

				#video-alert-close {
					position: absolute;
					top: 0;
					right: 0;
					margin: 4px;
					width: 20px;
					height: 20px;
					color: $color-white;
					cursor: pointer;
					font-size: 12px;
				}

			}

		}

		.video-notification {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			user-select: none;

			h1 {
				font-family: bebasNeuebold, Helvetica, Arial, sans-serif;
				font-size: 140px;
				color: $color-darkest-grey;
				line-height: 1em;
				margin-bottom: 0;
			}

			.video-notification-message {
				font-family: proxima, Helvetica, Arial, sans-serif;
				font-weight: bold;
				font-size: 20px;
				color: $color-light-grey;
				margin-bottom: 20px;
				text-transform: uppercase;
			}
			.video-notification-note {
				font-family: proxima, Helvetica, Arial, sans-serif;
				font-size: 14px;
				color: $color-grey;
			}
		}

	}

	&.splitscreen .container-virtual-tour {
		.secondary-view-container {
			right: 0;
			top: 0;
			height: 100% !important;
			width: 50% !important;
			border: 0 none;

			@include when-xs{
				z-index: 0;
			}
		}
		#tour-view {
			width: 50% !important;
			margin: 0;
			.OT_widget-container {
				width: 150% !important;
				left: -25%;
			}
		}
	}

	&.screenfollowing .container-virtual-tour {
		#videos {
			min-height: 450px;
			height:100%;

			@include when-xs {
				min-height: 100vh;
			}
		}
		#screen-view {
			position:absolute;
			z-index:55;
			left:0px;
			top:0px;
			width: 100% !important;
			height: 100% !important;
			background-color: $color-off-black;
		}
		// #my-view {
		// 	position:absolute;
		// 	right: 20px;
		// 	bottom: 20px;
		// 	z-index:100;
		// 	border: 1px solid $color-white;
		// }
		#tour-view {
			z-index: 99;
			right: 20px;			
			width: $video-width-small !important;
			height: $video-height-small !important;
			bottom: calc(40px + #{$video-height-small});
			border: 1px solid $color-white;
			position: absolute;
			padding-bottom: initial;
		}
	}

	&.screenfollowing.splitscreen .container-virtual-tour {
		#screen-view {
			width: 50% !important;
		}
	}

	&.screenfollowing .container-virtual-tour.webinar-call {
		#tour-view {
			bottom: 20px;
			right: 20px;
			width: 200px !important;
			height: 150px !important;

			@include when-xs {
				width: 150px !important;
				height: auto !important;
				padding-bottom: 100px;
				bottom: calc(6px + #{$navbar-xs-height});
				right: auto;
				left: 0;
			}
		}

		&.with-secondary {
			#tour-view {
				right: 230px;
			}
		}

		&.webinar-call-public-group {
			#tour-view {
				bottom: 220px;
			}
		}
	}

	&.screenfollowing.audio-only .container-virtual-tour.webinar-call {
		#tour-view {
			top: 0;
			left: 0;
			z-index: 1;
			right: auto;
			bottom: auto;
			width: 100% !important;
			height: 100% !important;
			border: 0 none;
		}
		#screen-view {
			left: auto;
			right: 20px;
			top: auto;
			bottom: 20px;
			width: 200px !important;
			height: 150px !important;
			border: 1px solid $color-white;
		}
		&.webinar-call-public-group {
			#screen-view {
				bottom: 220px;
			}
			#tour-view {
				bottom: auto;
			}
		}
	}

	&.recorded #tour-view:before,&.recording #tour-view:after {
		display:none;
	}
	&.recorded.videojs {

		#title {
			background-color:$color-green;
			padding:38px 0px 33px;
			margin-bottom:20px;
			.btn {
				border-color:$color-white;
				color:$color-white;
				&:hover {
					background-color:$color-white;
					color:$color-green;
				}
			}
			p.title {
				color:$color-white;
				font-size:20px;
				padding-top:3px;
			}
		}
		.location-image {
			height:0px;
			width:100%;
			padding-bottom:calc(100% / 3);
			background-size:cover !important;
			background-position:center center;
			background-repeat:no-repeat;
			margin-bottom:10px;
		}

		.recording-details,.participant-details {
			a { color:inherit; &:hover { color:inherit;} }
			i.icon img { width:25px; height:auto;}
			i.fa { color:$color-darker-green;position:relative;top:3px;font-size:24px; width:25px; text-align:center;}
			min-height:100%;
		}

		.rating-table {
			width:100%;
			td { padding: 1px 5px }
		}
		.participant-details { 
			margin-top:10px; margin-bottom:10px;
			.avatar { margin-bottom:5px;width:100%;padding-bottom:100%;background-size:cover; background-position:center center; background-repeat:no-repeat;}

			.review {
				.score {
					float:left;

				}
				 .value {
					font-size:10px;
					float:right;
					position:relative;
					top:3px;
				}
			}
		}

		.h4,h4 { 
			color:$color-darker-green;
		}
		p.guide { color:$color-blue; }
		p.traveler { color:$color-green; }

	}

	&.recorded.youtube {
		.x-holder {
			position:absolute;
			right:8px;
			top:10px;
			z-index:100;
		}
	}

	#vidps-config-container,
	#vidps-container,
	#vidps-progress,
	#vidps-progress-connect,
	#vidps-progress-disconnect, {
		@extend .embed-responsive-16by9;
		> div {
			@extend .embed-responsive-16by9;
		}
	}
	.video-block {
		@extend .embed-responsive-16by9;
	}
}

[data-to-root]#app-layout.virtual-tour.fullscreen {

	.full-container {
		padding-top: 0;
	}

	.container-virtual-tour {
		height: 100vh !important;

		@include when-xs {
			margin-top: 0;
		}

		#videos {
			@include when-xs {
				margin-top: 0;
			}
		}

		#my-view {
			@include when-xs {
				bottom: 70px;
			}
		}

		#video-container {
			max-width: none;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#video-controlpanel {
			position: absolute;
			bottom: 23px;
			z-index: 200;
			width: 80%;
			padding-right: 110px;
			background-color: transparent;

			@include when-xs{
				width: 100%;
				padding-right: 0;
				bottom: 0;
			}

			/*
			#video-controlpanel-buttons {
				width: 370px;
				margin: auto;
				padding: 0;

				.vi-hangup { margin-left: 120px; }
				.vi-microphone { margin-left: 22; }
				.vi-fullscreen { margin-left: 60px; }
			}
			*/

			#video-controlpanel-buttons {
				.vi-hangup {
					position: static;
					margin-left: 45%;

					@include when-xs {
						margin-left: 0;
					}
				}
			}

		}

		&.webinar-call {
			#video-controlpanel {
				/* need to override some styles from ~30 lines above, fullscreen case */
				bottom: 0;
				width: 100%;
				padding-right: 0;
			}
		}

		&.webinar-call-group-participant {
			#videos {
				height: 100%;
			}

			&.group-call-fullsize {
				/* should not have the 100%, now just define the same rule again... */
				height: calc(100% - 62px);
			}
		}

		#video-banner {
			/*
			width: 300px;
			height: 40px;
			padding: 12px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 11;
			background-color:rgba(0, 0, 0, 0.2);
			color: $color-white;
			*/
		}

		#tour-view {
			height: 100vh;

			@include when-xs {
				padding-bottom: 100vh;
			}
			/*
			height: calc(100vh - #{$navbar-height});

			@include when-xs {
				height: calc(100vh - #{$navbar-xs-height});
			}
			*/

			.OT_widget-container {
				max-height: 100vh;
			}

		}

		&.opentok #tour-view:after {
			content: "";
		}

	}

}

[data-to-root]#app-layout.virtual-tour.fullscreen.splitscreen {
	.container-virtual-tour {
		&.webinar-call-public-group {
			#video-controlpanel {
				left: 0;
			}
			&.webinar-call-host {
				#video-controlpanel {
					width: 50%;
				}				
			}
		}
	}
}

.lookalong-modal.connectionlost-modal {
	.modal-body {
		text-align: center;

		.close {
			display: none;
		}

		.modal-image {
			margin: 10px auto;
		}

		.modal-main {

			h5 {
				margin-bottom: 5px;
			}

			p {
				font-weight: normal;
				margin-bottom: 0;
			}

			span {
				font-size: 12px;
			}
		}
	}
}
