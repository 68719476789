.bigbutton,
.bigbutton:hover {
  display: flex;
  width: 220px;
  height: 40px;
  user-select: none;
  text-align: left;

  .bigbutton-initial {
    display: block;
    height: 100%;
    width: 45px;
    padding: 2px 0 0 15px;
    font-size: 30px;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
  }

  .bigbutton-wordinitial {
    display: block;
    height: 100%;
    padding: 2px 11px 0 15px;
    font-size: 30px;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    line-height: 1.4em;
  }

  .bigbutton-sublabel {
    display: block;
    padding: 8px 10px 0 0;
    font-size: 12px;
    line-height: 12px;
    color: $color-white;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-bold !important;
  }

  .bigbutton-word {
    display: block;
    height: 100%;
    width: 100%;
    padding: 2px 0 0 15px;
    font-size: 30px;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    color: $color-light-pink;
    line-height: 1.4em;

    span {
      color: $color-close-white;
    }
  }

  /* -------- color variations -------- */
  &.bigbutton-secondary {
    background-color: $color-nero;

    .bigbutton-initial,
    .bigbutton-wordinitial {
      color: $color-primary;
    }
  }

  &.bigbutton-primary {
    background-color: $color-primary;

    .bigbutton-initial,
    .bigbutton-wordinitial {
      color: $color-nero;
    }
  }

  &.bigbutton-grey {
    background-color: $color-closer-white;
  }

  /* -------- size variations -------- */
  &.bigbutton-small {
    width: 68px;
  }

  &.bigbutton-auto {
    width: auto;
  }
}

.button-alternative,
.button-alternative:hover {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
  color: $color-grey;
  user-select: none;

  &.button-alternative-dark {
    color: $color-nero;
  }

  &.button-alternative-grey {
    background-color: $color-grey;
    color: $color-white;
    padding: 4px 8px 0;
    display: inline-block;
  }

  span {
    color: $color-white;
  }
}

/* -------- call interface buttons -------- */

$call-ui-button-size-lg: 62px;
$call-ui-button-size-md: 44px;

$call-ui-font-size-lg: 20px;
$call-ui-font-size-md: 14px;

@mixin call-ui-btn-onoff {
  background-position: center center, center $call-ui-button-size-lg;

  &.off {
    background-position: center $call-ui-button-size-lg, center center;
  }

  @media (max-width: $container-md) {
    background-position: center center, center $call-ui-button-size-md;

    &.off {
      background-position: center $call-ui-button-size-md, center center;
    }
  }
}

@mixin call-ui-btn-wide {
  width: 160px;

  @media (max-width: $container-md) {
    width: 120px;
  }
}

.call-ui-btn {
  border: 0 none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $call-ui-button-size-lg;
  background-color: $color-panel-bg;
  width: $call-ui-button-size-lg;
  height: $call-ui-button-size-lg;
  font-size: $call-ui-font-size-lg;

  @media (max-width: $container-md) {
    background-size: $call-ui-button-size-md;
    width: $call-ui-button-size-md;
    height: $call-ui-button-size-md;
    font-size: $call-ui-font-size-md;
  }

  .call-ui-btn-inner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .call-ui-btn-bg {
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.66;
    min-width: $call-ui-button-size-lg;
    min-height: $call-ui-button-size-lg;

    @media (max-width: $container-md) {
      min-width: $call-ui-button-size-md;
      min-height: $call-ui-button-size-md;
    }
  }

  &.open {
    .call-ui-btn-bg {
      opacity: 1;
    }
  }

  .call-ui-btn-state {
    display: inline-block;
    height: 100%;
    width: 20px;
    margin-right: 10px;
    background-image: cdn("/images/icons/icon-arrow-up.png");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: $container-md) {
      width: 15px;
      background-size: 15px;
    }
  }

  &.open {
    .call-ui-btn-state {
      transform: rotate(180deg) translate(0, -2px);
    }
  }

  .call-ui-btn-label {
    display: inline-block;
    color: $color-light-grey;
    padding-top: 3px;
  }

  .call-ui-btn-note {
    font-size: 14px;
  }

  &--hangup {
    @extend .call-ui-btn;

    background-color: $color-pink;
    background-image: cdn("/images/video/vi-hangup.png");
  }

  &--microphone {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-mic.png"), cdn("/images/video/vi-mic-off.png");

    @include call-ui-btn-onoff;
  }

  &--camera {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-cam.png"), cdn("/images/video/vi-cam-off.png");

    @include call-ui-btn-onoff;
  }

  &--raisehand {
    @extend .call-ui-btn;

    background-image: cdn("/images/icons/webinar/icon-hand.svg");
    background-size: 22px;

    &.off {
      opacity: 0.66;
    }
  }

  &--fullscreen {
    @extend .call-ui-btn;

    background-image: cdn("/images/vi-fullscreen.png"), cdn("/images/vi-fullscreen-off.png");

    @include call-ui-btn-onoff;
  }

  &--settings {
    @extend .call-ui-btn;

    overflow: hidden;

    .call-ui-btn-bg {
      background-image: cdn("/images/icons/webinar/icon-settings.svg");
      background-size: 30px;

      @media (max-width: $container-md) {
        background-size: 26px;
      }
    }

    @include call-ui-btn-wide;
  }

  &--hand {
    @extend .call-ui-btn;

    overflow: hidden;

    .call-ui-btn-bg {
      background-image: cdn("/images/icons/webinar/icon-hand.svg");
      background-size: 28px;

      @media (max-width: $container-md) {
        background-size: 22px;
      }
    }

    @include call-ui-btn-wide;
  }

  &--screen-sharing-webinar {
    @extend .call-ui-btn;

    width: 110px;

    @media (max-width: $container-md) {
      width: 85px;
    }

    .call-ui-btn-bg {
      background-image: cdn("/images/icons/webinar/icon-screenshare-off.svg"), cdn("/images/icons/webinar/icon-screenshare-on.svg");
      background-size: 30px;
      background-position: center center, center $call-ui-button-size-lg;

      &.on {
        background-color: rgba($color-red, $light-alpha);
        border: solid 0.5px $color-white;
        background-position: center $call-ui-button-size-lg, center center;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      @media (max-width: $container-md) {
        background-size: 26px;
        background-position: center center, center $call-ui-button-size-md;

        &.on {
          background-position: center $call-ui-button-size-md, center center;
        }
      }
    }

    .call-ui-btn-state {
      margin-right: 0;
    }
  }

  &--phone-red {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-phone-red.png");
  }

  &--broadcast-fb {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-facebook-live.svg");
  }

  &--broadcast-yt {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-youtube-live.svg");
  }

  &--broadcast-fb-color {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-facebook-live-color.svg");
  }

  &--broadcast-yt-color {
    @extend .call-ui-btn;

    background-image: cdn("/images/video/vi-youtube-live-color.svg");
  }

  &--muted {
    @extend .call-ui-btn;

    background-image: cdn("/images/vi-volume.png");
  }

  &--ask-join-group {
    @extend .call-ui-btn;

    background-image: cdn("/images/vi-recording.png");

    // temp icon! - if used, need it's own icon TODO

    &.inactive {
      opacity: 0.3;
    }
  }

  &--end-join-group {
    @extend .call-ui-btn;

    background-image: cdn("/images/vi-share.png");

    // temp icon! - if used, need it's own icon TODO

  }

  &--panelbutton {
    @extend .call-ui-btn;

    width: 100%;
    height: 52px;
    background-color: transparent;
    cursor: pointer;

    .call-ui-btn-inner {
      padding-left: 0;

      div {
        background-color: transparent;
      }
    }

    &.switch {
      background-image: cdn("/images/video/vi-switch-on.png"), cdn("/images/video/vi-switch-off.png");
      background-size: 52px 28px;
      background-position: 95% center, 95% 300%;

      &.off {
        background-position: 95% 300%, 95% center;
      }
    }
  }
}

.call-ui-labeled {
  min-width: 60px;
  height: $call-ui-labeled-height;
  margin: 0 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .label {
    color: $color-white;
    pointer-events: none;
    margin-top: -10px;
  }

  .state-off {
    display: none;
  }

  .state-on {
    display: inline;
  }

  &.off {
    .state-off {
      display: inline;
    }

    .state-on {
      display: none;
    }
  }
}

/* -------- standard bootstrap .btn custom subclasses -------- */

.btn-grey {
  border: 0 none;
  color: $color-white !important;
  background-color: $color-dark-grey;
}

.btn-black, .btn-black:active {
  border: 0 none;
  color: $color-white !important;
  background-color: $color-black;
}

.btn-green, .btn-green:active {
  border: 0 none;
  color: $color-white !important;
  background-color: $color-green;
}

.btn-red, .btn-red:active {
  border: 0 none;
  color: $color-white !important;
  background-color: $color-red;
}

.btn-pink, .btn-pink:active, .btn-pink:hover {
  border: 0 none;
  color: $color-black !important;
  background-color: $color-light-pink;
}

.btn-green-black, .btn-green-black:active, .btn-green-black:hover {
  border: 0 none;
  color: $color-black !important;
  background-color: $color-green;
}

/* -------- other -------- */

.daterangepicker .cancelBtn {
  background-color: $color-dark-grey;
}
