#modal-review {
	.modal-header {
		padding-bottom: 0;

		h3:after {
			border-bottom: 0 none;
		}
	}

	.modal-body {
		padding-bottom: 0;

		.avatar {
			width: 60px;
			height: 60px;
			background-size: cover;
			float: left;
			margin-right: 4px;
		}

		.review-attributes {
			padding-top: 20px;
			padding-bottom: 10px;
		}

		.review-box {
			padding: 0 3px;
			float: left;
			height: 90px;
			width: 50%;
			line-height: 1.1em;

			@include when-xs {
				float: none;
				width: 100%;
				padding: 0;
				margin-bottom: 3px;
			}
		}
		// .review-box:first-child {
		// 	padding-left: 0;
		// }
		// .review-box:last-child {
		// 	padding-right: 0;
		// }

		.review-box-content {
			width: 100%;
			height: 100%;
			text-align: center;
			padding: 10px 5px;

			.star-review {
				margin: 10px auto 0 auto;
			}

		}

		.review-description {
			color: $color-grey;
		}

		.review-average {
			padding-top: 10px;
			color: $color-grey;
			font-weight: bold;
		}

		h6 {
		}

		textarea {
			height: 110px;
			resize: none;
		}

	}

	.modal-footer {
		.btn-default{
		    color: #878787;
		    border-color: #333333;
		    &:hover{
			    color: #333;
			    background-color: transparent;
		    }
		}
	}

	.review-error {
		color: $color-red;
	}

}
