$color-mycom-primary: #db546b;
$color-mycom-nero: #333333;
$color-mycom-offwhite: #f6f6f6;

// $color-primary:      $color-mycom-primary;
// $color-nero:     $color-mycom-nero;
// $color-offwhite:   $color-mycom-offwhite;

[data-to-root].framed {
  .navbar {
    display: block !important;

    .navbar-toggle {
      display: none !important;
    }
  }

  .show-mobile-view-app {
    display: none !important;
  }

  #main-container {
    display: block !important;
    clear: both;

    @include when-xs {
      padding-top: 0px !important;
    }


    overflow-y: auto;
    height: auto;
    padding-top: 0px !important;
    margin-top: 0px;
  }

  p {
    font-size: 20px;
    line-height: 22px;
  }

  &.single .search-page > .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  &.single {
    .grid-holder .grid.row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }

  &.single,
  &.map {
    .certification-about.below {
      @include when-xs {
        display: none !important;
      }
    }

    #form-search-options,
    .search-page .grid .stamp1,
    .map-search,
    .grid-item.become-one {
      display: none;
    }

    padding-top: 0px !important;
    max-width: 400px;
    margin: auto;

    nav.navbar {
      display: none !important;
    }

    #main-container {
      padding-top: 0px !important;
    }

    .search-page {
      padding: 0px;
    }

    .grid-item {
      padding: 0px !important;
    }

    @media only screen and (max-width: 1085px) {
      &#app-layout {
        .grid {
          .grid-sizer,
          .grid-item {
            width: 100% !important;
            padding: 10px 10px;
          }
        }
      }
    }

    @media only screen and (max-width: 510) {
      &#app-layout .grid .grid-sizer, &#app-layout .grid .grid-item {
        width: 100% !important;
        min-width: 310px !important;
      }
    }

    .grid {
      .grid-sizer,
      .grid-item {
        width: 100% !important;
        padding: 10px 10px;

        .media .user-avatar {
          max-height: 100% !important;
        }
      }
    }
  }

  @media only screen and (max-width: 735px) {
    &#app-layout .grid .grid-sizer, &#app-layout .grid .grid-item {
      width: 50% !important;

      // max-width:310px !important;
      // padding:0px !important;

    }
  }

  @media only screen and (max-width: 510px) {
    &#app-layout .grid .grid-sizer, &#app-layout .grid .grid-item {
      width: 100% !important;
      min-width: 310px !important;

      // padding:0px !important;
      max-width: 400px;
    }
  }

  .color-primary {
    color: $color-mycom-primary !important;
  }

  .color-mycom-primary {
    color: $color-mycom-primary !important;
  }

  .smaller {
    font-size: 16px;
    line-height: 20px;
  }

  .small {
    font-size: 18px;
    line-height: 20px;
  }

  .large {
    font-size: 26px;
    line-height: 28px;
  }

  .larger {
    font-size: 36px;
    line-height: 40px;
  }

  .big {
    font-size: 41px;
    line-height: 41px;
  }

  .bold {
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-bold !important;
  }

  .padding-small {
    padding: 10px;
  }

  .padding-normal {
    padding: 15px;
  }

  .btn {
    font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
    font-weight: $font-weight-bold !important;
    letter-spacing: initial;
  }

  .no-link {
    color: $color-nero;

    &:hover {
      color: $color-mycom-primary;
    }
  }

  @include when-sm {
    .sm-normal {
      font-size: 26px;
      line-height: 28px;
    }

    .sm-small {
      font-size: 16px;
      line-height: 20px;
    }
  }


  @include when-md {
    .md-larger, .sm-normal {
      font-size: 30px;
      line-height: 41px;
    }
  }


  @include when-lg {
    .md-larger, .sm-normal {
      font-size: 41px;
      line-height: 41px;
    }
  }


  .online-tag.online {
    background-color: #9adedb;
  }

  // .info-item:hover .price-tag, .info-item.selected .price-tag {
  //  background-color:$color-mycom-primary !important;
  // }

  #nav-holder {
    .certification-about {
      position: relative;
      overflow: visible;

      p {
        position: relative;
        background-color: $color-mycom-primary;
        font-size: 16px;

        @media only screen and (max-width: 835px) {
          font-size: 13px;
        }

        @media only screen and (max-width: 775px) {
          font-size: 11px;
        }

        line-height: 18px;
        padding: 10px 15px 20px 15px;
        margin: 0px 20px 0px 0px;
        top: -10px;
      }

      a.btn {
        position: absolute;
        bottom: 3px;
        right: 10px;
        color: $color-white !important;
        font-size: 14px;
        border-color: $color-nero !important;
        background-color: $color-nero !important;
        height: 25px !important;
        padding: 5px !important;

        &:hover {
          background-color: $color-grey !important;
          border-color: $color-grey !important;
        }
      }

      &.below {
        padding-top: 80px;
        background-color: $color-mycom-primary;
        width: 100%;

        a.btn {
          float: right;
          position: relative;
          top: -30px;
        }
      }
    }
  }

  .certification-about.below {
    display: none;

    @include when-xs {
      display: block !important;
    }


    padding-top: 70px;
    background-color: $color-mycom-primary;
    width: 100%;
    padding-bottom: 5px;

    p {
      margin-right: 10px;
      margin-left: 10px;
      font-size: 12px;
    }

    a.btn {
      float: right;
      top: -25px;
      font-size: 12px;
    }
  }
}

[data-to-root]#app-layout.certified {
  @include when-xs {
    .navbar-left.input-location.certified {
      width: calc(100vw - 100px) !important;
    }

    .nav-bar-search-wrapper {
      width: 100% !important;
    }
  }
}
