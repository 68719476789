

.banner-header {
  #nav-holder {
    .navbar-right {
      li {
        a {
          &:hover {
            color: $color-nero !important;

            &:after {
              background: #fff !important;
              content: "";
              bottom: -12px !important;
              left: 0;
              right: 0;
              top: -12px !important;
              width: 100%;
              position: absolute;
              z-index: -1;
            }
          }
        }

        .dropdown-menu {
          a {
            &:hover {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

#to-search-results-1 {
  p.group-title {
    color: $color-darker-grey;
  }
}


[data-to-root] {
  height: auto;

  // height: 100%;
  // overflow: hidden; /* why is this? */
  @include when-xs {
    padding-top: $navbar-xs-height;
  }
}


.navbar-default {
  border: 2px solid #F2F2F2;
}

.navbar {
  background-color: $color-white;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: content-box;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;

  li.profile {
    a{
      @include when-xs{
        align-items: center !important;
      }

      @include when-sm{
        padding-right: 27px;
      }

      @include when-md{
        padding-right: 34px;
      }

      @include when-lg{
        padding-right: 40px;
      }
    }

    .avatar {
      background-size: cover;
      vertical-align: text-bottom;
      background-position: center;

      @include when-xs{
        display: inline-block;
        width: 30px;
        padding-bottom: 30px;
      }

      @include when-sm{
        position: absolute;
        right: 0;
        bottom: 4px;
        width: 26px;
        padding-bottom: 26px;
      }
      @include when-md{
        bottom: 13px;
        width: 30px;
        padding-bottom: 30px;
      }

      @include when-lg{
        bottom: 10px;
        width: 36px;
        padding-bottom: 36px;
      }
    }
  }
}


.navbar-form {
  input,
  .input-group-addon {
    border: none;
    box-shadow: none;
  }

  .input-group-addon {
    padding: 0px;
    margin: 0px;
    padding-left: 20px;
    display: flex;

    @include when-xs {
      padding-top: 4px;
      text-align: left;
      width: auto !important;
      padding-left: 0px !important;
    }
  }

  padding: 0px;
  margin: 0px;
  border: none;

  .form-group,
  .input-group {
    width: 100%;
  }

  input {
    font-size: 16px;
  }
}

.navbar-left {
  width: auto;

  //*HARDCODED FOR CURRENT ITEMS!!!
  align-items: stretch;
  padding: 0;
  margin: 0;

  li {
    width: auto;
    align-items: flex-end;
    margin-right: 8px;

    a {
      padding: 0;
      padding-bottom: 22px;
    }
  }

  .input-group-addon {
    img {
      width: 37px;
    }
  }

  @media (max-width: $screen-md-min) {
    // width: calc(100vw - 615px); //*HARDCODED FOR CURRENT ITEMS!!!
  }

  @media (max-width: 871px) {
    // width: calc(100vw - 435px); //*HARDCODED FOR CURRENT ITEMS!!!
  }

  @include when-xs {
    height: $navbar-xs-height;
  }


  .form-holder {
    width: 100%;
  }

  li {
    height: 98px;
    justify-content: center;
    align-self: center;
    justify-content: center;
    align-self: center;

    /* height: auto; */
    display: flex;
    position: relative;
  }
}

// .navbar-brand {

//   img {
//     height: 28px;
//     width: auto;
//   }

//   @include when-xs {
//     height: $navbar-xs-height;
//     border-right: none;

//     &.mobile-search-open {
//       width: 0px;
//       border-right: none;
//       overflow: hidden;
//     }
//   }
// }

.navbar-right {
  margin: 0;
  float: none;
  position: absolute;
  right: 0;
  top: 0;

  @include when-xs {
    position: static;
    margin: 0 -15px;
  }
}

.navbar-right li:not(:last-child) {
  @include when-xs {
    border-right: none;
  }
}

#nav-holder {
  padding: 0px;
  margin: 0px;

  span.caret {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 8px;
    height: 16px;
    background-size: contain;
    background-image: cdn("/images/icon-next.svg");
    border: none !important;

    @include transition(all 0.2s ease-in-out);
  }

  


  // .dropdown-toggle {
  //    background-color:transparent !important;
  //    padding-top:7.5px;
  //    padding-bottom:8px;
  //    font-weight: $font-weight-bold;
  //    font-family: "proxima", "Helvetica", "Arial", sans-serif;
  // }
  .dropdown-toggle {
    background: none;
  }

  .dropdown-toggle[aria-expanded="true"] {
    background: none;

    span.caret {
      @include transform(rotate(90deg));
    }
  }

  .language-select {
    .btn {
      box-shadow: none;
    }

    .dropdown-toggle[aria-expanded="true"] {
      background: none;
    }

    .dropdown-menu {
      background-color: $color-white;
      height: auto;
      border: 1px solid rgba(0, 0, 0, 0.18);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.06);
    }

    @media (max-width: 1130px) {
      // display: none;
    }
  }
}

.language-select {
  .btn-group {
    height: 100%;
  }

  .btn {
    padding: 9px 10px 0;
    margin: 0;
    border: 0 none;
    color: $color-darker-grey !important;
    box-shadow: none;
    text-transform: none;

    span {
      padding-left: 25px;

      // background-image: url('/images/languages.png');
      background-repeat: no-repeat;
    }
  }

  .dropdown-toggle[aria-expanded="true"] {}

  .dropdown-menu {
    box-shadow: none;
    border: 0 none;
    height: auto;
    background-color: $color-white;
    top: calc(#{$navbar-height} - 57px);

    // 10px padding of .btn, 1px border
    width: 100%;

    a {
    }
  }

  .dropdown-divider {
    height: 1px;
    overflow: hidden;
    background-color: $color-dark-grey;
    margin: .5rem 0;
  }

  .dropdown-item {
    color: $color-darker-grey;
    width: 100%;
    padding: 5px 15px;
    display: block;
    float: none;
    position: static;
    text-decoration: none;
    border-bottom: 1px solid $color-light-grey;

    &:hover {
      background-color: $color-lighter-grey;
    }

    &:last-child {
      border-bottom: 0 none;
    }

    &.language-main {
      border-bottom: 0 none;
    }

    &:first-child {
      border-top: 0 none;
    }
  }
}

.badge {
  background-color: $color-red;
  width: 20px;
  height: 20px;
  position: relative;

  .badge-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 1410px) {
    padding-bottom: 0;
  }

  @media only screen and (max-width: 1085px) {
    font-size: 10px;
    width: 15px;
    height: 15px;
    margin-left: 3px;
  }
}

.navbar-nav > li > a {
  font-size: 16px;

  // padding-top: 21px !important;
  // padding-bottom: 20px !important;
  color: $color-darker-grey !important;

  &.messages {
    @media (max-width: $screen-md-min) {
    }
  }

  &.profile {
    @media (max-width: $screen-md-min) {
    }
  }

  &:hover {
    color: $primary-active-color !important;
  }

  @include when-xs {
  }
}

.navbar-nav {
  li .dropdown-menu {
    height: 0;

    @include transition(height 0.35s ease-in-out);
  }

  li.open .dropdown-menu {
    height: auto;
  }

  a {
    @include when-xs {
      // border-bottom: solid 1px $color-lighter-grey !important;
    }


    padding: 0px;
    margin: 0px;
  }

  .active a {
    background-color: transparent !important;
  }
}


input[name=search] {
  border: none !important;
}

.smartsearch {
  display: block;
  position: absolute;
  z-index: 20;
  left: 0;
  background-color: $color-white;
  height: fit-content;
  overflow: auto;
  width: 100%;
  box-shadow: 1.8px 3.61px 3.61px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1.8px 3.61px 3.61px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1.8px 3.61px 3.61px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 1.8px 3.61px 3.61px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 65px);

  @include when-md {
    max-height: calc(100vh - 88px);
  }


  @include when-lg {
    max-height: calc(100vh - 108px);
  }


  @include when-xs {
    left: 0;
    top: $navbar-xs-height;

    // height: calc(100vh - #{$navbar-xs-height});
    width: 100vw;
    overflow-y: auto;
  }


  > ul > li p.group-title {
    background-color: $color-off-white;
    padding: 8px 8px 8px 14px;
    position: relative;
    margin-bottom: 0px;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-weight: $font-weight-medium;
    font-size: 16px !important;
    text-align: left;
    text-transform: uppercase;

    &.places:after {
      content: "";
      background-image: cdn("/images/google.png");
      background-position: center right;
      background-size: contain;
      width: 100px;
      height: 18px;
      background-repeat: no-repeat;
      position: absolute;
      color: $color-grey;
      right: 10px;
      top: 12px;
    }
  }

  ul.results li {
    padding: 8px 8px 8px 14px;
    font-size: 16px;
    text-align: left;
    background-color: $color-white;

    span.unimportant {
      padding-left: 7px;
    }

    cursor: pointer;

    &:not(:last-child) {
      border-bottom: solid 1px $color-lighter-grey;
    }

    a {
      color: inherit;
      display: block;
      margin: 0px;
      padding: 0px;
      background-color: transparent;
    }

    a:hover {
      color: inherit;
    }

    &:hover {
      background-color: #F9F9F8;
      color: inherit;
    }
  }

  li {
    margin: 0 !important;
  }
}


.navbar-collapse {
  margin: 0 !important;
  padding: 0 !important;
}



.container-footer {
  background-color: #F5F5F5;

  

  .footer {
    color: $color-lighter-grey;
    position: relative;
    padding-top: 30px;
    font-size: 16px;
    
    .container{
        @include clearfix;
      }
    @include when-sm {
      padding-top: 60px;
    }


    .footer-holder {
      @extend .row;

      margin-bottom: 10px;

      @include when-sm {
        margin-bottom: 30px;

        &.blog-pages .footer-title {
          padding-top: 20px;
        }

        &:after {
          clear: both;
        }
      }


      @include when-xs {
        &.blog-pages {
          padding-top: 20px;
        }
      }

      &:after {
        clear: both;
      }

    }

    &.main {
      @include when-sm {
        background-color: $color-white;

        &:after {
          content: "";
          letter-spacing: 1px;
          left: 0;
          bottom: 0;
          right: 0;
          position: absolute;
          font-size: 40px;
          width: 100%;
          display: block;
          overflow: hidden;
          background-image: linear-gradient(to right, #ddd 50%, rgba(255, 255, 255, 0) 10%);
          background-position: top;
          background-size: 32px 2px;
          background-repeat: repeat-x;
          height: 1px;
        }
      }
    }

    ul {
      font-size: inherit;
    }

    li {
      line-height: 32px;
      font-size: inherit;
    }

    a {
      font-size: inherit;
      color: inherit;
    }
  }

  .footer-title {
    font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
    font-size: 22px;
    color: $color-light-pink;
    margin-bottom: 10px;
    text-align: center;

    @extend .col-xs-12;
  }

  .footer-list {
    @extend .col-xs-12;

    @extend .list-inline;

    margin-left: 0;
    text-align: center;
  }

  .expertises {
    @extend .list-unstyled;

    @extend .col-xs-12;

    @extend .col-sm-6;

    @extend .col-md-3;

    text-align: center;

    .main {
      font-weight: $font-weight-bold;
    }
  }

  .pages {
    @extend .list-unstyled;

    @extend .col-xs-12;

    text-align: center;

    &.col1of2 {
      @extend .col-sm-6;
    }

    &.col1of3 {
      @extend .col-md-4;

      @extend .col-sm-6;
    }

    &.col1of4 {
      @extend .col-md-3;

      @extend .col-sm-6;
    }

    .main {
      font-weight: $font-weight-bold;
    }

    @include when-xs {
      padding-bottom: 10px;
    }
  }

  .app-download {
    @extend .footer-list;

    .app-link {
      @extend .svg;

      @extend .no-margin;

      @extend .btn-border;

      @extend .gray;
    }
  }

  .social-icons {
    @extend .footer-list;

    padding: 50px 0px;

    @media only screen and (max-width: 729px) {
      padding: 30px 0px;
    }

    li {
      padding-right: 0px;
      padding-left: 20px;

      &:first-child {
        padding-left: 0;
      }

      a i {
        font-size: 25px;
        color: inherit;
      }

      a:hover i {
        color: $color-nero;
      }
    }
  }

  .footer-nav {
    @extend .footer-list;

    &.first a {
      font-weight: $font-weight-bold;
    }

    li {
      padding: 0;

      &:after {
        content: '|';
        padding: 10px;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      @include when-xs {
        &:nth-last-child(2) {
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .copyright {
    @extend .footer-list;

    font-size: 14px !important;
  }

  .languages {
    @extend .footer-list;

    font-size: 14px !important;

    li {
      a {
        padding: 12px 5px;
      }

      @include when-xs {
        &.title {
          display: block;
        }

        a {
          line-height: 48px;
          padding: 17px 11px;
        }
      }
    }
  }
}


.navbar-brand{
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding: 0;
  margin-left: 0 !important;
  
  @include when-xs{
    margin-right: 21px;
  }

  @media (min-width: 730px) and (max-width: 1084px) {
    margin-right: 15px;
  }

  @include when-md{
    margin-right: 25px;
  }

  img{
    @include when-md{
      height: 28px;
      width: auto;
    }
  }
}

.menu.mobile{
  @include when-sm{
    display: none;
  }
}

#main-container{
  @include when-xs{
    margin-top: 56px;
  }

  @media (min-width: 730px) and (max-width: 1084px) {
    margin-top: 56px;
  }

  @include when-md{
    margin-top: 77px;
  }
}

ul.app-links{
  float: left;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: flex-end;
  display: none;

  @include when-lg{
    display: flex;

    li{
      margin-right: 10px;
    }

    a{
      display: block;
      padding-bottom: 15px;

      .app-link{
        border: 1px solid $color-nero;
        border-radius: 5px;
        width: 115px;
        height: auto;
      }
    }
  }
}

ul.app-links,
.navbar.inner,
.navbar-brand,
.navbar-right{
  min-height: unset;

  @include when-sm-down{
    height: 54px;
  }

  @include when-md{
    height: 75px;
  }
}

.navbar-brand,
.navbar-right.navbar-nav > li > a,
.navbar-right.navbar-nav > li > button{
  @include when-sm-down{
    padding-bottom: 10px;
  }

  @include when-md{
    padding-bottom: 18px;
  }
}


#nav-holder {
  @include when-xs {
    position: fixed;
    width: 100vw;
    background-color: $color-white;
    left: 0;
    transition: height .5s;

    &.show{
      overflow: auto;
    }

    &:not(.show){
      height: 0;
      overflow: hidden;
    }
  }

  .navbar-collapse{
    @include when-xs{
      height: calc(100vh - 54px);
      overflow-y: auto;
      background-color: $color-off-white;
      display: flex;
      flex-direction: column;

      .menu-sidebar{
        top: auto;
        position: relative;
        margin-top: auto;
      }

      .download-apps{
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        list-style: none;
        margin: 30px 0 0;
      }
    }
    @include when-sm{
      .menu-sidebar{
        display: none;
      }
    }
  }
}

.navbar-right{
  position: relative;
  display: flex;

  @include when-xs{
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;
    margin: 0;
  }

  @include when-sm{
    -ms-flex-pack: center;
    justify-content: flex-end;
    -ms-flex-align: center;
    align-items: flex-end;
    top: 0;
  }

  >li{
    z-index: 99999;
    position: relative;
    height: auto;
    line-height: 1;

    @include when-xs{
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.profile a,
    &.login a{
      @include when-xs{
        padding-top: 40px !important;
      }
    }

    &.profile a{
      @include when-xs{
        padding-bottom: 40px !important;
        color: $color-black !important;
        pointer-events: none;
        cursor: default;
      }
    }

    > .messages{
      .badge{
        @include when-xs{
          margin-bottom: 1px;
        }

        @include when-sm{
          margin-bottom: -3px;
          margin-top: -5px;
        }

        @include when-md{
          margin-bottom: -8px;
          margin-top: -8px;
        }
      }
    }

    &.app-links{
      @include when-sm-down{
        display: none;
      }

      @include when-lg{
        display: none;
      }
    }

    &.message{
      @include when-xs{
        display: none;
      }
    }

    &.home{
      @include when-sm{
        display: none;
      }
    }
  }

  >li + li{
    @include when-sm{
      margin-left: 18px;
    }

    @include when-md{
      margin-left: 20px;
    }

    @include when-lg{
      margin-left: 30px;
    }
  }

  >li > button,
  >li > a{
    position: relative;
    color: $color-darkest-grey !important;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    border: none;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      color: $color-white !important;

      &:after {
        background: $color-nero;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        z-index: -1;
      }

      @include when-xs{
        color: $color-black !important;
        &:after {
          content: none;
        }
      }
    }

    @include when-xs{
      font-size: inherit;
      line-height: inherit;
      flex: 1;
      justify-content: center;
      color: $color-dark-grey !important;
      display: block;
      padding: 10px 20px;
      border: none !important;
    }

    @include when-sm{
      line-height: 1em;
    }

    @media (min-width: 730px) and (max-width: 1084px) {
      font-size: 13px;
      padding-top: 1.5em;
    }

    @include when-md{
      font-size: 18px;
      padding-top: 1.5em;
    }

    @include when-lg{
      font-size: 20px;
    }
  }
}

.navbar-right .app-links{
  &.open {
    @include when-xs{
      .dropdown-menu{
        display: flex;
        justify-content: center;
      }
    }
  }

  .dropdown-menu{
    left: 0;
    right: auto;
  }

  .app-link a{
    padding-top: 10px;
    padding-bottom: 10px;

    @include when-xs{
      padding: 10px !important;
    }
  }

  img{
    width: 128px;
  }
}

.navbar-right,
.menu-sidebar{
  @include when-xs{
    li {
      font-size: 20px;
      line-height: 1.5em;
    }
  }
}


.navbar-header {
  position: relative;

  .container {
    padding: 0 40px;
    height: 100%;

    .navbar-brand {
      margin-left: 0px !important;
    }
  }

  .navbar-collapse {
    margin: 0px !important;
  }

  .navbar-left {
  }

  .nav-bar-search-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-shadow: 1.8px 1.8px 3.6px rgba(0, 0, 0, 0.08);

    @extend .flex-class;

    align-items: center;
    background: $color-white;

    .input-group {
      padding: 0px 10px;
      align-items: flex-end;
      display: flex;
      display: -webkit-box;

      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;

      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;

      /* TWEENER - IE 10 */
      display: -webkit-flex;
      flex-direction: row;

      .form-control {
        height: 100% !important;
        z-index: 999;
        position: relative;
        box-sizing: border-box;
      }
    }

    .fa-search {
      color: #3C3C3B;
      font-size: 50px;

      /* font-weight: 200 !important;
       */
      -webkit-text-stroke: 3px $color-white;
      -moz-text-stroke: 3px $color-white;
      -ms-text-stroke: 3px $color-white;
    }

    @include when-xs {
      width: calc(100vw - 162px);

      &.mobile-search-open {
        width: 100vw;

        #mobile-search-close-button {
          display: block;
        }
      }

      input {
        padding-left: 10px;
        display: none;
      }

      #mobile-search-close-button {
        display: none;

        i {
          position: absolute;
          z-index: 20;
          top: 9px;
          right: 15px;
        }
      }
    }
  }
}

.inner {
  #nav-holder .navbar-right li {
    .user-balance {
      display: inline-block;
      padding-right: 5px;

      .main-currency {
        display: block;
        font-size: 12px;
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-light;
        text-align: right;
        color: $color-light-grey;
      }
    }
  }
}

#nav-holder {
  .navbar-right {
    li {
      a {
        &.company {
          font-size:14px;
          background-color:$color-primary !important;
          color:$color-white !important;
          padding-left: 12px !important;
          padding-right: 12px !important;

          &:hover {
            background-color:$color-nero !important;
            
          }
          &:hover::after {
            display:none !important;
          }
        }
        &.messages,
        &.profile {
          color: $color-white;
        }
      }

      &.profile {
        margin-right: 0px;
      }

      .user-balance {
        display: inline-block;
        padding-right: 5px;

        .main-currency {
          display: block;
          font-size: 12px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-light;
          text-align: right;
          color: $color-white;
        }
      }
    }
  }
}

.navbar.navbar-default {
  position: fixed !important;
  z-index: 1000;
}

.nav-bar-search-wrapper input.location {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: $color-nero;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: $color-nero;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: $color-nero;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: $color-nero;
  }

  @media (min-width: 1085px) and (max-width: 1399px) {
    ::-webkit-input-placeholder {
      font-size: 22px;
    }

    ::-moz-placeholder {
      font-size: 22px;
    }

    :-ms-input-placeholder {
      font-size: 22px;
    }

    :-moz-placeholder {
      font-size: 22px;
    }
  }

  @include when-lg{
    ::-webkit-input-placeholder {
      font-size: 30px;
    }

    ::-moz-placeholder {
      font-size: 30px;
    }

    :-ms-input-placeholder {
      font-size: 30px;
    }

    :-moz-placeholder {
      font-size: 30px;
    }
  }
}


.navbar-left {
  &.input-location {
    position: relative;

    li {
      display: block;
      height: auto;
      top: 0;
      font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
      font-size: 16px;
      color: #808080;

      p {
        font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
        font-size: 16px;
        color: $color-nero;
      }
    }

    .smartsearch > ul {
      & > li p.group-title {
        background: #F1F1F1;
      }
    }

    .smartsearch {
      width: auto;
      min-width: 498px;

      @include when-xs {
        max-width: 345px;
        min-width: auto;
        width: calc(100% + 35px);
      }


      ul.results {
        li:not(:last-child) {
          border-bottom: solid 1px #E5E5E5;
        }

        li {
          white-space: normal;
          color: $color-nero !important;

          &:hover {
            background: #F9F9F8 !important;
            color: $color-nero !important;
          }

          .unimportant {
            color: #9C9C9B;
          }
        }
      }
    }
  }
}

#nav-holder {
  .navbar-right .language-select {
    position: relative;

    button{
      .lan{
        position: relative;

        &:after{
          @include icon-arrow-down($color-darkest-grey);

          display: inline-block;
          margin-left: 3px;
          margin-right: 10px;

          @include when-xs{
            @include icon-arrow-down($color-dark-grey);
          }

          @media (min-width: 730px) and (max-width: 1084px) {
            width: 13px;
            height: auto;
            margin-right: 7px;
            margin-left: 2px;
          }
        }
      }

      &:hover{
        .lan{
          &:after{
            @include icon-arrow-down($color-white);

            @include when-xs{
              @include icon-arrow-down($color-nero);
            }

            @media (min-width: 730px) and (max-width: 1084px) {
              width: 13px;
              height: auto;
            }
          }
        }
      }
    }
    
    .dropdown-menu {
      border: none;
      padding: 0;
      left: 0px;
      border-top: 2px solid #E5E5E5;
      top: 100%;

      @include when-xs{
        margin-bottom: 20px;
        width: 100%;
      }

      @include when-sm{
        width: max-content;
      }

      a {
        font-family: "proxima", "Helvetica", "Arial", sans-serif !important;
        color: #808080;
        text-align: left;
        border-bottom: 1px solid #E5E5E5;
        background: $color-white;
        top: 0;
        padding-right: 5px;
        padding-top: 10px;
        padding-bottom: 10px;

        @include when-xs{
          justify-content: center;
          font-size: 14px;
          padding-left: 0 !important;
        }

        @media (min-width: 730px) and (max-width: 1084px) {
          font-size: 12px;
        }

        @media (min-width: 1085px) and (max-width: 1399px) {
          font-size: 14px;
        }

        @include when-lg{
          font-size: 16px;
        }

        &:hover {
          color: $color-nero;
          background: #F9F9F8;
        }
      }

      .dropdown-divider {
        display: none;
        margin: 0;
      }
    }
  }
}

.language-select {

  span {

    &:after {
      // display: inline-block;
      // font: normal normal normal 14px/1 FontAwesome;
      // font-size: inherit;
      // text-rendering: auto;
      // -webkit-font-smoothing: antialiased;
      // -moz-osx-font-smoothing: grayscale;
      // content: "\f078";
      // left: -19px;
      // position: ABSOLUTE;
      // position: absolute;
      // top: 1px;
      // color: #808080;
      // -webkit-text-stroke: 2px $color-white;
      // -ms-text-stroke: 2px $color-white;
      // -moz-text-stroke: 2px $color-white;
      // font-size: 18px;
  
    }
  }
}


.navbar-default {
  .nav > li > a {}
}

