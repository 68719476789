%blog-container {
	@include when-xs {
		padding-right: 10px !important;
		padding-left: 10px !important;
	}


	@include when-md {
		padding-right: 30px !important;
		padding-left: 30px !important;
	}


	@include when-lg {
		padding-right: 50px !important;
		padding-left: 50px !important;
	}
}

%blog-container-bg {
	@include when-xs {
		padding-right: 10px !important;
		padding-left: 10px !important;
	}


	@include when-md {
		padding-right: 45px !important;
		padding-left: 45px !important;
	}


	@include when-lg {
		padding-right: 65px !important;
		padding-left: 65px !important;
	}
}

[data-to-root].info.features #main-container {
	position: relative;
	padding-top: 0;
	overflow-x: hidden;

	%features-bottom-border {
		border-bottom: 1px solid $color-close-white;

		@include when-xs {
			border-bottom: none;
		}


		@include when-sm {
			margin-top: 60px;
			padding-top: 38px;
			padding-bottom: 60px;
		}


		@include when-md {
			margin-top: 90px;
			padding-bottom: 85px;
		}


		@include when-lg {
			margin-top: 120px;
			padding-top: 50px;
			padding-bottom: 130px;
		}
	}

	.box {
		width: 76px;
		height: 76px;
		font-size: 60px;
		background-color: $color-nero;
		color: $color-white;
		font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
		text-align: center;
		position: absolute;
		left: 0;
		top: 0;

		@include when-xs {
			display: none;
		}


		@include when-lg {
			width: 100px;
			height: 100px;
			font-size: 80px;
		}


		&.white {
			background-color: $color-white;
			color: $color-nero;
		}

		&.wide {
			height: 41px;
			width: 76px;

			@include when-lg {
				height: 70px;
				width: 130px;
			}
		}
	}

	%features-subtitle,
	.subtitle {
		font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
		color: $color-white;
		line-height: 1;
		font-size: 16px;
		height: 28px;
		padding: 8px;

		@include when-xs {
			font-size: 13px;
			min-height: 28px;
			height: auto;
			letter-spacing: 1px;
		}


		@include when-md {
			font-size: 20px;
			height: 36px;
			padding: 10px;
		}


		@include when-lg {
			font-size: 22px;
			height: 40px;
			padding: 12px;
		}


		&.big {
			font-size: 24px;
			height: 44px;
			padding: 12px;

			@include when-md {
				font-size: 30px;
				height: 55px;
				padding: 16px;
			}


			@include when-lg {
				font-size: 36px;
				height: 66px;
				padding: 20px;
			}
		}
	}

	.boxed-texts {
		background-color: $color-nero;
		font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
		width: auto;
		padding: 13px 17px;
		color: $color-white;
		letter-spacing: 1px;
		font-size: 13.4px;
		max-width: fit-content;
		display: flex;
		flex-direction: column;
		margin: auto;
		text-align: right;

		@include when-md {
			font-size: 18px;
		}


		&.button {
			&:hover {
				opacity: .7;
			}
		}

		.big-text {
			font-size: 89px;
			line-height: 60%;
			padding-top: 20px;
			text-align: center;

			@include when-md {
				font-size: 120px;
			}
		}

		.fancy {
			overflow: hidden;
			text-align: left;

			span {
				display: inline-block;
				position: relative;

				&.after {
					&:after {
						content: "";
						position: absolute;
						height: 1em;
						border-bottom: 1px solid white;
						top: 0;
						width: 600px;
						left: 100%;
						margin-left: 10px;
					}
				}

				&.before {
					&:before {
						content: "";
						position: absolute;
						height: 5px;
						border-bottom: 1px solid white;
						top: 0;
						width: 600px;
						right: 100%;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.big-tag {
		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
		font-size: 61px;
		margin: 0;
		color: $color-nero;
		line-height: 93%;
		white-space: nowrap;

		@include when-lg {
			font-size: 80px;
		}


		span {
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			font-size: 16px;
			margin: 0;
			color: $color-white;
			width: fit-content;
			margin-top: -16px;
			z-index: 9;
			position: relative;
			line-height: 80%;
			padding: 12px 13px;
			letter-spacing: 1px;
			white-space: nowrap;
			background-color: $color-nero;
			height: 32px;
			display: block;

			@include when-lg {
				margin-top: -20px;
			}
		}
	}

	h2, .h2 {
		font-family: "proxima", "Helvetica", "Arial", sans-serif;
		font-weight: $font-weight-bold !important;
		font-size: 28px;
		color: $color-nero;
		text-transform: none;
		margin: 0 0 15px;

		@include when-xs {
			font-size: 22px;
			margin: 0 0 20px;
		}


		@include when-md {
			margin: 0 0 25px;
			font-size: 36px;
		}


		@include when-lg {
			font-size: 44px;
		}
	}

	ol {
		p {
			margin-bottom: 0;
		}
	}

	.section3,
	.section4,
	.section5,
	.section6,
	.section7,
	.section8,
	.section9,
	.section10,
	.section11,
	.section12,
	.section13,
	.section14 {
		@extend %features-bottom-border;

		position: relative;

		.section-inner {
			padding-top: 36px;
			padding-left: 15px;
			padding-right: 15px;
			position: relative;

			@include when-xs {
				padding-left: 0;
				padding-right: 0;
			}


			@include when-lg {
				padding-top: 50px;
			}
		}
	}

	.section2 {
		@extend %blog-container;

		@include make-container;

		margin-top: 100px;
		margin-bottom: 50px;

		@include when-xs {
			margin-top: 60px;
		}


		@include when-md {
			margin-top: 140px;
		}


		.section2-inner {
			@include when-sm {
				max-width: 500px;
				margin: auto;
			}


			@include when-md {
				max-width: none;
				display: flex;

				@include make-row;
			}


			h1 {
				font-family: "proxima", "Helvetica", "Arial", sans-serif;
				font-weight: $font-weight-black !important;
				min-width: fit-content;
				font-size: 23px;
				color: $color-white;
				text-transform: uppercase;
				text-align: center;
				color: $color-light-grey;
				margin: 0 auto 20px;

				@include when-xs {
					font-size: 16px;
				}


				@include when-md {
					font-size: 34px;
				}
			}

			.left-content,
			.right-content {
				@include make-md-column(6);
			}

			.left-content {
				text-align: center;

				@include when-md {
					display: flex;
					flex-flow: column;
					justify-content: center;
				}


				p {
					color: #4D4D4D;
				}

				.item-container {
					display: flex;
					justify-content: center;
					margin-top: 15px;

					@include when-xs {
						margin-top: 40px;
						margin-bottom: 60px;
					}


					@include when-md {
						margin-top: 25px;
					}


					.item {
						margin: 0 13px;

						.icon-live-video,
						.icon-247,
						.icon-globe {
							height: 60px;
							width: auto;
							display: inline-block;

							@include when-xs {
								height: 46.885px;
							}


							@include when-md {
								height: 80px;
							}
						}

						.subtitle {
							margin-top: 5px;
							color: $color-light-grey;

							@include when-md {
								margin-top: 10px;
							}
						}
					}
				}
			}

			.right-content {
				text-align: center;
				margin: auto;
				margin-top: 60px;

				@include when-md {
					margin-top: auto;
					max-width: fit-content;
				}


				img {
					width: 100%;
					height: auto;
					max-width: 70%;

					@include when-xs {
						max-width: 220px;
					}


					@include when-lg {
						max-width: max-content;
					}
				}

				.subtitle {
					position: relative;
					background-color: $color-nero;

					@include when-xs {
						&:before {
							content: "";
							height: 100%;
							background-color: $color-nero;
							width: 50vw;
							position: absolute;
							top: 0;
							left: 100%;
						}
					}


					@include when-md {
						background-color: none;

						&:before {
							content: '';
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							background-color: $color-nero;
							z-index: -1;
							width: 300%;
						}
					}
				}
			}
		}
	}

	.section3 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;

			padding-bottom: 50px;
			background-color: $color-light-lavender;

			@include when-xs {
				background-color: #F2F2F2;
				padding-top: 60px;
				padding-bottom: 0px;
				text-align: center;

				.left-content > *:not(h2),
				.right-content {
					display: none;
				}

				h2 {
					color: $color-soft-blue;
				}
			}
		}

		.left-content {
			@include make-md-column(6);
		}

		.left-content {
			.big-tag {
				color: $color-white;
				padding-left: 76px;
				padding-bottom: 200px;

				@include when-md {
					padding-bottom: 50px;
				}


				@include when-lg {
					padding-left: 115px;
					padding-bottom: 80px;
				}
			}

			.h2 {
				color: $color-white;
				margin: 30px 0 0;

				@include when-md {
					margin: 80px 0 0;
				}
			}
		}

		.right-content {
			.img-wrapper {
				position: absolute;
				right: 0;
				top: 30px;

				@include when-md {
					top: auto;
					bottom: -40px;

					&:after {
						content: " ";
						width: 65%;
						height: 60px;
						position: absolute;
						bottom: 0;
						right: 0;
						background-color: $color-light-lavender;
						z-index: -1;
					}
				}


				@include when-lg {
					bottom: -47px;
				}


				img {
					width: 100%;
					height: auto;

					@include when-sm {
						max-width: 450px;
					}


					@include when-md {
						max-width: 620px;
					}


					@include when-lg {
						max-width: 710px;
					}
				}
			}

			.right-text {
				position: absolute;
				top: 0;
				right: 2px;
				width: 26px;
				height: 240px;
				min-height: fit-content;
				border: 1px solid $color-black;
				writing-mode: vertical-rl;
				-webkit-writing-mode: vertical-rl;
				-ms-writing-mode: vertical-rl;
				background-color: $color-nero;

				@include when-md {
					width: 47px;
					height: 463px;
				}


				div {
					float: left;
					padding: 4px;
					width: 100%;

					@include when-md {
						padding: 7px;
					}
				}

				.subtitle {
					font-size: 13px;
					height: auto;
					font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;

					@include when-md {
						font-size: 22px;
					}
				}

				.top {
					background-color: $color-white;
					height: auto;

					.subtitle {
						color: $color-nero;
					}
				}

				.bottom {
					height: auto;
				}
			}
		}
	}

	.section4 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;

			position: relative;

			&:before {
				content: ' ';
				width: 70%;
				height: 100%;
				position: absolute;
				background-color: $color-off-white;
				z-index: -1;
				left: 0;
				top: 0;
				display: block;
			}

			@include when-xs {
				background-color: $color-soft-blue;
				text-align: center;
				padding: 35px 20px;

				&:before {
					content: none;
				}

				.right-content > *:not(h2),
				.left-content,
				.img-wrapper {
					display: none;
				}

				h2 {
					color: $color-white;
				}

				.img-wrapper.mobile {
					margin-left: -20px;
					margin-right: -20px;
					padding-top: 15px;

					img {
						width: 100%;
						height: auto;
					}
				}
			}


			@include when-md {
				display: flex;
			}
		}

		.left-content {
			@include when-sm-down {
				@include make-sm-column(7);
			}


			@include when-md {
				@include make-md-column(3);
			}


			min-width: fit-content !important;
			display: flex;
			justify-content: space-between;

			.big-tag {
				color: $color-soft-blue !important;
				padding-top: 76px;

				@include when-lg {
					padding-top: 90px;
				}
			}
		}

		.img-wrapper:not(.mobile) {
			margin-top: -60px;
			text-align: center;

			@include when-sm-down {
				@include make-sm-column(5);
			}


			@include when-md {
				margin-top: -110px;
			}


			img {
				width: 100%;
				height: auto;
				position: relative;
			}

			@include when-sm {
				> img {
					width: 230px;
				}
			}


			@include when-md {
				> img {
					width: 270px;
				}
			}


			@include when-lg {
				> img {
					width: 310px;
				}
			}


			.bg-tag {
				position: absolute;
				right: -15px;
				width: calc(200% + 45px + 35%);
				z-index: 0;
				padding-bottom: 30px;
				top: 92%;

				@include when-md {
					width: 100%;
					top: auto;
					bottom: 0;
					right: 0;
					left: 0;
				}


				a.expertise {
					display: block;
					width: 100%;
					position: relative;

					&:hover {
						.tag-btn {
							color: $color-grey;
						}
					}

					.tag-btn {
						font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
						margin: 0 0 25px;
						color: $color-nero;
						border: .5px solid #c6c6c6;
						width: fit-content;
						position: absolute;
						cursor: pointer;
						font-size: 21px;
						padding: 20px 10px;
						line-height: 85%;
						top: -26px;
						right: 0;
						min-width: 28%;

						@include when-md {
							font-size: 32px;
							padding: 35px 10px;
							line-height: 70%;
							top: -38px;
						}


						@include when-lg {
							font-size: 44px;
							padding: 45px 10px;
							top: -45px;
						}
					}
				}
			}
		}

		.right-content {
			@include when-sm {
				@include make-sm-column(8);

				margin-top: 87px;
				padding-top: 30px;
				padding-bottom: 30px;
			}


			@include when-md {
				@include make-md-column(6);

				margin-top: 0;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	.section5 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;
			@include when-xs {
				padding: 55px 20px 60px;

				h2 {
					text-align: center;
				}

				.left-content {
					&:before {
						content: none;
					}

					p {
						display: none;
					}
				}
			}


			@include when-md {
				display: flex;
			}
		}

		.left-content {
			@include make-md-column(7);

			@include when-sm {
				padding-top: 112px;
				margin-top: -36px;
				padding-bottom: 30px;
			}


			@include when-md {
				padding-top: 0;
				margin-top: 0;
				padding-bottom: 60px;
			}


			&:before {
				content: ' ';
				width: 100vw;
				height: 100%;
				position: absolute;
				background-color: $color-light-lavender;
				z-index: -1;
				top: 0;
				right: 35%;

				@include when-md {
					right: -30%;
					top: 20px;
				}
			}

			h2 {
				@include when-sm {
					margin-bottom: 30px;
					width: calc(100% - 320px);
				}


				@include when-md {
					padding-left: 76px;
					width: unset;
				}


				@include when-lg {
					padding-left: 115px;
				}
			}
		}

		.right-content {
			@include when-xs {
				padding-top: 15px;
				text-align: right;
			}


			@include when-sm {
				text-align: right;
				padding-top: 20px;
				display: flex;
				flex-grow: 2;
				justify-content: flex-end;
				position: absolute;
				top: 0;
				right: 0;
				align-items: center;
			}


			@include when-md {
				position: relative;
				top: auto;
				right: auto;

				@include make-row;
			}


			img {
				width: 100%;
				max-width: 335px;
				height: auto;

				@include when-xs {
					max-height: 100px;
					width: auto;
				}


				@include when-md {
					max-width: max-content;
				}
			}
		}
	}

	.section6 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			overflow: hidden;

			@include make-row;

			display: flex;
			flex-flow: column;

			@include when-xs {
				padding-top: 0;
				background-color: $color-soft-blue;

				.right-content p,
				.left-content {
					display: none;

					&:after {
						content: none;
					}
				}
			}


			@include when-md {
				display: block;
			}
		}

		.left-content {
			@include make-md-column(6);

			margin-top: 38px;

			@include when-lg {
				margin-top: 50px;
			}


			img {
				width: 470px;
				height: auto;
				margin-left: -30px;

				@include when-md {
					width: 100%;
				}
			}

			.big-tag {
				position: absolute;
				bottom: 30px;
				color: $color-white;
			}
		}

		.right-content {
			@include make-sm-column(11);
			@include make-md-column(6);

			padding-top: 38px;
			margin-left: auto;
			padding-bottom: 40px;

			@include when-xs {
				text-align: center;
				padding-bottom: 0;
				margin-left: 0;
			}


			@include when-md {
				padding-bottom: 0;
			}


			@include when-lg {
				padding-top: 100px;
			}


			&:after {
				content: ' ';
				width: calc(100% + 30px);
				position: absolute;
				background-color: $color-soft-blue;
				z-index: -2;
				top: -344px;
				right: -15px;
				bottom: 0;

				@include when-md {
					top: -50px;
					width: calc(200% + 30px);
				}
			}

			.img-wrapper {
				@include when-xs {
					img {
						height: 165px;
						width: auto;
					}
				}


				@include when-sm {
					padding-right: 0;
					position: absolute;
					bottom: calc(100% - 1px);
					right: -15px;
				}


				@include when-md {
					padding-right: 30px;
					padding-top: 30px;
					position: relative;
					bottom: auto;
					right: auto;
				}


				img {
					margin-left: auto;
					display: block;
				}
			}
		}
	}

	.section7 {
		@extend %blog-container-bg;

		@include make-container;

		img {
			width: 100%;
			height: auto;
		}

		.section-inner {
			@include make-row;

			display: flex;
			flex-flow: column;

			@include when-xs {
				padding-top: 100px;

				.right-content p,
				.left-content {
					display: none;
				}
			}


			@include when-md {
				flex-flow: unset;
			}
		}

		.box {
			z-index: 1;
		}

		.left-content {
			@include make-sm-column(8);
			@include make-md-column(6);
			@include make-lg-column(5);

			margin-left: -30px;
			margin-top: -38px;

			@include when-md {
				margin-left: 0;
				margin-top: 0;
			}


			@include when-lg {
				margin-top: -20px;
			}


			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.right-content {
			@include make-sm-column(12);
			@include make-md-column(6);
			@include make-lg-column(7);

			position: initial;

			@include when-md {
				position: relative;
				display: flex;
				flex-flow: column;
				justify-content: space-between;
			}


			h2 {
				@include when-xs {
					text-align: center;
				}


				@include when-sm {
					font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
					font-size: 116px;
					margin: 0;
					color: $color-light-pink;
					white-space: nowrap;
					line-height: 90%;
					top: 20px;
					left: 40%;
					position: absolute;
					white-space: normal;
				}


				@include when-md {
					margin-top: 80px;
					margin-bottom: 20px;
					margin-left: -35%;
					top: auto;
					left: auto;
					position: relative;
					white-space: normal;
					font-size: 143px;
				}


				@include when-lg {
					margin-top: 120px;
					margin-left: -25%;
				}


				span {
					font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
					font-size: 16px;
					margin: 0;
					color: $color-white;
					width: fit-content;
					min-width: 220px;
					position: relative;
					line-height: 93%;
					padding: 20px;
					letter-spacing: 1px;
					white-space: nowrap;
					background-color: $color-nero !important;
					display: block;
				}
			}

			.bottom {
				position: relative;
				display: flex;
				align-items: flex-end;
				padding-bottom: 20px;

				@include when-sm {
					padding-top: 60px;

					@include make-row;
				}


				@include when-md {
					padding-top: 0;
				}


				@include when-lg {
					padding-bottom: 40px;
				}


				p {
					@include make-sm-column(12);
					@include make-md-column(6);
					@include make-lg-column(5);

					text-align: center;

					@include when-md {
						text-align: left;
					}
				}

				.img-wrapper {
					@include make-sm-column(5);
					@include make-md-column(6);
					@include when-xs {
						padding-top: 10px;
						padding-bottom: 90px;
						border-bottom: 1px solid #b3b3b3;
						text-align: center;
					}


					@include when-sm {
						position: absolute;
						bottom: 100%;
						right: -30px;
					}


					@include when-md {
						position: relative;
						bottom: auto;
						right: auto;
					}


					img {
						max-width: max-content;
					}
				}
			}
		}
	}

	.section8 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;
			@include when-xs {
				padding-bottom: 55px;

				.bottom {
					display: none;
				}

				.top {
					img {
						max-width: 375px;
						max-height: 239px;
						width: 100%;
						height: auto;
					}
				}
			}
		}

		.top, .bottom {
			@include make-sm-column(12);
		}

		.top {
			h2 {
				text-align: center;

				@include when-sm {
					margin-bottom: 60px;
					margin-left: 80px;
					margin-right: 80px;
				}
			}
		}

		.bottom {
			text-align: center;
			position: relative;

			&:before {
				content: ' ';
				position: absolute;
				background-color: $color-off-white;
				z-index: -1;
				top: 100px;
				bottom: 305px;
				display: block;
				width: 100vw;
				left: calc(-1 * (100vw - 100%) / 2 - 5px);

				@include when-md {
					bottom: 18px;
					top: 75px;
				}


				@include when-lg {
					top: 100px;
				}
			}

			.img-wrapper {
				display: inline-block;
				margin-bottom: 60px;

				&.wp {
					padding: 30px;
					margin-bottom: 0;

					@include when-md {
						padding: 0;
						position: absolute;
						top: 0;
						right: 68%;
						margin-bottom: 60px;
					}


					@include when-lg {
						right: 70%;
					}
				}

				&.html {
					padding: 30px;
					margin-bottom: 0;

					@include when-md {
						padding: 0;
						position: absolute;
						top: 0;
						left: 68%;
						margin-bottom: 60px;
					}


					@include when-lg {
						left: 70%;
					}
				}

				img {
					height: 200px;
					width: auto;

					@include when-md {
						height: 150px;
					}


					@include when-lg {
						height: 200px;
					}
				}

				span {
					@extend %features-subtitle;

					color: $color-nero;
					display: block;
					padding-top: 15px;
				}
			}

			p {
				max-width: 800px;
				margin: auto;

				span {
					display: block;
					margin-bottom: 10px;
				}
			}

			.btn {
				max-width: 460px;
				width: 100%;
				margin: auto;
				margin-top: 30px;
				display: block;
			}
		}
	}

	.section9 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;

			display: flex;
			align-items: center;
			justify-content: space-evenly;

			&:before {
				content: ' ';
				width: 100%;
				position: absolute;
				background-color: $color-bright-blue;
				z-index: -2;
				bottom: 0;
				top: 56px;
				display: block;

				@include when-xs {
					content: none;
				}


				@include when-lg {
					top: 80px;
				}
			}

			@include when-xs {
				background-color: $color-bright-blue;
				padding-top: 60px;
				flex-flow: column-reverse;

				p {
					display: none;
				}

				.left-content {
					text-align: center;
					padding-top: 40px;
				}
			}
		}

		.box {
			z-index: 1;
		}

		.left-content {
			@include make-sm-column(4);

			@include when-sm {
				margin-top: -18px;
				text-align: right;
			}


			@include when-md {
				margin-top: -38px;
			}


			@include when-lg {
				margin-top: -50px;
			}


			img {
				max-width: 184px;
				height: auto;

				@include when-md {
					max-width: 235px;
				}


				@include when-lg {
					max-width: fit-content;
				}
			}
		}

		.right-content {
			@include make-sm-column(8);
			@include make-md-column(7);

			text-align: center;

			@include when-lg {
				align-items: center;
			}
		}
	}

	.section10 {
		@extend %blog-container-bg;

		@include make-container;

		img {
			width: 90%;
			max-width: fit-content;
			height: auto;
			max-height: fit-content;

			@include when-xs {
				max-height: 175px;
				width: auto;
			}


			@include when-lg {
				width: 100%;
			}
		}

		.section-inner {
			@include make-row;

			padding-right: 0;

			@include when-xs {
				padding-top: 65px;

				.left-content p,
				.right-content {
					display: none;
				}
			}
		}

		.box,
		h2,
		.left-content {
			z-index: 1;
		}

		h2 {
			@include make-sm-column(12);

			margin-bottom: 30px;
			padding-left: 91px;

			@include when-lg {
				padding-left: 115px;
			}
		}

		.left-content {
			@include make-sm-column(6);
			@include make-md-column(5);
			@include when-xs {
				@include make-row;
			}


			@include when-sm {
				display: flex;
				flex-flow: column;
				padding-top: 60px;
			}


			@include when-md {
				padding-top: 0;
			}


			img {
				display: block;
				margin-left: auto;
				margin-top: auto;
				z-index: 1;

				@include when-sm {
					padding-top: 30px;
				}


				@include when-md {
					padding-top: 50px;
					margin-right: -15px;
				}


				@include when-lg {
					margin-right: -25%;
				}
			}
		}

		.right-content {
			position: absolute;
			right: 0;
			bottom: 0;
			text-align: right;
			max-width: 80%;

			@include when-md {
				max-width: none;
			}


			.boxed-texts {
				position: absolute;
				bottom: 50px;
				right: 0;

				@include when-md {
					bottom: 70px;
				}
			}
		}
	}

	.section11 {
		@extend %blog-container-bg;

		@include make-container;

		img {
			width: 100%;
			max-width: fit-content;
			height: auto;
		}

		.section-inner {
			@include make-row;

			display: flex;

			&:before {
				width: 100vw;
				left: calc(-1 * (100vw - 100%) / 2);
				content: " ";
				position: absolute;
				background-color: $color-soft-blue;
				z-index: 0;
				top: 0;
				bottom: 0;
				display: block;
			}

			@include when-xs {
				background-color: $color-soft-blue;
				flex-flow: column;

				&:before {
					content: none;
				}

				.left-content p,
				.left-content .big-tag {
					display: none;
				}
			}
		}

		.left-content {
			@include make-sm-column(7);
			@include make-md-column(6);

			padding-bottom: 30px;
			display: flex;
			flex-flow: column;

			@include when-xs {
				padding-bottom: 0px;
			}


			@include when-md {
				display: block;
			}


			.big-tag {
				color: #E7E6ED;
				order: 2;
				padding-top: 30px;
				white-space: normal;

				@include when-md {
					padding-left: 76px;
					padding-bottom: 50px;
					padding-top: 0;
					white-space: nowrap;
				}


				@include when-lg {
					padding-left: 115px;
					padding-bottom: 40px;
					padding-top: 20px;
				}
			}

			h2, p {
				color: $color-white;
			}

			h2 {
				@include when-xs {
					text-align: center;
				}


				@include when-sm {
					margin-left: 76px;
					margin-top: 8px;
					margin-bottom: 30px;
				}


				@include when-md {
					margin-left: 0;
					margin-top: 0;
					margin-bottom: 20px;
				}
			}
		}

		.right-content {
			@include make-sm-column(6);
			@include make-md-column(5);
			@include when-xs {
				margin-top: 0;
				margin-left: 0;
			}


			margin-left: auto;
			margin-top: -120px;
			display: flex;
			justify-content: center;

			img {
				margin-top: auto;
				max-width: 420px;

				@include when-xs {
					max-width: 240px;
				}
			}
		}
	}

	.section12 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;

			display: flex;

			@include when-xs {
				border-bottom: 1px solid #b3b3b3;
				padding: 95px 15px 40px;

				p {
					display: none;
				}
			}
		}

		.content {
			@include make-sm-column(8);

			margin-right: auto;
			margin-left: auto;
			text-align: center;
		}

		.icon-cross {
			@include icon-cross($color-violet);

			margin: 30px auto;
		}

		h2 {
			@include when-sm {
				text-transform: uppercase;
				font-weight: $font-weight-black !important;
				color: $color-light-grey;
			}
		}
	}

	.section13 {
		@extend %blog-container-bg;

		@include make-container;

		.h2 {
			padding: 80px 0 30px;
			text-align: center;

			span {
				font-weight: $font-weight-normal;
				display: block;
			}
		}

		.section-inner {
			display: flex;
			flex-direction: column;
			margin-left: 10px;
			margin-right: 10px;

			&:before {
				content: ' ';
				width: 100vw;
				height: auto;
				position: absolute;
				background-color: #868E8F;
				background-image: cdn("/images/home/community.jpg");
				background-repeat: no-repeat;
				z-index: 0;
				left: 50%;
				transform: translateX(-50%);
				top: 0;
				bottom: 0;
				display: block !important;
				background-position: top center;
				background-size: 65%;

				@include when-md {
					background-position: center;
					background-size: contain;
				}
			}

			@include when-xs {
				padding-top: 0;
				border-bottom: 10px solid $color-violet;

				&:before {
					width: 100%;
					left: 0;
					transform: none;
					background-position: top left 222px;
					background-size: contain;
				}

				.bottom {
					display: none;
				}

				.top {
					padding: 30px 15px 0;
				}
			}


			@include when-sm {
				@include make-row;
			}
		}

		.top {
			@include make-sm-column(12);
		}

		.bottom {
			display: flex;
			flex-flow: column-reverse;
			align-items: flex-end;
			padding-bottom: 30px;
			position: relative;

			&:before {
				content: ' ';
				width: 100vw;
				height: auto;
				position: absolute;
				background-color: $color-white;
				z-index: 0;
				left: 50%;
				transform: translateX(-50%);
				top: 100px;
				bottom: 0;
				display: block;

				@include when-md {
					content: none;
					background-position: center;
					background-size: contain;
				}
			}

			@include when-md {
				padding-bottom: 0;
				margin-top: 40px;
				justify-content: space-between;
				flex-flow: row;
				align-items: normal;
			}
		}

		h2 {
			text-align: left;
			font-size: 104px;
			font-family: bebasNeuebold,Helvetica,Arial,sans-serif;
			margin: 0;
			white-space: nowrap;
			line-height: 90%;
			color: $color-white;
			margin-top: 90px;

			@include when-xs {
				line-height: .9;
				font-size: 52px;
				margin: 0;
				padding-bottom: 20%;
			}


			@include when-lg {
				font-size: 143px;
			}


			span {
				@include when-xs {
					background-color: $color-violet;
					height: 21px;
					padding: 5px;
					font-size: 12px;
					width: -webkit-fit-content;
					margin-top: -12px;
					z-index: 1;
					color: $color-white;
					position: relative;
				}


				color: $color-nero;
				font-size: 34px;
				display: block;
				line-height: 1;
				font-family: "proxima", "Helvetica", "Arial", sans-serif;
				text-transform: uppercase;
				font-weight: $font-weight-black !important;
			}
		}

		p {
			@include make-sm-column(12);
			@include make-md-column(8);
			@include when-md {
				color: $color-white;
			}
		}

		.boxed-texts {
			@include make-sm-column(12);
			@include make-md-column(4);

			margin: unset;
			margin-right: -15px;
			margin-bottom: 30px;

			@include when-md {
				margin-bottom: -40px;
			}
		}
	}

	.section14 {
		@extend %blog-container-bg;

		@include make-container;

		.section-inner {
			@include make-row;

			padding-right: 0;
			display: flex;
			flex-flow: column;

			@include when-xs {
				margin-top: 90px;
				background-color: $color-off-white;

				.big-tag,
				.left-content {
					display: none;
				}
			}


			@include when-md {
				display: block;
			}
		}

		.big-tag {
			@include make-sm-column(12);
			@include make-md-column(7);
			@include make-lg-column(8);

			color: $color-light-pink;
			padding-left: 91px;

			@include when-md {
				padding-bottom: 50px;
			}


			@include when-lg {
				padding-left: 115px;
				padding-bottom: 70px;
				margin-top: -20px;
			}
		}

		.left-content {
			@include make-sm-column(12);
			@include make-md-column(6);
			@include make-lg-column(8);

			order: 3;
			text-align: center;

			@include when-md {
				text-align: left;
			}


			p {
				max-width: 600px;
				margin: auto;

				@include when-md {
					margin: unset;
				}
			}

			.h2, .app-links {
				display: inline-block;
			}

			.h2 {
				margin-top: 100px;
				margin-right: 20px;
			}

			.app-links {
				padding-left: 0;
				list-style: none;

				> li {
					display: inline-block;
					padding-right: 5px;
					padding-left: 5px;
				}
			}
		}

		.right-content {
			@include make-sm-column(8);
			@include make-md-column(6);
			@include make-lg-column(4);

			background-color: $color-off-white;
			padding-top: 70px;
			padding-bottom: 70px;
			text-align: center;

			@include when-xs {
				padding-top: 40px;
				padding-bottom: 50px;
			}


			@include when-sm {
				margin: 30px auto 60px;
				background-color: $color-off-white;
			}


			@include when-md {
				margin: unset;
				margin-top: -170px;
			}


			@include when-lg {
				margin-top: -210px;
			}


			.title {
				font-family: "proxima", "Helvetica", "Arial", sans-serif;
				font-weight: $font-weight-bold !important;
				font-size: 26px;

				@include when-md {
					font-size: 29px;
				}
			}

			.rating {
				font-family: "proxima", "Helvetica", "Arial", sans-serif;
				font-weight: $font-weight-black !important;
				font-size: 19px;
				text-transform: uppercase;
				color: #7190BA;

				@include when-md {
					font-size: 21px;
				}
			}

			.stars {
				margin-top: -9px;
				display: flex;
				margin-top: 15px;

				.star-rating {
					margin-left: auto;
					margin-right: auto;
				}
			}

			.icon-video-circle {
				margin: 50px auto 40px;
			}

			p {
				margin-top: 20px;
			}
		}
	}

	.section15 {
		padding: 100px 0;

		.btn-signup {
			cursor: pointer;
		}
	}
}

[data-to-root].info.inside #main-container .blog-content {
	padding-bottom: 50px;
	padding-top: 50px;

	.big-text {
		font-family: "proxima", "Helvetica", "Arial", sans-serif;
		font-weight: $font-weight-black !important;
		font-size: 28px;
		color: $color-light-grey;
		text-transform: uppercase;
		margin-top: 0;

		@include when-sm {
			font-size: 30px;
		}


		@include when-md {
			font-size: 34px;
		}
	}

	.subtitle {
		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
		margin-top: 2px;
		font-size: 13px;

		@include when-sm {
			font-size: 14px;
		}


		@include when-md {
			font-size: 16px;
		}
	}

	h2 {
		margin-bottom: 5px;
		margin-top: 0;
		text-transform: uppercase;
		font-weight: $font-weight-bold !important;
		font-size: 14px;

		@include when-sm {
			margin-bottom: 10px;
			font-size: 20px;
		}
	}

	p {
		margin-bottom: 20px;
		line-height: 1.5;

		@include when-md {
			margin-bottom: 30px;
		}
	}

	img {
		width: 100%;
		height: auto;
		max-width: fit-content;
	}

	.quote {
		font-family: "Times New Roman", Times, serif;
		font-style: italic;
		font-size: 16px;
		line-height: 1.5;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
		position: relative;
		padding-left: 40px;
		padding-right: 40px;

		@include when-sm {
			font-size: 18px;
			padding-left: 50px;
			padding-right: 50px;
		}


		@include when-md {
			font-size: 24px;
		}


		&:before {
			@include icon-open-quote($color-light-pink);

			width: 30px;
			height: auto;
			left: 0;
			position: absolute;
			top: -.5em;

			@include when-sm {
				width: 40px;
				top: -.3em;
			}
		}

		&:after {
			@include icon-close-quote($color-light-pink);

			width: 30px;
			height: auto;
			right: 0;
			position: absolute;
			bottom: -1em;

			@include when-sm {
				width: 40px;
				bottom: -.7em;
			}
		}
	}

	.section1 {
		@extend %blog-container;

		@include make-container;

		.section-inner {
			@include when-sm {
				@include make-row;

				display: flex;
				flex-direction: column;
			}
		}

		.top {
			text-align: center;

			@include when-sm {
				@include make-sm-column(10);

				margin: auto;
			}
		}

		.bottom {
			padding-top: 30px;

			@include when-xs {
				p {
					text-align: left;
				}
			}


			@include when-sm {
				@include make-sm-column(10);

				text-align: center;
				margin: auto;
			}


			@include when-md {
				padding-top: 50px;
			}
		}

		.h2 {
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-black !important;
			font-size: 16px;
			color: $color-primary;
			text-transform: uppercase;
			margin-top: 0;

			@include when-sm {
				font-size: 18px;
			}


			@include when-md {
				font-size: 21px;
			}
		}

		.h2-subtitle {
			color: #B3B3B3;
			margin-bottom: 0;
		}

		.icon-right-arrow {
			@include icon-right-arrow($color-light-grey);

			transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			margin: 0 auto;
			height: 42px !important;
			width: auto !important;

			@include when-sm {
				height: 70.117px !important;
				width: 35.058px !important;
			}
		}
	}

	.section2 {
		@extend %blog-container;

		@include make-container;

		margin-top: 30px;

		@include when-sm {
			margin-top: 60px;
		}


		.section-inner {
			@include when-sm {
				@include make-row;
			}
		}

		.left-content,
		.right-content {
			@include when-sm {
				@include make-sm-column(6);
			}
		}
	}

	.section3 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		margin-top: 30px;

		@include when-sm {
			margin-top: 120px;
		}


		&:before {
			content: "";
			background-color: #f5f5f5;
			position: absolute;
			z-index: -1;
			top: 0;
			bottom: 0;
			display: block;
			width: 100vw;
			left: calc(-1 * (100vw - 100%) / 2);
		}

		.img-container {
			position: relative;
			margin-bottom: 50px;

			@include when-xs {
				&:before {
					content: "";
					background-color: $color-white;
					position: absolute;
					z-index: -1;
					top: 0;
					height: 50%;
					display: block;
					width: 100vw;
					left: calc(-1 * (100vw - 100%) / 2);
				}
			}


			@include when-sm {
				display: flex;
				height: 0;
				align-items: center;
				margin-bottom: 0;
				width: 50%;
				margin-left: auto;

				img {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		.left-content,
		.right-content {
			@include when-sm {
				@include make-sm-column(6);
			}
		}

		.wide-content {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.top {
			@include when-sm {
				@include make-row;

				padding-top: 50px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}


			@include when-md {
				padding-top: 90px;
			}


			.right-content {
				@include when-xs {
					display: flex;
					flex-direction: column;
				}


				@include when-sm {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
				}
			}
		}

		.bottom {
			@include when-sm {
				@include make-row;

				padding-bottom: 30px;
			}


			@include when-md {
				padding-bottom: 60px;
			}
		}
	}

	.section4 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		margin-top: 50px;

		@include when-md {
			margin-top: 90px;
		}


		.section-inner {
			@include when-sm {
				@include make-row;

				display: flex;
				flex-wrap: wrap;
			}
		}

		.left-content,
		.right-content {
			@include when-sm {
				@include make-sm-column(6);
			}
		}

		.right-content {
			display: flex;
			flex-flow: column;
		}

		.wide-content {
			@include make-sm-column(10);

			text-align: center;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 50px;

			@include when-md {
				margin-bottom: 60px;
			}


			.icon-video-interaction {
				margin-bottom: 40px;
				margin-left: auto;
				margin-right: auto;

				@include when-xs {
					margin-bottom: 20px;
					height: 54px;
					width: auto;
				}
			}
		}

		.item-container {
			display: flex;
			margin-top: auto;
			margin-bottom: auto;
			justify-content: space-evenly;

			@include when-xs {
				order: -1;
				margin-top: 30px;
				margin-bottom: 30px;
			}


			> * {
				height: 31px;
				width: auto;

				@include when-sm {
					height: 45px;
				}


				@include when-md {
					height: 58.488px;
				}
			}
		}
	}

	.section5 {
		@extend %blog-container;

		@include make-container;

		margin-top: 50px;

		@include when-md {
			margin-top: 100px;
		}


		.section-inner {
			@include when-sm {
				@include make-row;
			}


			display: flex;

			@include when-xs {
				flex-flow: column;
			}
		}

		.left-content {
			@include when-xs {
				position: absolute;
				width: 50%;
			}


			@include when-sm {
				@include make-sm-column(6);
			}


			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.right-content {
			position: relative;

			@include when-sm {
				@include make-sm-column(6);
			}


			@include when-md {
				display: flex;
				flex-flow: column;
			}


			.top {
				margin-bottom: 30px;

				@include when-xs {
					width: 65%;
					margin-left: auto;
					margin-bottom: 60px;
				}


				@include when-sm {
					margin-left: -30%;
				}
			}

			.bottom {
				margin: auto;
			}
		}

		.quote {
			max-width: 450px;

			@include when-xs {
				max-width: 310px;
			}
		}

		.big-tag {
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			line-height: .9;
			font-size: 58px;
			width: fit-content;
			color: $color-light-pink;

			@include when-xs {
				margin-top: 20px;
				margin-bottom: 30px;
			}


			@include when-sm {
				font-size: 71px;
				margin-top: 30px;
				margin-bottom: 20px;
			}


			@include when-md {
				font-size: 121px;
				margin-top: 50px;
			}


			@include when-lg {
				font-size: 161px;
				margin-top: 90px;
				margin-bottom: 50px;
			}


			.subtitle {
				margin-bottom: 10px;
				display: table;
				white-space: nowrap;
				color: $color-nero;

				&:after {
					content: '';
					border-bottom: 0.7px solid #333333;
					display: table-cell;
					position: relative;
					left: 0;
					width: 100%;
				}
			}
		}

		.stats {
			background-color: $color-nero;
			color: $color-white;
			margin-bottom: 5px;
			max-width: min-content;
			position: relative;
			height: auto;
			font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
			font-size: 13px;
			min-width: 175px;
			padding: 13px 15px 13px 10px;
			line-height: 1;

			@include when-md {
				font-size: 16px;
				min-width: 245px;
				padding: 16px 20px;
			}


			&:before {
				@include icon-right-arrow($color-white);

				height: auto;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 6px;
				width: 5px;

				@include when-md {
					width: 6px;
					right: 10px;
				}
			}

			.content {
				position: relative;
				overflow: hidden;
			}

			b {
				position: relative;

				&:after {
					content: "";
					display: block;
					width: 200%;
					position: absolute;
					top: 0;
					height: 1em;
					font-size: .5em;
					border-bottom: 1px solid $color-white;
					left: calc(100% + 5px);
				}
			}
		}
	}

	.section6 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		margin-top: 100px;
		margin-bottom: 50px;

		@include when-md {
			margin-top: 150px;
			margin-bottom: 100px;
		}


		.top,
		.bottom {
			@include when-sm {
				@include make-row;
			}
		}

		.bottom {
			margin-top: 30px;
			padding-top: 60px;
			border-top: 1px solid #b3b3b3;

			@include when-sm {
				display: flex;

				.right-content {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.left-content,
		.right-content {
			@include when-sm {
				@include make-sm-column(6);
			}
		}

		.big-text {
			margin-bottom: 0;
			padding-top: 20px;
			margin-left: auto;
			font-size: 24px;
			text-align: center;

			@include when-sm {
				font-size: 20px;
				text-align: right;
				margin-bottom: 20px;
				margin-top: auto;
			}


			@include when-md {
				font-size: 30px;
				margin-bottom: 30px;
			}


			@include when-lg {
				font-size: 34px;
			}
		}

		.icon-cross {
			@include icon-cross($color-violet);

			height: 50px;
			width: 50px;
			margin-left: 0%;
			vertical-align: middle;
			display: block;
			margin: auto;

			@include when-sm {
				height: 40px;
				width: 40px;
				margin-left: 10px;
				display: inline;
			}


			@include when-md {
				height: 75px;
				width: 75px;
				margin-left: 20px;
			}


			@include when-lg {
				height: 92px;
				width: 92px;
			}
		}
	}

	.section7 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		padding-top: 50px;
		padding-bottom: 50px;

		&:before {
			content: "";
			background-color: $color-light-lavender;
			position: absolute;
			z-index: -1;
			top: 0;
			bottom: 0;
			display: block;
			width: 100vw;
			left: calc(-1 * (100vw - 100%) / 2);
		}

		@include when-md {
			padding-top: 70px;
			padding-bottom: 150px;
		}


		.icon-pointer-right {
			height: 50px;
			width: 50px;
			display: block;
			margin-left: auto;
			margin-right: auto;

			@include when-sm {
				height: 40px;
				width: 40px;
				margin-right: 0;
			}


			@include when-md {
				height: 75px;
				width: 75px;
			}


			@include when-lg {
				height: 92px;
				width: 92px;
			}
		}

		.section-inner {
			@include when-sm {
				@include make-row;

				display: flex;
				flex-wrap: wrap;
			}
		}

		.wide-content {
			@include make-sm-column(10);

			text-align: center;
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
			margin-bottom: 30px;

			@include when-md {
				margin-top: 30px;
				margin-bottom: 50px;
			}


			p {
				color: $color-nero;
			}
		}

		.left-content {
			@include when-sm {
				@include make-sm-column(5);
			}
		}

		.right-content {
			@include when-sm {
				@include make-sm-column(7);
			}
		}

		p {
			color: #666;

			span {
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				display: block;
			}
		}
	}

	.section8 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		margin-top: 50px;
		margin-bottom: 80px;

		@include when-md {
			margin-top: -40px;
			margin-bottom: 170px;
		}


		.section-inner {
			display: flex;
			flex-flow: column-reverse;

			@include when-sm {
				@include make-row;

				flex-flow: row;
			}


			@include when-lg {
				align-items: flex-end;
			}
		}

		.left-content,
		.right-content {
			@include when-sm {
				@include make-sm-column(6);
			}
		}

		.left-content {
			position: relative;

			img {
				height: 100%;
				object-fit: cover;
			}

			@include when-xs {
				&:before {
					content: '';
					background-color: $color-white;
					width: 85px;
					height: 20px;
					position: absolute;
					right: 100%;
					top: 0;
					left: 0;
				}
			}
		}

		.right-content {
			@include when-md {
				padding-top: 60px;
			}
		}

		.ben {
			position: relative;

			@include when-xs {
				margin: 50px 0;
			}


			@include when-sm {
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 0;
			}


			@include when-md {
				padding-top: 20px;
				padding-bottom: 20px;
			}


			@include when-lg {
				padding-top: 30px;
				padding-bottom: 30px;
			}


			&:before {
				content: '';
				background-color: $color-white;
				width: 80px;
				height: 100%;
				position: absolute;
				right: 100%;
				bottom: -1px;

				@include when-xs {
					content: none;
				}
			}
		}
	}
}

[data-to-root].info.employee-coaching #main-container .blog-content {
	@include make-container;
	@include when-sm {
		max-width: 720px;
	}


	@include when-md {
		max-width: 1085px;
	}


	@include when-lg {
		max-width: 1160px;
	}


	padding-left: 0;
	padding-right: 0;
	overflow: hidden;

	@include when-xs {
		padding-top: 50px;
		padding-bottom: 50px;
	}


	p, li {
		font-size: var(--text-font-size-default);
		line-height: 1.5em;
	}

	[onclick],
	a {
		color: darken($color-primary, 20%);

		&:hover {
			color: $color-primary;
		}
	}

	h2 {
		font-family: "proxima", "Helvetica", "Arial", sans-serif;
		font-weight: $font-weight-bold !important;
		color: $color-nero;
		text-transform: none;
		margin: 0 0 30px;

		@include when-xs {
			font-size: 22px;
		}


		@include when-md {
			font-size: 36px;
		}


		@include when-lg {
			font-size: 44px;
			margin: 0 0 50px;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	%employee-content-wrapper {
		@include when-xs {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}


		@include when-sm {
			padding-left: 25px !important;
			padding-right: 25px !important;
		}


		@include when-md {
			padding-left: 40px !important;
			padding-right: 40px !important;
		}


		@include when-lg {
			padding-left: 60px !important;
			padding-right: 60px !important;
		}
	}

	%employee-content-wrapper-left {
		@include when-xs {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}


		@include when-sm {
			padding-left: 25px !important;
			padding-right: 25px !important;
		}


		@include when-md {
			padding-left: 40px !important;
			padding-right: 30px !important;
		}


		@include when-lg {
			padding-left: 60px !important;
			padding-right: 50px !important;
		}
	}

	%employee-content-wrapper-right {
		@include when-xs {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}


		@include when-sm {
			padding-left: 25px !important;
			padding-right: 25px !important;
		}


		@include when-md {
			padding-left: 10px !important;
			padding-right: 40px !important;
		}


		@include when-lg {
			padding-left: 10px !important;
			padding-right: 60px !important;
		}
	}

	.section1 {
		padding: 0 10px 60px;

		@include when-xs {
			padding: 0 10px 38px;
		}


		display: flex;

		.inner {
			@include make-xs-column(12);
			@include make-md-column(10);
			@include make-lg-column(8);

			margin: auto;
			text-align: center;
		}

		h1,
		.title {
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-black !important;
			min-width: fit-content;
			margin: 0 auto 10px;
			color: #3C3C3B;
			text-transform: uppercase;
			text-align: center;

			@include when-md {
				font-size: 34px;
			}


			@include when-xs {
				font-size: 16px;
				max-width: 255px;
			}
		}

		.title {
			display: block;
			color: $color-light-grey;
		}

		.title, p {
			margin-bottom: 70px;

			@include when-xs {
				margin-bottom: 30px;
			}
		}

		.arrow {
			@include icon-right-arrow($color-lighter-grey);

			height: 42px !important;
			width: auto !important;
			transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			margin: 35px auto 0;

			@include when-sm {
				margin: -35px auto 0;
				height: 70.117px !important;
				width: 35.058px !important;
			}
		}
	}

	.section2 {
		background-color: #E7E6ED;
		padding: 50px 10px 0;
		margin-bottom: 60px;

		@include when-md {
			padding: 90px 10px 30px;
			margin-bottom: 130px;
		}


		@include when-sm-down {
			overflow: hidden;
		}


		.inner {
			@include make-row;
		}

		.title {
			@include make-xs-column(12);

			@extend %employee-content-wrapper;
		}

		.left-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-left;
		}

		.right-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-right;
		}

		.benefit {
			padding-top: 45px;
			text-transform: uppercase;
		}

		ul {
			@include when-sm-down {
				margin-bottom: 55px;
			}
		}

		li {
			padding-bottom: 10px;
		}

		p {
			position: relative;
		}

		.img-wrapper {
			margin: 30px 0 20px;

			@include when-md {
				margin: 60px 0 20px;
			}
		}

		.right-content .img-wrapper {
			@include when-sm-down {
				position: relative;
				z-index: 0;

				&:after {
					content: '';
					background-color: $color-white;
					position: absolute;
					width: 100vw;
					height: 243px;
					top: 50%;
					z-index: -1;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	.section3 {
		.right-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-right;
		}
	}

	.section3, .section5 {
		background-color: #F2F2F2;
		padding: 50px 10px;

		.inner {
			@include make-row;

			display: flex;

			@include when-sm-down {
				flex-flow: column-reverse;
			}
		}

		.left-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-left;

			@include when-md {
				padding-left: 0 !important;
			}
		}

		.img-wrapper {
			margin-top: 30px;

			@include when-md {
				margin-top: -90px;
			}
		}
	}

	.section4 {
		padding: 80px 10px;
		color: #808080;

		@include when-xs {
			padding: 30px 0;
		}


		.inner {
			display: flex;

			@include when-md {
				@include make-row;
			}
		}

		.right-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-right;

			margin-left: auto;
		}
	}

	.section5 {
		@include when-sm-down {
			padding-bottom: 0;

			.left-content.img-wrapper {
				&:after {
					content: '';
					background-color: $color-white;
					position: absolute;
					width: 100%;
					height: 243px;
					bottom: 0;
					z-index: -1;
					left: 0;
				}
			}
		}


		@include when-xs {
			.left-content.img-wrapper {
				&:after {
					content: none;
				}
			}
		}


		.inner {
			position: relative;

			@include when-sm-down {
				flex-flow: column;
			}


			@include when-xs {
				flex-flow: column-reverse;
			}


			.right img {
				margin-left: auto;
				margin-right: 0;
			}
		}

		.left-content {
			z-index: 0;
		}

		.img-wrapper.right {
			@include when-xs {
				position: relative;
				z-index: 1;
				text-align: center;

				&:after {
					content: '';
					background-color: $color-white;
					position: absolute;
					width: 100%;
					height: 269px;
					bottom: 0;
					z-index: -1;
					left: 0;
				}
			}


			@include when-sm {
				margin-top: -80px;
				text-align: right;
			}


			@include when-md {
				position: absolute;
				right: 0;
				width: 160px;
				margin-top: -75px;
			}


			@include when-lg {
				margin-top: -82px;
				width: 200px;
			}
		}

		.right-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-right;

			@include when-sm-down {
				position: absolute;
				top: 0;
			}


			@include when-xs {
				position: relative;
			}


			.inner {
				@include make-row;
				@include when-sm-down {
					flex-flow: row;
				}


				.left {
					@include make-xs-column(12);
					@include make-sm-column(8);

					@include when-sm-down {
						padding-left: 25px;
					}


					@include when-xs {
						padding-left: 10px;
						padding-right: 10px;
					}
				}

				.right {
					@include make-xs-column(12);
					@include make-sm-column(4);

					text-align: right;

					@include when-sm {
						padding-right: 0;
						margin-top: -90px;
					}
				}
			}
		}
	}

	.section6 {
		margin-top: 50px;
		margin-bottom: 50px;
		position: relative;
		padding: 50px 10px 0;

		@include when-md {
			margin-top: 130px;
			margin-bottom: 100px;
		}


		&:before {
			content: '';
			background-color: #C0FCF9;
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 440px;
			z-index: -1;

			@include when-sm-down {
				height: 938px;
			}


			@include when-xs {
				height: 574px;
			}
		}

		.inner {
			@include make-row;
			@include when-md {
				display: flex;
			}
		}

		.left-content,
		.right-content {
			@include make-xs-column(12);
			@include make-md-column(6);
		}

		.left-content {
			@extend %employee-content-wrapper-left;

			.img-wrapper {
				margin-left: -40px;
				margin-top: 40px;
				margin-bottom: 30px;

				@include when-sm-down {
					margin-left: 0;
					text-align: center;
					padding: 0;
				}
			}
		}

		.right-content {
			@extend %employee-content-wrapper-right;

			@include when-md {
				position: unset;
			}


			.texts {
				@include when-sm-down {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}


				@include when-md {
					@include make-md-column(6);
					@include make-row;

					position: absolute;
					left: 0;
					bottom: 13%;
				}


				@include when-lg {
					bottom: 8%;
				}


				@extend %employee-content-wrapper-left;
			}

			.img-wrapper {
				@include when-sm-down {
					text-align: center;
					max-width: 400px;
					height: auto;
					margin: -100px auto 50px;
				}


				@include when-xs {
					margin: -60px auto 50px;

					img {
						max-width: 180px;
					}
				}


				@include when-md {
					text-align: right;
				}
			}
		}
	}

	.section7 {
		background-color: #F2F2F2;
		padding: 0 10px;

		@include when-sm-down {
			padding: 0 10px 25px;
		}


		.inner {
			@include make-row;
		}

		.big-text-wrapper {
			@include make-xs-column(12);

			@extend %employee-content-wrapper;

			background: cdn("/images/blog/employee-coaching/available.jpg") no-repeat;
			background-size: cover;
			padding-top: 90px;
			min-height: 560px;
			display: block;
			text-align: right;
			padding-bottom: 13px;
			background-position: center;

			@include when-sm-down {
				padding-top: 50px;
				min-height: 290px;

				.big-text {
					margin-bottom: 25px;
				}
			}


			@include when-xs {
				background: cdn("/images/blog/employee-coaching/available-mob.jpg") no-repeat;
				padding: 60px 10px 0;
				min-height: 560px;
				background-position: center;
				background-size: cover;
			}


			.big-text {
				font-size: 80px;
				letter-spacing: 1px;
				line-height: .8;
				justify-content: left;
				font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
				text-transform: uppercase;
				color: $color-white;
				display: block;
				position: relative;
				margin-bottom: 55px;
				padding-bottom: 13px;

				@include when-xs {
					font-size: 36px;
					line-height: .9;
					padding-bottom: 20px;
				}
			}

			.subtitle {
				font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
				color: $color-white;
				line-height: 1;
				font-size: 13px;
				min-height: 28px;
				height: auto;
				padding: 8px;
				letter-spacing: 1px;
				background-color: $color-nero;
				position: absolute;
				width: fit-content;
				bottom: 0;
				right: 0;
				min-width: 130px;
				text-align: center;
			}

			.inner {
				display: flex;

				.right {
					@include make-xs-column(12);
					@include make-md-column(5);
					@include make-lg-column(4);

					margin-left: auto;
				}
			}

			p {
				color: $color-white;

				@include when-xs {
					max-width: 172px;
					margin-left: auto;
				}
			}

			@include when-sm-down {
				div > div {
					max-width: 400px;
				}
			}
		}

		.left-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-left;
		}

		.right-content {
			@include make-xs-column(12);
			@include make-md-column(6);

			@extend %employee-content-wrapper-right;

			@include when-md {
				padding-right: 0 !important;
			}
		}

		.left-content {
			padding-top: 50px;
			padding-bottom: 20px;
		}

		.img-wrapper {
			@include when-md {
				padding: 0;
			}
		}
	}

	.section8 {
		padding: 180px 10px 130px;
		overflow: hidden;

		@include when-sm-down {
			padding: 90px 10px 10px;
		}


		.inner {
			@include make-row;
			@include when-xs {
				display: flex;
				flex-flow: column-reverse;
			}
		}

		.left-content {
			@include make-xs-column(12);
			@include make-sm-column(7);

			@extend %employee-content-wrapper-left;
		}

		.right-content {
			@include make-xs-column(12);
			@include make-sm-column(5);

			@extend %employee-content-wrapper-right;

			@include when-xs {
				padding-bottom: 40px;
			}
		}

		h2 {
			margin-top: 20px;
		}

		span {
			cursor: pointer;
		}

		.btn {
			width: 600px;
			height: 66px;
			font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif !important;
			color: $color-white;
			font-size: 30px;
			position: relative;
			text-align: left;
			margin-bottom: 20px;
			max-width: 100%;
			padding: 18px 26px;
			line-height: 1.2;

			&:after {
				@include icon-right-arrow($color-white);

				width: 13px;
				height: auto;
				position: absolute;
				right: 20px;

				@include when-sm-down {
					width: 9px;
					height: auto;
					right: 13px;
				}
			}

			@include when-lg {
				font-size: 36px;
				line-height: 1;
			}


			@include when-sm-down {
				padding: 10px 13px;
				height: 36px;
				font-size: 19px;
				line-height: 1;
				max-width: 270px;
			}
		}

		.personal-coach {
			background-color: #66C7C2;

			&:hover {
				border: 1px solid #66C7C2;
				color: #66C7C2;
				background-color: $color-white;

				&:after {
					@include icon-right-arrow(#66C7C2);

					width: 13px;
					height: auto;

					@include when-sm-down {
						width: 9px;
						height: auto;
					}
				}
			}
		}

		.business-coach {
			background-color: #7190BA;

			&:before {
				content: '';
				background-color: $color-nero;
				bottom: -13px;
				position: absolute;
				width: 60vw;
				height: 40px;
				left: 31%;
				z-index: -1;

				@include when-sm-down {
					bottom: -7px;
					height: 21px;
				}


				@include when-xs {
					width: 100vw;
				}
			}

			&:hover {
				border: 1px solid #7190BA;
				color: #7190BA;
				background-color: $color-white;

				&:after {
					@include icon-right-arrow(#7190BA);

					width: 13px;
					height: auto;

					@include when-sm-down {
						width: 9px;
						height: auto;
					}
				}
			}
		}
	}
}

[data-to-root].info.how-it-works #main-container .blog-content {
	padding-bottom: 50px;
	padding-top: 50px;

	h2 {
		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
		font-size: 58px;
		text-transform: uppercase;
		font-weight: $font-weight-bold !important;
		padding-left: 80px;
		line-height: .7;
		padding-top: 15px;

		@include when-xs {
			padding-left: 30px;
			font-size: 40px;
			padding-top: 0;
			line-height: 1;
			margin-top: 0;
		}


		@include when-md {
			padding-left: 140px;
			font-size: 80px;
			padding-top: 25px;
		}


		span {
			margin-left: -80px;
			font-size: 116px;
			display: inline-block;

			@include when-xs {
				width: auto;
				font-size: inherit;
				margin-left: -30px;
			}


			@include when-md {
				margin-left: -140px;
				font-size: 222px;
			}
		}
	}

	.content {
		padding-left: 80px;

		@include when-xs {
			padding-left: 30px;
		}


		@include when-md {
			padding-left: 140px;
		}


		p {
			margin-top: 50px;

			@include when-xs {
				margin-top: 30px;

				&:first-of-type {
					margin-top: 0;
				}
			}


			b {
				font-weight: $font-weight-normal;
			}
		}
	}

	ul {
		padding-inline-start: 0;
		list-style-type: none;

		li {
			display: inline-block;
			margin-right: 5px;

			@include when-xs {
				margin-right: 0;
				max-width: 50%;
			}


			a {
				color: inherit;
			}
		}
	}

	.section1,
	.section2,
	.section3,
	.section4 {
		padding-top: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid $color-lighter-grey;

		@include when-xs {
			padding-bottom: 50px;
		}


		@include when-md {
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}

	.section1 {
		@extend %blog-container;

		@include make-container;

		display: flex;
		align-items: center;
		padding-bottom: 40px;
		position: relative;

		@include when-xs {
			padding-top: 0;
		}


		.left-content {
			width: 70%;

			@include when-md {
				width: 60%;
			}


			.big-tag {
				font-size: 90px;
				letter-spacing: 1px;
				line-height: 1;
				justify-content: left;
				font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
				text-transform: uppercase;
				color: $color-light-pink;

				@include when-xs {
					font-size: 40px;
				}


				@include when-md {
					font-size: 116px;
				}
			}

			h1 {
				color: $color-light-grey;
				font-size: 22px;
				text-transform: uppercase;
				font-family: "proxima", "Helvetica", "Arial", sans-serif;
				font-weight: $font-weight-black !important;
				letter-spacing: 1px;

				@include when-xs {
					font-size: 16px;
				}


				@include when-lg {
					font-size: 34px;
				}
			}
		}

		.right-content {
			position: relative;
			width: 30%;

			@include when-xs {
				position: absolute;
				right: 0;
				top: 0;
				width: 45%;
				z-index: -1;
			}


			@include when-md {
				width: 35%;
			}


			.img-wrapper {
				background: cdn("/images/blog/howitworks-img.jpg");
				background-repeat: no-repeat;
				background-size: contain;
				z-index: -1;
				display: block;
				width: 100%;
				height: 0;
				background-size: 100%;
				padding-top: 90%;
			}
		}
	}

	.section2 {
		@extend %blog-container;

		@include make-container;

		.btn-callmenow {
			display: flex;
			justify-content: center;
			cursor: default;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
			font-size: 22px;
			color: $color-nero;
			width: 265px;
			max-width: 100%;
			letter-spacing: 1px;
			line-height: 1 !important;
			height: 38px;
			padding: 10px 0;
			text-align: center;

			span {
				margin-left: 5px;
				align-self: center;
			}
		}

		.appstore a {
			width: 127.546px;
			height: 37.8px;
			content: url("/images/appstore.svg");
		}

		.playstore a {
			width: 127.546px;
			height: 37.8px;
			content: url("/images/playstore.svg");
		}

		.playstore a,
		.appstore a {
			border: 1px solid $color-nero;
			border-radius: 4px;
			display: inline-block;
		}
	}

	.section3 {
		@extend %blog-container;

		@include make-container;

		p {
			span {
				color: $color-primary;
				text-transform: uppercase;
				display: block;
				margin: 10px 0;
			}
		}

		.btn-startnow {
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
			font-size: 22px;
			color: $color-nero;
			width: 265px;
			max-width: 100%;
			letter-spacing: 1px;
			line-height: 1 !important;
			height: 38px;
			padding: 10px 0;
			text-align: center;
		}
	}

	.section4 {
		@extend %blog-container;

		@include make-container;

		.share-icons {
			display: flex;

			li {
				margin-right: 0;
			}
		}
	}

	.section5 {
		@extend %blog-container;

		@include make-container;

		text-align: center;
		padding-top: 80px;
		padding-bottom: 50px;
		position: relative;

		@include when-md {
			padding-bottom: 150px;
		}


		.tag {
			color: $color-white;
			width: fit-content;
			position: absolute;
			top: -20px;
			left: 50%;
			transform: translateX(-50%);
			font-size: 17px;
			line-height: 1;
			padding: 12px;
			background-color: $color-nero;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			letter-spacing: 1px;
			cursor: default;
		}

		p {
			line-height: 3em;
		}

		.btn {
			margin-top: 30px;

			&:hover {
				background-color: $color-white;
				color: $color-nero;
			}
		}
	}
}

// [data-to-root].info.about #main-container .blog-content {
// 	padding-bottom: 50px;
// 	padding-top: 50px;

// 	.big-tag {
// 		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
// 		line-height: .8;
// 		white-space: nowrap;
// 		position: relative;
// 		font-size: 116px;
// 		width: fit-content;
// 		margin-top: 0;

// 		@include when-xs {
// 			font-size: 70px;
// 		}

// 		@include when-md {
// 			font-size: 172px;
// 		}

// 		@include when-lg {
// 			font-size: 222px;
// 		}

// 		span {
// 			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
// 			font-size: 20px;
// 			color: $color-white;
// 			position: relative;
// 			padding: 12px 13px 8px;
// 			letter-spacing: 1px;
// 			white-space: nowrap;
// 			background-color: $color-nero;
// 			display: block;
// 			text-align: center;
// 			margin-top: -20px;

// 			@include when-xs {
// 				font-size: 14px;
// 				margin-top: -15px;
// 			}

// 			@include when-sm-down {
// 				width: fit-content;
// 			}

// 			@include when-md {
// 				margin-top: 0;
// 			}
// 		}

// 		.subtitle {
// 			font-size: 22px;
// 			font-family: "proxima", "Helvetica", "Arial", sans-serif;
// 			line-height: 1;
// 			display: block;
// 			text-align: center;
// 			color: $color-light-grey;
// 			text-transform: uppercase;
// 			font-weight: $font-weight-black;
// 			margin-top: -15px;

// 			@include when-xs {
// 				font-size: 14px;
// 				margin-top: -5px;
// 			}

// 			@include when-md {
// 				font-size: 34px;
// 				margin-top: -25px;
// 			}
// 		}
// 	}

// 	img {
// 		max-width: 100%;
// 		height: auto;
// 	}

// 	.boxed-texts {
// 		background-color: $color-nero;
// 		font-family: "bebasNeueBold", "Helvetica", "Arial", sans-serif;
// 		width: auto;
// 		padding: 13px 17px;
// 		color: $color-white;
// 		letter-spacing: 1px;
// 		font-size: 13.4px;
// 		max-width: fit-content;
// 		display: flex;
// 		flex-direction: column;
// 		text-align: right;

// 		@include when-sm {
// 			font-size: 18px;
// 		}

// 		&.button {
// 			&:hover {
// 				opacity: .7;
// 			}
// 		}

// 		.big-text {
// 			font-size: 70px;
// 			line-height: 60%;
// 			padding-top: 20px;
// 			text-align: center;

// 			@include when-sm {
// 				font-size: 120px;
// 			}
// 		}

// 		.fancy {
// 			overflow: hidden;
// 			text-align: left;

// 			span {
// 				display: inline-block;
// 				position: relative;

// 				&.after {
// 					&:after {
// 						content: "";
// 						position: absolute;
// 						height: 1em;
// 						border-bottom: 1px solid white;
// 						top: 0;
// 						width: 600px;
// 						left: 100%;
// 						margin-left: 10px;
// 					}
// 				}

// 				&.before {
// 					&:before {
// 						content: "";
// 						position: absolute;
// 						height: 5px;
// 						border-bottom: 1px solid white;
// 						top: 0;
// 						width: 600px;
// 						right: 100%;
// 						margin-right: 10px;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.section1 {
// 		@extend %blog-container;

// 		@include make-container;

// 		.section-inner {
// 			@include when-sm {
// 				@include make-row;
// 			}

// 			display: flex;
// 			align-items: center;

// 			@include when-xs {
// 				flex-flow: column-reverse;
// 			}
// 		}

// 		.left-content {
// 			@include when-sm {
// 				@include make-sm-column(5);
// 				@include make-md-column(4);
// 			}
// 		}

// 		.right-content {
// 			@include when-sm {
// 				@include make-sm-column(7);
// 				@include make-md-column(8);
// 			}
// 		}

// 		.big-tag {
// 			color: $color-bright-pink;
// 			z-index: 1;
// 			margin-bottom: 20px;

// 			@include when-xs {
// 				margin-top: -10px;
// 			}

// 			@include when-md {
// 				margin-bottom: 30px;
// 			}

// 			@include when-lg {
// 				margin-bottom: 50px;
// 			}
// 		}
// 	}

// 	.section2 {
// 		@extend %blog-container;

// 		@include make-container;

// 		margin-top: 70px;

// 		@include when-xs {
// 			margin-top: 30px;
// 		}

// 		@include when-md {
// 			margin-top: 100px;
// 		}

// 		.big-tag {
// 			margin: auto;
// 			position: relative;
// 			line-height: .9;
// 			padding: 30px 0;
// 			width: 100%;
// 			text-align: center;

// 			@include when-xs {
// 				padding: 20px 0;
// 				font-size: 50px;
// 			}

// 			&:before {
// 				content: '';
// 				position: absolute;
// 				bottom: 0;
// 				width: 100vw;
// 				z-index: -1;
// 				display: block;
// 				left: calc(-1 * (100vw - 100%) / 2);
// 			}

// 			&.top {
// 				&:before {
// 					background-color: $color-bright-pink;
// 					top: 25%;
// 				}
// 			}

// 			&.bottom {
// 				color: $color-bright-pink;

// 				&:before {
// 					background-color: $color-nero;
// 					top: 0;
// 				}
// 			}
// 		}
// 	}

// 	.section3 {
// 		@extend %blog-container;

// 		@include make-container;

// 		margin-top: 100px;

// 		@include when-md {
// 			margin-top: 150px;
// 		}

// 		.section-inner {
// 			@include make-row;
// 			@include when-lg {
// 				display: flex;
// 				align-items: center;
// 				flex-flow: row-reverse;
// 			}
// 		}

// 		.left-content {
// 			@include make-xs-column(12);
// 			@include make-sm-column(8);
// 			@include make-lg-column(3);

// 			padding-top: 30px;

// 			@include when-lg {
// 				padding-top: 0;
// 			}
// 		}

// 		.right-content {
// 			@include make-xs-column(12);
// 			@include make-lg-column(9);

// 			display: flex;

// 			@include when-xs {
// 				flex-flow: column;
// 				align-items: flex-end;
// 			}

// 			@include when-sm {
// 				display: flex;
// 				align-items: center;
// 			}

// 			img {
// 				@include when-xs {
// 					width: 100%;
// 					height: auto;
// 				}

// 				@include when-sm {
// 					max-width: 400px;
// 				}

// 				@include when-md {
// 					max-width: 100%;
// 				}
// 			}
// 		}

// 		.big-tag {
// 			margin-left: -70px;
// 			color: $color-light-pink;
// 			margin-bottom: 0;

// 			@include when-xs {
// 				margin-left: auto;
// 				margin-top: -10px;
// 			}

// 			@include when-md {
// 				margin-left: -100px;
// 			}

// 			@include when-lg {
// 				margin-left: -150px;
// 			}
// 		}
// 	}

// 	.section4 {
// 		@extend %blog-container;

// 		@include make-container;

// 		margin-top: 50px;

// 		@include when-md {
// 			margin-top: 100px;
// 		}

// 		@include when-lg {
// 			margin-top: 150px;
// 		}

// 		.img-wrapper {
// 			background: cdn("/images/blog/about_bot.jpg");
// 			background-repeat: no-repeat;
// 			background-position: top right;
// 			background-size: contain;
// 			padding-top: 43%;
// 			position: relative;

// 			@include when-sm {
// 				padding-top: 40%;
// 				height: 0;
// 				background-position: top right -200% !important;
// 			}

// 			@include when-md {
// 				background-position: top right !important;
// 			}

// 			.boxed-texts {
// 				@include when-sm {
// 					position: absolute;
// 					top: 50%;
// 					transform: translateY(-50%);
// 				}
// 			}
// 		}

// 		p {
// 			text-align: center;
// 			margin-top: 20px;

// 			@include when-md {
// 				margin-top: 50px;
// 			}
// 		}
// 	}

// 	.section5 {
// 		@extend %blog-container;

// 		@include make-container;

// 		margin-top: 50px;
// 		text-align: center;

// 		@include when-md {
// 			margin-top: 100px;
// 		}

// 		@include when-lg {
// 			margin-top: 150px;
// 		}

// 		.big-tag {
// 			color: $color-light-pink;
// 			margin: 0 auto 20px;
// 			line-height: 1;
// 		}

// 		.hiw-btn {
// 			width: 250px;
// 			color: $color-white;
// 			margin: 30px auto;
// 			background-color: $color-nero;
// 			font-family: bebasNeuebold,Helvetica,Arial,sans-serif;
// 			letter-spacing: 1px;
// 			line-height: 1;
// 			font-size: 14px;
// 			display: flex;
// 			align-items: center;
// 			padding: 20px 40px;
// 			position: relative;
// 			white-space: nowrap;

// 			@include when-md {
// 				width: 320px;
// 				height: 100px;
// 				font-size: 20px;
// 				margin: 50px auto;
// 			}

// 			&:after {
// 				content: "";
// 				height: .2em;
// 				border-top: 1px solid white;
// 				top: 0;
// 				width: 100%;
// 				margin-left: 20%;
// 			}

// 			.icon-right-arrow {
// 				height: 1em;
// 				width: auto;
// 				position: absolute;
// 				right: 25px;
// 			}
// 		}
// 	}
// }

[data-to-root].info.contact #main-container .blog-content {
	h1 {
		font-family: "bebasNeuebook", "Helvetica", "Arial", sans-serif !important;
		font-size: 42px;
		color: #808080;
		text-align: center;
		margin: 45px 0;
	}

	h2 {
		font-family: "bebasNeuebook", "Helvetica", "Arial", sans-serif !important;
		font-size: 42px;
		color: #808080;
		margin: 0 0 25px;
	}

	p {
		color: #4D4D4D;
	}

	img {
		width: 100%;
		height: auto;
	}

	.header {
		@extend %blog-container;

		@include make-container;

		.img-wrapper {
			background-image: cdn("/images/blog/contact/theone-contact.jpg");
			padding-top: 21%;
			background-position: right center;
			background-size: cover;

			@include when-xs {
				padding-top: 74%;
				background-image: cdn("/images/blog/contact/theone-contact-mob.jpg");
			}
		}
	}

	.section1 {
		@extend %blog-container;

		@include make-container;

		.section-inner {
			@include make-row;
		}

		.left-content {
			@include make-md-column(3);

			.content-inner {
				background-color: $color-light-pink;
				padding: 40px 15px;
				position: relative;

				@include when-md {
					padding: 40px 15px 70px;
				}


				@include when-lg {
					padding: 40px 20px 115px;
				}
			}
		}

		.right-content {
			@include make-md-column(9);
		}

		.big-tag {
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
			font-size: 42px;
			color: $color-white;
			line-height: 85%;
			margin-bottom: 40px;

			span {
				background-color: $color-nero;
				font-size: 12px;
				width: fit-content;
				margin-top: -7px;
				line-height: 80%;
				padding: 10px;
				letter-spacing: 1px;
				white-space: nowrap;
				height: 24px;
				display: block;
			}
		}

		.btn {
			background-color: $color-white;
			color: $color-nero;
			position: absolute;
			bottom: -1px;
			right: -1px;
			line-height: 78%;
			padding: 11px 15px 7px;

			&:hover {
				background-color: $color-nero;
				color: $color-white;
			}
		}
	}

	.section2 {
		@extend %blog-container;

		@include make-container;

		.top {
			padding-top: 80px;

			@include when-sm {
				@include make-row;

				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}


			@include when-lg {
				padding-bottom: 40px;
			}


			.content {
				@include when-sm {
					@include make-sm-column(6);
					@include make-lg-column(3);

					display: flex;
					flex-flow: column;
				}
			}
		}

		.bottom {
			padding-top: 80px;
			border-top: 1px solid #ccc;

			@include when-xs {
				padding-top: 0;
				border-top: none;
			}


			@include when-sm {
				@include make-row;
			}


			@include when-lg {
				padding-bottom: 40px;
			}


			.download-btn {
				color: $color-white;
				background-color: $color-nero;
				border-color: $color-nero;
				display: block;
				width: fit-content;
				margin-top: 30px;
			}

			.content {
				@include when-sm {
					@include make-sm-column(6);
					@include make-md-column(3);

					&.large {
						@include make-sm-column(6);
						@include make-md-column(9);
					}
				}


				.content-inner {
					@include when-md {
						@include make-row;

						display: flex;
						flex-wrap: wrap;
						align-items: center;
					}


					.img-wrapper {
						@include when-md {
							@include make-md-column(4);
						}


						cursor: pointer;
						padding-bottom: 20px;
						color: inherit;
						display: block;
					}
				}
			}
		}

		.content {
			margin-bottom: 70px;

			@include when-lg {
				margin-bottom: 30px;
			}


			p {
				margin-bottom: 30px;
			}

			.mail {
				margin-top: auto;
				margin-bottom: initial;

				a {
					color: $color-primary;

					@include when-lg {
						display: block;
					}
				}
			}
		}
	}
}

[data-to-root].info.help #main-container .blog-content {
	@include when-xs {
		padding-top: 50px;
		padding-bottom: 50px;
	}


	.help-container {
		@include make-container;
		@include when-sm {
			max-width: 720px;
		}


		@include when-md {
			max-width: 1085px;
		}


		@include when-lg {
			max-width: 1160px;
		}


		padding-left: 20px;
		padding-right: 20px;

		h1 {
			line-height: 1;
			color: $color-bright-pink;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			font-size: 116px;
			margin-top: 0;
			margin-bottom: 0;

			@include when-xs {
				font-size: 70px;
			}


			@include when-md {
				font-size: 172px;
			}


			@include when-lg {
				font-size: 222px;
			}
		}
	}

	.first-customer {
		@include make-container;
		@include when-sm {
			max-width: 720px;
		}


		@include when-md {
			max-width: 1085px;
		}


		@include when-lg {
			max-width: 1160px;
		}


		padding-left: 20px;
		padding-right: 20px;
		margin-top: 100px;

		h2 {
			font-family: proxima,Helvetica,Arial,sans-serif;
			font-weight: $font-weight-black !important;
			font-size: 28px;
			color: $color-light-grey;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 50px;

			@include when-md {
				font-size: 30px;
			}
		}

		.sub-title {
			text-transform: uppercase;
			margin-top: 30px;
		}

		.steps-wrapper {
			margin-top: 30px;

			.step {
				margin: 10px 0;

				&.active {
					.step-title {
						background-color: $color-bright-pink;

						&:after {
							transform: rotate(90deg);
							right: 20px;
							top: 6px;

							@include when-md {
								right: 15px;
								top: 7px;
							}
						}
					}

					.content {
						display: block;
					}
				}
			}

			.step-title {
				font-size: 24px;
				font-family: "bebasNeuebook", "Helvetica", "Arial", sans-serif;
				line-height: 1;
				cursor: pointer;
				margin-bottom: 0px;
				padding: 10px 30px 2px 10px;
				background-color: $color-closer-white;
				position: relative;

				@include when-md {
					font-size: 32px;
				}


				&:after {
					@include icon-right-arrow($color-nero);

					width: 10px;
					height: auto;
					position: absolute;
					right: 10px;

					@include when-md {
						width: 13px;
					}
				}
			}

			.content {
				padding: 20px;
				display: none;
			}
		}

		.tip {
			font-size: 12px;
			font-style: italic;

			@include when-md {
				font-size: 16px;
			}


			+ p {
				margin-top: 30px;
			}
		}

		.underline {
			text-decoration: none;
			background-color: rgba(255, 255, 204, 1);
			box-decoration-break: clone;
			box-shadow: 5px 0 0 rgba(255, 255, 204, 1), -5px 0 0 rgba(255, 255, 204, 1);
			display: inline;
			margin: 0;
			padding: 5px;
			transform: rotate(88deg) translateX(67px) translateY(-17px) skewX(3deg) skewY(-2deg);
		}
	}
}

[data-to-root].info.toc #main-container .blog-content {
	@include when-xs {
		padding-top: 50px;
		padding-bottom: 50px;
	}


	@include make-container;
	@include when-sm {
		max-width: 720px;
	}


	@include when-md {
		max-width: 1085px;
	}


	@include when-lg {
		max-width: 1160px;
	}


	padding-left: 20px;
	padding-right: 20px;

	h1, h2 {
		font-size: 40px;
		line-height: 1;
		color: $color-nero;
		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

		@include when-md {
			font-size: 80px;
		}
	}

	h2 {
		margin-top: 70px;

		@include when-md {
			margin-top: 100px;
		}
	}

	.title {
		font-weight: $font-weight-bold;
		display: block;
		margin-bottom: 5px;
		margin-top: 30px;

		@include when-md {
			margin-top: 50px;
		}
	}

	p {
		margin-bottom: 10px;
		line-height: 1.5;
	}

	ul {
		margin-bottom: 30px;

		li {
			margin-bottom: 10px;

			ul {
				margin-bottom: 0;
				margin-top: 10px;
			}
		}
	}
}

[data-to-root].info.embed #main-container .blog-content {
	@include when-sm {
		padding-bottom: 50px;
		padding-top: 50px;
	}


	.section1 {
		@extend %blog-container;

		@include make-container;

		margin-bottom: 60px;

		.section-inner {
			@include when-md {
				@include make-row;

				display: flex;
			}
		}

		.left-content,
		.right-content {
			@include make-md-column(6);
		}

		h1 {
			font-size: 84px !important;
			letter-spacing: 1px;
			line-height: 1;
			text-transform: uppercase;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			margin: 0 0 30px;
			color: $color-light-pink;
		}

		.right-content {
			background: cdn("/images/blog/embed/mac_small.png");
			background-repeat: no-repeat;
			height: 0;
			padding-top: 32%;
			background-size: initial;
			background-position: center;
			margin: auto;
			margin-top: 30px;

			@include when-md {
				padding-top: 21%;
				margin-top: auto;
			}
		}
	}

	.section2 {
		@extend %blog-container;

		@include make-container;

		.section-inner {
			@include when-md {
				@include make-row;
			}
		}

		.left-content,
		.right-content {
			@include make-md-column(6);

			margin-bottom: 50px;
		}

		.content {
			padding: 30px;
			background-color: $color-off-white;
		}

		.img-container {
			background-repeat: no-repeat !important;
			height: 100px;
			background-size: contain !important;
			background-position: center !important;
			margin: auto;
		}

		h2 {
			margin: 20px 0 50px;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			font-size: 22px;
			text-align: center;
		}

		p {
			margin: 0 0 20px;

			&.no-margin {
				margin: 0;
			}
		}

		.code-wrapper {
			max-width: 480px;
			margin: 50px auto 0;

			.form-control {
				text-transform: none;
				-webkit-appearance: none;
				background: cdn("/images/icon-next-thick.svg") no-repeat right $color-nero;
				background-size: 9px;
				background-position: right 10px top 9px;
			}
		}

		code {
			white-space: normal;
			color: #c7254e;
			display: block;
			padding: 1em 1em;
			overflow: auto;
			border-radius: 0.3em;
			background: hsl(24, 20%, 95%);
		}

		.download-btn {
			margin: 30px auto;
			display: block;
			max-width: fit-content;
		}

		.video-container {
			margin-top: 50px;
			position: relative;
			height: 0px;
			padding-bottom: 56.25%;

			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
			}
		}

		.left-content .img-container {
			background: cdn("/images/blog/embed/html-embed-icon.png");
		}

		.right-content .img-container {
			background: cdn("/images/blog/embed/wp-embed-icon.png");
		}
	}
}

[data-to-root].info.careers #main-container .blog-content {
	padding-bottom: 50px;
	padding-top: 50px;

	@include when-xs {
		padding-top: 20px;
	}


	@extend %blog-container;

	@include make-container;

	h1 {
		font-size: 70px;
		line-height: 1;
		color: $color-light-pink;
		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

		@include when-sm {
			font-size: 80px;
		}


		@include when-md {
			font-size: 172px;
		}
	}

	p {
		margin-bottom: 10px;
		line-height: 1.5;
	}
}

[data-to-root].info.partner-investor-2020 #main-container .blog-content {
	padding-bottom: 50px;
	padding-top: 50px;

	@mixin width-tight {
		margin-left: auto;
		margin-right: auto;

		@include when-xs {
			max-width: 1020px;
		}


		@include when-sm {
			max-width: 1020px;
		}


		@include when-md {
			max-width: 1020px;
		}


		@include when-lg {
			max-width: 1020px;
		}
	}

	counter-reset: step;

	.step-wrapper {
		position: relative;

		&:before {
			counter-increment: step;
			content: counter(step);
			position: absolute;
			left: calc(-1 * (100vw - 100%) / 2);
			line-height: 1;
			width: 100vw;
			z-index: -1;
			color: $color-light-pink;
			font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
			display: none;

			@media (min-width: 900px) and (max-width: 1084px) {
				display: block;
				top: -20px;
				font-size: 150px;
			}

			@media (min-width: 1270px) and (max-width: 1399px) {
				display: block;
				top: -20px;
				font-size: 200px;
			}

			@media (min-width: 1600px) {
				top: -30px;
				font-size: 280px;
				display: block;
			}
		}
	}

	hr {
		border-top: 2px solid $color-close-white;
		position: relative;
		overflow: visible;

		@include when-sm-down {
			margin: 70px 0 60px;
		}


		@include when-md {
			margin: 70px 0 100px;
		}


		&:before {
			@include icon-live-video($color-close-white);

			height: 59.854px;
			width: 85.054px;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;

			@include when-sm-down {
				height: 40.015px;
				width: 56.861px;
			}
		}

		&:after {
			content: '';
			background-color: $color-white;
			height: 2px;
			width: 76px;
			position: absolute;
			top: -2px;
			left: calc(50% + -2px);
			transform: translateX(-50%);
			z-index: 1;

			@include when-sm-down {
				width: 50px;
			}
		}
	}

	.big-text {
		font-family: "proxima", "Helvetica", "Arial", sans-serif;
		font-weight: $font-weight-black !important;
		font-size: 28px;
		color: $color-light-grey;
		text-transform: uppercase;
		margin-top: 0;

		@include when-sm {
			font-size: 30px;
		}


		@include when-md {
			font-size: 34px;
		}
	}

	.btn-19-ver {
		color: $color-nero;
		font-size: 14px;

		@include when-md {
			font-size: 20px;
		}


		&:hover {
			color: $color-primary;
		}
	}

	.subtitle {
		font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
		margin-top: 2px;
		font-size: 13px;

		@include when-sm {
			font-size: 14px;
		}


		@include when-md {
			font-size: 16px;
		}
	}

	h2 {
		margin-bottom: 5px;
		margin-top: 0;
		text-transform: uppercase;
		font-weight: $font-weight-bold !important;
		font-size: 14px;

		span {
			color: $color-primary;
		}

		@include when-sm {
			margin-bottom: 10px;
			font-size: 20px;
		}
	}

	p {
		margin-bottom: 20px;
		line-height: 1.5;

		@include when-md {
			margin-bottom: 30px;
		}
	}

	.quote {
		font-family: "Times New Roman", Times, serif;
		font-style: italic;
		font-size: 16px;
		line-height: 1.5;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
		position: relative;
		padding-left: 40px;
		padding-right: 40px;

		@include when-sm {
			font-size: 18px;
			padding-left: 50px;
			padding-right: 50px;
		}


		@include when-md {
			font-size: 24px;
		}


		&:before {
			@include icon-open-quote($color-light-pink);

			width: 30px;
			height: auto;
			left: 0;
			position: absolute;
			top: -.5em;

			@include when-sm {
				width: 40px;
				top: -.3em;
			}
		}

		&:after {
			@include icon-close-quote($color-light-pink);

			width: 30px;
			height: auto;
			right: 0;
			position: absolute;
			bottom: -1em;

			@include when-sm {
				width: 40px;
				bottom: -.7em;
			}
		}
	}

	.section1 {
		@extend %blog-container;

		@include make-container;

		@include when-sm {
			margin-top: 50px;
		}


		@include when-md {
			margin-top: 100px;
		}


		.top {
			@include width-tight;

			text-align: center;
			position: relative;

			.h2-subtitle {
				display: table;
				white-space: nowrap;
				width: 100%;

				@include when-sm {
					&:before, &:after {
						content: '';
						display: table-cell;
						position: relative;
						top: 0.75em;
						width: 45%;
					}

					&:after {
						border-top: 1px solid #B3B3B3;
					}

					&:before {
						right: 1.5%;
					}

					&:after {
						left: 0;
					}
				}
			}

			.btn-19-ver {
				margin: 0;
				text-align: right;
				padding: 0 20px;
				font-weight: $font-weight-bold;
				z-index: 1;

				@include when-xs {
					margin-top: 20px;
					margin-left: auto;
					display: inline-block;
				}


				@include when-sm {
					position: absolute;
					bottom: 0;
					right: calc(-1 * (100vw - 100%) / 2);
				}


				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					background-color: $color-yellow;
					left: 0;
					right: 0;
					bottom: 0;
					height: 10px;

					@include when-md {
						height: 15px;
					}
				}
			}
		}

		.bottom {
			@include width-tight;

			padding-top: 30px;
			text-align: center;

			@include when-md {
				padding-top: 50px;
			}
		}

		.h2 {
			font-family: "proxima", "Helvetica", "Arial", sans-serif;
			font-weight: $font-weight-black !important;
			font-size: 16px;
			color: $color-primary;
			text-transform: uppercase;
			margin-top: 0;

			@include when-sm {
				font-size: 18px;
			}


			@include when-md {
				font-size: 21px;
			}
		}

		.h2-subtitle {
			color: #B3B3B3;
			margin-bottom: 0;
		}

		.icon-right-arrow {
			@include icon-right-arrow($color-light-grey);

			transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			margin: 0 auto;
			height: 42px !important;
			width: auto !important;

			@include when-md {
				height: 70.117px !important;
				width: 35.058px !important;
			}
		}
	}

	.section2 {
		@extend %blog-container;

		@include make-container;

		margin-top: 30px;

		@include when-sm {
			margin-top: 60px;
			padding-bottom: 90px;
		}


		.top {
			@include when-sm {
				@include make-row;
			}


			.left,
			.right {
				@include when-sm {
					@include make-sm-column(6);
				}
			}
		}

		.bottom {
			@include width-tight;

			.quote {
				margin-top: 40px;
				margin-bottom: 60px;
			}
		}
	}

	.section3 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		margin-top: 30px;

		@include when-sm {
			margin-top: 120px;
			padding-bottom: 60px;
		}


		&:before {
			content: "";
			background-color: #f5f5f5;
			position: absolute;
			z-index: -1;
			top: 0;
			bottom: 0;
			display: block;
			width: 100vw;
			left: calc(-1 * (100vw - 100%) / 2);
		}

		.img-wrapper {
			position: relative;
			margin-bottom: 50px;

			img {
				max-width: 100%;
				height: auto;
			}

			@include when-xs {
				&:before {
					content: "";
					background-color: $color-white;
					position: absolute;
					z-index: -1;
					top: 0;
					height: 50%;
					display: block;
					width: 100vw;
					left: calc(-1 * (100vw - 100%) / 2);
				}
			}


			@include when-sm {
				display: flex;
				height: 0;
				align-items: center;
				margin-bottom: 0;
				width: 50%;
				margin-left: auto;

				img {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		.top {
			@include when-sm {
				@include make-row;
			}


			.left,
			.right {
				@include when-sm {
					@include make-sm-column(6);
				}
			}
		}

		.top {
			.left {
				position: relative;

				@include when-sm {
					padding-top: 60px;
				}


				.big-text {
					@include when-xs {
						line-height: 1em;
						margin-bottom: 30px;
					}


					@include when-sm {
						position: absolute;
						bottom: calc(100% - 1em);
						line-height: .9;
					}


					@include when-md {
						bottom: 100%;
						line-height: .7;
					}
				}
			}
		}

		.bottom {
			@include when-sm {
				column-count: 2;
				column-gap: 30px;
			}


			.content1,
			.content2,
			.content3,
			.content4 {
				@include when-xs {
					margin-bottom: 40px;
				}
			}

			.content1,
			.content3 {
				@include when-sm {
					margin-bottom: 0;
					min-height: 230px;
				}


				@include when-md {
					min-height: 250px;
				}


				@include when-lg {
					min-height: 200px;
				}
			}
		}

		.bottom {
			margin-top: 50px;
		}
	}

	.section4 {
		@extend %blog-container;

		@include make-container;

		.icon-video-interaction {
			margin: auto;
			margin-bottom: 70px;
			margin-top: 100px;

			@include when-sm-down {
				width: 80px;
				height: auto;
			}


			@include when-xs {
				margin-bottom: 120px;
			}


			@include when-sm {
				margin-bottom: 50px;
				margin-top: 70px;
			}


			@include when-md {
				margin-bottom: 70px;
				margin-top: 100px;
			}
		}

		.top {
			position: relative;

			@include when-sm-down {
				margin-top: 100px;
				padding-bottom: 20px;
				padding-top: 40px;
			}


			@include when-md {
				margin-top: 160px;
				padding-bottom: 70px;
				padding-top: 70px;
			}


			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				background-color: #9ADEDB;
				opacity: .33;
				height: 100%;
				bottom: 0;

				@include when-xs {
					right: calc(-10px  - ((100vw - #{$container-xs}) / 2));
					left: -20px;
				}


				@include when-sm {
					right: calc(-10px  - ((100vw - #{$container-sm}) / 2));
					left: -30px;
				}


				@include when-md {
					right: calc(-30px  - ((100vw - #{$container-md}) / 2));
					left: -70px;
				}


				@include when-lg {
					right: calc(-50px  - ((100vw - #{$container-lg}) / 2));
				}
			}

			.big-text {
				position: absolute;
				bottom: 100%;
				line-height: .7;
				color: #63C9C5;
			}
		}

		.bottom {
			text-align: center;
			position: relative;
			padding-top: 70px;
			padding-bottom: 10px;

			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				background-color: #F6E0E2;
				opacity: .33;
				height: 100%;
				right: -10px;
				bottom: 0;

				@include when-xs {
					right: -20px;
					height: calc(100% + 2em);
					left: calc(-10px  - ((100vw - #{$container-xs}) / 2));
				}


				@include when-sm {
					left: calc(-10px  - ((100vw - #{$container-sm}) / 2));
				}


				@include when-md {
					left: calc(-30px  - ((100vw - #{$container-md}) / 2));
				}


				@include when-lg {
					left: calc(-50px  - ((100vw - #{$container-lg}) / 2));
				}
			}

			.big-text {
				line-height: .9;
				position: absolute;
				text-align: center;
				display: block;
				width: 100%;
				bottom: calc(100% - 1em);
				color: $color-primary;
			}
		}
	}

	.section5 {
		@extend %blog-container;

		@include make-container;

		margin-top: 110px;
	}

	.section6 {
		@extend %blog-container;

		@include make-container;

		@include when-sm {
			display: flex;
		}


		.left {
			flex: 1;
		}

		.right {
			position: relative;

			@include when-sm {
				width: 180px;
			}


			img {
				position: absolute;
				right: 0;
				bottom: 0;

				@include when-sm-down {
					width: 130px;
					height: auto;
				}


				@include when-xs {
					position: relative;
					margin: auto;
					display: block;
				}
			}
		}
	}

	.section7 {
		@extend %blog-container;

		@include make-container;

		.top {
			.img-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 70px;
				margin-bottom: 50px;
				position: relative;

				@include when-sm-down {
					display: block;
					margin-top: 30px;
				}


				.logo {
					@include when-sm-down {
						height: 60px;
						width: auto;
						margin-bottom: 40px;
					}


					@include when-xs {
						display: block;
						margin-left: auto;
					}


					@include when-md {
						height: 70px;
						width: auto;
					}


					@include when-lg {
						width: auto;
					}
				}

				.webinars {
					max-width: 100%;
					height: auto;

					@include when-xs {
						display: block;
					}


					@include when-md {
						height: 150px;
						width: auto;
					}


					@include when-lg {
						width: auto;
					}
				}

				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					background-color: $color-yellow;
					bottom: 25px;

					@include when-xs {
						left: 40px;
						right: calc(-10px  - ((100vw - #{$container-xs}) / 2));
						bottom: 0;
						top: auto;
						height: 80px;
					}


					@include when-sm {
						top: 0;
						left: 50%;
						right: calc(-10px  - ((100vw - #{$container-sm}) / 2));
					}


					@include when-md {
						top: -35px;
						left: 25%;
						right: calc(-30px  - ((100vw - #{$container-md}) / 2));
					}


					@include when-lg {
						right: calc(-50px  - ((100vw - #{$container-lg}) / 2));
					}
				}
			}

			.quote {
				margin-bottom: 50px;
				margin-top: 40px;

				@include when-md {
					margin-top: 50px;
				}
			}

			strong {
				position: relative;
				display: inline-block;

				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					background-color: $color-yellow;
					height: 100%;
					right: -20px;
					bottom: 0;

					@include when-md {
						left: calc(-30px  - ((100vw - #{$container-md}) / 2));
					}


					@include when-lg {
						left: calc(-50px  - ((100vw - #{$container-lg}) / 2));
					}
				}
			}
		}

		.bottom {
			@include when-sm {
				@include make-row;

				display: flex;
			}


			margin-top: 80px;
			margin-bottom: 40px;
			align-items: center;

			.left {
				@include when-sm {
					@include make-sm-column(6);
				}


				p span {
					color: #FF0000;
				}
			}

			.right {
				display: flex;
				align-items: flex-end;
				justify-content: space-evenly;

				@include when-sm {
					@include make-sm-column(6);
				}


				@include when-xs {
					margin-top: 50px;
				}


				.content1,
				.content2 {
					text-align: center;

					img {
						margin: auto;

						@include when-sm-down {
							width: 80px;
							height: auto;
						}
					}

					h3 {
						font-weight: $font-weight-black !important;
						font-size: 30px;
						color: #878C90;

						@include when-sm-down {
							font-size: 20px;
						}


						span {
							margin-top: 20px;
							font-size: 22px;
							display: block;

							@include when-sm-down {
								margin-top: 10px;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	.section8 {
		@extend %blog-container;

		@include make-container;

		display: flex;

		.left {
			flex: 1;
		}

		.right {
			position: relative;

			@include when-sm {
				width: 250px;
			}


			@include when-md {
				width: 305px;
			}


			img {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				max-width: 90%;
				height: auto;
			}
		}
	}

	.section9 {
		@extend %blog-container;

		@include make-container;

		.top {
			@include width-tight;

			text-align: center;
			margin-bottom: 50px;

			@include when-sm {
				margin-bottom: 100px;
			}


			.big-text {
				margin-bottom: 30px;
				color: $color-primary;
			}
		}

		.bottom {
			@include when-sm {
				@include make-row;
			}


			.left {
				@include when-sm {
					@include make-sm-column(6);
				}
			}

			.right {
				@include when-sm {
					@include make-sm-column(6);
				}
			}
		}

		img {
			margin: 70px auto 100px;
			display: block;
			max-width: 100%;
			height: auto;

			@include when-xs {
				margin: 30px auto 30px;
			}


			@include when-sm {
				margin: 50px auto 50px;
				width: 70%;
			}


			@include when-md {
				margin: 70px auto 100px;
				width: auto;
			}
		}
	}

	.section10 {
		@extend %blog-container;

		@include make-container;

		position: relative;
		margin-top: 50px;

		@include when-md {}


		.top {
			@include width-tight;

			text-align: center;
			position: relative;

			@include when-xs {
				padding-top: 40px;
				padding-bottom: 20px;
			}


			@include when-sm {
				padding-top: 100px;
				padding-bottom: 50px;
			}


			@include when-md {
				padding-top: 150px;
				padding-bottom: 150px;
			}


			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				background-color: $color-light-lavender;
				width: 100vw;
				top: 0;
				bottom: 0;

				@include when-xs {
					left: calc(-10px  - ((100vw - #{$container-xs}) / 2));
				}


				@include when-sm {
					left: calc(-10px  - ((100vw - #{$container-sm}) / 2));
					bottom: -40px;
				}


				@include when-md {
					left: calc(-30px  - ((100vw - #{$container-md}) / 2));
				}


				@include when-lg {
					left: calc(-50px - 140px - ((100vw - #{$container-lg}) / 2));
				}
			}
		}

		.bottom {
			display: flex;

			@include when-xs {
				flex-direction: column-reverse;
				padding-top: 30px;
				padding-bottom: 50px;
			}


			@include when-sm {
				@include make-row;
			}


			.left {
				@include when-sm {
					@include make-sm-column(7);
				}


				@include when-md {
					@include make-md-column(6);
				}


				img {
					max-width: 100%;
				}
			}

			.right {
				@include when-sm {
					@include make-sm-column(5);
				}


				@include when-md {
					@include make-md-column(6);
				}
			}

			.left {
				position: relative;

				img {
					height: 100%;
					object-fit: cover;
				}

				@include when-xs {
					&:before {
						content: '';
						background-color: $color-white;
						width: 85px;
						height: 20px;
						position: absolute;
						right: 100%;
						top: 0;
						left: 0;
					}
				}
			}

			.right {
				@include when-sm {
					padding-top: 50px;
				}


				@include when-md {
					padding-top: 60px;
				}


				display: flex;
				flex-flow: column;
				justify-content: flex-end;
			}

			.ben {
				position: relative;

				@include when-xs {
					margin: 50px 0;
				}


				@include when-sm {
					padding-top: 10px;
					padding-bottom: 10px;
					margin-bottom: 0;
				}


				@include when-md {
					padding-top: 20px;
					padding-bottom: 20px;
				}


				@include when-lg {
					padding-top: 30px;
					padding-bottom: 30px;
				}


				&:before {
					content: '';
					background-color: $color-white;
					width: 80px;
					height: 100%;
					position: absolute;
					right: 100%;
					bottom: -1px;

					@include when-xs {
						content: none;
					}
				}
			}
		}

		.btn-19-ver {
			text-align: center;
			display: block;

			@include when-sm {
				margin-top: 100px;
			}


			@include when-md {
				margin-top: 150px;
			}
		}
	}
}
