/*
 * theone modals
 */

.modal-body textarea {
  width:100%;
  margin:10px 0px;
}

.to-alert {
  display: none;
  position: fixed;
  z-index: 100000;
  right: -375px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
  width: 375px;
  height: 100vh;

  @include transition(all 0.2s ease-in-out);

  @include when-xs{
    width: 100% !important;
  }

  .flavor-container {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: visible;
    transform: rotate(-90deg);
    
    @include when-xs{
      width: 100%;
      height: auto;
      transform: none;
      bottom: 0;
    }

    .flavor {
      position: absolute;
      right: 0;
      width: 600px;
      font-size: 100px;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      line-height: 1;
      color: #4D4D4D;
      text-align: right;

      @include when-xs{
        position: relative;
        width: 100%;
        font-size: 20vw;
        line-height: .8em;
      }
    }
  }

  .dashline {
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, $color-grey 50%, rgba(255, 255, 255, 0) 0);
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }

  .content {
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px;
    
    @include when-xs{
      padding: 0 10px;
    }

    .text {
      color: $color-white;
      text-align: center;
      padding: 40px 0 10px 0;
      font-size: 18px;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-bold !important;
    }

    .smalltext {
      color: $color-white;
      text-align: center;
      padding: 20px 0;
      font-size: 12px;
    }
  }

  .buttons-container {
    position: relative;
  }

  .buttons {
    padding: 30px 0;
    border-bottom: 1px solid $color-grey;
    width: 100%;
    display: flex;
    justify-content: center;

    button, button:hover {
      color: #4D4D4D;
      width: 160px;
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 18px;
      color: $color-nero;
      text-align: center;
      border: 0 none;
      padding: 6px 15px 3px;
      margin-left: 10px;

      @include when-xs{
        width: 135px;
      }

      span {
        position: absolute;
        right: 10px;
      }

      &.btn-yes {
        background-color: #D4E652;
        background-image: cdn('/images/icon-next-thick.svg');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 20px 14px;
      }

      &.btn-no {
        background-color: #DBC0C3;
      }
    }

    button:first-child {
      margin-left: 0;
    }
  }

  .btn-close, .btn-close:hover {
    border: 0 none;
    outline: none;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    background-image: cdn("/images/icon-cross.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
  }

  /* --------------------------------- call data --------------------------------- */

  .call-data {
    background-color: $color-white;
    color: $color-nero;
    position: relative;
    width: 335px;

    @include when-xs{
      width: 100%;
      max-width: 335px;
    }

    .call-more {
      position: absolute;
      z-index: 10;
      right: 20px;
      top: 20px;

      .more-button {
        background-image: cdn('/images/icons/icon-tips.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 27px;
        height: 27px;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;

        &.open {
          background-image: cdn("/images/icon-cross.svg");
          background-size: 6px 6px;
        }
      }

      .more-options {
        border: 1px solid $color-grey;
        background-color: $color-closer-white;
        font-size: 12px;
        color: $color-nero;
        padding: 4px 5px 2px 5px;
        width: 300px;
        display: none;

        .more-option {
          padding-bottom: 1px;
          user-select: none;
          cursor: pointer;
        }
      }
    }

    .call-avatar {
      position: absolute;
      top: -63px;
      left: 127px;
      width: 125px;
      height: 130px;

      .call-avatar-img {
        width: 100%;
        height: 100%;
        background-color: $color-off-white;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        clip-path: url(#theonepath);

        .placeholder {
          width: 97%;
          height: 100%;
          background-image: cdn('/images/onboarding/icon-avatar.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 40%;
        }
      }

      &.call .call-avatar-img{
        clip-path: url(#theonepathcall);
      }
    }

    .call-title,
    .call-name {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 20px;
      padding-left: 20px;
      line-height: 1;
    }

    .call-title {
      color: $color-light-pink;
      padding-top: 20px;
      width: 100px;
    }

    .call-name {
      color: $color-black;
    }

    .call-message {
      padding: 10px 20px 10px 20px;
      font-family: "proxima", "Helvetica", "Arial", sans-serif;
      font-weight: $font-weight-bold !important;
    }

    .call-info {
      padding: 10px 20px 10px 20px;

      .call-info-profession {
        font-family: "proxima", "Helvetica", "Arial", sans-serif;
        font-weight: $font-weight-bold !important;
      }

      .call-info-location {}

      .call-rating {
        height: 30px;
        padding-top: 15px;

        .score {
          background-color: $color-white;
          position: relative;
          margin: 0 auto;
          top: -8px;
        }
      }

      .call-callback {
        display: none;
        text-align: center;
        padding: 20px 0 10px 0;

        .dashline {
          opacity: 0.4;
        }

        .call-info-price {
          background-color: $color-black;
          color: $color-white;
          display: inline-block;
          padding: 2px 10px 1px 10px;
          position: relative;
          top: -10px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-black !important;
          font-size: 22px;

          .call-info-price-label {
            font-size: 14px;
            font-family: "proxima", "Helvetica", "Arial", sans-serif;
            font-weight: $font-weight-bold !important;
          }
        }

        .call-callback-title {
          text-transform: uppercase;
          font-size: 14px;
          margin-top: 5px;
          line-height: 1.1em;
        }

        .call-callback-subtitle {
          text-transform: uppercase;
          color: $color-light-pink;
          font-size: 14px;
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-bold !important;
          line-height: 1.1em;
        }
      }

      .call-tagline {
        .tagline {
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-bold !important;
        }
      }
    }

    .buttons {
      padding: 10px 0;
      border-bottom: 0 none;

      .btn-accept, .btn-accept:hover {
        // background-image: url("/images/icons/icon-accept.svg");
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 20px 14px;
        padding-right: 20px;
      }

      .btn-decline {
        // background-image: url("/images/icons/icon-decline.svg");
        background-repeat: no-repeat;
        background-position: left 10px center;
        background-size: 20px 14px;
        padding-left: 20px;
      }

      .btn-cancel {
        // background-image: url("/images/icons/icon-decline.svg");
        background-repeat: no-repeat;
        background-position: left 10px center;
        background-size: 20px 14px;
        padding-left: 20px;
      }
    }

    .buttons-cancel {
      .loader {
        overflow: hidden;
        width: 35px;
        height: 35px;

        img {
          height: 35px;
        }
      }

      .btn {
        width: 140px;
      }

      .btn-green {
        color: $color-black !important;
      }
      .btn-green:hover {
        background-color: $color-green;
      }
    }

    .buttons-close {
      .btn,
      .btn:hover {
        background-image: none;
      }
    }

    .call-options {
      padding: 10px 20px 10px 20px;

      .decline-reasons {
        display: none;
        font-size: 12px;
      }

      .decline-reason {
        background-image: cdn('/images/icon-next-thick.svg');
        background-repeat: no-repeat;
        background-position: right 8px;
        background-size: 10px 9px;
        padding: 5px 0;
        cursor: pointer;
      }

      .loader {
        text-align: center;
        height: 35px;
        overflow: hidden;

        img {
          height: 35px;
        }
      }
    }

    .review-main {
      padding-top: 40px;
    }

    .review-title,
    .review-name {
      font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
      font-size: 20px;
      line-height: 1;
    }

    .review-title {
      padding-left: 20px;
      width: 120px;
      color: $color-black;
    }

    .review-name {
      padding: 10px 0 10px 20px;
      color: $color-light-pink;
    }
  }
}

.to-alert.to-alert-callback {
  width: 100%;
  text-align: center;

  .call-title {
    width: auto;
    text-align: left;
  }

  .buttons {
    .btn,
    .btn:hover {
      background-image: none;
    }
  }

  .extras {
    color: $color-white;
    position: relative;
    width: 335px;
    padding: 20px 0;

    .btn-more {
      user-select: none;
      cursor: pointer;

      .when-open {
        display: none;
      }

      &.opened {
        .when-open {
          display: block;
        }

        .when-closed {
          display: none;
        }
      }
    }

    .more-content {
      padding-top: 20px;

      .more-note {
        padding: 20px 0;
        color: $color-close-white;
      }
    }
  }
}
