// html {
//     height: 100%;
// }

.color-primary {
  color: $color-primary !important;
}

.color-blue {
  color: $color-blue !important;
}

.color-violet {
  color: $color-violet !important;
}

.color-light-blue {
  color: $color-light-blue !important;
}

.color-light-pink {
  color: $color-light-pink !important;
}

.color-soft-blue {
  color: $color-soft-blue !important;
}

.color-red {
  color: $color-red !important;
}

.color-green {
  color: $color-green !important;
}

.color-darker-green {
  color: $color-darker-green !important;
}

.color-white {
  color: $color-white !important;
}

.color-black {
  color: $color-black !important;
}

.color-off-black {
  color: $color-off-black !important;
}

.color-nero {
  color: $color-nero !important;
}

.color-darker-grey {
  color: $color-darker-grey !important;
}

.color-dark-grey {
  color: $color-dark-grey !important;
}

.color-grey {
  color: $color-grey !important;
}

.color-light-grey {
  color: $color-light-grey !important;
}

.color-off-white {
  color: $color-off-white !important;
}

.color-xmas {
  color: $color-xmas !important;
}

.bg-color-xmas {
  background-color: $color-xmas !important;
}

.bg-color-blue {
  background-color: $color-blue !important;
}

.bg-color-soft-blue {
  background-color: $color-soft-blue !important;
}

.bg-color-bright-blue {
  background-color: $color-bright-blue !important;
}

.bg-color-violet {
  background-color: $color-violet !important;
}

.bg-color-light-blue {
  background-color: $color-light-blue !important;
}

.bg-color-red {
  background-color: $color-red !important;
}

.bg-color-white {
  background-color: $color-white !important;
}

.bg-color-black {
  background-color: $color-black !important;
}

.bg-color-off-black {
  background-color: $color-off-black !important;
}

.bg-color-nero {
  background-color: $color-nero !important;
}

.bg-color-darker-grey {
  background-color: $color-darker-grey !important;
}

.bg-color-dark-grey {
  background-color: $color-dark-grey !important;
}

.bg-color-grey {
  background-color: $color-grey !important;
}

.bg-color-light-grey {
  background-color: $color-light-grey !important;
}

.bg-color-off-white {
  background-color: $color-off-white !important;
}

.bg-color-light-lavender {
  background-color: $color-light-lavender !important;
}

.bg-color-light-pink {
  background-color: $color-light-pink !important;
}

.color-warning {
  color: $color-warning;
}

.bg-color-warning {
  background-color: rgba($color-warning, $dark-alpha) !important;
}

.greyed {
  -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -o-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.noscroll {
  overflow: hidden;
}

.noheight {
  height: 0 !important;
}

.bb-0 {
  border-bottom: none !important;
}

.inline {
  display: inline-block;
}

.block {
  display: block;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.p-50 {
  padding: 50px 0px;

  @include when-xs {
    padding: 20px 0px;
  }
}

.p-0 {
  padding: 0;
}

#main-container {
  // height: calc(100% - 75px);

  // overflow-y: scroll;
  .row,
  .container {
    @include clearfix;
  }
}

[data-to-root].home #main-container {
  overflow-y: visible;
}

.pac-container.request-map,
.pac-container.user-location {
  z-index: 1000000;
}

.container.max-width-xs {
  max-width: $container-xs;
}

.container.max-width-sm {
  max-width: $container-sm;
}

.container.max-width-mds {
  max-width: calc(#{$container-md} - 0px);
}

.container.max-width-md {
  max-width: $container-md;
}

.container.max-width-lg {
  max-width: $container-lg;
}

.container.min-width-xs {
  min-width: $container-xs;
}

.container.min-width-sm {
  min-width: $container-sm;
}

.container.min-width-md {
  min-width: $container-md;
}

.container.min-width-lg {
  min-width: $container-lg;
}

.fake-link {
  color: $primary-color;
  cursor: pointer;
  border: none;
  padding: 0px;
  text-decoration: inherit;
  margin: 0px;
}

.embed-responsive-home {
  padding-bottom: 42.8125%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn {
  font-family: "proxima", "Helvetica", "Arial", sans-serif;
}

input,
select,
textarea {
  border-radius: 0px;
  border: 1px solid $color-lighter-grey !important;

  &:active,
  &:focus,
  &:hover {
    border: 1px solid $color-nero !important;
  }

  font-family: "proxima", "Helvetica", "Arial", sans-serif;
  resize: none;
}

label {
  font-size: 14px;
  font-weight: $font-weight-normal;
  color: $color-darker-grey;
}

.padded {
  padding-top: 10px;
  padding-bottom: 10px;
}

.backdrop {
  background-color: $color-off-white;
}

.bootstrap-select {
  .btn {
    border-color: $color-lighter-grey;
    text-transform: inherit;
    font-weight: inherit;
    color: $color-darker-grey;
    box-shadow: none;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;

    &:hover {
      background-color: inherit;
      border-color: inherit;
      color: $color-nero;
    }
  }

  .btn:hover,
  .btn:active {
    @include box-shadow(none !important);
  }
}

.has-error .bootstrap-select {
  .btn.dropdown-toggle {
    border-color: $color-warning !important;
  }

  .form-control {
    border: none !important;
  }
}

.tight-fit .bootstrap-select {
  width: auto !important;
}

.btn:hover,
.btn:active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-group,
.btn-group.open,
.btn-group:focus,
.btn-group:active {
  @include box-shadow(none !important);
}

.iframeplay {
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    width: 65px;
    height: 65px;
    background-size: 65px 65px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    top: calc(50% - 32.5px);
    left: calc(50% - 32.5px);
    background-image: cdn("/images/icon-iframe-play.svg");

    @include opacity($alpha);
    @include when-sm {
      margin-top: 25px;
    }


    @include when-md {
      margin-top: 0;
    }
  }

  &:hover:after {
    @include opacity(1);
  }
}

#sidebar,
.menu-sidebar {
  top: 101px;
  position: sticky;

  // @media only screen and (max-width: 1084px) {
  //     top: auto;
  //     position: relative;
  // }
  .fixed-row.affix {
    width: 365px;
    top: 60px;

    // is this always $navbar-height ? if yes, consider xs-height too?
    @include when-sm {
      width: auto;
      position: relative;
      top: auto;
    }


    @include when-md {
      width: 365px;
    }


    @include when-lg {
      width: 365px;
      top: auto;
      position: relative;
    }
  }
}

h3,
.h3 {
  font-size: 28px;
  text-transform: uppercase;

  &.line-half:after {
    width: calc(50% - 15px);

    @include when-xs {
      width: 100%;
    }
  }

  &.line-full:after {
    width: 100%;
  }

  &.line-text-only {
    display: inline-block;

    &:after {
      width: 100%;
    }
  }

  &.noline {
    margin-bottom: 0px;

    &:after {
      display: none;
    }
  }
}

table td {
  border-top: none !important;
}

table th {
  border-bottom: none !important;
}

.text-center h3:after,
.text-center .h3:after {
  margin: auto;
}

.upper {
  text-transform: uppercase  !important;
}

.lower {
  text-transform: lowercase  !important;
}

.capital {
  text-transform: capitalize;
}

.bigger {
  font-size: 18px !important;
}

.big {
  font-size: 16px;
}

.small {
  font-size: 14px;
}

.smaller {
  font-size: 12px;
}

.huge {
  font-size: 34px;
}

.proxima {
  font-family: "proxima", "Helvetica", "Arial", sans-serif;
}

.bebas-bold {
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif !important;
}

.bebas {
  font-family: "bebasNeuebook", "Helvetica", "Arial", sans-serif !important;
}

.proxima_black {
  font-family: "proxima", "Helvetica", "Arial", sans-serif;
  font-weight: $font-weight-black !important;
}

.light {
  font-weight: $font-weight-light !important;
}

.bold {
  font-weight: $font-weight-bold !important;
}

.bbold {
  font-weight: $font-weight-black !important;
}

.normal {
  font-weight: $font-weight-normal !important;
}

.nopadding {
  padding: 0px !important;
}

.nomargin {
  margin: 0px !important;
}

.error {
  color: $color-red;
}

.justify-content-center {
  justify-content: center;
}

.align-items-end {
  align-items: flex-end;
}

h4,
.h4 {
  font-size: 22px;
  text-transform: uppercase;
  color: $primary-highlight-color;
}

h5,
.h5 {
  font-family: "proxima", "Helvetica", "Arial", sans-serif;
  font-size: 22px;
  font-weight: $font-weight-light;
  text-transform: lowercase;
}

h3.small,
.h3.small {
  font-size: 16px;
}

.btn {
  color: $color-white;
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

  &:hover {
    color: $color-white;
    background-color: $primary-active-color;
    border-color: $primary-active-color;
  }

  text-transform: uppercase;

  /** weird font? **/
  &.btn-primary {
    color: $color-white;
    border-color: $color-nero;
    background: $color-nero;
    padding-top: 9px;
    line-height: 119%;
  }

  &.btn-small {
    padding-top: 4px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: $font-size-small;
  }

  &.btn-primary-inverted {
    color: $color-white;
    background-color: $primary-active-color;
    border-color: $primary-active-color;

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  &.btn-default-inverted {
    background-color: $color-nero;
    color: $color-white;
    border: solid 1px $color-nero;

    &:hover {
      background-color: $color-white;
      color: $color-nero;
    }
  }

  &.btn-warning {
    color: $color-white;
    background-color: $primary-active-color;
    border-color: $primary-active-color;

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  &.btn-white {
    background-color: $color-white;
    color: $color-nero;
    border: solid 0.5px $color-nero;

    &:hover {
      color: $color-dark-grey;
      border: solid 0.5px $color-dark-grey;
    }
  }

  &.btn-big {
    background: cdn("/images/icon-next-thick.svg") no-repeat $color-nero;
    background-size: 6px;
    background-position: right 8px center;
    padding-right: 22px;
    font-size: 18px;
  }
}

.unimportant {
  // color: $color-grey;
}

input:focus,
.form-control:focus,
*:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

#request-block {
  position: absolute;
  z-index: 10;
  right: 15px;
  top: 0px;
  width: 320px;

  .title {
    background-color: $primary-active-color;

    @extend .barrow-dark;

    h2 {
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 16px;
      color: $color-white;
    }
  }

  .content {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba($color-white, $alpha);

    p {
      padding-top: 15px;
      padding-bottom: 20px;
    }

    .btn {
      border-color: $color-light-grey;
      color: $color-grey;
      background-color: $color-white;
      clear: both;
      display: block;
      margin: 4px auto;
      max-width: 50%;
      min-width: 100px;

      &:hover {
        background-color: $primary-active-color;
        border-color: $primary-active-color;
        color: $color-white;
      }
    }
  }
}

.popover {
  z-index: 1060;
}

.container-upcoming-tour {
  position: absolute;
  z-index: 100;
  background-color: transparent;
  width: 100vw;

  .col-upcoming-tour {
    background-color: rgba($color-white, $dark-alpha);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .h2 {
    font-size: 28px;
  }

  .btn-primary {
    margin-bottom: 20px;

    @extend .barrow;
  }

  p {
    display: inline;
    font-family: "proxima", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    font-weight: $font-weight-light;
  }
}

#xmas-rules.modal,
.delete-review.modal,
.delete-registration.modal,
#request-nearby.modal,
#request-guide.modal,
#modal-messages.modal,
#profile-popover.modal,
.mobile-call.modal {
  h3 {
    font-size: 40px !important;
    margin-top: 15px;
  }

  .modal-content {
    @include when-xs {
      height: 100%;
    }


    @include box-shadow(none !important);

    background-color: transparent;
    border: none !important;

    .modal-header {
      background-color: transparent;

      .close {
        opacity: $dark-alpha;

        &:hover {
          opacity: 1;
        }
      }

      border: none !important;
    }

    .modal-body {
      padding-top: 0px;
      padding-bottom: 0px;

      > div > div {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      background-color: $color-off-white;
      max-height: calc(100vh - 80px);

      @include when-xs {
        height: 100vh;
      }


      p {
        margin-bottom: 0;
      }

      overflow-y: visible;

      .close-button {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .aside {
        background-color: $color-white;

        @media (min-width: 1085px) {
          min-width: 310px;
        }
      }
    }

    .btn-default {
      background-color: $color-off-black;
      color: $color-white;
      opacity: 0.7;

      &:hover {
        color: $color-white;
        opacity: 1;
      }
    }

    .popover {
      top: 0px !important;
      left: 60px !important;
    }
  }

  .has-error .form-control {
    border: solid 1px $color-warning !important;
  }

  .has-error .input-group-addon {
    background-color: $color-white !important;
    border-color: $color-warning !important;
  }
}

#profile-popover.modal {
  .modal-content
  .modal-body {
    padding: 0 10px;
  }
}

#xmas-rules.modal,
#profile-popover.modal,
#profile-popover.modal,
#register-user.modal,
#register-selfinvite.modal,
#login-user.modal {
  .modal-dialog {
    .modal-body {
      @include when-xs {
        height: calc(95vh - 44PX);
        max-height: 500px;
      }


      > .row {
        @include when-sm {
          display: flex;
        }
      }
    }
  }

  p {
    font-size: 14px !important;
    line-height: 1.3;
  }

  .balloon-wrapper {
    width: fit-content;

    @include when-xs {
      position: absolute;
      width: 100%;
      left: 0;
      overflow: hidden;
      top: -1px;
    }


    @include when-sm {
      margin-top: 30px;
    }


    .balloon {
      width: auto;

      &:before {
        @include bg-balloon-text(#B7D45A);

        content: '';
        transform: rotate(180deg);
        width: 212.565px;
        height: 217.404px;

        @include when-xs {
          width: 200px;
          height: 160px;
          position: absolute;
          left: -25px;
          bottom: 0;
        }
      }

      .balloon-content {
        @include when-xs {
          z-index: 1;
          padding: 10px;
          width: 150px;
        }


        @include when-sm {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
        }


        span {
          font-family: "proxima", "Helvetica", "Arial", sans-serif;
          font-weight: $font-weight-black;
          font-size: 16px;
          text-transform: uppercase;
          line-height: 1;

          @include when-xs {
            font-size: 12px;
          }
        }

        p {
          font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
          font-size: 58px !important;
          line-height: 1 !important;
          margin-top: 12px;

          @include when-xs {
            font-size: 40px !important;
            line-height: .6 !important;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .left-section {
    background: cdn("/images/onboarding/profile_popover_mob.jpg") no-repeat;
    background-size: cover;
    padding: 10px;

    @include when-sm {
      background: cdn("/images/onboarding/profile_popover.jpg") no-repeat;
      background-size: cover;
    }


    .left-section-inner {
      margin-top: 70px;

      @include when-sm {
        margin-top: 200px;
      }


      h4 {
        font-size: 18px;

        @include when-sm {
          font-size: 22px;
        }
      }

      .text-container {
        &:before {
          content: '';
          background-color: rgba(0, 0, 0, 0.66);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 27%;
          left: 0;

          @media only screen and (max-width: 729px) {
            right: 75px;
          }
        }
      }
    }
  }

  .top-section {
    padding: 30px 20px;

    .img-wrapper {
      width: fit-content;
      margin: auto;

      img {
        height: 125px;
      }

      .placeholder {
        visibility: hidden;
      }

      .user-avatar {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;

        .user-avatar-img {
          width: 100%;
          height: 100%;
          background-color: $color-off-white;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          clip-path: url(#theonepath);
        }
      }
    }
  }

  .right-section {
    .top-section {
      padding: 15px 0;
    }

    .btn-wrapper {
      margin-bottom: 5px;

      p {
        text-transform: none !important;
        line-height: 1;
      }

      &:hover {
        background-color: $color-nero !important;
      }
    }

    .signup-icon {
      width: 40px;
    }

    .app-links {
      .logo-wrapper {
        width: 60px;
        height: 60px;

        img {
          width: 100%;
        }
      }

      ul {
        margin: 0;
        flex-grow: 1;
        text-align: center;
        max-width: calc(100% - 60px);

        a {
          padding: 5px;
          line-height: 0;

          li {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }

  .bottom-section {
    padding: 20px;
  }
}

#xmas-rules.modal {
  .modal-dialog {
    max-width: 345px;

    @include when-sm {
      max-width: 500px;
    }


    .modal-body {
      height: fit-content;

      button.close {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      p {
        line-height: 1.5;
      }
    }
  }
}

.mobile-call.modal {
  .modal-content {
    @include when-xs {
      max-width: 345px;

      .modal-body {
        height: auto;
        background-color: #B7D25A;

        .app-links {
          .logo-wrapper {
            width: 60px;
            height: 60px;

            img {
              width: 100%;
            }
          }

          ul {
            margin: 0;
            flex-grow: 1;
            text-align: center;
            max-width: calc(100% - 60px);

            a {
              padding: 5px;
              line-height: 0;

              li {
                width: 100% !important;
                height: auto !important;
              }
            }
          }
        }
      }
    }
  }
}

#request-nearby.modal {
  @include when-xs {
    .modal-dialog {
      margin: 0px;

      .modal-content {
        background-color: $color-off-white;

        .modal-header {
          position: fixed;
          width: 100vw;
          z-index: 100;
          top: 0;
          background-color: $color-off-white;
        }

        .modal-body {
          max-height: calc(100vh);
          height: calc(100vh);
          overflow-y: auto;

          #request-nearby_request-title {
            margin-top: 30px;
          }

          #request-nearby_request-tips {
            .popover {
              display: block;
              position: fixed;
              left: 0 !important;
              top: 25vh !important;
              max-width: calc(100vw - 20px);
              height: calc(50vh);
            }
          }
        }
      }
    }
  }
}

[data-to-root].register-mobile-layout {
  padding-top: 0;
}

#modal-messages.modal,
#modal-error.modal {
  .modal-dialog {
    width: 250px;

    @include when-xs {
      height: calc(100% - 20px);
    }
  }

  &.modal-error {
    ul, li {
      padding: 0px;
      margin: 0px;
    }

    ul {
      margin-bottom: 10px;

      /* because for some reason the modal button footer is top:-10px?*/
    }
  }

  .modal-content {
    width: 250px;

    @include when-xs {
      height: auto;

      /* to override the 100vh defined above */
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    }


    .modal-body {
      @include when-xs {
        height: auto;

        /* to override the 100vh defined above */
      }
    }
  }

  .modal-header {
    width: 250px;
  }

  .modal-body {
    width: 250px;
    min-height: 80px;
    padding: 20px 20px 0px 20px !important;
    height: auto;
    background-color: $color-white !important;
  }

  .modal-footer {
    width: 250px;
    background-color: $color-white !important;
    border: none !important;
    position: relative;

    // margin-top: -10px;

  }
}

#request-guide.modal {
  .guide-activities h4 {
    border-top: solid 1px $color-lighter-grey;
    padding-top: 15px !important;
    margin-top: 15px !important;
    margin-bottom: 0px;
  }

  .guide-activities h4:first-child {
    border-top: none;
    margin-top: 0px;
    padding-top: 0px !important;
  }

  #modal-guide-details {
    p.review,
    p.price_scheme {
      display: inline-block;
    }

    .detail-lines {
      @include when-sm {
        margin: 0;
      }
    }
  }

  #avatar-sidebar-holder {
    background: transparent;
  }

  #request-title {
    line-height: 100%;
  }
}

.lookalong-modal {
  .modal-dialog {
    width: $lookalong-modal-dialog-width;

    @include when-xs {
      width: $lookalong-modal-dialog-width-xs;
    }
  }

  .modal-content {
    background-color: transparent;

    @include box-shadow(none !important);

    border: none !important;
  }

  .modal-header {
    border: none !important;
    display: none;
  }

  .modal-body {
    background-color: $color-white;

    .close {
      position: absolute;
      right: 0;
      top: 0;
    }

    .modal-image {
      width: $lookalong-modal-image-width;
      height: 115px;
      background-size: cover;
      background-position: center center;
    }

    .modal-main {
      h5 {
        text-transform: none;
        font-size: 14px;
        color: $color-black;
        font-weight: bold;
      }
    }

    br {
      clear: both;
    }
  }

  .modal-footer {
    clear: both;
    border: 0 none;
    background-color: $color-white;
  }

  .btn {
    font-weight: normal;
  }

  .btn-more-options {
    background-image: cdn("/images/icons/icon-tips.png");
    background-repeat: no-repeat;
    float: right;
    width: 27px;
    height: 27px;
    cursor: pointer;
    overflow: hidden;
    margin-top: 2px;
  }

  .more-options {
    clear: both;
    padding-top: 10px;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    display: none;

    li {
      border-top: 1px solid $color-light-grey;
      cursor: pointer;
      padding: 2px 0;
    }
  }

  .loader {
    background-image: url("/images/balls.svg");
    background-size: contain;
    width: 32px;
    height: 32px;
    float: right;
  }
}

.lookalong-modal.lookalong-call-modal {
  .modal-body {
    padding: 0;

    .modal-image {
      float: left;
    }

    .modal-main {
      float: left;
      padding: 15px;
      min-height: 115px;
      width: $lookalong-modal-dialog-width - $lookalong-modal-image-width - 2;

      @include when-xs {
        width: calc(#{$lookalong-modal-dialog-width-xs} - #{$lookalong-modal-image-width} - 2px);
      }


      p {
        font-weight: bold;
      }
    }
  }
}

.lookalong-modal.outgoingcall-modal {
  .btn {
    text-transform: none;
  }

  .btn-cancel {
    background-color: $color-pink;
    border: 0px none;
    color: $color-white;
    float: left;
  }

  .modal-body {
    .modal-main {
      p.msg {
        margin: 0;
        font-weight: normal;
      }
    }
  }

  .modal-footer p {
    text-align: left;
    margin: 0;
  }
}

.lookalong-modal.incomingcall-modal {
  .btn {
    text-transform: none;
    border: 0px none;
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 23px;
    color: $color-white;
  }

  .btn-primary {
    background-color: $color-green;
    background-image: url("/images/call-accept.png");
  }

  .btn-secondary {
    background-color: $color-pink;
    float: left;
    background-image: url("/images/call-decline.png");
  }

  .btn-block {
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }

  .decline-holder {
    float: left;
  }

  .decline-action {
    color: $color-pink;
    display: none;
    padding: 7px 10px;
  }

  .decline-reasons {
    display: (none,);
  }

  .decline-reason {
    text-align: left;
    width: 100%;
    border-top: 1px solid $color-light-grey;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
  }

  .modal-footer {
    padding: 0;
  }

  .lookalong-modal-buttons {
    padding: 15px;
  }

  .btn-more-options {
    float: left;
    margin-left: 5px;
  }

  .more-options {
    margin-top: 0;

    li {
      text-align: left;
      padding-left: 10px;
    }
  }

  .user-block-wrap {
    margin: 0;
  }

  .user-block-form-holder {
    padding: 10px;
  }
}

.lookalong-modal.askreview-modal {
  .modal-header {
    display: block;
  }

  .modal-body {
    padding: 0;

    .modal-image {
      float: left;
    }

    .modal-main {
      float: left;
      padding: 15px;
      min-height: 115px;
      width: $lookalong-modal-dialog-width - $lookalong-modal-image-width - 2;

      @include when-xs {
        width: calc(#{$lookalong-modal-dialog-width-xs} - #{$lookalong-modal-image-width} - 2px);
      }
    }

    p {
      margin: 0;
    }
  }

  .btn-primary {
    float: left;
  }

  .btn-secondary {
    text-transform: none;
    background-color: $color-white;
    border-color: $color-grey;
  }
}

.lookalong-modal.sessionended-modal {
  .modal-body {
    text-align: center;

    .close {
      display: none;
    }

    .modal-image {
      float: none;
      margin: 10px auto;
    }

    .modal-main {
      float: none;
      padding: 0;
      width: auto;
      min-height: 0;

      h5 {
        margin-bottom: 5px;
      }

      p {
        font-weight: normal;
        margin-bottom: 0;
      }

      span {
        font-size: 12px;
        display: block;
        padding-top: 10px;
      }
    }
  }

  .btn {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}

label.radio-select {
  padding: 5px 0px;
  position: relative;
  cursor: pointer;

  &.flexed {
    display: flex;
    padding: 0px;
  }

  input {
    display: none;
  }

  > span {
    margin-left: 32px;
    margin-right: 5px;

    &:after {
      content: " ";
      width: 12px;
      height: 12px;
      background: cdn("/images/icon-check-blue.svg") no-repeat center center;
      background-size: 12px 12px;
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 3;
      display: block;
      opacity: 0.0;

      @include transition(0.15s all ease-in-out);
    }

    &:before {
      content: " ";
      width: 25px;
      height: 25px;
      background-size: 25px 25px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      display: block;
      border: solid 1px $color-lighter-grey;
    }
  }

  &.radio-select-large {
    span {
      display: inline-block;
      margin-left: 42px;

      &:before {
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
        top: 9px;
      }

      &:after {
        width: 30px;
        height: 30px;
        top: 8px;
        left: 0px;
      }
    }
  }

  &.spinner {
    span {
      &:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }

    .fa-spinner {
      color: $color-blue;
    }

    //why does orange take precendence?

  }

  > input:checked + span:after {
    opacity: 1.0;
  }

  &:hover > input + span:after {
    opacity: 0.5;
  }

  &.has-error {
    > span:before {
      border-color: $color-warning;
    }
  }
}

.capabilities-list,
.expertises-list {
  margin-left: 0px;
  margin-bottom: 0px;

  li {
    padding: 0px;
  }

  .cap,
  .exp {
    display: inline-block;
    width: $capability-size;
    height: $capability-size;
    background-size: $capability-size $capability-size;
    background-color: #DADADA;
    font-size: 0px;
    color: transparent;
    margin-right: 6px;

    &.mini {
      width: $capability-size-mini;
      height: $capability-size-mini;
      background-size: $capability-size-mini $capability-size-mini;
      margin-right: 2px;
    }

    &.midi {
      width: $capability-size-midi;
      height: $capability-size-midi;
      background-size: $capability-size-midi $capability-size-midi;
      margin-right: 2px;
    }

    &.not {
      background-color: $color-lighter-grey;
    }
  }

  .exp {
    background-color: $color-blue;
  }

  label {
    width: $capability-size;
    height: $capability-size;

    span {
      width: $capability-size;
      height: $capability-size;
      color: transparent;
    }
  }

  .cap.video_hd,
  input#video_hd + span {
    background-image: cdn("/images/capabilities/hd_72x72.svg");
  }

  .cap.video_fullhd,
  input#video_fullhd + span {
    background-image: cdn("/images/capabilities/fullhd_72x72.svg");
  }

  .cap.video_4k,
  input#video_4k + span {
    background-image: cdn("/images/capabilities/4k_72x72.svg");
  }

  .cap.video_360,
  input#video_360 + span {
    background-image: cdn("/images/capabilities/360_72x72.svg");
  }

  .cap.connection_3g,
  input#connection_3g + span {
    background-image: cdn("/images/capabilities/3g_72x72.svg");
  }

  .cap.connection_4g,
  input#connection_4g + span {
    background-image: cdn("/images/capabilities/4g_72x72.svg");
  }

  .cap.connection_5g,
  input#connection_5g + span {
    background-image: cdn("/images/capabilities/5g_72x72.svg");
  }

  .cap.connection_wifi,
  input#connection_wifi + span {
    background-image: cdn("/images/capabilities/wifi_72x72.svg");
  }

  .cap.transport_bike,
  input#transport_bike + span {
    background-image: cdn("/images/capabilities/bike_72x72.svg");
  }

  .cap.transport_motor,
  input#transport_motor + span {
    background-image: cdn("/images/capabilities/motor_72x72.svg");
  }

  .cap.transport_car,
  input#transport_car + span {
    background-image: cdn("/images/capabilities/car_72x72.svg");
  }

  .cap.transport_boat,
  input#transport_boat + span {
    background-image: cdn("/images/capabilities/boat_72x72.svg");
  }

  .exp.lookalong {
    background-image: url("/images/la-lookalong.svg");
  }

  .exp.takealong {
    background-image: url("/images/la-takealong.svg");
  }

  .exp.learnalong {
    background-image: url("/images/la-learnalong.svg");
  }

  .exp.cookalong {
    background-image: url("/images/la-cookalong.svg");
  }

  .exp.shopalong {
    background-image: url("/images/la-shopalong.svg");
  }

  .exp.workalong {
    background-image: url("/images/la-workalong.svg");
  }
}

#sidebar .capabilities-list {
  .cap,
  .exp {
    background-color: $color-lighter-grey;
  }
}

.social-icons:not(.footer) {
  li {
    position: relative;

    a {
      font-size: 16px;
      position: relative;
      background-color: $color-white;
      width: 20px;
      padding: 5px 5px 5px 15px;

      @include border-radius(7px);

      -webkit-box-shadow: 3px 0px 0px 0px $color-black;
      -moz-box-shadow: 3px 0px 0px 0px $color-black;
      box-shadow: 3px 0px 0px 0px $color-black;
      color: $color-nero;

      &:hover {
        background-color: $primary-active-color;
      }
    }

    margin-right: -17px;

    &:first-child {
      &::before {
        content: " ";
        background-size: 10px 5.5px;
        width: 10px;
        height: 5.5px;
        background-image: cdn("/images/fliebel.svg");
        position: absolute;
        bottom: -6.5px;
        left: 7px;
      }

      a {
        padding-left: 20px;
      }
    }
  }
}

#sidebar .social-icons, .community-option .social-icons {
  li {
    a {
      font-size: 10px;
      width: 14px;
      background-color: #818181;
      color: $color-white;

      @include border-radius(3px);

      -webkit-box-shadow: 2px 0px 0px 0px $color-white;
      -moz-box-shadow: 2px 0px 0px 0px $color-white;
      box-shadow: 2px 0px 0px 0px $color-white;

      &:hover {
        background: $color-nero;
      }
    }

    &:first-child {
      &::before {
        bottom: -4.5px;
        left: 7px;
        background-image: cdn("/images/fliebel-grey.svg");
        background-repeat: no-repeat;
      }

      a {
        padding-left: 12px;
      }

      &:hover {
        &::before {
          bottom: -4.5px;
          left: 7px;
          background-image: cdn("/images/fliebel-nero.svg");
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.popover[role=tooltip] {
  color: $color-white;
  background-color: $color-darker-grey;
  border: none;
  position: absolute;

  & > .arrow {
    display: none;
  }

  .remove {
    position: absolute;
    z-index: 20;
    right: 5px;
    top: 5px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1.0;
    }
  }
}

.online-indicator {
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 0px;
  color: $color-white;
  font-size: 12px;
  margin: 0px;
  padding: 4px 7px 2px;
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

  &.online {
    background-color: $color-green;
  }

  &.offline {
    background-color: $primary-color;
  }
}

.user-block-wrap {
  margin-top: 20px;

  .user-block_label-content {
    display: none;
  }
}

.user-block-form-holder {
  display: none;
  text-align: center;

  textarea {
    width: 100%;
    height: 60px;
  }

  .btn-block {
    background-color: $color-blue;
    color: $color-white;
  }
}

#main-container {
  > .container {
    min-height: calc(100vh - 77px - 490px);
  }
}

.d-flex {
  display: flex;
}

.share-icons li {
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

#modal-guide-details {
  padding-bottom: 100px !important;
}

.place-request-button-wrapper {
  position: absolute;
  right: 0;
  bottom: 30px;
  width: 100%;
}

#place-request-button,
#request-nearby_place-request-button {
  width: 80%;
  color: $color-light-pink;
  font-size: 36px;
  text-align: left;
  background: $color-black;
  border: none;
  padding: 9px 15px 0px;
  line-height: 1;
  font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;

  &:hover {
    background-color: $color-light-pink;
    color: $color-white;
  }
}

.spinner {
  position: relative;

  img {
    position: absolute;
    right: 0;
    width: 15px;
    bottom: 0;
  }
}

.request-max-days {
  margin: 30px 0 5px;

  p {
    color: $color-grey;
    font-size: 16px !important;
    line-height: 1;
    margin: 0;
  }
}

.location-note {
  p {
    color: $color-grey;
    line-height: 1.5  !important;
    font-size: 18px !important;
  }
}

#modal-request-details {
  textarea {
    min-height: 150px;
  }
}

#request-nearby.modal,
#request-guide.modal {
  .modal-body .row {
    display: flex;
  }

  h5#request-subtitle {
    font-size: 16px;
    color: $color-lighter-grey;
    text-transform: none;
  }

  h3 {
    color: $color-light-pink;
    display: block;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
    border: none;
  }

  p.h5 {
    color: $color-dark-grey;
    font-family: "bebasNeuebold", "Helvetica", "Arial", sans-serif;
    letter-spacing: 1px;
    font-size: 20px;
  }

  #request-nearby_request-tips {
    text-transform: uppercase;
    background: $color-light-grey;
    color: $color-white;
    padding: 8px 8px 4px 8px;
    font-family: "bebasNeueBook", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1;
    width: fit-content;
    margin-left: auto;

    .popover {
      display: block;
      font-size: 11px;
      width: 270px;
      margin-left: 0;
    }
  }

  .dropdown-menu .inner {
    opacity: 1;
  }
}

.daterangepicker.show-calendar {
  z-index: 999999 !important;
}

.call-history {
  table {
    th {
      &:not(:first-child) {
        width: calc(70% / 3);
      }
    }
  }
}

/* ----------------------- some generic styles --------------------- */

.currencies-image {
  img {
    width: 100%;
  }
}

.currencies-list {
  color: $color-lighter-grey;
}

* {
  -webkit-font-smoothing: antialiased !important;

  /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale !important;

  /* Firefox */
}

/* ----------------------- webinar beta banner --------------------- */

.webinar-beta-banner {
  background-color: $color-white;
  border-bottom: 1px solid #F2F2F2;
  position: fixed;
  z-index: 9;
  height: 50px;
  padding: 8px 0;
  top: 56px;

  @include when-xs {
    width: 100vw;
    padding: 12px 10px 7px 53px;
    padding-left: calc((100vw - #{$container-xs}) / 2 + 53px);
    padding-right: calc((100vw - #{$container-xs}) / 2 + 10px);
    line-height: 1;
  }


  @media (max-width: 374px) {
    padding-left: 60px;
  }

  @include when-sm {
    width: 100vw;
    padding: 15px 0 7px;
    padding-left: calc((100vw - #{$container-sm}) / 2 + 10px);
    top: 54px;
  }


  @include when-md {
    padding: 13px 0 7px;
    font-size: 20px;
    top: 77px;
    height: 60px;
    padding-left: calc((100vw - #{$container-md}) / 2 + 10px);
  }


  @include when-lg {
    padding: 10px 0;
    padding-left: calc((100vw - #{$container-lg}) / 2 + 10px);
    top: 101px;
    height: 75px;
  }


  img {
    height: 100%;
    width: auto;
    margin-right: 10px;

    @include when-xs {
      position: absolute;
      left: calc((100vw - #{$container-xs}) / 2 + 10px);
      height: 30px;
    }
  }

  span {
    color: #8698C0;
  }
}

.webinar-menu-container {
  &.webinar-beta {
    margin-top: 105px !important;

    @include when-md {
      margin-top: 135px !important;
    }


    @include when-lg {
      margin-top: 176px !important;
    }


    .webinar-menu,
    .menu-sidebar {
      top: 105px !important;

      @include when-md {
        top: 135px !important;
      }


      @include when-lg {
        top: 176px !important;
      }
    }
  }
}
