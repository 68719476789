/*the bold font SHOULD have been set as the same font family, because you want to just set the font-weight, not change the font family for a different weight */
@font-face {
  font-family: proxima;
  font-display: swap;
  src: cdn("/fonts/proxima/proxima_bold.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/proxima/proxima_bold.eot?#iefix") format("embedded-opentype"), cdn("/fonts/proxima/proxima_bold.woff") format("woff"), cdn("/fonts/proxima/proxima_bold.ttf") format("truetype"), cdn("/fonts/proxima/proxima_bold.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-bold;
}

@font-face {
  font-family: proxima;
  font-display: swap;
  src: cdn("/fonts/proxima/proxima.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/proxima/proxima.eot?#iefix") format("embedded-opentype"), cdn("/fonts/proxima/proxima.woff") format("woff"), cdn("/fonts/proxima/proxima.ttf") format("truetype"), cdn("/fonts/proxima/proxima.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-normal;
}

@font-face {
  font-family: proxima;
  font-display: swap;
  src: cdn("/fonts/proxima/proxima_light.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/proxima/proxima_light.eot?#iefix") format("embedded-opentype"), cdn("/fonts/proxima/proxima_light.woff") format("woff"), cdn("/fonts/proxima/proxima_light.ttf") format("truetype"), cdn("/fonts/proxima/proxima_light.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-light;
}

@font-face {
  font-family: proxima;
  font-display: swap;
  src: cdn("/fonts/proxima/proxima_black.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/proxima/proxima_black.eot?#iefix") format("embedded-opentype"), cdn("/fonts/proxima/proxima_black.woff") format("woff"), cdn("/fonts/proxima/proxima_black.ttf") format("truetype"), cdn("/fonts/proxima/proxima_black.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-black;
}

@font-face {
  font-family: bebas;
  font-display: swap;
  src: cdn("/fonts/bebasNeueBook/BebasNeueBook.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/bebasNeueBook/BebasNeueBook.eot?#iefix") format("embedded-opentype"), cdn("/fonts/bebasNeueBook/BebasNeueBook.woff") format("woff"), cdn("/fonts/bebasNeueBook/BebasNeueBook.ttf") format("truetype"), cdn("/fonts/bebasNeueBook/BebasNeueBook.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-light;
}

@font-face {
  font-family: bebas;
  font-display: swap;
  src: cdn("/fonts/bebasNeuebold/BebasNeueBold.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/bebasNeuebold/BebasNeueBold.eot?#iefix") format("embedded-opentype"), cdn("/fonts/bebasNeuebold/BebasNeueBold.woff") format("woff"), cdn("/fonts/bebasNeuebold/BebasNeueBold.ttf") format("truetype"), cdn("/fonts/bebasNeuebold/BebasNeueBold.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-bold;
}

@font-face {
  font-family: bebasNeueBook;
  font-display: swap;
  src: cdn("/fonts/bebasNeueBook/BebasNeueBook.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/bebasNeueBook/BebasNeueBook.eot?#iefix") format("embedded-opentype"), cdn("/fonts/bebasNeueBook/BebasNeueBook.woff") format("woff"), cdn("/fonts/bebasNeueBook/BebasNeueBook.ttf") format("truetype"), cdn("/fonts/bebasNeueBook/BebasNeueBook.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-light;
}

@font-face {
  font-family: bebasNeuebold;
  font-display: swap;
  src: cdn("/fonts/bebasNeuebold/BebasNeueBold.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/bebasNeuebold/BebasNeueBold.eot?#iefix") format("embedded-opentype"), cdn("/fonts/bebasNeuebold/BebasNeueBold.woff") format("woff"), cdn("/fonts/bebasNeuebold/BebasNeueBold.ttf") format("truetype"), cdn("/fonts/bebasNeuebold/BebasNeueBold.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-normal;
}

@font-face {
  font-family: icomoon;
  font-display: swap;
  src: cdn("/fonts/icomoon/icomoon.eot");
  src: cdn("/fonts/icomoon/icomoon.eot#iefix") format("embedded-opentype"), cdn("/fonts/icomoon/icomoon.ttf") format("truetype"), cdn("/fonts/icomoon/icomoon.woff") format("woff"), cdn("/fonts/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font-family: icomoon;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-plus:before {
  content: "\f067";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f09a";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before, .fa-angle:before {
  content: "\f105";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-instagram:before {
  content: "\f16d";
}
